import { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isArray } from "lodash";

const useQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const navigateWithSearchQuery = useCallback(() => {
    navigate(
      {
        pathname: location.pathname,
        search: query.toString(),
      },
      {
        replace: true,
      },
    );
  }, [location.pathname, navigate, query]);

  const setQuery = useCallback(
    (name, value) => {
      if (Array.isArray(value)) {
        value.forEach(val => query.append(name, val));
      } else {
        query.set(name, value);
      }

      navigateWithSearchQuery();
    },
    [navigateWithSearchQuery, query],
  );

  const deleteQuery = useCallback(
    names => {
      if (isArray(names)) {
        names.forEach(name => query.delete(name));
      } else {
        query.delete(names);
      }

      navigateWithSearchQuery();
    },
    [navigateWithSearchQuery, query],
  );

  return {
    query,
    setQuery,
    deleteQuery,
  };
};

export default useQuery;
