import styled, { css } from "styled-components/macro";
import { darkGrey3, white, darkGrey6, darkGrey1 } from "../../constants/colors";

const textStyles = css`
   {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    text-transform: uppercase;
    color: ${darkGrey3};
    fill: ${darkGrey3} !important;
  }
`;

export const StyledChartWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "yaxis chart"
    "xaxis xaxis";
`;

export const StyledChart = styled.div`
  grid-area: chart;

  ${props =>
    props.bubble &&
    css`
      .apexcharts-canvas {
        background: ${white};
        margin-left: 0.5rem;
      }
    `}
`;

export const StyledYAxisTitle = styled.div`
  grid-area: yaxis;
  ${textStyles};
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  padding: 1rem 0;
`;

export const StyledXAxisTitle = styled.div`
  grid-area: xaxis;
  ${textStyles};
  margin-top: -0.5rem;
`;

export const StyledChartTooltipContainer = styled.div`
  background-color: ${darkGrey6};
  padding: 10px 21px;
`;

export const StyledChartTooltipTitle = styled.div`
  color: ${white};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const StyledChartTooltipOFName = styled.div`
  color: ${darkGrey1};
`;

export const StyledChartTooltipOFValue = styled.div`
  margin: 2px 0 8px 0;
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;
