import { LoadingOutlined } from "@ant-design/icons";
import { StyledLoading } from "./Loading.styled";

const Loading = () => (
  <StyledLoading>
    <LoadingOutlined style={{ fontSize: 24 }} />
  </StyledLoading>
);

export default Loading;
