import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { StyledStatisticWrapper } from "../../../../components/StatisticCard/styles";
import { StyledFailedCheckIcon } from "../../../../components/Icons";
import {
  StyledErrorCardBody,
  StyledErrorCardContent,
  StyledErrorCardLink,
  StyledErrorCardList,
  StyledErrorCardListItem,
  StyledErrorCardListItemBody,
  StyledErrorCardText,
  StyledErrorCardTitle,
  StyledErrorConstraintName,
  StyledErrorConstraintNumber,
  StyledErrorConstraintPercent,
} from "./styles";
import * as routes from "../../../../constants/routes";

const ErrorMessagesCard = ({
  constraints,
  failedConstraints = {},
  errorMessage = "",
}) => {
  const navigate = useNavigate();
  const { workbook_id: wId } = useParams();
  const urlPath = `${routes.WORKBOOKS}/${wId}`;

  const getConstraintsData = () => {
    let errorsSum = 0;
    const constraintsList = [];
    for (const [key, value] of Object.entries(failedConstraints)) {
      errorsSum += value;
      constraintsList.push({
        name: constraints?.[key]?.title,
        numErrors: value,
      });
    }

    return constraintsList.map(constraint => ({
      ...constraint,
      percent: (constraint.numErrors * 100) / errorsSum,
    }));
  };

  const errorData = getConstraintsData();

  return (
    <StyledStatisticWrapper>
      <StyledErrorCardContent>
        <StyledFailedCheckIcon />
        <StyledErrorCardBody>
          <StyledErrorCardTitle>
            Portfolio Generation Failed
          </StyledErrorCardTitle>
          <StyledErrorCardList>
            {errorData.map(({ name, numErrors, percent }) => (
              <StyledErrorCardListItem key={name}>
                <StyledErrorCardListItemBody>
                  <StyledErrorConstraintName>{`"${name}"`}</StyledErrorConstraintName>
                  failed
                  <StyledErrorConstraintNumber>
                    {numErrors || 0}
                  </StyledErrorConstraintNumber>
                  time(s)
                  <StyledErrorConstraintPercent>
                    {`(${Number(percent).toFixed(2)}%)`}
                  </StyledErrorConstraintPercent>
                </StyledErrorCardListItemBody>
              </StyledErrorCardListItem>
            ))}
          </StyledErrorCardList>

          {errorMessage.length > 0 && (
            <StyledErrorCardText>{errorMessage}</StyledErrorCardText>
          )}

          <StyledErrorCardLink
            onClick={() => navigate(`${urlPath}/${routes.CONSTRAINTS}`)}
          >
            Back To Optimization Arguments
          </StyledErrorCardLink>
        </StyledErrorCardBody>
      </StyledErrorCardContent>
    </StyledStatisticWrapper>
  );
};

ErrorMessagesCard.propTypes = {
  constraints: PropTypes.shape(),
  failedConstraints: PropTypes.shape(),
  errorMessage: PropTypes.string,
};

export default ErrorMessagesCard;
