import PropTypes from "prop-types";
import ModalDialog from "../../../components/Modals/ModalDialog";
import StyledButton from "../../../components/Buttons/Button.styled";
import {
  StyledModalPrimaryText,
  StyledModalBoldText,
  StyledModalButtonsContainer,
} from "../../../components/Modals/ModalDialog.styled";

const DeleteResultModal = ({
  isOpen = false,
  resultName,
  onClose,
  onDelete,
}) => {
  return (
    <ModalDialog isOpen={isOpen} onClose={onClose} title="Remove Result">
      <div>
        <StyledModalPrimaryText>
          Are you sure you want to remove this result
          <StyledModalBoldText> &quot;{resultName}&quot;</StyledModalBoldText> ?
        </StyledModalPrimaryText>
      </div>
      <StyledModalButtonsContainer>
        <StyledButton onClick={onClose} type="secondary" $autoWidth>
          Cancel
        </StyledButton>
        <StyledButton
          data-cy="delete-workbook-button"
          key="submit"
          type="primary"
          onClick={onDelete}
          $autoWidth
        >
          Remove
        </StyledButton>
      </StyledModalButtonsContainer>
    </ModalDialog>
  );
};

DeleteResultModal.propTypes = {
  isOpen: PropTypes.bool,
  resultName: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteResultModal;
