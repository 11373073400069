import PropTypes from "prop-types";
import { Progress } from "antd";
import { StyledPortfolioProgress } from "../../../styled";
import {
  StyledFailedCheckIcon,
  StyledCancelCheckIcon,
  StyledCheckmarkCircleIcon,
  StyledCheckmarkCircleIcon2,
} from "../../../../components/Icons";
import { PROGRESS_STATES } from "../../../../constants/constraints";
import { blue } from "../../../../constants/colors";

const BuildProgress = ({ percent = 0, buildStatus = "", isActive = false }) => {
  const isInProgress =
    buildStatus === PROGRESS_STATES.inProgress ||
    buildStatus === PROGRESS_STATES.queued;
  const isError = buildStatus === PROGRESS_STATES.failed;
  const isCanceled = buildStatus === PROGRESS_STATES.cancelled;
  const isFinished = buildStatus === PROGRESS_STATES.finished;

  return (
    <StyledPortfolioProgress>
      {isInProgress && (
        <Progress
          type="circle"
          percent={Number(percent).toFixed(0)}
          status="normal"
          size={16}
          strokeColor={blue}
          showInfo={false}
        />
      )}
      {isFinished &&
        (isActive ? (
          <StyledCheckmarkCircleIcon2 width={16} />
        ) : (
          <StyledCheckmarkCircleIcon width={16} />
        ))}
      {isError && <StyledFailedCheckIcon width={16} />}
      {isCanceled && <StyledCancelCheckIcon width={16} />}
    </StyledPortfolioProgress>
  );
};

BuildProgress.propTypes = {
  percent: PropTypes.number,
  buildStatus: PropTypes.string,
  isActive: PropTypes.bool,
};

export default BuildProgress;
