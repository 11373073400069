import { PageContextProvider as ResultsLayoutContextProvider } from "../ResultLayout/context";
import { PageContextProvider as ResultsListContextProvider } from "../../features/ResultList/context";
import { PageContextProvider as WorkbooksContextProvider } from "../../features/Workbooks/context";
import WorkbookContent from "./content";

const WorkbookLayout = () => (
  <WorkbooksContextProvider>
    <ResultsListContextProvider>
      <ResultsLayoutContextProvider>
        <WorkbookContent />
      </ResultsLayoutContextProvider>
    </ResultsListContextProvider>
  </WorkbooksContextProvider>
);

export default WorkbookLayout;
