const divideNumberBy = (num, base, fractionDigits = 2) => {
  const number = (num / base).toFixed(fractionDigits);
  const [integerPart, decimalPart] = number.toString().split(".");
  if (!parseInt(decimalPart, 10)) {
    return `${integerPart}`;
  }
  return `${integerPart}.${decimalPart}`;
};

export default divideNumberBy;
