import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import ReactApexChart from "react-apexcharts";
import StyledCard from "../../../../../components/Card";
import { StyledChartWrapper } from "../../../styles";

const { Option } = Select;

const DistributionChartCard = ({ chartsData }) => {
  const { pieChartData = [], onChangeAttribute, selectedColumn } = chartsData;
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const chartSeries = pieChartData.map(({ value }) => value);
    const chartLabels = pieChartData.map(({ type }) => type);
    const newOptions = {
      chart: {
        type: "pie",
        width: "100%",
        height: 350,
      },
      dataLabels: {
        style: {
          fontSize: "12px",
          fontFamily: "Onest,'Open Sans',Helvetica,Arial,sans-serif",
        },
      },
      legend: {
        show: true,
        position: "bottom",
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: "Onest,'Open Sans',Helvetica,Arial,sans-serif",
        },
        y: {
          formatter: val => `${val}%`,
        },
      },
      labels: chartLabels,
    };

    setOptions(newOptions);
    setSeries(chartSeries);
  }, [pieChartData]);

  const distActions = (
    <Select
      showSearch
      placeholder="Select an attribute"
      optionFilterProp="children"
      value={selectedColumn}
      onChange={onChangeAttribute}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      <Option value="issuer">Obligor</Option>
      <Option value="industry_group">Industry</Option>
    </Select>
  );

  return (
    <StyledCard
      grow
      dataCy="result-pieChart"
      title="Distribution"
      actions={distActions}
    >
      <StyledChartWrapper>
        <ReactApexChart
          type="pie"
          options={options}
          series={series}
          height={350}
        />
      </StyledChartWrapper>
    </StyledCard>
  );
};

DistributionChartCard.propTypes = {
  chartsData: PropTypes.shape(),
};

export default DistributionChartCard;
