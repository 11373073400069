import { Component } from "react";
import * as Sentry from "@sentry/browser";
import childrenType from "../../propTypes/childrenType";
import { showMessageError } from "../../utils/messages";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });

    if (process.env.NODE_ENV !== "development") {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }

    if (window.Cypress) {
      showMessageError({ text: JSON.stringify(error) });
      showMessageError({ text: JSON.stringify(errorInfo) });
    }

    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: childrenType,
};

export default ErrorBoundary;
