import { useCallback, useEffect, useState, useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router";
import { Skeleton } from "antd";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  StyledObjectiveItemWrapper,
  StyledObjectiveListWrapper,
  StyledObjectiveSelectWrapper,
  StyledSkeletonContainer,
} from "../styles";
import { Select } from "../../../components/Form/Select/Select";
import RadioGroup from "../../../components/Form/RadioGroup";
import {
  MAX_COMPOSITE_OBJECTIVES_NUMBER,
  OBJECTIVE_ORDER,
} from "../../../constants/objectives";
import NumberInput from "../../../components/Form/NumberInput";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import { StyledCloseIcon } from "../../../components/Icons";

const ObjectiveSelect = ({
  defaultObjective = [],
  constraintsData,
  disabled = false,
  hideSelect = false,
}) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const { selectedPortfolioUUID, objective, setObjective, saveObjective } =
    constraintsData;
  const { objectives } = useOutletContext();
  const [selectedColumns, setSelectedColumns] = useState({});

  useEffect(() => {
    const selColumns = {};
    objective?.forEach(obj => {
      const { name, order, priorityFactor } = obj || {};
      selColumns[name] = { order, priorityFactor };
    });
    setSelectedColumns(selColumns);
  }, [objective, selectedPortfolioUUID]);

  useEffect(() => {
    setObjective(selectedPortfolioUUID, defaultObjective);
  }, [selectedPortfolioUUID, defaultObjective, setObjective]);

  const availableColumns = _.map(objectives, (value, key) => ({
    label: value,
    value: key,
  })).filter(({ value }) => !selectedColumns[value]);

  const getObjectiveArray = useCallback(
    dataObj => {
      const newObjectives = [];
      _.forEach(dataObj, ({ order, priorityFactor }, key) => {
        if (key.includes("custom_weighted")) {
          const sourceColumn = key.replace("custom_weighted_", "");
          const newObjective = {
            name: key,
            order,
            priorityFactor,
            title: objectives[key],
            sourceColumn,
          };

          newObjectives.push(newObjective);
        } else {
          newObjectives.push({ name: key, order, priorityFactor });
        }
      });
      return newObjectives;
    },
    [objectives],
  );

  const handleSave = useCallback(
    (objectiveName, name, value) => {
      const newSelectedColumns = {
        ...selectedColumns,
        [objectiveName]: { ...selectedColumns[objectiveName], [name]: value },
      };

      const newObjectives = getObjectiveArray(newSelectedColumns);
      setUpdateLoading(true);
      saveObjective(
        newObjectives,
        () => setSelectedColumns(newSelectedColumns),
        () => setUpdateLoading(false),
      );
    },
    [getObjectiveArray, saveObjective, selectedColumns],
  );

  const debounce = useMemo(
    () =>
      _.debounce(
        (objectiveName, name, value) => handleSave(objectiveName, name, value),
        1000,
      ),
    [handleSave],
  );

  const handleInputChange = useCallback(
    (objectiveName, name, value) => {
      debounce(objectiveName, name, value);
    },
    [debounce],
  );

  const onAddColumn = useCallback(
    name => {
      const newSelectedColumns = {
        ...selectedColumns,
        [name]: { order: "min", priorityFactor: 1 },
      };

      const newObjectives = getObjectiveArray(newSelectedColumns);
      setUpdateLoading(true);
      saveObjective(
        newObjectives,
        () => setSelectedColumns(newSelectedColumns),
        () => setUpdateLoading(false),
      );
    },
    [selectedColumns, getObjectiveArray, saveObjective],
  );

  const onReplaceColumn = useCallback(
    (fromName, toName) => {
      const newData = { ...selectedColumns };
      delete Object.assign(newData, { [toName]: newData[fromName] })[fromName];
      const newObjectives = getObjectiveArray(newData);
      setUpdateLoading(true);
      saveObjective(
        newObjectives,
        () => setSelectedColumns(newData),
        () => setUpdateLoading(false),
      );
    },
    [getObjectiveArray, saveObjective, selectedColumns],
  );

  const onDeleteColumn = useCallback(
    name => {
      const newData = { ...selectedColumns };
      delete newData[name];
      const newObjectives = getObjectiveArray(newData);
      setDeleteLoading(true);
      saveObjective(
        newObjectives,
        () => setSelectedColumns(newData),
        () => setDeleteLoading(false),
      );
    },
    [selectedColumns, getObjectiveArray, saveObjective],
  );

  const showAddObjectiveSelect =
    objective?.length < MAX_COMPOSITE_OBJECTIVES_NUMBER - 1 && !hideSelect;

  if (updateLoading || !objective?.length || !availableColumns?.length) {
    return (
      <StyledSkeletonContainer>
        <Skeleton.Input active />
        <Skeleton.Input active />
      </StyledSkeletonContainer>
    );
  }

  const hasSeveralOptions = Object.keys(selectedColumns).length > 1;

  return (
    <StyledObjectiveSelectWrapper>
      <StyledObjectiveListWrapper>
        {_.map(selectedColumns, ({ order, priorityFactor }, name) => (
          <StyledObjectiveItemWrapper key={name}>
            <Select
              menuPortalTarget={document.body}
              onChange={value => onReplaceColumn(name, value)}
              options={[
                ...availableColumns,
                { label: objectives[name] || name, value: name },
              ]}
              placeholder="Select column"
              isOptionDisabled={option => !option?.value}
              value={name}
              disabled={deleteLoading || updateLoading || disabled}
            />
            <RadioGroup
              options={OBJECTIVE_ORDER}
              onChange={value => handleInputChange(name, "order", value)}
              value={order}
              direction="horizontal"
              disabled={updateLoading || deleteLoading || disabled}
            />
            {hasSeveralOptions && (
              <>
                <NumberInput
                  type="percent"
                  min={0}
                  max={100}
                  title=""
                  defaultValue={priorityFactor || 0}
                  disabled={updateLoading || deleteLoading || disabled}
                  name="priorityFactor"
                  onChange={(fieldName, attr, value) =>
                    handleInputChange(name, "priorityFactor", value)
                  }
                />
                {deleteLoading ? (
                  <div>
                    <LoadingOutlined />
                  </div>
                ) : (
                  <StyledTextButton
                    $transparent
                    $noPadding
                    shape="circle"
                    data-cy="remove-constraints"
                    $autoHeight
                    icon={<StyledCloseIcon />}
                    onClick={() => onDeleteColumn(name)}
                    disabled={updateLoading || disabled}
                  />
                )}
              </>
            )}
          </StyledObjectiveItemWrapper>
        ))}
        {showAddObjectiveSelect &&
          availableColumns?.length > 0 &&
          !hideSelect && (
            <StyledObjectiveItemWrapper>
              <Select
                menuPortalTarget={document.body}
                onChange={onAddColumn}
                options={availableColumns}
                placeholder="Select column"
                isOptionDisabled={option => !option?.value}
                disabled={deleteLoading || updateLoading || disabled}
              />
            </StyledObjectiveItemWrapper>
          )}
      </StyledObjectiveListWrapper>
    </StyledObjectiveSelectWrapper>
  );
};

ObjectiveSelect.propTypes = {
  defaultObjective: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      order: PropTypes.string,
    }),
  ),
  constraintsData: PropTypes.shape(),
  disabled: PropTypes.bool,
  hideSelect: PropTypes.bool,
};

export default ObjectiveSelect;
