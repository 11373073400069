import PropTypes from "prop-types";
import {
  StyledDoubleArrowUpIcon,
  StyledDoubleArrowDownIcon,
  StyledEmojiEventIcon,
  StyledArrowCircleLeftIcon,
} from "../Icons";
import {
  StyledStatisticLabel,
  StyledStatisticText,
  StyledStatisticWrapper,
  StyledDiffItem,
  StyledDiffItemsWrapper,
} from "./styles";

const handleGetDiffValue = (value, valueToCompare) => {
  const currValueNum = Number(value.replace(/[^\d.]*/g, ""));
  const valueToCompareNum = Number(valueToCompare.replace(/[^\d.]*/g, ""));
  const diffValue = currValueNum - valueToCompareNum;
  return Number(diffValue.toFixed(2));
};

const StatisticCard = ({
  label = "",
  value = "",
  prevValue = "",
  lastStepValue = "",
  showPrevStepDiff = false,
  showLastStepDiff = false,
  showDiff = false,
  dataCy = "",
}) => {
  const prevValueDiff = showPrevStepDiff
    ? handleGetDiffValue(value, prevValue)
    : false;

  const lastStepDiff =
    showLastStepDiff && handleGetDiffValue(lastStepValue, value);

  return (
    <StyledStatisticWrapper>
      <StyledStatisticLabel>{label}</StyledStatisticLabel>
      <StyledStatisticText data-cy={dataCy}>{value}</StyledStatisticText>
      <StyledDiffItemsWrapper>
        {showDiff && showPrevStepDiff && (
          <StyledDiffItem>
            <StyledArrowCircleLeftIcon />
            {Boolean(prevValueDiff) &&
              (prevValueDiff < 0 ? (
                <StyledDoubleArrowDownIcon />
              ) : (
                <StyledDoubleArrowUpIcon />
              ))}
            <div>{prevValueDiff}</div>
          </StyledDiffItem>
        )}
        {showDiff && showLastStepDiff && (
          <StyledDiffItem>
            <StyledEmojiEventIcon />
            {Boolean(lastStepDiff) &&
              (lastStepDiff < 0 ? (
                <StyledDoubleArrowDownIcon />
              ) : (
                <StyledDoubleArrowUpIcon />
              ))}
            <div>{lastStepDiff}</div>
          </StyledDiffItem>
        )}
      </StyledDiffItemsWrapper>
    </StyledStatisticWrapper>
  );
};

StatisticCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  prevValue: PropTypes.string,
  lastStepValue: PropTypes.string,
  showPrevStepDiff: PropTypes.bool,
  showLastStepDiff: PropTypes.bool,
  showDiff: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default StatisticCard;
