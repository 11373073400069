import styled from "styled-components/macro";
import { darkGrey5 } from "../../../constants/colors";

export const StyledToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr;
  gap: 0.5rem;
  align-items: center;
  margin-right: 1rem;
`;

export const StyledToggleLabel = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: ${darkGrey5};
`;
