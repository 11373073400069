import { useCallback, useEffect, useState, useMemo } from "react";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import NumberInput from "../../../components/Form/NumberInput";
import {
  StyledActionsRow,
  StyledConstraintItemActions,
  StyledConstraintItemWrapper,
  StyledConstraintTextArea,
  StyledConstraintTextAreaContent,
  StyledLoadingWrapper,
  StyledSkeletonInput,
} from "../styles";
import NumberInputRange from "../../../components/Form/NumberInputRange";
import { Select } from "../../../components/Form/Select/Select";
import {
  CONSTRAINTS_INPUT_TYPE_VALUES,
  CONSTRAINTS_INPUT_TYPES,
} from "../../../constants/objectives";
import { StyledCloseIcon, StyledInfoIcon } from "../../../components/Icons";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import { NEW_CONSTRAINT_KEY } from "../../../constants/constraints";
import { ConstraintPropType, InputPropType } from "./types";

const ConstraintsItem = ({
  isSimple = false,
  canDelete = false,
  readOnly = false,
  dataLoading = false,
  constraint = {},
  loading,
  valueFieldName,
  inputType: initInputType,
  constraintsData,
  attributeError = [],
  handleError = () => {},
  setAttributeError = () => {},
  category,
}) => {
  const {
    isEnabled = false,
    min = null,
    max = null,
    eq: eqVal = null,
    help = "",
    title = "",
    type,
  } = constraint;

  const [status, setStatus] = useState(min <= max ? "default" : "error");
  const currentErrors = attributeError?.filter(
    error => error?.valueFieldName === valueFieldName,
  );

  useEffect(() => {
    if (currentErrors?.length) {
      setStatus("error");
    }
  }, [currentErrors]);

  const {
    hiddenConstraints,
    onSwitchItem,
    onChangeConstraint,
    onUpdateConstraint,
    onUpdateConstraintData,
    onChangeConstraintInputType,
  } = constraintsData;
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    const selOptions = [
      {
        label: title,
        value: valueFieldName === NEW_CONSTRAINT_KEY ? "" : valueFieldName,
      },
    ];

    _.forEach(hiddenConstraints[category], (data, key) => {
      selOptions.push({ label: data.title, value: key });
    });

    setSelectOptions(selOptions);
  }, [category, hiddenConstraints, title, valueFieldName]);

  const [inputType, setInputType] = useState(initInputType);
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);
  const [eqValue, setEqValue] = useState(eqVal);

  useEffect(() => {
    setMinValue(min);
  }, [min]);

  useEffect(() => {
    setMaxValue(max);
  }, [max]);

  useEffect(() => {
    setEqValue(eqVal);
  }, [eqVal]);

  const handleChangeInputType = useCallback(
    value => {
      setInputType(value);
      onChangeConstraintInputType(valueFieldName, value);
    },
    [onChangeConstraintInputType, valueFieldName],
  );

  const handleSuccess = () => {
    setAttributeError([]);
  };

  const handleOnChange = useCallback(
    (name, attributeName, value) => {
      if (attributeName === "max") setMaxValue(value);
      if (attributeName === "min") setMinValue(value);

      if (inputType === CONSTRAINTS_INPUT_TYPE_VALUES.RANGE) {
        setStatus("default");
        onUpdateConstraintData({
          name,
          options: {
            min: attributeName === "min" ? value : minValue,
            max: attributeName === "max" ? value : maxValue,
          },
          category,
          handleError,
        });
      } else {
        onUpdateConstraint({
          name,
          attributeName,
          value,
          category,
          handleError,
          handleSuccess,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      inputType,
      minValue,
      maxValue,
      onUpdateConstraintData,
      category,
      onUpdateConstraint,
    ],
  );

  const debounce = useMemo(
    () =>
      _.debounce(
        (name, attributeName, value) =>
          handleOnChange(name, attributeName, value),
        1000,
      ),
    [handleOnChange],
  );

  const handleInputChange = useCallback(
    (name, attributeName, value) => {
      debounce(name, attributeName, value);
    },
    [debounce],
  );

  const handleDisableConstraint = useCallback(() => {
    onSwitchItem({
      key: valueFieldName,
      switchArg: false,
      min: minValue,
      max: maxValue,
    });
  }, [valueFieldName, onSwitchItem, maxValue, minValue]);

  const checkIsError = fieldName => {
    const hasError = currentErrors?.find(
      item => item?.attributeNameError === fieldName,
    );
    return Boolean(hasError);
  };

  const inputTypeValue = CONSTRAINTS_INPUT_TYPES.find(
    item => item.value === inputType,
  );

  return (
    <StyledConstraintItemWrapper data-cy={valueFieldName}>
      {isSimple && (
        <>
          <StyledConstraintTextArea readOnly={readOnly}>
            <StyledConstraintTextAreaContent>
              {title}
            </StyledConstraintTextAreaContent>
          </StyledConstraintTextArea>
          <StyledConstraintTextArea readOnly={readOnly} small>
            <StyledConstraintTextAreaContent>
              {inputTypeValue?.label}
            </StyledConstraintTextAreaContent>
          </StyledConstraintTextArea>
        </>
      )}
      {!isSimple && (
        <>
          <Select
            menuPortalTarget={document.body}
            name="constaint-type"
            onChange={value =>
              onChangeConstraint(valueFieldName, value, category)
            }
            value={valueFieldName}
            options={selectOptions}
            placeholder="Select constraint"
            isOptionDisabled={option => !option?.value}
            disabled={readOnly}
          />

          <Select
            menuPortalTarget={document.body}
            onChange={handleChangeInputType}
            value={inputType}
            name="constaint-condition"
            disabled={!isEnabled || readOnly}
            placeholder="Select Operator"
            options={CONSTRAINTS_INPUT_TYPES}
          />
        </>
      )}

      {dataLoading && <StyledSkeletonInput active />}

      {inputType === CONSTRAINTS_INPUT_TYPE_VALUES.RANGE && !dataLoading && (
        <NumberInputRange
          type={type}
          disabled={!isEnabled || readOnly}
          onChange={handleInputChange}
          name={valueFieldName}
          max={maxValue || 0}
          min={minValue || 0}
          title={title}
          status={status}
          checkIsError={checkIsError}
        />
      )}

      {inputType === CONSTRAINTS_INPUT_TYPE_VALUES.GREAT_OR_EQUAL &&
        !dataLoading && (
          <NumberInput
            type={type}
            title={title}
            disabled={!isEnabled || readOnly}
            defaultValue={minValue || 0}
            name={valueFieldName}
            data-cy="constraint-value"
            attributeName="min"
            status={status}
            showError={checkIsError("min")}
            onChange={handleInputChange}
          />
        )}

      {inputType === CONSTRAINTS_INPUT_TYPE_VALUES.LESS_OR_EQUAL &&
        !dataLoading && (
          <NumberInput
            type={type}
            title={title}
            disabled={!isEnabled || readOnly}
            defaultValue={maxValue || 0}
            name={valueFieldName}
            data-cy="constraint-value"
            attributeName="max"
            status={status}
            showError={checkIsError("max")}
            onChange={handleInputChange}
          />
        )}

      {inputType === CONSTRAINTS_INPUT_TYPE_VALUES.EQUAL && !dataLoading && (
        <NumberInput
          type={type}
          title={title}
          disabled={!isEnabled || readOnly}
          defaultValue={eqValue || 0}
          name={valueFieldName}
          data-cy="constraint-value"
          attributeName="eq"
          status={status}
          showError={checkIsError("eq")}
          onChange={handleInputChange}
        />
      )}

      {!inputType && (
        <StyledConstraintTextArea readOnly>value</StyledConstraintTextArea>
      )}

      <StyledConstraintItemActions>
        {canDelete && (
          <StyledActionsRow>
            {loading ? (
              <StyledLoadingWrapper>
                <LoadingOutlined />
              </StyledLoadingWrapper>
            ) : (
              <StyledTextButton
                $transparent
                $noPadding
                $autoHeight
                shape="circle"
                data-cy="remove-constraints"
                icon={<StyledCloseIcon />}
                onClick={handleDisableConstraint}
                disabled={readOnly}
              />
            )}
          </StyledActionsRow>
        )}

        {help && (
          <Tooltip title={help}>
            <StyledInfoIcon $hasMargin={!canDelete} />
          </Tooltip>
        )}
      </StyledConstraintItemActions>
    </StyledConstraintItemWrapper>
  );
};

ConstraintsItem.propTypes = {
  isSimple: PropTypes.bool,
  canDelete: PropTypes.bool,
  dataLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  attributeError: PropTypes.arrayOf(PropTypes.shape()),
  setAttributeError: PropTypes.func,
  handleError: PropTypes.func,
  constraint: ConstraintPropType,
  loading: PropTypes.bool,
  category: PropTypes.string,
  valueFieldName: PropTypes.string.isRequired,
  inputType: InputPropType,
  constraintsData: PropTypes.shape(),
};

export default ConstraintsItem;
