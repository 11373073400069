import { useCallback, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import PropTypes from "prop-types";
import { Skeleton } from "antd";

import StepDetails from "./StepDetails";
import {
  StyledLayoutSection,
  StyledPortfolioPageArea,
  StyledPortfolioPageContent,
  StyledPortfolioPageFooter,
  StyledPortfolioPageSideArea,
} from "../../styles";
import { PROGRESS_STATES } from "../../../../constants/constraints";
import { StyledLoadingSidebar } from "./styles";
import SummaryOverview from "./OverviewDetails";
import SideNavigation from "./SideNavigation";

const PortfolioResultContent = ({ pageLoading }) => {
  const {
    constraintsData,
    portfolioBuildProgress,
    portfolioResultData,
    selectedGenerationUUID,
    getTitleDetailsByStatus,
    getPortfolioResultAtStep,
    filteredPortfolioList,
    selectedGenerations,
  } = useOutletContext();

  const { selectedPortfolio } = constraintsData;

  const { currentStep, setCurrentStep } = portfolioResultData;

  useEffect(() => {
    if (selectedPortfolio?.uuid) {
      setCurrentStep(null);
    }
  }, [selectedPortfolio?.uuid, setCurrentStep]);

  const handleMenuItemClick = useCallback(
    thisStep => {
      setCurrentStep(thisStep);
    },
    [setCurrentStep],
  );

  const { status } = portfolioBuildProgress;

  return (
    <StyledPortfolioPageArea hasExtraMenu>
      <StyledPortfolioPageSideArea>
        {pageLoading ? (
          <StyledLoadingSidebar>
            <Skeleton.Button active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
            <Skeleton.Input active />
          </StyledLoadingSidebar>
        ) : (
          <SideNavigation
            handleMenuItemClick={handleMenuItemClick}
            getTitleDetailsByStatus={getTitleDetailsByStatus}
            portfolioList={filteredPortfolioList}
            selectedGenerations={selectedGenerations}
          />
        )}
      </StyledPortfolioPageSideArea>
      <StyledPortfolioPageContent>
        {pageLoading && (
          <StyledLayoutSection>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </StyledLayoutSection>
        )}
        {!pageLoading && (
          <>
            {!currentStep && (
              <SummaryOverview
                isInProgress={status === PROGRESS_STATES.inProgress}
                getTitleDetailsByStatus={getTitleDetailsByStatus}
              />
            )}
            {!!currentStep && (
              <StepDetails
                step={currentStep}
                selectedGenerationUUID={selectedGenerationUUID}
                getPortfolioResultAtStep={getPortfolioResultAtStep}
                constraintsData={constraintsData}
                portfolioResultData={portfolioResultData}
              />
            )}
          </>
        )}
      </StyledPortfolioPageContent>
      <StyledPortfolioPageFooter />
    </StyledPortfolioPageArea>
  );
};

PortfolioResultContent.propTypes = {
  pageLoading: PropTypes.bool,
};

export default PortfolioResultContent;
