import styled from "styled-components/macro";
import { darkGrey6, white, darkGrey1 } from "../../constants/colors";
import { maxScreensSizesPx } from "../../features/styled";

export const StyledFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 24px));
  gap: 24px;
  align-items: flex-start;
  height: 242px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${darkGrey6};
  padding: 77px 32px;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    height: auto;
    grid-template-columns: repeat(2, calc(50% - 24px));
    padding: 32px 16px;
  }

  @media (max-width: ${maxScreensSizesPx.mobile}) {
    height: auto;
    grid-template-columns: auto;
    padding: 32px 16px;
  }
`;

export const StyledLogo = styled.div`
  margin-bottom: 26px;
`;

export const StyledCopyrightText = styled.span`
  color: ${darkGrey1};
  margin-right: 4px;
`;

export const StyledCopyrightBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  color: ${white};
  font-size: 12px;
  line-height: 16px;
  max-width: 320px;
`;

export const StyledTitle = styled.div`
  color: ${darkGrey1};
  text-transform: uppercase;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const StyledLink = styled.a`
  display: block;
  color: ${white};
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  text-decoration: underline;

  ${({ noMargin }) =>
    noMargin &&
    `
    margin: 0;
  `}
`;

export const StyledInfoRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 11px;
  color: ${white};
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
`;
