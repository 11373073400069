import { useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Skeleton } from "antd";

import {
  CONSTRAINTS_CATEGORIES,
  CONSTRAINTS_CATEGORIES_ORDER,
} from "../../../../../constants/objectives";
import ConstraintsList from "../../../../Constraints/content/ConstraintsList";
import { StyledModalSectionTitle } from "../../../styles";
import { StyledObjectivesContainer } from "../../../../Constraints/styles";
import useConstraintsData from "../../../../../layout/WorkbookLayout/useConstraintsData";
import { StyledModalScrollContainer } from "../../../../../components/Modals/ModalDialog.styled";
import ObjectiveSelect from "../../../../Constraints/content/ObjectiveSelect";

const SelectedConstraints = ({
  selectedPortfolio = null,
  scrollProps = {},
  constraintsData,
}) => {
  const selectedPortfolioConstraintsData = useConstraintsData({
    portfolio: selectedPortfolio,
  });

  const selectedConstraintsdata = selectedPortfolio
    ? selectedPortfolioConstraintsData
    : constraintsData;

  const {
    objective,
    constraintsByCategory = {},
    selectedConstraints,
  } = selectedConstraintsdata;

  const getOnlyVisible = useCallback(
    key => {
      if (
        [
          CONSTRAINTS_CATEGORIES.COMPLIANCE_TESTS,
          CONSTRAINTS_CATEGORIES.CONSTRAINTS,
        ].includes(key)
      ) {
        return selectedConstraints[key];
      }

      return constraintsByCategory[key];
    },
    [selectedConstraints, constraintsByCategory],
  );

  const isDisabledSellReplace = useCallback(
    constraints =>
      Object.values(constraints).every(constraint => !constraint.isEnabled),
    [],
  );

  if (isEmpty(selectedConstraints)) {
    return (
      <StyledModalScrollContainer>
        <Skeleton />
      </StyledModalScrollContainer>
    );
  }

  return (
    <StyledModalScrollContainer {...scrollProps}>
      <StyledModalSectionTitle>Portfolio Objectives</StyledModalSectionTitle>
      <StyledObjectivesContainer>
        <ObjectiveSelect
          defaultObjective={objective}
          constraintsData={selectedConstraintsdata}
          disabled
          hideSelect
        />
      </StyledObjectivesContainer>
      {CONSTRAINTS_CATEGORIES_ORDER.map(key => {
        if (
          key === CONSTRAINTS_CATEGORIES.SELL_REPLACE &&
          constraintsByCategory[key] &&
          isDisabledSellReplace(constraintsByCategory[key])
        ) {
          return null;
        }

        const visibleConstraints = getOnlyVisible(key);

        return (
          <Fragment key={key}>
            {!isEmpty(visibleConstraints) && selectedConstraintsdata && (
              <>
                <StyledModalSectionTitle>{key}</StyledModalSectionTitle>
                <ConstraintsList
                  simple
                  readOnly
                  dataCy={`${key}-list`}
                  constraints={visibleConstraints}
                  constraintsLoading={{}}
                  constraintsData={selectedConstraintsdata}
                />
              </>
            )}
          </Fragment>
        );
      })}
    </StyledModalScrollContainer>
  );
};

SelectedConstraints.propTypes = {
  selectedPortfolio: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  }),
  scrollProps: PropTypes.shape({
    onScroll: PropTypes.func,
  }),
  constraintsData: PropTypes.shape(),
};

export default SelectedConstraints;
