import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import { OktaAuth } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { PageContextProvider } from "./layout/AppLayout/context";
import ErrorBoundary from "./components/ErrorBoundary";
import { OKTAConfig } from "./config";
import GlobalStyle from "./GlobalStyle";

import App from "./app";
import Loading from "./components/Loading";
import { gray80 } from "./constants/colors";

const render = () => {
  const rootElement = document.getElementById("root");
  const root = createRoot(rootElement);

  const securityConfig = new OktaAuth(OKTAConfig());
  const restoreOriginalUri = (val, originalUri) => {
    window.location.replace(originalUri);
  };

  root.render(
    <Suspense fallback={<Loading />}>
      <Router>
        <Security
          oktaAuth={securityConfig}
          restoreOriginalUri={restoreOriginalUri}
        >
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Onest, sans-serif",
                borderRadiusLG: "2px",
                borderRadius: "2px",
                color: gray80,
              },
              components: {
                Pagination: {
                  borderRadius: "2px",
                },
              },
              hashed: false,
            }}
          >
            <ErrorBoundary>
              <GlobalStyle />
              <PageContextProvider>
                <App />
              </PageContextProvider>
            </ErrorBoundary>
          </ConfigProvider>
        </Security>
      </Router>
    </Suspense>,
  );
};

render();
