import axiosInstance from "../axiosInstance";

export const getAllConstants = async () => {
  const response = await axiosInstance({
    url: "/constants/all/",
  });
  return response?.data;
};

const endpoints = {
  getAllConstants,
};

export default endpoints;
