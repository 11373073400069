const exportDataTableCSV = (gridRef, fileName) => {
  const currentColumnDefs = gridRef.current.api.getColumnDefs();

  const columnKeys = currentColumnDefs.reduce((acc, columnDef) => {
    if (columnDef.field === "") {
      return acc;
    }
    return [...acc, columnDef.field];
  }, []);

  gridRef.current.api.exportDataAsCsv({
    fileName,
    exportedRows: "filteredAndSorted",
    columnKeys,
  });
};

export default exportDataTableCSV;
