import { useState, useCallback } from "react";

const useErrorStatus = () => {
  const [isNotFound, setIsNotFound] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);

  const checkIsErrorStatus = useCallback(status => {
    return status === 404 || status === 422 || status === 403;
  }, []);

  const setErrorStatus = useCallback(status => {
    switch (status) {
      case 403:
        setIsForbidden(true);
        setIsNotFound(false);
        break;
      case 404:
        setIsForbidden(false);
        setIsNotFound(true);
        break;
      case 422:
        setIsForbidden(false);
        setIsNotFound(true);
        break;
      default:
        setIsForbidden(false);
        setIsNotFound(false);
        break;
    }
  }, []);
  return { isNotFound, setErrorStatus, checkIsErrorStatus, isForbidden };
};

export default useErrorStatus;
