import PropTypes from "prop-types";
import {
  StyledInfoSelectIcon,
  StyledCloseIcon,
} from "../../../../components/Icons";
import { white } from "../../../../constants/colors";
import {
  StyledInfoBlock,
  StyledInfoBlockContainer,
  StyledCloseBtn,
} from "./styles";

const ChartSelectInfo = ({ handleClose }) => {
  return (
    <StyledInfoBlockContainer>
      <StyledInfoBlock>
        <StyledInfoSelectIcon />
        <div>Select the second value to see the comparison.</div>
        <StyledCloseBtn onClick={handleClose}>
          <StyledCloseIcon color={white} />
        </StyledCloseBtn>
      </StyledInfoBlock>
    </StyledInfoBlockContainer>
  );
};

ChartSelectInfo.propTypes = {
  handleClose: PropTypes.func,
};

export default ChartSelectInfo;
