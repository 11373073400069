import { createContext, useMemo } from "react";
import PropTypes from "prop-types";
import useWorkbooksData from "./useWorkbooksData";

export const Context = createContext({});

export const Provider = ({ children }) => {
  const workbookData = useWorkbooksData();
  const contextData = useMemo(() => ({ workbookData }), [workbookData]);

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.defaultProps = {};
Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
