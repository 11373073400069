import styled from "styled-components/macro";
import { Layout as AntLayout } from "antd";
import { maxScreensSizesPx } from "../../features/styled";

export const { Content: AntContent } = AntLayout;

export const StyledContentCentered = styled(AntContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 20px;
  }
`;

export default StyledContentCentered;
