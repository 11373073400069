import { useState, useCallback, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Input } from "antd";
import HeaderContainer from "../../layout/AppLayout/content/header";
import {
  StyledWelcomePageContent,
  StyledButtonContainer,
  StyledLinkText,
  StyledHelpText,
  StyledPageTitle,
  StyledNeedHelpBlock,
  StyledImageWrapper,
  StyledFormContainer,
} from "./WelcomePage.styled";
import { StyledLayout } from "../../components/Layout";
import { login } from "../../api/endpoints/users";
import StyledButton from "../../components/Buttons/Button.styled";
import { StyledContentCentered } from "../../components/Layout/Content";
import Footer from "../../components/Footer";
import { StyledLoginIcon } from "../../components/Icons";
import { handleErrorMessages } from "../../utils/messages";

const WelcomePage = () => {
  const { oktaAuth } = useOktaAuth();
  const [values, setFormValues] = useState({ email: "", password: "" });
  const isButtonEnabled = values?.email && values?.password;
  const isOktaAuthFlow = process.env.REACT_APP_AUTH_FLOW === "okta";

  const handleChangeInput = useCallback(event => {
    const { value, name } = event.target;
    setFormValues(prevState => ({ ...prevState, [name]: value }));
  }, []);

  const handleLogIn = async () => {
    if (isOktaAuthFlow) {
      return oktaAuth.signInWithRedirect({ originalUri: "/" });
    }

    try {
      const response = await login(values);
      const token = response?.accessToken;
      localStorage.setItem("accessToken", token);
      if (token) {
        window.location.replace(`${window.location.origin}/workbooks`);
      }
      return token;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      handleErrorMessages(err);
      return null;
    }
  };

  const onKeyDown = useCallback(
    event => {
      if (event.key === "Enter" && isButtonEnabled) {
        handleLogIn(event);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isButtonEnabled],
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [values, onKeyDown]);

  return (
    <StyledLayout>
      <HeaderContainer />
      <StyledContentCentered>
        <StyledWelcomePageContent>
          <StyledImageWrapper>
            <StyledLoginIcon />
          </StyledImageWrapper>
          <StyledPageTitle>Welcome to Loan Hunter Portal!</StyledPageTitle>
          <StyledFormContainer>
            {!isOktaAuthFlow && (
              <>
                <Input
                  value={values?.email}
                  name="email"
                  placeholder="Email"
                  onChange={handleChangeInput}
                  autoFocus
                  data-cy="login-email"
                />
                <Input
                  value={values?.password}
                  name="password"
                  onChange={handleChangeInput}
                  placeholder="Password"
                  type="password"
                  data-cy="login-password"
                />
              </>
            )}
            <StyledButtonContainer>
              <StyledButton
                type="primary"
                data-cy="login-button"
                onClick={handleLogIn}
                disabled={isOktaAuthFlow ? false : !isButtonEnabled}
              >
                Log in
              </StyledButton>
            </StyledButtonContainer>
          </StyledFormContainer>
          <StyledNeedHelpBlock>
            <StyledHelpText uppercase>Need help logging in?</StyledHelpText>
            <StyledLinkText
              href="mailto:support@investwithvantage.com"
              target="_blank"
              rel="noreferrer"
            >
              support@investwithvantage.com
            </StyledLinkText>
          </StyledNeedHelpBlock>
        </StyledWelcomePageContent>
      </StyledContentCentered>
      <Footer />
    </StyledLayout>
  );
};

export default WelcomePage;
