import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { getCurrentUser } from "../../../api/endpoints/users";
import { handleErrorMessages } from "../../../utils/messages";

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [user, setUser] = useState(null);

  const handleGetUser = useCallback(async () => {
    try {
      (async () => {
        setUser(await getCurrentUser());
      })();
    } catch (error) {
      handleErrorMessages(error);
    }
  }, []);

  useEffect(() => {
    handleGetUser();
  }, [handleGetUser]);

  const contextData = useMemo(
    () => ({
      user,
      handleGetUser,
    }),
    [user, handleGetUser],
  );

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

Provider.defaultProps = {};
Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
