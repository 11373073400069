import axiosInstance from "../axiosInstance";

export const getWorkbooksList = async (page = 1) => {
  const response = await axiosInstance({
    url: "/workbooks/my/",
    method: "GET",
    params: {
      page,
    },
  });
  return response?.data;
};

export const getWorkbookContributors = async ({ workbookUUID, page }) => {
  const params = { page };
  const response = await axiosInstance({
    url: `/workbooks/${workbookUUID}/contributors/`,
    params,
    method: "GET",
  });
  return response?.data;
};

export const deleteWorkbookContributor = async (
  workbookUUID,
  contributorUUID,
) => {
  const response = await axiosInstance({
    url: `/workbooks/${workbookUUID}/contributors/${contributorUUID}/`,
    method: "DELETE",
  });
  return response?.data;
};

export const getWorkbook = async workbookId => {
  const response = await axiosInstance({
    url: `/workbooks/${workbookId}/`,
  });
  return response?.data;
};

export const getWorkbookLoanGroup = async workbookId => {
  const response = await axiosInstance({
    url: `/workbooks/${workbookId}/loan-groups/`,
  });
  return response?.data;
};

export const addWorkbook = async data => {
  const response = await axiosInstance({
    url: "/workbooks/",
    method: "POST",
    data,
  });
  return response?.data;
};

export const editWorkbook = async (workbookId, data) => {
  const response = await axiosInstance({
    url: `/workbooks/${workbookId}/`,
    method: "PATCH",
    data,
  });
  return response?.data;
};

export const deleteWorkbook = async workbookId => {
  const response = await axiosInstance({
    url: `/workbooks/${workbookId}/`,
    method: "DELETE",
  });
  return response?.data;
};

export const shareWorkbook = async (workbookId, data) => {
  const response = await axiosInstance({
    url: `/workbooks/${workbookId}/contributors/`,
    method: "POST",
    data,
  });
  return response?.data;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getWorkbooksList,
};
