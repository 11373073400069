import { useCallback, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Button, Tooltip } from "antd";
import formatISO from "date-fns/formatISO";
import generatePicker from "antd/es/date-picker/generatePicker";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";

import {
  StyledActionsRow,
  StyledAnimatedSyncOutlineIconWrapper,
  StyledStatusLabel,
  StyledStatusLabelRow,
} from "../styles";
import {
  PROGRESS_STATES,
  PROGRESS_STATES_LABEL,
  ALL_PROGRESS_STATES_WITH_LABEL,
} from "../../../constants/constraints";
import RunTimeCell from "../RunTimeCell";
import {
  StyledNavLink,
  StyledDateFilterContainer,
  StyledTableFilterContainer,
} from "../../Workbooks/styles";
import StyledButton, {
  StyledTextButton,
} from "../../../components/Buttons/Button.styled";
import {
  StyledUploadIcon,
  StyledAnimatedSyncOutlineIcon,
  StyledWarningIcon,
  StyledTrashOutlineIcon,
  StyledLayoutIcon,
  StyledTableListIcon,
  StyledCloseIcon,
} from "../../../components/Icons";
import {
  cancelPortfolioResult,
  downloadPortfolioResult,
} from "../../../api/endpoints/results";
import handleFormatDate from "../../../utils/handleFormatDate";
import Checkbox from "../../../components/Form/Checkbox";
import { gold } from "../../../constants/colors";
import { handleDownloadData } from "../../../utils/handleDownloadData";
import { WORKBOOK_TYPE_VALUES } from "../../../constants/workbooks";
import { handleErrorMessages } from "../../../utils/messages";
import { getDefaultDateValue } from "../../../utils/formatDate";

const DatePicker = generatePicker(dateFnsGenerateConfig);

const { RangePicker } = DatePicker;

const statuses = ALL_PROGRESS_STATES_WITH_LABEL.filter(
  status => status.value !== PROGRESS_STATES.draft,
);

const useResultsData = ({ onClickPortfolioBuild, startBuildLoading }) => {
  const navigate = useNavigate();
  const { getWorkbookResults } = useOutletContext();
  const [filters, setFilters] = useState({});
  const [showResetBtn, setShowResetBtn] = useState(false);
  const { workbook_id: workbookId } = useParams();

  const [deleteResultUUID, setDeleteResultUUID] = useState("");

  const onPortfolioCancelBuild = useCallback(id => {
    cancelPortfolioResult(id)
      .then(() => {
        getWorkbookResults({ page: 1 });
      })
      .catch(error => handleErrorMessages(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFile = resultId => {
    downloadPortfolioResult(resultId).then(response => {
      handleDownloadData(response, `result-${resultId}.csv`);
    });
  };

  const handleCreateDateFilter = datesObj => {
    const datesObjDateFrom = new Date(datesObj?.dateFrom);
    datesObjDateFrom.setHours(0);
    datesObjDateFrom.setMinutes(0);
    datesObjDateFrom.setSeconds(0);
    const dateFrom = formatISO(new Date(datesObjDateFrom));

    const datesObjDateTo = new Date(datesObj?.dateTo);
    datesObjDateTo.setHours(23);
    datesObjDateTo.setMinutes(59);
    datesObjDateTo.setSeconds(59);
    const dateTo = formatISO(new Date(datesObjDateTo));
    return [`<${dateTo}`, `>${dateFrom}`];
  };

  const handleDatePicketChange = (value, key) => {
    const dateObj = {
      dateFrom: value[0],
      dateTo: value[1],
    };
    const filter = handleCreateDateFilter(dateObj);
    getWorkbookResults({
      filter: {
        ...filters,
        [key]: filter,
      },
    });
    setShowResetBtn(true);
    setFilters(prevState => ({ ...prevState, [key]: filter }));
  };

  const handleResetFilterValue = key => {
    getWorkbookResults({ filter: { ...filters, [key]: null } });
    const filtersKeys = Object.keys(filters);
    if (filtersKeys?.length === 1 && filtersKeys[0] === key) {
      setShowResetBtn(false);
    }
    setFilters(prevState => {
      const newData = { ...prevState };
      delete newData[key];
      return newData;
    });
  };

  const clearFilters = () => {
    setFilters({});
    getWorkbookResults({ filters: {} });
    setShowResetBtn(false);
  };

  const resultsName = useCallback((text, record = {}) => {
    const { workbookUuid, uuid, status } = record;
    const urlLink =
      status === PROGRESS_STATES.draft
        ? `/workbooks/${workbookUuid}/results/${uuid}/constraints`
        : `/workbooks/${workbookUuid}/results/${uuid}`;
    return (
      <StyledNavLink to={urlLink} data-cy={text}>
        {`Result ID ${text}`}
      </StyledNavLink>
    );
  }, []);

  const dataActions = useCallback(
    (text, record = {}) => {
      const { workbookUuid, uuid, status, type, amountOfPortfolios } = record;
      const urlLink = `/workbooks/${workbookUuid}/results/${uuid}`;
      const tradeSummaryUrlLink = `${urlLink}/trade_summary`;
      const isSellReplace = type === WORKBOOK_TYPE_VALUES.SELL_REPLACE;
      const hasData = Boolean(amountOfPortfolios);
      const showTradeSummary =
        isSellReplace &&
        [PROGRESS_STATES.finished, PROGRESS_STATES.finishedPartially].includes(
          status,
        );
      const isDraft = status === PROGRESS_STATES.draft;
      const isInProgress =
        status === PROGRESS_STATES.inProgress ||
        status === PROGRESS_STATES.queued;
      return (
        <StyledActionsRow>
          {isDraft && hasData && (
            <Tooltip placement="topRight" title="Generate Portfolio">
              {startBuildLoading ? (
                <StyledAnimatedSyncOutlineIconWrapper>
                  <StyledAnimatedSyncOutlineIcon />
                </StyledAnimatedSyncOutlineIconWrapper>
              ) : (
                <StyledButton
                  type="secondary"
                  $width={86}
                  $height={32}
                  $lowercase
                  $autoWidth
                  $noPadding
                  onClick={event => {
                    event.stopPropagation();
                    onClickPortfolioBuild(uuid, () => navigate(urlLink));
                  }}
                >
                  Generate
                </StyledButton>
              )}
            </Tooltip>
          )}
          <Tooltip placement="topRight" title="Trade Summary">
            <StyledTextButton
              $iconOnly
              data-cy={`view-result-${uuid}`}
              type="text"
              icon={<StyledLayoutIcon />}
              disabled={!showTradeSummary || isDraft}
              onClick={event => {
                event.stopPropagation();
                navigate(tradeSummaryUrlLink);
              }}
            />
          </Tooltip>
          <Tooltip title="Download Results">
            <StyledTextButton
              $iconOnly
              data-cy={`view-result-${uuid}`}
              type="text"
              disabled={isDraft}
              icon={<StyledUploadIcon />}
              onClick={event => {
                event.stopPropagation();
                downloadFile(uuid);
              }}
            />
          </Tooltip>
          <Tooltip title="Optimization Arguments">
            <StyledTextButton
              $iconOnly
              type="text"
              icon={<StyledTableListIcon />}
              disabled={!hasData}
              onClick={event => {
                event.stopPropagation();
                navigate(
                  `/workbooks/${workbookId}/results/${uuid}/constraints`,
                );
              }}
            />
          </Tooltip>
          {isInProgress ? (
            <Tooltip title="Cancel portfolio generation">
              <StyledTextButton
                $iconOnly
                type="text"
                disabled={isDraft}
                icon={<StyledCloseIcon style={{ marginBottom: "2px" }} />}
                onClick={event => {
                  event.stopPropagation();
                  onPortfolioCancelBuild(uuid);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Delete Result">
              <StyledTextButton
                $iconOnly
                type="text"
                disabled={isDraft}
                icon={
                  <StyledTrashOutlineIcon style={{ marginBottom: "2px" }} />
                }
                onClick={event => {
                  event.stopPropagation();
                  setDeleteResultUUID(uuid);
                }}
              />
            </Tooltip>
          )}
        </StyledActionsRow>
      );
    },
    [
      navigate,
      onClickPortfolioBuild,
      startBuildLoading,
      workbookId,
      onPortfolioCancelBuild,
    ],
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "uuid",
      key: "uuid",
      render: resultsName,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterDropdown: () => (
        <StyledTableFilterContainer>
          {statuses.map(item => (
            <div key={item.value}>
              <Checkbox
                value={filters?.status?.includes(item.value)}
                checked={filters?.status?.includes(item.value)}
                title={item.label}
                onChange={event => {
                  let newCheckedStatuses = filters?.status?.length
                    ? [...filters.status]
                    : [];
                  if (event?.target?.checked) {
                    newCheckedStatuses = [...newCheckedStatuses, item.value];
                  } else {
                    newCheckedStatuses = newCheckedStatuses.filter(
                      status => status !== item.value,
                    );
                  }
                  setFilters(prevState => ({
                    ...prevState,
                    status: newCheckedStatuses,
                  }));
                }}
              />
            </div>
          ))}
          <Button onClick={() => handleResetFilterValue("status")}>
            Reset
          </Button>
          <Button
            onClick={() => {
              setShowResetBtn(true);
              getWorkbookResults({
                filter: { ...filters, status: filters?.status },
              });
            }}
          >
            Apply
          </Button>
        </StyledTableFilterContainer>
      ),
      render: status => {
        const label =
          status === PROGRESS_STATES.finishedPartially
            ? PROGRESS_STATES_LABEL.FINISHED
            : PROGRESS_STATES_LABEL[status];
        return (
          <StyledStatusLabelRow>
            <StyledStatusLabel status={status}>
              {label}
              {status === PROGRESS_STATES.finishedPartially && (
                <StyledWarningIcon color={gold} />
              )}
            </StyledStatusLabel>
          </StyledStatusLabelRow>
        );
      },
    },
    {
      title: "Generations",
      dataIndex: "amountOfGenerations",
      key: "amountOfGenerations",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: data => handleFormatDate({ value: data, showTime: true }),
      filterDropdown: () => (
        <StyledDateFilterContainer>
          <RangePicker
            onChange={value => handleDatePicketChange(value, "created_at")}
            allowClear={false}
            defaultValue={getDefaultDateValue(filters?.created_at)}
          />
          <Button onClick={() => handleResetFilterValue("created_at")}>
            Reset
          </Button>
        </StyledDateFilterContainer>
      ),
    },
    {
      title: "Started at",
      dataIndex: "startedAt",
      key: "startedAt",
      render: data => data && handleFormatDate({ value: data, showTime: true }),
      filterDropdown: () => (
        <StyledDateFilterContainer>
          <RangePicker
            onChange={value => handleDatePicketChange(value, "started_at")}
            allowClear={false}
            defaultValue={getDefaultDateValue(filters?.started_at)}
          />
          <Button onClick={() => handleResetFilterValue("started_at")}>
            Reset
          </Button>
        </StyledDateFilterContainer>
      ),
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: data => handleFormatDate({ value: data, showTime: true }),
    },
    {
      title: "Run Time",
      dataIndex: "startedAt",
      key: "startedAt",
      render: (value, row) => <RunTimeCell record={row} />,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: dataActions,
      align: "right",
    },
  ];

  return {
    columns,
    resultsName,
    clearFilters,
    filters,
    showResetBtn,
    setDeleteResultUUID,
    deleteResultUUID,
  };
};

export default useResultsData;
