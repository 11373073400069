import { capitalize, isEmpty, isObject } from "lodash";
import { valueTransformer } from "../../../../../utils/parseValue";
import { STATUSES, StyledStatusLabel } from "../../../../Shared/styled";

import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableCellContent,
} from "../styles";

export const formatKey = key => {
  return capitalize(key.replaceAll("_", " "));
};

export const allConstraintColumns = [
  {
    title: "Constraints",
    dataIndex: "title",
    key: "title",
    render: (value, row) => (
      <StyledTableCell $isEnabled={row?.isEnabled}>{value}</StyledTableCell>
    ),
  },
  {
    title: "Min",
    dataIndex: "min",
    key: "min",
    render: (value, { isEnabled, type }) => (
      <StyledTableCell $isEnabled={isEnabled}>
        {valueTransformer[type](value, true) || <>&mdash;</>}
      </StyledTableCell>
    ),
  },
  {
    title: "Max",
    dataIndex: "max",
    key: "max",
    render: (value, { isEnabled, type }) => (
      <StyledTableCell $isEnabled={isEnabled}>
        {valueTransformer[type](value, true) || <>&mdash;</>}
      </StyledTableCell>
    ),
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    render: (values, row) => {
      const { isValid, isEnabled, type } = row;

      if (!values || (isObject(values) && isEmpty(values)))
        return (
          <StyledTableCell $isEnabled={isEnabled}>&mdash;</StyledTableCell>
        );

      if (typeof values === "object" && values) {
        return Object.entries(values).map(([key, value]) => {
          if (Array.isArray(value)) {
            if (key === "invalid_rows" && value.length)
              return (
                <StyledTableCell key={key} $isEnabled={isEnabled}>
                  <StyledTableCellTitle style={{ marginBottom: 0 }}>
                    {formatKey(key)}:
                  </StyledTableCellTitle>
                </StyledTableCell>
              );
            if (key === "invalid_rows" && !value.length) return <>&mdash;</>;
            return (
              <StyledTableCell key={key} $isEnabled={isEnabled}>
                {Boolean(value.length) && (
                  <StyledTableCellTitle>{formatKey(key)}:</StyledTableCellTitle>
                )}
                {Boolean(value.length) &&
                  value?.map(item => {
                    if (item && typeof item === "object") {
                      return Object.entries(item).map(
                        ([itemKey, itemValue]) => (
                          <StyledTableCellContent
                            key={itemKey}
                            $valid={isValid}
                            $isEnabled={isEnabled}
                          >
                            <StyledTableCellTitle>
                              {formatKey(itemKey)}:{" "}
                            </StyledTableCellTitle>
                            {valueTransformer[type](itemValue, true) || (
                              <>&mdash;</>
                            )}
                          </StyledTableCellContent>
                        ),
                      );
                    }
                    return (
                      <div key={item}>
                        {valueTransformer[type](item, true) || <>&mdash;</>}
                      </div>
                    );
                  })}
              </StyledTableCell>
            );
          }
          return (
            <StyledTableCellContent key={key} $isEnabled={isEnabled}>
              {formatKey(key)}:{" "}
              {valueTransformer[type](value, true) || <>&mdash;</>}
            </StyledTableCellContent>
          );
        });
      }
      return (
        <StyledTableCell $isEnabled={isEnabled}>
          {valueTransformer[type](values, true) || <>&mdash;</>}
        </StyledTableCell>
      );
    },
  },
  {
    title: "Is Enabled",
    dataIndex: "isEnabled",
    key: "isEnabled",
    render: (value, row) => {
      const renderedValue = value ? "yes" : "no";
      const { isEnabled } = row;
      return (
        <StyledTableCell $isEnabled={isEnabled}>
          <StyledStatusLabel
            status={isEnabled ? STATUSES.success : STATUSES.disabled}
          >
            {renderedValue}
          </StyledStatusLabel>
        </StyledTableCell>
      );
    },
  },
  {
    title: "Is Valid",
    dataIndex: "isValid",
    key: "isValid",
    render: (value, row) => {
      const renderedValue = value ? "yes" : "no";
      const { isValid, isEnabled } = row;
      const validityStatus = isValid ? STATUSES.success : STATUSES.error;
      const status = isEnabled ? validityStatus : STATUSES.disabled;
      return (
        <StyledTableCell>
          <StyledStatusLabel status={status}>{renderedValue}</StyledStatusLabel>
        </StyledTableCell>
      );
    },
  },
];
