import { StyledLayout } from "../Layout";
import HeaderContainer from "../../layout/AppLayout/content/header";
import Footer from "../Footer";
import PoliciesAndDisclosures from "./PoliciesAndDisclosures";
import { StyledContentCentered } from "../Layout/Content";
import { PageContextProvider } from "../../layout/AppLayout/context";

const PoliciesAndDisclosuresContainer = () => {
  return (
    <PageContextProvider>
      <StyledLayout>
        <HeaderContainer />
        <StyledContentCentered>
          <PoliciesAndDisclosures />
        </StyledContentCentered>
        <Footer />
      </StyledLayout>
    </PageContextProvider>
  );
};

export default PoliciesAndDisclosuresContainer;
