import PropTypes from "prop-types";
import { Input } from "antd";
import { StyledCheckbox, StyledInputLabel } from "./styled";

const Checkbox = ({
  onChange,
  title,
  type = "default",
  value,
  name,
  field,
  disabled = false,
  otherValue,
  inputDisabled,
  indeterminate,
  children,
  color = "",
}) => {
  const onChangeHandler = event => {
    if (onChange) {
      onChange(event);
    }
  };

  const onChangeOtherValue = event => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const isChecked = checkType => {
    switch (checkType) {
      case "other":
        return !!otherValue;
      case "default":
      default:
        return value;
    }
  };

  return (
    <StyledCheckbox
      name={name}
      onChange={onChangeHandler}
      checked={isChecked(type)}
      disabled={disabled}
      data-cy={name}
      data-field={field}
      $title={title}
      indeterminate={indeterminate}
      $type={type}
      $color={color}
    >
      {type === "other" && (
        <Input
          placeholder="Other..."
          value={otherValue}
          onChange={onChangeOtherValue}
          autoComplete="off"
          type="text"
          disabled={inputDisabled}
        />
      )}
      {type === "default" && title && (
        <StyledInputLabel uppercase>{title}</StyledInputLabel>
      )}
      {type === "custom" && children && (
        <StyledInputLabel>{children}</StyledInputLabel>
      )}
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  title: PropTypes.string,
  otherValue: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  field: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["default", "other", "custom"]),
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Checkbox;
