import { createContext, useMemo, useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import PropTypes from "prop-types";
import { PROGRESS_STATES } from "../../../constants/constraints";
import useConstraintsData from "../../../layout/WorkbookLayout/useConstraintsData";
import usePortfolioResultData from "../content/ResultContent/usePortfolioResultData";

export const Context = createContext({});
export const Provider = ({ children }) => {
  const {
    currentWorkbook,
    resultUUID,
    isNotFound,
    isForbidden,
    getResultData,
    objectives,
    allConstraintsValue,
    selectedGenerationUUID,
  } = useOutletContext();

  const constraintsData = useConstraintsData({
    availableObjectiveFunctions: objectives,
  });
  const [portfolioBuildProgress, setPortfolioBuildProgress] = useState({});
  const { selectedPortfolio } = constraintsData;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState({
    selectedPortfolio: {},
    status: null,
    step: null,
    constraintsData,
  });

  useEffect(() => {
    const { status, portfolios } = portfolioBuildProgress;

    const portfolio = portfolios?.[selectedPortfolio?.uuid];
    const step = portfolio ? portfolio?.step : null;

    setOptions({
      selectedPortfolio,
      status,
      step,
      constraintsData,
    });
  }, [constraintsData, portfolioBuildProgress, selectedPortfolio]);

  useEffect(() => {
    setIsLoading(true);
    getResultData(() => setIsLoading(false));
  }, [getResultData]);

  useEffect(() => {
    const statuses = Object.values(PROGRESS_STATES).filter(
      item => item !== PROGRESS_STATES.draft,
    );
    setIsLoading(!statuses.includes(portfolioBuildProgress?.status));
  }, [portfolioBuildProgress?.status]);

  const portfolioResultData = usePortfolioResultData(options);

  const contextData = useMemo(
    () => ({
      isLoading,
      isNotFound,
      isForbidden,
      constraintsData,
      allConstraintsValue,
      currentWorkbook,
      portfolioBuildProgress,
      setPortfolioBuildProgress,
      selectedGenerationUUID,
      portfolioResultData,
      resultUUID,
    }),
    [
      isLoading,
      isNotFound,
      isForbidden,
      constraintsData,
      allConstraintsValue,
      currentWorkbook,
      portfolioBuildProgress,
      setPortfolioBuildProgress,
      selectedGenerationUUID,
      portfolioResultData,
      resultUUID,
    ],
  );

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.defaultProps = {};
Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
