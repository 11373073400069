import styled, { css } from "styled-components/macro";
import { Layout } from "antd";
import {
  accentRed,
  blueWhite,
  darkGrey2,
  darkGrey3,
  darkGrey4,
  darkGrey5,
  darkGrey6,
  gray4,
  gray80,
  gray90,
  white,
} from "../constants/colors";

export const maxScreensSizes = {
  mobile: 480,
  tablet: 1024,
  smallPc: 1408,
  pc: 1920,
};

export const maxScreensSizesPx = {
  mobile: `${maxScreensSizes.mobile}px`,
  tablet: `${maxScreensSizes.tablet}px`,
  smallPc: `${maxScreensSizes.smallPc}px`,
  pc: `${maxScreensSizes.pc}px`,
};

export const tableScrollWidth = 600;

export const StyledPage = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 12px;

  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}
`;

export const StyledPageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 2rem;

  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}

  ${props =>
    props.fill &&
    css`
      background-color: ${white};
    `}
  
  ${props =>
    props.$noPadding &&
    css`
      padding: 0;
    `}  

  ${props =>
    props.$inner &&
    css`
      padding: 1rem;
    `}

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: ${props => (props.$inner ? "1rem" : 0)};
  }

  .ant-upload.ant-upload-drag {
    background-color: inherit;
    border: none;
    cursor: auto;
    position: relative;
    text-align: initial;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      background-color: transparent;
      border: 2px dashed transparent;
      transition: all 0.3s ease;

      pointer-events: none;
    }

    &.ant-upload-drag-hover {
      &:before {
        border: 2px dashed ${gray4};
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 999;
      }
    }
  }
`;

export const StyledLabelsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledUploadSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid ${gray4};
  cursor: pointer;
  padding: 1rem;
`;

export const StyledPageSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0 1rem;
  margin: 0 0 2rem;

  ${props =>
    props.nopadding &&
    css`
      padding: 0;
    `}

  ${props =>
    props.nospace &&
    css`
      margin: 0;
      padding: 0;
    `}

  ${props =>
    props.grow &&
    css`
      flex: 1 1 auto;
    `}

  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${gray4};
    `}
`;

export const StyledPageSectionHeader = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 0;
  margin: 0 0 1rem;
  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 1rem 0.5rem;
  }
`;

export const StyledPageSectionTitle = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${gray80};

  > svg path {
    fill: currentColor;
  }
`;

export const StyledPageSectionSubTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: ${darkGrey5};
`;

export const StyledPageSectionActions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const StyledPageHeader = styled.div`
  display: flex;
  column-gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: ${white};
  border-bottom: 1px solid ${gray4};
  color: ${darkGrey6};
  min-height: 4.5rem;
`;

export const StyledPageTitle = styled.p`
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${darkGrey6};

  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${props =>
    props.bold &&
    css`
      font-weight: 700;
    `}

  ${props =>
    props.nomargin &&
    css`
      margin: 0;
    `}
  
  ${props =>
    props.$hasMargin &&
    css`
      margin-right: 24px;
    `}
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const StyledSectionContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 1rem 2rem 0 2rem;
  padding: 1rem 2rem;
  background-color: ${white};
  box-sizing: border-box;

  ${props =>
    props.nomargin &&
    css`
      margin: 0;
    `}
`;

export const StyledSectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.379166px;
  text-transform: uppercase;

  color: ${darkGrey2};
  margin-bottom: 0.5em;

  ${props =>
    props.nomargin &&
    css`
      margin: 0;
    `}
`;

export const StyledSectionText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  color: ${darkGrey3};
  margin-bottom: 0.5em;
`;

export const StyledPageHeaderTitleContainer = styled.div`
  margin: 24px 32px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const StyledPageHeaderTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${darkGrey4};
  margin: 0;
`;

export const StyledPageHeaderContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 24px;
`;

export const StyledPageHeaderScrollContainer = styled.div`
  overflow-y: auto;
`;

export const StyledPageHeaderActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
`;

export const StyledPortfolioProgressTitle = styled.p`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${darkGrey5};
  margin: 0;
  text-transform: capitalize;
`;

export const StyledPortfolioProgressValue = styled.p`
  font-style: normal;
  font-size: 12px;
  line-height: 16px;

  ${({ status = "normal" }) =>
    status === "exception"
      ? css`
          color: ${accentRed};
        `
      : css`
          color: ${darkGrey4};
        `};

  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}

  margin: 0;
  white-space: pre-line;
`;

export const StyledPortfolioProgress = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 26px;
  box-sizing: border-box;
  flex: 0 0 auto;

  .ant-progress-inner {
    background-color: ${blueWhite};
  }

  .ant-progress-text {
    font-size: 0.6rem;
    color: ${darkGrey6} !important;
  }
`;

export const StyledPortfolioProgressData = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: 1rem;
`;

export const StyledPageText = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: ${darkGrey6};
  margin: 0 0 0.5em;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0 0 2rem;

  ${props =>
    props.centered &&
    css`
      width: 100%;
      max-width: 1024px;
    `}

  ${props =>
    props.grow &&
    css`
      flex: 1 1 auto;
    `}

  ${props =>
    props.nomargin &&
    css`
      margin: 0;
    `}
`;

export const StyledCardText = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: ${darkGrey6};
  margin: 0;
`;

export const StyledCardTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: ${darkGrey6};
`;

export const StyledProgress = styled.div`
  display: none;
  align-items: center;
  justify-content: center;

  .anticon-loading {
    width: 32px;
    height: 32px;
  }
`;

export const StyledProgressButton = styled.div`
  display: none;
  align-items: center;
  justify-content: center;

  .ant-btn-icon-only {
    height: 38px;
    border: none;
    box-shadow: none;
  }
`;

export const StyledProgressActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${StyledProgress} {
    display: flex;
  }

  &:hover {
    ${StyledProgress} {
      display: none;
    }
    ${StyledProgressButton} {
      display: flex;

      .ant-btn {
        background: transparent;
        border-color: ${white};
        color: ${white};
      }
    }
  }
`;

export const StyledLayout = styled(Layout)`
  padding: 3rem 2rem;
  background: transparent;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 2rem 1rem;
  }
`;

export const StyledPageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 40px 0 10px 0;
  max-width: 450px;
`;

export const StyledTableText = styled("div")`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${gray90};
`;
