import { useCallback, useEffect, useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { capitalize } from "lodash";
import {
  getPortfolioGenerationByUUID,
  getPortfolioHistogramData,
  getPortfolioIndustries,
  getPortfolioPieData,
} from "../../../../../api/endpoints/portfolios";
import { handleErrorMessages } from "../../../../../utils/messages";
import { PageContext } from "../../../../../layout/ResultLayout/context";

const useChartsData = ({ step }) => {
  const { currentGenerations } = useContext(PageContext);

  const [currentGenerationUUID, setCurrentGenerationUUID] = useState(null);
  const [ratingChartData, setRatingChartData] = useState([]);
  const [ratingColumn, setRatingColumn] = useState("moodys");

  const [pieChartData, setPieChartData] = useState([]);
  const [column, setColumn] = useState("issuer");

  const [histData, setHistData] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [distColumn, setDistColumn] = useState("warf");

  const [resultList, setResultList] = useState([]);

  const convertResults = useCallback((resObj = {}) => {
    const resArray = Object.values(resObj) || [];
    return resArray.map((item, index) => ({ ...item, gen: index }));
  }, []);

  const updatePortfolioResult = useCallback(
    currentStep => {
      const generation = currentGenerations.find(
        gen => gen.step === currentStep,
      );
      if (!generation) return;
      setCurrentGenerationUUID(generation.uuid);
      getPortfolioGenerationByUUID(generation.uuid)
        .then(data => {
          setResultList(
            convertResults(
              data?.portfolio?.portfolio_group?.result?.payload?.steps,
            ),
          );
        })
        .catch(error => handleErrorMessages(error));
    },
    [convertResults, currentGenerations],
  );

  const getIndustriesList = useCallback(
    generationUUID => {
      if (!generationUUID) return;
      getPortfolioIndustries({ generationUUID })
        .then(data => {
          setIndustries(data);
          if (industry.length === 0) {
            setIndustry(data?.[0]);
          }
        })
        .catch(error => handleErrorMessages(error));
    },
    [industry.length],
  );

  useEffect(() => {
    getIndustriesList(currentGenerationUUID);
  }, [currentGenerationUUID, getIndustriesList]);

  useEffect(() => {}, [step, currentGenerations]);

  const getHistogramData = useCallback(
    generationUUID => {
      if (!generationUUID) return;
      getPortfolioHistogramData({
        generationUUID,
        industry,
        distColumn,
      })
        .then(data => setHistData(data))
        .catch(error => handleErrorMessages(error));
    },
    [distColumn, industry],
  );

  useEffect(() => {
    if (industry.length) {
      getHistogramData(currentGenerationUUID);
    } else {
      setHistData([]);
    }
  }, [industry, distColumn, currentGenerationUUID, getHistogramData]);

  const onIndustryChange = useCallback(
    value => setIndustry(value),
    [setIndustry],
  );

  const onDistColumnChange = useCallback(
    value => setDistColumn(value),
    [setDistColumn],
  );

  const onChangeAttribute = useCallback(value => setColumn(value), [setColumn]);

  const onChangeRatingColumn = useCallback(
    value => setRatingColumn(value),
    [setRatingColumn],
  );

  const calculateTypes = useCallback((data = [], col = "moodys") => {
    const rateColumn = col === "moodys" ? "Rtg Moody" : "Rtg Fitch";
    const results = {};

    data?.forEach(item => {
      const itemData = item[rateColumn] || "None";
      results[itemData] ||= 0;
      results[itemData] += 1;
    });

    const resultData = [];
    for (const [key, value] of Object.entries(results)) {
      resultData.push({
        type: key,
        value: (value * 100) / data.length,
      });
    }

    return resultData;
  }, []);

  const getRatingChartData = useCallback(
    (data, col) => {
      const chartData = calculateTypes(data, col);
      setRatingChartData(chartData);
    },
    [calculateTypes],
  );

  const getPieChartData = useCallback(
    generationUUID => {
      if (!generationUUID) return;
      getPortfolioPieData({ generationUUID, column })
        .then(data => setPieChartData(data))
        .catch(error => handleErrorMessages(error));
    },
    [column],
  );

  useEffect(() => {
    updatePortfolioResult(step);
  }, [step, updatePortfolioResult]);

  useEffect(() => {
    getPieChartData(currentGenerationUUID);
  }, [currentGenerationUUID, column, getPieChartData]);

  const columns = useMemo(
    () => [
      {
        title: capitalize(column),
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Percentage",
        dataIndex: "value",
        key: "value",
      },
    ],
    [column],
  );

  const histConfig = useMemo(
    () => ({
      data: histData,
      binField: distColumn,
      interactions: [
        {
          type: "element-highlight",
        },
      ],
      meta: {
        yField: {
          range: [0, 20],
        },
      },
      tooltip: {
        title: (data, { range }) => {
          return `${Number(range[0]).toFixed(0)} - ${Number(range[1]).toFixed(
            0,
          )}`;
        },
      },
    }),
    [distColumn, histData],
  );

  return useMemo(
    () => ({
      pieChartData,
      onChangeAttribute,

      histData,
      industry,
      distColumn,
      onIndustryChange,
      onDistColumnChange,

      resultList,

      selectedColumn: column,
      columns,
      industries,
      histConfig,

      ratingChartData,
      ratingColumn,
      onChangeRatingColumn,
      getRatingChartData,
    }),
    [
      column,
      columns,
      distColumn,
      getRatingChartData,
      histConfig,
      histData,
      industries,
      industry,
      onChangeAttribute,
      onChangeRatingColumn,
      onDistColumnChange,
      onIndustryChange,
      pieChartData,
      ratingChartData,
      ratingColumn,
      resultList,
    ],
  );
};

useChartsData.propTypes = {
  step: PropTypes.number.isRequired,
};

export default useChartsData;
