import { useContext, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Select, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { PageContext } from "../../../layout/ResultLayout/context";
import ModalDialog from "../../../components/Modals/ModalDialog";
import { getPortfolioArguments } from "../../../api/endpoints/portfolios";
import { handleErrorMessages } from "../../../utils/messages";
import StyledButton, {
  StyledTextButton,
} from "../../../components/Buttons/Button.styled";
import {
  StyledInputLabel,
  StyledSelectContainer,
  StyledSelectContainerMultiple,
} from "../../../components/Form/styles";
import {
  StyledModalButtonsContainer,
  StyledModalPrimaryText,
  StyledModalShadowBtnContainer,
} from "../../../components/Modals/ModalDialog.styled";
import {
  StyledBriefcaseIcon,
  StyledPreviewIcon,
} from "../../../components/Icons";
import SelectedConstraints from "../../ResultDetails/content/modals/SelectedConstraintsModal/SelectedConstraints";
import { darkGrey2 } from "../../../constants/colors";
import { StyledPortfolioName, StyledPortfolioListContainer } from "../styles";
import useScrollTop from "../../../hooks/useScrollTop";

const { Option } = Select;

const PortfolioListModal = ({
  isOpen = false,
  onClose,
  constraintsData,
  setIsLoading = () => {},
  loading = false,
}) => {
  const [scrollTop, scrollProps] = useScrollTop();

  const { portfolioList } = useContext(PageContext);
  const { selectedPortfolio: currentPortfolio, onUpdateAllConstraints } =
    constraintsData;

  const [optionsFrom, setOptionsFrom] = useState([]);
  const [optionsTo, setOptionsTo] = useState([]);
  const [showConstraints, setShowConstraints] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [selectedPortfolioConstraints, setSelectedPortfolioConstraints] =
    useState({});
  const [portfoliosToAplly, setPortfoliosToApply] = useState([]);

  const onChangeSelectedPortfolio = useCallback(
    value => {
      const portfolio = portfolioList.find(item => item.uuid === value);
      setSelectedPortfolio(portfolio);
      setPortfoliosToApply([]);
      if (value) {
        getPortfolioArguments(value)
          .then(data => setSelectedPortfolioConstraints(data))
          .catch(error => handleErrorMessages(error));
      }
    },
    [portfolioList],
  );

  const modalTitle = showConstraints
    ? "Preview Constraints"
    : "Select From Portfolio";

  const getFilteredList = useCallback(
    excludedId => {
      const filteredList = portfolioList?.filter(
        item => item.uuid !== excludedId,
      );
      return filteredList;
    },
    [portfolioList],
  );

  const setInitData = useCallback(() => {
    const filteredListFrom = getFilteredList(currentPortfolio.uuid);
    const firstPortfolio = filteredListFrom[0];
    if (firstPortfolio && isOpen) {
      onChangeSelectedPortfolio(firstPortfolio?.uuid);
    }
    setOptionsFrom(filteredListFrom);

    const filteredListTo = getFilteredList(firstPortfolio?.uuid);
    setOptionsTo(filteredListTo);

    setPortfoliosToApply([currentPortfolio?.uuid]);
  }, [
    currentPortfolio.uuid,
    getFilteredList,
    isOpen,
    onChangeSelectedPortfolio,
  ]);

  const handleClose = () => {
    onClose();
    setShowConstraints(false);
    setInitData();
  };

  const handleCancel = () => {
    if (showConstraints) {
      setShowConstraints(false);
    } else {
      handleClose();
    }
  };

  const handleApplyConstraints = () => {
    setIsLoading(true);
    Promise.all(
      portfoliosToAplly?.map((portfolioUUID, index) => {
        const updatedPortfolio = portfolioList?.find(
          item => item?.uuid === portfolioUUID,
        );
        return onUpdateAllConstraints({
          portfolioUUID,
          name: updatedPortfolio?.name,
          data: selectedPortfolioConstraints,
          isLast: index === portfoliosToAplly.length - 1,
          setLoading: setIsLoading,
          handleSuccess: () => {
            handleClose();
            message.success({
              content: "Constraints updated",
              className: "exos-message",
            });
          },
        });
      }),
    );
  };

  useEffect(() => {
    setInitData();
  }, [setInitData]);

  useEffect(() => {
    const filteredListTo = getFilteredList(selectedPortfolio?.uuid);
    setOptionsTo(filteredListTo);
  }, [getFilteredList, selectedPortfolio]);

  return (
    <ModalDialog
      title={
        <>
          {modalTitle}
          {showConstraints && (
            <StyledPortfolioName>
              <StyledBriefcaseIcon />
              {selectedPortfolio?.name}
            </StyledPortfolioName>
          )}
        </>
      }
      isOpen={isOpen}
      onClose={handleClose}
      width="auto"
      scrollTop={showConstraints ? scrollTop : 0}
      dataCy="select-from-modal"
      noContentPadding={showConstraints}
      noHeaderPadding={!showConstraints}
    >
      {showConstraints ? (
        <>
          <SelectedConstraints
            selectedPortfolio={selectedPortfolio}
            scrollProps={scrollProps}
          />
          <StyledModalShadowBtnContainer>
            <StyledButton onClick={handleCancel} type="secondary" $autoWidth>
              Cancel
            </StyledButton>
            <StyledButton
              onClick={handleApplyConstraints}
              $autoWidth
              disabled={!selectedPortfolio || loading}
            >
              {loading ? <LoadingOutlined /> : "Apply"}
            </StyledButton>
          </StyledModalShadowBtnContainer>
        </>
      ) : (
        <>
          <StyledPortfolioListContainer>
            <StyledModalPrimaryText>
              You can copy Constraints from the another Portfolio. Please select
              needed Portfolio from the list.
            </StyledModalPrimaryText>
            <StyledSelectContainer>
              <StyledInputLabel uppercase color={darkGrey2}>
                Select from portfolio
              </StyledInputLabel>
              <Select
                showSearch={false}
                placeholder="Select from portfolio"
                optionFilterProp="children"
                value={selectedPortfolio?.uuid || ""}
                onChange={onChangeSelectedPortfolio}
                data-cy="copy-from-select"
              >
                {optionsFrom.map(portfolio => (
                  <Option value={portfolio.uuid} key={portfolio.uuid}>
                    {portfolio.name}
                  </Option>
                ))}
              </Select>
            </StyledSelectContainer>
            <StyledSelectContainerMultiple>
              <StyledInputLabel uppercase color={darkGrey2}>
                Select to portfolio (accept array)
              </StyledInputLabel>
              <Select
                showSearch={false}
                mode="multiple"
                allowClear
                placeholder="Select an attribute"
                optionFilterProp="children"
                value={portfoliosToAplly || []}
                onChange={values => {
                  setPortfoliosToApply(values);
                }}
              >
                {optionsTo.map(portfolio => (
                  <Option value={portfolio.uuid} key={portfolio.uuid}>
                    {portfolio.name}
                  </Option>
                ))}
              </Select>
            </StyledSelectContainerMultiple>
            <StyledTextButton
              $primary
              type="text"
              $noPadding
              disabled={!selectedPortfolio}
              icon={<StyledPreviewIcon />}
              onClick={() => setShowConstraints(true)}
              data-cy="preview-copied-constraints"
            >
              preview constraints
            </StyledTextButton>
          </StyledPortfolioListContainer>
          <StyledModalButtonsContainer>
            <StyledButton onClick={handleClose} type="secondary" $autoWidth>
              Cancel
            </StyledButton>
            <StyledButton
              onClick={handleApplyConstraints}
              $autoWidth
              type="primary"
              disabled={
                !(selectedPortfolio && portfoliosToAplly.length) || loading
              }
              data-cy="apply-copied-constraints"
            >
              {loading ? <LoadingOutlined /> : "Apply"}
            </StyledButton>
          </StyledModalButtonsContainer>
        </>
      )}
    </ModalDialog>
  );
};

PortfolioListModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  onClose: PropTypes.func,
  currentPortfolio: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
  }),
  constraintsData: PropTypes.shape(),
};

export default PortfolioListModal;
