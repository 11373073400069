/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
import { Tooltip } from "antd";

import {
  StyledCardDetails,
  StyledCardDetailsRow,
  StyledCardDetailsSection,
  StyledCardDetailsStatTitle,
  StyledSellBlock,
  StyledCardDetailsText,
  StyledCardDetailsTitle,
  StyledCardDivider,
  StyledButtonsContainer,
  StyledCardDetailsRowActive,
  StyledCardDetailsRowActiveTransactions,
  StyledCardDetailsRowContainer,
  StyledArrowContainer,
  StyledArrowBlock,
  StyledDot,
  StyledCardDetailsHeader,
  StyledCompareBlock,
  StyledTitlesBlock,
  StyledCompareButtons,
  StyledDiffValue,
} from "./styles";
import {
  StyledRemoveCompareIcon,
  StyledDoubleArrowLeftIcon,
  StyledDoubleArrowRightIcon,
  StyledDownloadIcon,
  StyledCompareIcon,
  StyledEmptyTradeSummaryIcon,
  StyledCloseIcon,
  StyledStarCompareIcon,
  StyledStarSelectIcon,
  StyledTradeInfo,
} from "../../../../components/Icons";

import StyledButton, {
  StyledTextButton,
} from "../../../../components/Buttons/Button.styled";
import { StyledEmptyView } from "../ResultContent/styles";
import {
  moneyFormatter,
  transfromFloatingNumbers,
} from "../../../../utils/dataFormatters";
import { accentRed, green } from "../../../../constants/colors";

const SolutionDetails = ({
  selectedValues,
  selectedCollapseIndex,
  selectedDataPoint,
  compareDataPoint,
  valuesToCompare,
  filteredObjectiveDropdownOptions,
  generateDetailsLink,
  onBtnExport,
  setValuesToCompare,
  setCompareDataPoint,
  isMinBest,
  handleSelectOption,
}) => {
  const navigate = useNavigate();
  const [compareValuesClass, setCompareValuesClass] = useState("");

  const handleChangeCompareValuesClass = () => {
    setCompareValuesClass(prevValue => (prevValue ? "" : "columns-reverse"));
  };

  const showCompareValues =
    !isEqual(selectedDataPoint, compareDataPoint) && valuesToCompare;

  const isActive = selectedValues !== null;
  const portfolioGenerations =
    selectedValues?.sourceData?.[selectedCollapseIndex]?.portfolioGenerations ||
    null;
  const selectedCollapsedValues =
    selectedValues?.sourceData?.[selectedCollapseIndex];

  const selectedCollapsedValuesToCompare =
    valuesToCompare?.sourceData?.[selectedCollapseIndex];

  const diffData = useCallback(
    (currValue, compareValue, objectiveFunc) => {
      const isMinBestData = !objectiveFunc ? false : isMinBest[objectiveFunc];
      const diff = currValue - compareValue;
      const isEqualData = isEqual(selectedValues, valuesToCompare);

      const diffObject = {
        value: objectiveFunc
          ? transfromFloatingNumbers(Math.abs(diff))
          : Math.abs(diff),
        isEqual: isEqualData || !diff,
      };

      if ((diff < 0 && isMinBestData) || (diff > 0 && !isMinBestData)) {
        diffObject.isBetter = true;
      } else {
        diffObject.isBetter = false;
      }
      return diffObject;
    },
    [isMinBest, selectedValues, valuesToCompare],
  );

  const averageCurrentHoldings =
    selectedCollapsedValues?.averageCurrentHoldings;
  const averageSellAmount = selectedCollapsedValues?.averageSellAmount;

  const averageCurrentHoldingsCompare =
    selectedCollapsedValuesToCompare?.averageCurrentHoldings;
  const averageSellAmountCompare =
    selectedCollapsedValuesToCompare?.averageSellAmount;

  const averageCurrentHoldingsDiff =
    valuesToCompare &&
    diffData(averageCurrentHoldings, averageCurrentHoldingsCompare, null);

  const averageCurrentHoldingsDiffColor = averageCurrentHoldingsDiff?.isBetter
    ? green
    : accentRed;

  const averageSellAmountDiff =
    valuesToCompare &&
    diffData(averageSellAmount, averageSellAmountCompare, null);

  const transactionsDiff =
    valuesToCompare &&
    diffData(selectedValues?.transactions, valuesToCompare?.transactions, null);

  const objectives = filteredObjectiveDropdownOptions?.map((item, index) => {
    const currentValue = transfromFloatingNumbers(
      selectedCollapsedValues?.[item.value],
    );
    const currentValueToCompare = transfromFloatingNumbers(
      selectedCollapsedValuesToCompare?.[item.value],
    );

    const diff = selectedCollapsedValuesToCompare
      ? diffData(
          selectedCollapsedValues[item.value],
          selectedCollapsedValuesToCompare[item.value],
          item.value,
        )
      : 0;
    const isLast = index === filteredObjectiveDropdownOptions.length - 1;
    const selectedIndex = filteredObjectiveDropdownOptions.findIndex(
      itemData => itemData.value === selectedDataPoint?.objFunc,
    );
    const isSelected = selectedIndex === index;

    return (
      <StyledCardDetailsRowContainer key={item.label} $isSelected={isSelected}>
        <StyledCardDetailsRowActive
          $isSelected={isSelected}
          $hasBorder={index !== selectedIndex - 1 && !isLast}
        >
          <StyledCardDetailsText>
            <StyledDot $color={item?.color} />
            <span>{item.label}</span>
          </StyledCardDetailsText>
          <StyledTitlesBlock className={compareValuesClass}>
            {showCompareValues && (
              <StyledCompareBlock>
                <StyledCardDetailsText $rightAlign>
                  <StyledDiffValue $color={diff?.isBetter ? green : accentRed}>
                    {currentValueToCompare}
                  </StyledDiffValue>
                </StyledCardDetailsText>
              </StyledCompareBlock>
            )}
            <StyledCompareBlock>
              <StyledCardDetailsText $rightAlign>
                {currentValue}
              </StyledCardDetailsText>
            </StyledCompareBlock>
          </StyledTitlesBlock>
        </StyledCardDetailsRowActive>
      </StyledCardDetailsRowContainer>
    );
  });

  return (
    <StyledCardDetails>
      <StyledCardDetailsSection>
        <StyledCardDetailsHeader>
          <StyledCardDetailsTitle $active={isActive}>
            Solution Details
          </StyledCardDetailsTitle>
          {compareDataPoint &&
          selectedDataPoint &&
          !isEqual(compareDataPoint, selectedDataPoint) ? (
            <StyledCompareButtons>
              <StyledTextButton
                type="text"
                $primary
                $normal
                icon={<StyledCloseIcon />}
                onClick={() => {
                  handleSelectOption(compareDataPoint);
                  setValuesToCompare(null);
                  setCompareDataPoint(null);
                }}
              >
                Close comparison
              </StyledTextButton>
              <Tooltip
                placement="leftTop"
                title="Comparison mode allows you to compare the selected parameters to evaluate their deviation. Add the  needed parameter to the comparison by clicking on the button and then select the next parameter on the chart for comparison."
              >
                <StyledTradeInfo />
              </Tooltip>
            </StyledCompareButtons>
          ) : (
            isActive && (
              <StyledCompareButtons>
                {!valuesToCompare ? (
                  <StyledTextButton
                    type="text"
                    $primary
                    $normal
                    icon={<StyledCompareIcon />}
                    onClick={() => {
                      setValuesToCompare(selectedValues);
                      setCompareDataPoint(selectedDataPoint);
                    }}
                  >
                    Pin for comparison
                  </StyledTextButton>
                ) : (
                  <StyledTextButton
                    type="text"
                    $primary
                    $normal
                    icon={<StyledRemoveCompareIcon />}
                    onClick={() => {
                      setValuesToCompare(null);
                      setCompareDataPoint(null);
                    }}
                  >
                    Unpin from comparison
                  </StyledTextButton>
                )}
                <Tooltip
                  placement="leftTop"
                  title="Comparison mode allows you to compare the selected parameters to evaluate their deviation. Add the  needed parameter to the comparison by clicking on the button and then select the next parameter on the chart for comparison."
                >
                  <StyledTradeInfo />
                </Tooltip>
              </StyledCompareButtons>
            )
          )}
        </StyledCardDetailsHeader>
        <StyledCardDivider />
        {isActive && (
          <>
            <StyledCardDetailsRow>
              <StyledCardDetailsStatTitle $uppercase>
                value name
              </StyledCardDetailsStatTitle>
              <StyledTitlesBlock className={compareValuesClass}>
                {showCompareValues && (
                  <StyledCompareBlock>
                    <StyledCardDetailsStatTitle $rightAlign $uppercase>
                      <StyledStarCompareIcon />
                      <span>value</span>
                    </StyledCardDetailsStatTitle>
                  </StyledCompareBlock>
                )}
                <StyledCompareBlock
                  onClick={() => {
                    handleChangeCompareValuesClass();
                    if (compareDataPoint) {
                      handleSelectOption(compareDataPoint);
                    }
                    setValuesToCompare(selectedValues);
                    setCompareDataPoint(selectedDataPoint);
                  }}
                >
                  <StyledCardDetailsStatTitle $rightAlign $uppercase>
                    {valuesToCompare && !showCompareValues ? (
                      <StyledStarCompareIcon />
                    ) : (
                      <StyledStarSelectIcon />
                    )}
                    <span>value</span>
                  </StyledCardDetailsStatTitle>
                </StyledCompareBlock>
              </StyledTitlesBlock>
            </StyledCardDetailsRow>
            <StyledCardDetailsRowContainer $isSelected>
              <StyledCardDetailsRowActiveTransactions $noPadding $isSelected>
                <StyledArrowContainer>
                  <StyledArrowBlock
                    onClick={() => {
                      if (selectedDataPoint.index > 0) {
                        handleSelectOption({
                          ...selectedDataPoint,
                          index: selectedDataPoint.index - 1,
                        });
                      }
                    }}
                  >
                    <StyledDoubleArrowLeftIcon />
                  </StyledArrowBlock>
                  <StyledCardDetailsText $noPadding>
                    Transactions
                  </StyledCardDetailsText>
                </StyledArrowContainer>
                <StyledArrowContainer $rightAlign>
                  <StyledTitlesBlock className={compareValuesClass}>
                    {showCompareValues && (
                      <StyledCompareBlock>
                        <StyledCardDetailsText $rightAlign $noPadding>
                          <StyledDiffValue
                            $color={
                              transactionsDiff?.isBetter ? green : accentRed
                            }
                          >
                            {valuesToCompare?.transactions}
                          </StyledDiffValue>
                        </StyledCardDetailsText>
                      </StyledCompareBlock>
                    )}
                    <StyledCompareBlock>
                      <StyledCardDetailsText $rightAlign $noPadding>
                        {selectedValues.transactions}
                      </StyledCardDetailsText>
                    </StyledCompareBlock>
                  </StyledTitlesBlock>
                  <StyledArrowBlock
                    onClick={() => {
                      if (selectedValues?.lastIndex > selectedDataPoint.index) {
                        handleSelectOption({
                          ...selectedDataPoint,
                          index: selectedDataPoint.index + 1,
                        });
                      }
                    }}
                  >
                    <StyledDoubleArrowRightIcon />
                  </StyledArrowBlock>
                </StyledArrowContainer>
              </StyledCardDetailsRowActiveTransactions>
            </StyledCardDetailsRowContainer>
            {objectives}
            <StyledSellBlock>
              <StyledCardDetailsRowContainer>
                <StyledCardDetailsRowActive>
                  <StyledCardDetailsText>
                    <StyledDot />
                    <span>Average Sell/Replace</span>
                  </StyledCardDetailsText>
                  <StyledTitlesBlock className={compareValuesClass}>
                    {showCompareValues && (
                      <StyledCompareBlock>
                        <StyledCardDetailsText $rightAlign>
                          <StyledDiffValue
                            $color={
                              averageSellAmountDiff?.isBetter
                                ? green
                                : accentRed
                            }
                          >
                            {moneyFormatter.format(averageSellAmountCompare)}
                          </StyledDiffValue>
                        </StyledCardDetailsText>
                      </StyledCompareBlock>
                    )}
                    <StyledCompareBlock>
                      <StyledCardDetailsText $rightAlign>
                        {moneyFormatter.format(averageSellAmount)}
                      </StyledCardDetailsText>
                    </StyledCompareBlock>
                  </StyledTitlesBlock>
                </StyledCardDetailsRowActive>
              </StyledCardDetailsRowContainer>
              <StyledCardDivider $dashed />
              <StyledCardDetailsRowContainer>
                <StyledCardDetailsRowActive>
                  <StyledCardDetailsText>
                    <StyledDot />
                    <span>Average Current Holdings</span>
                  </StyledCardDetailsText>
                  <StyledTitlesBlock className={compareValuesClass}>
                    {showCompareValues && (
                      <StyledCompareBlock>
                        <StyledCardDetailsText $rightAlign>
                          <StyledDiffValue
                            $color={
                              !averageCurrentHoldingsDiff?.isEqual
                                ? averageCurrentHoldingsDiffColor
                                : null
                            }
                          >
                            {moneyFormatter.format(
                              averageCurrentHoldingsCompare,
                            )}
                          </StyledDiffValue>
                        </StyledCardDetailsText>
                      </StyledCompareBlock>
                    )}
                    <StyledCompareBlock>
                      <StyledCardDetailsText $rightAlign>
                        {moneyFormatter.format(averageCurrentHoldings)}
                      </StyledCardDetailsText>
                    </StyledCompareBlock>
                  </StyledTitlesBlock>
                </StyledCardDetailsRowActive>
              </StyledCardDetailsRowContainer>
            </StyledSellBlock>
          </>
        )}
        {!isActive && (
          <StyledEmptyView>
            <StyledEmptyTradeSummaryIcon />
            <div>No Data</div>
            <StyledCardDetailsText>
              Click on the spot on the left graph to see the details...
            </StyledCardDetailsText>
          </StyledEmptyView>
        )}
        <StyledButtonsContainer>
          <StyledTextButton
            $primary
            type="text"
            icon={<StyledDownloadIcon />}
            onClick={() => onBtnExport(true)}
            disabled={!isActive}
          >
            download portfolios
          </StyledTextButton>
          <StyledButton
            disabled={!isActive}
            onClick={() => navigate(generateDetailsLink(portfolioGenerations))}
          >
            View Portfolios
          </StyledButton>
        </StyledButtonsContainer>
      </StyledCardDetailsSection>
    </StyledCardDetails>
  );
};

SolutionDetails.propTypes = {
  selectedValues: PropTypes.shape(),
  selectedCollapseIndex: PropTypes.number,
  selectedDataPoint: PropTypes.shape(),
  compareDataPoint: PropTypes.shape(),
  valuesToCompare: PropTypes.shape(),
  handleSelectOption: PropTypes.func,
  filteredObjectiveDropdownOptions: PropTypes.arrayOf(PropTypes.shape()),
  generateDetailsLink: PropTypes.func,
  onBtnExport: PropTypes.func,
  setValuesToCompare: PropTypes.func,
  setCompareDataPoint: PropTypes.func,
  isMinBest: PropTypes.shape(),
};

export default SolutionDetails;
