export const handleDownloadData = (response, name) => {
  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  const objectUrl = window.URL.createObjectURL(
    new Blob([response], { type: "text/plain" }),
  );
  anchor.href = objectUrl;
  anchor.download = name;
  anchor.click();
  window.URL.revokeObjectURL(objectUrl);
  anchor.remove();
};

export default handleDownloadData;
