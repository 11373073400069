import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import ReactApexChart from "react-apexcharts";
import StyledCard from "../../../../../components/Card";
import { StyledChartWrapper } from "../../../styles";

const { Option } = Select;

const RatingChartCard = ({ chartsData, filterProps }) => {
  const {
    ratingChartData = [],
    ratingColumn,
    onChangeRatingColumn,
    getRatingChartData,
  } = chartsData;

  useEffect(() => {
    getRatingChartData(filterProps.data, ratingColumn);
  }, [filterProps.data, getRatingChartData, ratingColumn]);

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const chartSeries = ratingChartData.map(({ value }) => value);
    const chartLabels = ratingChartData.map(({ type }) => type);
    const newOptions = {
      chart: {
        type: "pie",
        width: "100%",
        height: 350,
      },
      dataLabels: {
        style: {
          fontSize: "12px",
          fontFamily: "Onest,'Open Sans',Helvetica,Arial,sans-serif",
        },
        formatter: val => `${val.toFixed(2)}%`,
      },
      legend: {
        show: true,
        position: "bottom",
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: "Onest,'Open Sans',Helvetica,Arial,sans-serif",
        },
        y: {
          formatter: val => `${val.toFixed(2)}%`,
        },
      },
      labels: chartLabels,
    };

    setOptions(newOptions);
    setSeries(chartSeries);
  }, [ratingChartData]);

  const distActions = (
    <Select
      showSearch
      placeholder="Select an attribute"
      optionFilterProp="children"
      value={ratingColumn}
      onChange={onChangeRatingColumn}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      <Option value="moodys">Moodys</Option>
      <Option value="fitch">Fitch</Option>
    </Select>
  );

  return (
    <StyledCard
      grow
      dataCy="result-ratingChart"
      title="Rating"
      actions={distActions}
    >
      <StyledChartWrapper>
        <ReactApexChart
          type="donut"
          options={options}
          series={series}
          height={350}
        />
      </StyledChartWrapper>
    </StyledCard>
  );
};

RatingChartCard.propTypes = {
  chartsData: PropTypes.shape(),
  filterProps: PropTypes.shape(),
};

export default RatingChartCard;
