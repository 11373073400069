import { Navigate, Route, Routes } from "react-router-dom";
import { useState, useCallback, useEffect, useContext, Fragment } from "react";
import * as Sentry from "@sentry/react";
import { useOktaAuth } from "@okta/okta-react";
import Constraints from "./features/Constraints";
import PortfolioBuilder from "./features/ResultDetails";
import AppLayout from "./layout/AppLayout";
import WorkbookLayout from "./layout/WorkbookLayout";
import * as routes from "./constants/routes";
import DataFilters from "./features/DataFilters";
import HandleLogIn from "./HandleLogIn";
import ProtectedArea from "./components/ProtectedRoute";
import Results from "./features/ResultList";
import LoginPage from "./features/Auth/LoginPage";
import WelcomePage from "./features/Auth/WelcomePage";
import { configureAxiosInstance } from "./api/axiosInstance";
import WorkbooksPage from "./features/Workbooks";
import NotFound from "./features/ErrorPages/NotFound";
import AppContent from "./layout/AppLayout/content";
import config from "./config";
import TradeSummaryPage from "./features/ResultDetails/content/TradeSummary";
import ResultLayout from "./layout/ResultLayout";
import PoliciesAndDisclosuresContainer from "./components/PoliciesAndDisclosures/PoliciesAndDisclosuresContainer";
import ContributorsTable from "./features/Workbooks/content/ContributorsTable";
import TermsAndConditionsContainer from "./components/TermsAndConditions/TermsAndConditionsContainer";
import TestSentry from "./features/ErrorPages/TestSentry";
import SettingsLayout from "./features/Settings";
import AdminPageLayout from "./features/Admin";
import { getAdminMenu } from "./api/endpoints/menu";
import TableComponent from "./features/Admin/Table";
import { handleErrorMessages } from "./utils/messages";
import { PageContext } from "./layout/AppLayout/context";
import DetailedView from "./features/Admin/DetailedView";

if (config.environment !== "development") {
  if (window.location.protocol !== "https:") {
    window.location.replace(
      `https:${window.location.href.substring(
        window.location.protocol.length,
      )}`,
    );
  }

  if (config.SENTRY_DSN) {
    Sentry.init({
      dsn: config.SENTRY_DSN,
      integrations: [new Sentry.Replay()],
      environment: config.environment,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}

const App = () => {
  const { user } = useContext(PageContext);
  const { authState, oktaAuth } = useOktaAuth();
  const { isAuthenticated } = authState || {};
  const isOktaAuthFlow = process.env.REACT_APP_AUTH_FLOW === "okta";
  const token = localStorage.getItem("accessToken");

  const [adminMenu, setAdminMenu] = useState([]);

  const isAuth = isAuthenticated || process.env.REACT_APP_FAKE_TOKEN;
  const hasToken = isOktaAuthFlow ? isAuth : token;

  if (isAuth && isOktaAuthFlow) {
    const accessTokenProvider = async () => {
      if (process.env.REACT_APP_FAKE_TOKEN) {
        return process.env.REACT_APP_FAKE_TOKEN;
      }
      // isAuthenticated()has side effect of updating access token
      const oktaAuthIsAuthenticated = await oktaAuth.isAuthenticated();
      // If we call getAccessToken() for non-auth user it would throw an error
      return oktaAuthIsAuthenticated ? oktaAuth.getAccessToken() : null;
    };
    configureAxiosInstance(accessTokenProvider);
  } else {
    configureAxiosInstance(() => token);
  }

  const handleGetAdminMenu = useCallback(async () => {
    try {
      const data = await getAdminMenu();
      const availableItems = data.filter(item => item?.isEnabled);
      const menuItems = availableItems?.map(item => ({
        ...item,
        key: item?.name,
        label: item?.name,
      }));
      setAdminMenu(menuItems);
    } catch (err) {
      handleErrorMessages(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdminMenu]);

  useEffect(() => {
    if (user?.isSuperuser) {
      handleGetAdminMenu();
    }
  }, [handleGetAdminMenu, user]);

  return (
    <Routes>
      <Route path={routes.OKTA_CALLBACK} element={<HandleLogIn />} />
      <Route path={routes.HOME} element={<ProtectedArea />}>
        <Route
          path=""
          element={
            hasToken ? (
              <Navigate replace to={routes.WORKBOOKS} />
            ) : (
              <WelcomePage />
            )
          }
        />
        <Route path={routes.LOGIN} element={<LoginPage />} />
        <Route
          path="/policies-and-disclosures"
          element={<PoliciesAndDisclosuresContainer />}
        />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsContainer />}
        />
        <Route path={routes.TEST_SENTRY} element={<TestSentry />} />
        <Route path={routes.SETTINGS} element={<SettingsLayout />} />
        <Route
          path={routes.ADMIN}
          element={<AdminPageLayout menu={adminMenu} />}
        >
          {adminMenu?.map(item => (
            <Fragment key={item.path}>
              <Route
                path={item.path}
                element={<TableComponent config={item} menu={adminMenu} />}
              />
              {item?.detailPath && (
                <Route
                  path={item.detailPath}
                  element={<DetailedView config={item} />}
                />
              )}
            </Fragment>
          ))}
        </Route>
        <Route path={routes.WORKBOOKS} element={<AppLayout />}>
          <Route path="" element={<WorkbooksPage />} />
          <Route path={routes.WORKBOOK} element={<WorkbookLayout />}>
            <Route path={routes.CONTRIBUTORS} element={<ContributorsTable />} />
            <Route path={routes.RESULTS} element={<Results />} />
            <Route path={routes.RESULTS_ITEM} element={<ResultLayout />}>
              <Route path={routes.FILTERING} element={<DataFilters />} />
              <Route path={`${routes.CONSTRAINTS}`} element={<Constraints />} />
              <Route path="" element={<PortfolioBuilder />} />
              <Route
                path={routes.RESULTS_TRADE_SUMMARY}
                element={<TradeSummaryPage />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path="*"
          element={
            <AppContent>
              <NotFound />
            </AppContent>
          }
        />
      </Route>
    </Routes>
  );
};

export default App;
