const Star = () => (
  <>
    <g
      clipPath="url(#clip0_5407_73156)"
      style={{
        transform: "translate(-13px, -14px)",
      }}
    >
      <g filter="url(#filter0_d_5407_73156)">
        <path
          d="M18.06 21C17.9001 21.0007 17.7423 20.9629 17.6 20.89L12.5 18.22L7.40001 20.89C7.2344 20.9771 7.04768 21.016 6.86108 21.0022C6.67448 20.9885 6.49548 20.9226 6.34444 20.8122C6.19339 20.7018 6.07635 20.5512 6.00662 20.3776C5.9369 20.204 5.91728 20.0142 5.95001 19.83L6.95001 14.2L2.83001 10.2C2.70146 10.0717 2.61028 9.91089 2.56623 9.73471C2.52219 9.55854 2.52695 9.3737 2.58001 9.20002C2.63797 9.0223 2.74458 8.86437 2.88776 8.74418C3.03093 8.62398 3.20493 8.54633 3.39001 8.52002L9.09001 7.69002L11.6 2.56002C11.6819 2.39095 11.8097 2.24836 11.9689 2.14859C12.1281 2.04883 12.3122 1.99591 12.5 1.99591C12.6879 1.99591 12.8719 2.04883 13.0311 2.14859C13.1903 2.24836 13.3181 2.39095 13.4 2.56002L15.94 7.68002L21.64 8.51002C21.8251 8.53633 21.9991 8.61398 22.1423 8.73418C22.2854 8.85437 22.392 9.0123 22.45 9.19002C22.5031 9.3637 22.5078 9.54854 22.4638 9.72471C22.4197 9.90089 22.3286 10.0617 22.2 10.19L18.08 14.19L19.08 19.82C19.1157 20.0075 19.097 20.2013 19.0261 20.3785C18.9553 20.5557 18.8352 20.7089 18.68 20.82C18.4989 20.9469 18.2809 21.0102 18.06 21Z"
          fill="#E9BA1D"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_5407_73156"
        x="-2.46362"
        y="-3.00409"
        width="31.9573"
        height="31.009"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5407_73156"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5407_73156"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_5407_73156">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </>
);

export default Star;
