/* eslint-disable react/prop-types */
import { Checkbox } from "antd";

const CheckboxRenderer = ({ column, node, getValue, disableInputs }) => {
  const value = getValue();
  const checkedHandler = val => {
    node.setDataValue(column.colId, val);
  };

  return (
    <Checkbox
      checked={value}
      onChange={event => {
        checkedHandler(event.target.checked);
      }}
      disabled={disableInputs}
      name="ag-grid-checkbox"
    >
      {value ? "Y" : "N"}
    </Checkbox>
  );
};

export default CheckboxRenderer;
