import PropTypes from "prop-types";
import { Layout } from "antd";
import { Outlet } from "react-router";
import HeaderContainer from "./header";
import Footer from "../../../components/Footer";
import { StyledLayoutContent } from "../../styles";

const AppContent = ({ children }) => (
  <Layout>
    <HeaderContainer />
    <StyledLayoutContent>
      <Outlet />
      {children}
    </StyledLayoutContent>
    <Footer />
  </Layout>
);

AppContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AppContent;
