import PropTypes from "prop-types";
import {
  StyledCardActions,
  StyledCardContent,
  StyledCardHeader,
  StyledCardLabel,
  StyledCardWrapper,
  StyledLoading,
} from "./styles";

const StyledCard = ({
  title = "",
  actions = null,
  children = null,
  nopadding = false,
  grow = false,
  stickyHeader = false,
  loading = false,
  dataCy,
}) => (
  <StyledCardWrapper grow={grow} data-cy={dataCy}>
    {(title || actions) && (
      <StyledCardHeader stickyHeader={stickyHeader}>
        <StyledCardLabel>
          {title}
          {loading && <StyledLoading />}
        </StyledCardLabel>
        {actions && <StyledCardActions>{actions}</StyledCardActions>}
      </StyledCardHeader>
    )}
    {children && (
      <StyledCardContent grow={grow} nopadding={nopadding}>
        {children}
      </StyledCardContent>
    )}
  </StyledCardWrapper>
);

StyledCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  nopadding: PropTypes.bool,
  grow: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  loading: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default StyledCard;
