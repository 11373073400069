// Waiting for BE support to hardcode following keys > titles
// objective -> Objective Function value
// warf -> Weighted Average Rating Factor
// was -> Weighted Average Spread
// bidAskSpread -> Bid-Ask Spread
// wbas -> Weighted Bid-Ask Spread
// maturity_date maturityDate -> Loan Maturity

import {
  chartAccentGreen,
  chartAccentGreenLight,
  chartBlue,
  chartBlueLight,
  chartGreen,
  chartGreenLight,
  chartOrange,
  chartOrangeLight,
  chartViolet,
  chartVioletLight,
  chartYellow,
  chartYellowLight,
} from "../../../constants/colors";

export const objectivesOptions = [
  {
    value: "weightedWarf",
    label: "WARF",
    color: chartOrange,
    tooltipColor: chartOrangeLight,
  },
  {
    value: "weightedSpread",
    label: "Spread",
    color: chartViolet,
    tooltipColor: chartVioletLight,
  },
  {
    value: "weightedBidAskSpread",
    label: "Bid-Ask Spread",
    color: chartAccentGreen,
    tooltipColor: chartAccentGreenLight,
  },
  {
    value: "weightedSpreadBps",
    label: "Spread Bps",
    color: chartYellow,
    tooltipColor: chartYellowLight,
  },
  {
    value: "weightedYearsTm",
    label: "Loan Maturity",
    color: chartBlue,
    tooltipColor: chartBlueLight,
  },
  {
    value: "objective",
    label: "Objective Function",
    color: chartGreen,
    tooltipColor: chartGreenLight,
  },
];

export default objectivesOptions;
