import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import Loading from "../../components/Loading/Loading";

const LoginPage = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    oktaAuth.signInWithRedirect({ originalUri: "/" });
  }, [oktaAuth]);

  return <Loading />;
};

export default LoginPage;
