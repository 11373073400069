import PropTypes from "prop-types";
import {
  StyledAccordionMenuItem,
  StyledMenuItemContent,
  StyledMenuItemExtra,
  StyledMenuItemIcon,
  StyledMenuTitle,
  StyledMenuValue,
} from "./styles";
import {
  StyledEmojiEventIcon,
  StyledStarIcon,
} from "../../../../components/Icons";
import { goldLight } from "../../../../constants/colors";
import { transfromFloatingNumbers } from "../../../../utils/dataFormatters";

const SideAccordionMenuItem = ({
  icon,
  step,
  title,
  value,
  extraInfo,
  onClick = () => {},
  isFeatured = false,
  isSameValue = false,
  isActive = false,
  showIcon = false,
  header = false,
  isLast = false,
  dataCy = "menu-item",
}) => (
  <StyledAccordionMenuItem
    data-cy={isActive ? `${dataCy}-active` : dataCy}
    active={isActive}
    onClick={() => onClick(step)}
    header={header}
  >
    <StyledMenuItemIcon showIcon={showIcon}>{icon}</StyledMenuItemIcon>
    <StyledMenuItemContent showIcon={showIcon}>
      <StyledMenuTitle header={header}>{title}</StyledMenuTitle>
      <StyledMenuValue isLighter={!isLast && isSameValue}>
        {transfromFloatingNumbers(value)}
      </StyledMenuValue>
      {isLast && <StyledEmojiEventIcon color={goldLight} />}
      {isFeatured && <StyledStarIcon color={goldLight} />}
    </StyledMenuItemContent>
    {extraInfo && <StyledMenuItemExtra>{extraInfo}</StyledMenuItemExtra>}
  </StyledAccordionMenuItem>
);

SideAccordionMenuItem.propTypes = {
  isActive: PropTypes.bool,
  isSameValue: PropTypes.bool,
  isFeatured: PropTypes.bool,
  showIcon: PropTypes.bool,
  header: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  step: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.number,
  ]),
  extraInfo: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  onClick: PropTypes.func,
  isLast: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default SideAccordionMenuItem;
