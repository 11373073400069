import { useCallback, useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router";
import _ from "lodash";
import omit from "lodash/omit";
import { message } from "antd";
import {
  CONSTRAINTS_CATEGORIES,
  CONSTRAINTS_INPUT_TYPE_VALUES,
  OBJECTIVES,
} from "../../constants/objectives";
import { handleErrorMessages, showMessageSuccess } from "../../utils/messages";
import {
  getPortfolioArguments,
  getPortfolioColumnsSummary,
  updatePortfolioArguments,
} from "../../api/endpoints/portfolios";
import {
  newDefaultConstraint,
  NEW_CONSTRAINT_KEY,
} from "../../constants/constraints";
import { valueTransformer } from "../../utils/parseValue";

const useConstraintsData = ({
  availableObjectiveFunctions = {},
  portfolio = {},
}) => {
  const [selectedPortfolio, setSelectedPortfolio] = useState(portfolio);
  const selectedPortfolioUUID = portfolio?.uuid || selectedPortfolio?.uuid;

  const [portfoliosConstraints, setPortfoliosConstraints] = useState({});
  const [portfoliosObjectives, setPortfoliosObjectives] = useState({});

  const constraints = portfoliosConstraints?.[selectedPortfolioUUID];
  const objective = portfoliosObjectives?.[selectedPortfolioUUID];
  const [isConstraintsInit, setIsConstraintsInit] = useState(true);
  const [isSellReplaceEnabled, setIsSellReplaceEnabled] = useState(false);
  const [constraintsLoading, setConstraintsLoading] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [constraintsByCategory, setConstraintsByCategory] = useState({});
  const [selectedConstraints, setSelectedConstraints] = useState({});
  const [newConstraints, setNewConstraints] = useState(newDefaultConstraint);
  const [hiddenConstraints, setHiddenConstraints] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(false);

  const { getWorkbookResults, setObjectives } = useOutletContext();
  const currentObjName = availableObjectiveFunctions?.[objective?.name];

  useEffect(() => {
    const sellReplaceConstraints =
      constraintsByCategory[CONSTRAINTS_CATEGORIES.SELL_REPLACE] || {};
    setIsSellReplaceEnabled(
      sellReplaceConstraints.sell_holdings_of_notional?.isEnabled,
    );
  }, [constraintsByCategory]);

  const replaceConstraints = ({
    replacedConstraints,
    key: constraintKey,
    category,
    replace = [],
  }) => {
    const newConstraintsList = { ...replacedConstraints };
    const resultConstraintsList = {};
    if (replacedConstraints?.[category]) {
      Object.keys(replacedConstraints?.[category]).forEach(key => {
        if (constraintKey === key) {
          replace.forEach(({ key: addKey, value: addData }) => {
            resultConstraintsList[addKey] = addData;
          });
        } else {
          resultConstraintsList[key] = replacedConstraints[category][key];
        }
      });
      newConstraintsList[category] = resultConstraintsList;
    }
    return newConstraintsList;
  };

  const getConstraintsByState = useCallback(
    newConstraintsData => {
      const visible = {};
      const hidden = {};
      const categorizedConstraints = {};

      _.forEach(newConstraintsData, (data, key) => {
        categorizedConstraints[data.category] ||= {};
        categorizedConstraints[data.category][key] = data;

        visible[data.category] ||= {};
        hidden[data.category] ||= {};

        if (data.isEnabled) {
          visible[data.category][key] = data;
        } else {
          hidden[data.category][key] = data;
        }
      });

      if (isSellReplaceEnabled) {
        _.unset(hidden, "Constraints.overall_notional");
      }

      return {
        categorizedConstraints,
        visible,
        hidden,
      };
    },
    [isSellReplaceEnabled],
  );

  const setConstraints = useCallback((portfolioUUID, constraintsData) => {
    setPortfoliosConstraints(prevConstraintsData => ({
      ...prevConstraintsData,
      [portfolioUUID]: {
        ...prevConstraintsData[portfolioUUID],
        ...constraintsData,
      },
    }));
  }, []);

  const setObjective = useCallback((portfolioUUID, objectiveData) => {
    setPortfoliosObjectives(prevObjectivesData => ({
      ...prevObjectivesData,
      [portfolioUUID]: objectiveData,
    }));
  }, []);

  const saveConstraintsData = useCallback(
    (portfolioUUID, data) => {
      if (data?.constraints) {
        setConstraints(portfolioUUID, data.constraints);
      }

      if (data?.objective) {
        setObjective(portfolioUUID, data.objective);
      }
    },
    [setConstraints, setObjective],
  );

  useEffect(() => {
    const { visible } = getConstraintsByState(constraints || {});
    setSelectedConstraints(visible);
  }, [constraints, getConstraintsByState]);

  useEffect(() => {
    if (!selectedPortfolioUUID) return;
    setIsConstraintsInit(true);
    setIsLoading(true);

    getPortfolioArguments(selectedPortfolioUUID)
      .then(data => saveConstraintsData(selectedPortfolioUUID, data))
      .catch(error => handleErrorMessages(error))
      .finally(() => {
        setIsConstraintsInit(false);
        setShowSkeleton(false);
        setIsLoading(false);
      });

    getPortfolioColumnsSummary(selectedPortfolioUUID)
      .then(data => {
        const newObjectives = Object.entries(data).filter(
          ([, value]) => value.canBeObjective,
        );

        setObjectives(prevState => {
          const objectives = { ...prevState };
          newObjectives.forEach(([key, value]) => {
            objectives[`custom_weighted_${key}`] = value?.title;
          });
          return objectives;
        });
      })
      .catch(error => handleErrorMessages(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveConstraintsData, selectedPortfolioUUID, setObjectives]);

  const updateSelectedItemsData = useCallback((key, data, category) => {
    setSelectedConstraints(items => {
      const updatedItem = { ...items?.[category]?.[key], ...data };
      return replaceConstraints({
        replacedConstraints: items,
        key,
        category,
        replace: [{ key, value: updatedItem }],
      });
    });
  }, []);

  const saveObjective = useCallback(
    (data, saveColumns, callbackFn = () => {}) => {
      updatePortfolioArguments(selectedPortfolioUUID, { objective: data })
        .then(response => {
          if (response) {
            saveConstraintsData(selectedPortfolioUUID, response);
            showMessageSuccess({ text: "Updated Objective Function" });
            saveColumns();
          }
        })
        .catch(error => {
          handleErrorMessages(error, "Failed to update Objective Function");
        })
        .finally(() => {
          callbackFn();
        });
    },
    [saveConstraintsData, selectedPortfolioUUID],
  );

  useEffect(() => {
    const { categorizedConstraints, hidden } =
      getConstraintsByState(constraints);
    setConstraintsByCategory(categorizedConstraints);
    setHiddenConstraints(hidden);
  }, [constraints, getConstraintsByState]);

  const onSwitchItem = useCallback(
    ({ key, switchArg, category, min, max }) => {
      setConstraintsLoading(prevState => ({
        ...prevState,
        [key]: true,
      }));

      if (key && key !== NEW_CONSTRAINT_KEY) {
        updatePortfolioArguments(selectedPortfolioUUID, {
          constraints: { [key]: { isEnabled: switchArg, min, max } },
        })
          .then(data => {
            saveConstraintsData(selectedPortfolioUUID, data);

            if (!switchArg) {
              setSelectedConstraints(prevState => ({
                ...prevState,
                [category]: { ...omit(prevState[category], key) },
              }));
            }

            const isEnabledText = switchArg ? "enabled" : "disabled";
            showMessageSuccess({
              text: `${constraints[key].title} is ${isEnabledText}`,
            });
          })
          .finally(() => {
            setConstraintsLoading(prevState => ({
              ...prevState,
              [key]: false,
            }));
          });
      } else {
        setNewConstraints(newDefaultConstraint);
      }
    },
    [selectedPortfolioUUID, saveConstraintsData, constraints],
  );

  const onUpdateConstraint = useCallback(
    ({
      name,
      attributeName,
      value,
      category,
      handleError,
      handleSuccess = null,
    }) => {
      updatePortfolioArguments(selectedPortfolioUUID, {
        constraints: { [name]: { [attributeName]: value } },
      })
        .then(response => {
          const constraintsData = response?.constraints;
          saveConstraintsData(selectedPortfolioUUID, response);
          updateSelectedItemsData(name, { [attributeName]: value }, category);
          showMessageSuccess({
            text: `Updated ${constraintsData[name]?.title}`,
          });
          if (handleSuccess) {
            handleSuccess();
          }
        })
        .catch(error => handleError(error.response.data));
    },
    [saveConstraintsData, selectedPortfolioUUID, updateSelectedItemsData],
  );

  const onUpdateConstraintData = useCallback(
    ({ name, options, category, handleError }) => {
      updatePortfolioArguments(selectedPortfolioUUID, {
        constraints: { [name]: { ...options } },
      })
        .then(response => {
          const constraintsData = response?.constraints;
          saveConstraintsData(selectedPortfolioUUID, response);
          updateSelectedItemsData(name, options, category);
          showMessageSuccess({
            text: `Updated ${constraintsData[name]?.title}`,
          });
        })
        .catch(error => handleError(error.response.data));
    },
    [saveConstraintsData, selectedPortfolioUUID, updateSelectedItemsData],
  );

  const onUpdateAllConstraints = useCallback(
    ({
      portfolioUUID,
      data,
      name,
      isLast,
      setLoading = () => {},
      handleSuccess = () => {},
    }) => {
      updatePortfolioArguments(portfolioUUID, data)
        .then(response => {
          handleSuccess();
          saveConstraintsData(portfolioUUID, response);
          if (isLast) {
            getWorkbookResults({ page: 1 });
            setLoading(false);
          }
        })
        .catch(error => {
          const errorMsg = error?.response?.data?.detail[0]?.msg;
          const customMsg = `Portfolio ${name} has error: ${errorMsg}`;
          handleErrorMessages(error, customMsg);
          setLoading(false);
        });
    },
    [saveConstraintsData, getWorkbookResults],
  );

  const onToggleSellReplace = useCallback(
    (isEnabled, callback, handleError) => {
      const sellReplaceConstraints = {};
      for (const [key, data] of Object.entries(constraints)) {
        if ([CONSTRAINTS_CATEGORIES.SELL_REPLACE].includes(data.category)) {
          sellReplaceConstraints[key] = { ...data, isEnabled };
        }
      }
      updatePortfolioArguments(selectedPortfolioUUID, {
        constraints: sellReplaceConstraints,
      })
        .then(response => {
          saveConstraintsData(selectedPortfolioUUID, response);
          showMessageSuccess({
            text: "Updated items",
          });
          const isOverallNotionalEnabled =
            constraints.overall_notional.isEnabled;

          if (isEnabled && isOverallNotionalEnabled) {
            message.warning({
              content:
                "Overall notional has been disabled as selected sell replace scenario",
            });
          }
          if (callback && typeof callback === "function") {
            callback();
          }
        })
        .catch(error => {
          handleError(error.response.data);
          handleErrorMessages(error);
        });
    },
    [selectedPortfolioUUID, constraints, saveConstraintsData],
  );

  const onChangeConstraint = useCallback(
    (currentKey, constraintKey, category) => {
      if (currentKey && currentKey !== NEW_CONSTRAINT_KEY) {
        const currentConstraint = constraints[currentKey] || {};
        const newConstraint = {
          ...constraints[constraintKey],
          isEnabled: true,
          min: currentConstraint.min,
          max: currentConstraint.max,
        };
        updatePortfolioArguments(selectedPortfolioUUID, {
          constraints: {
            [currentKey]: { isEnabled: false },
            [constraintKey]: newConstraint,
          },
        }).then(response => {
          saveConstraintsData(selectedPortfolioUUID, response);
          setSelectedConstraints(items =>
            replaceConstraints({
              replacedConstraints: items,
              key: currentKey,
              category,
              replace: [{ key: constraintKey, value: newConstraint }],
            }),
          );
          showMessageSuccess({
            text: `Updated ${currentConstraint.title}`,
          });
        });
      } else {
        const newConstraint = {
          ...constraints[constraintKey],
          isEnabled: true,
        };
        updatePortfolioArguments(selectedPortfolioUUID, {
          constraints: { [constraintKey]: newConstraint },
        }).then(response => {
          saveConstraintsData(selectedPortfolioUUID, response);
          setNewConstraints(newDefaultConstraint);
          setSelectedConstraints(items => ({
            ...items,
            [category]: {
              ...items[category],
              [constraintKey]: newConstraint,
            },
          }));

          showMessageSuccess({
            text: `Updated ${newConstraint.title}`,
          });
        });
      }
    },
    [constraints, selectedPortfolioUUID, saveConstraintsData],
  );

  const onChangeConstraintInputType = useCallback(
    (key, type) => {
      const getMinMaxValues = (curKey, curType) => {
        const values = {
          min: null,
          max: null,
        };
        switch (curType) {
          case CONSTRAINTS_INPUT_TYPE_VALUES.EQUAL:
            break;
          case CONSTRAINTS_INPUT_TYPE_VALUES.LESS_OR_EQUAL:
            values.max = constraints[curKey]?.max || 0;
            break;
          case CONSTRAINTS_INPUT_TYPE_VALUES.GREAT_OR_EQUAL:
            values.min = constraints[curKey]?.min || 0;
            break;
          case CONSTRAINTS_INPUT_TYPE_VALUES.RANGE:
          default:
            values.min = constraints[curKey]?.min || 0;
            values.max = constraints[curKey]?.max || 0;
        }

        return values;
      };
      if (key && key !== NEW_CONSTRAINT_KEY) {
        const newConstraint = {
          ...constraints[key],
          ...getMinMaxValues(key, type),
        };
        updatePortfolioArguments(selectedPortfolioUUID, {
          constraints: { [key]: newConstraint },
        }).then(response => {
          saveConstraintsData(selectedPortfolioUUID, response);
        });
      }
    },
    [constraints, selectedPortfolioUUID, saveConstraintsData],
  );

  const getFormattedValue = useCallback((value, precision, suffix, prefix) => {
    const data = [];
    if (prefix) data.push(prefix);
    data.push(valueTransformer.number(value, precision));
    if (suffix) data.push(suffix);
    return data.join("");
  }, []);

  const getObjectiveValue = useCallback(
    (value, isFormatted = true) => {
      const name = objective?.name;
      const precision = OBJECTIVES[name]?.precision || 0;
      const suffix = OBJECTIVES[name]?.suffix || "";
      const prefix = OBJECTIVES[name]?.prefix || "";
      if (
        !isFormatted ||
        ["number_of_holdings", "portfolio_weighted_warf", "warf"].includes(name)
      ) {
        return Number(value).toFixed(precision || 0);
      }
      return getFormattedValue(value, precision, suffix, prefix);
    },
    [getFormattedValue, objective?.name],
  );

  const getCorrectPrecisionsValue = useCallback(value => {
    return value;
  }, []);

  return useMemo(
    () => ({
      constraints,
      constraintsLoading,
      isConstraintsInit,
      objective,
      saveObjective,
      onUpdateConstraint,
      onUpdateConstraintData,
      onUpdateAllConstraints,
      onChangeConstraintInputType,
      onChangeConstraint,
      onSwitchItem,
      constraintsByCategory,
      hiddenConstraints,
      selectedConstraints,
      newConstraints,
      setObjective,
      availableObjectiveFunctions,
      onToggleSellReplace,
      selectedPortfolio,
      setSelectedPortfolio,
      selectedPortfolioUUID,
      currentObjName,
      getFormattedValue,
      getObjectiveValue,
      getCorrectPrecisionsValue,
      setIsSellReplaceEnabled,
      isSellReplaceEnabled,
      showSkeleton,
      setShowSkeleton,
      setIsLoading,
      isLoading,
    }),
    [
      availableObjectiveFunctions,
      constraints,
      constraintsByCategory,
      constraintsLoading,
      currentObjName,
      getCorrectPrecisionsValue,
      getFormattedValue,
      getObjectiveValue,
      hiddenConstraints,
      isConstraintsInit,
      isSellReplaceEnabled,
      newConstraints,
      objective,
      onChangeConstraint,
      onChangeConstraintInputType,
      onSwitchItem,
      onToggleSellReplace,
      onUpdateAllConstraints,
      onUpdateConstraint,
      onUpdateConstraintData,
      saveObjective,
      selectedConstraints,
      selectedPortfolio,
      selectedPortfolioUUID,
      setObjective,
      showSkeleton,
      setShowSkeleton,
      setIsLoading,
      isLoading,
    ],
  );
};

export default useConstraintsData;
