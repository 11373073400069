import { StyledUploadSection } from "../../styled";
import { StyledInboxIcon } from "../../../components/Icons";
import { StyledLink } from "../../../components/Typography";
import { StyledFileUploaderTitle } from "../styles";

const FileUploaderContent = () => {
  return (
    <StyledUploadSection>
      <p className="ant-upload-drag-icon">
        <StyledInboxIcon />
      </p>
      <StyledFileUploaderTitle className="ant-upload-text">
        Nothing here at the moment
      </StyledFileUploaderTitle>
      <p className="ant-upload-hint">
        To upload files drag n drop them or{" "}
        <StyledLink nonUnderlined>click here to upload</StyledLink>
      </p>
    </StyledUploadSection>
  );
};

FileUploaderContent.propTypes = {};

export default FileUploaderContent;
