import PropTypes from "prop-types";
import { WORKBOOK_TYPE_VALUES } from "../../constants/workbooks";

import {
  StyledBriefcaseIcon,
  StyledPieChartIcon,
  StyledShuffleIcon,
  StyledTrendingUpIcon,
  StyledSellReplaceIcon,
} from "../Icons";
import { StyledLabelContainer, StyledLabelText } from "./WorkbookLabel.styled";
import { purple100 } from "../../constants/colors";

const getLabelIcon = type => {
  switch (type) {
    case WORKBOOK_TYPE_VALUES.SELL_REPLACE:
      return <StyledSellReplaceIcon $color={purple100} />;
    case WORKBOOK_TYPE_VALUES.SINGLE_PORTFOLIO:
      return <StyledBriefcaseIcon />;
    case WORKBOOK_TYPE_VALUES.RAMP_UP:
      return <StyledTrendingUpIcon $color={purple100} />;
    case WORKBOOK_TYPE_VALUES.MULTI_PORTFOLIO:
      return <StyledPieChartIcon />;
    case WORKBOOK_TYPE_VALUES.MIXED:
      return <StyledShuffleIcon $color={purple100} />;
    default:
      return <StyledBriefcaseIcon />;
  }
};

const WorkbookLabel = ({ label }) =>
  label && (
    <StyledLabelContainer type={label.type}>
      {getLabelIcon(label.type)}
      <StyledLabelText>{label.title}</StyledLabelText>
    </StyledLabelContainer>
  );

WorkbookLabel.propTypes = {
  label: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default WorkbookLabel;
