const addAuthorizationHeader = accessTokenProvider => axios => {
  return axios.interceptors.request.use(
    async config => {
      const accessToken = await accessTokenProvider();
      if (accessToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    error => Promise.reject(error),
  );
};

export default addAuthorizationHeader;
