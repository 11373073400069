import styled, { css, keyframes } from "styled-components/macro";
import { EyeInvisibleOutlined, LockFilled } from "@ant-design/icons";
import { ReactComponent as ExcelIcon } from "./svg/excel.svg";
import { ReactComponent as CloseIcon } from "./svg/close.svg";
import { ReactComponent as InfoIcon } from "./svg/info.svg";
import { ReactComponent as AddIcon } from "./svg/add.svg";
import { ReactComponent as TrashOutlineIcon } from "./svg/trash-outline.svg";
import { ReactComponent as WorkbookIcon } from "./svg/workbook.svg";
import { ReactComponent as DoubleArrowUpIcon } from "./svg/double-arrow-up.svg";
import { ReactComponent as DoubleArrowDownIcon } from "./svg/double-arrow-down.svg";
import { ReactComponent as ArrowCircleLeftIcon } from "./svg/arrow-circle-left.svg";
import { ReactComponent as StarIcon } from "./svg/star.svg";
import { ReactComponent as StarOutlineIcon } from "./svg/star-outline.svg";
import { ReactComponent as PeopleOutlineIcon } from "./svg/people-outline.svg";
import { ReactComponent as EditOutlineIcon } from "./svg/edit-outline.svg";
import { ReactComponent as EmailIcon } from "./svg/email.svg";
import { ReactComponent as PhoneIcon } from "./svg/phone.svg";
import { ReactComponent as PinIcon } from "./svg/pin.svg";
import { ReactComponent as ChevronLeftCircle } from "./svg/chevronLeftCircle.svg";
import { ReactComponent as OverviewIcon } from "./svg/acc-overview.svg";
import { ReactComponent as ResignIcon } from "./svg/resign.svg";
import { ReactComponent as SyncOutlineIcon } from "./svg/sync-outline.svg";
import { ReactComponent as LoginIcon } from "./svg/login.svg";
import { ReactComponent as BriefcaseIcon } from "./svg/briefcase.svg";
import { ReactComponent as SuccessCheckIcon } from "./svg/successCheck.svg";
import { ReactComponent as FailedCheckIcon } from "./svg/failedCheck.svg";
import { ReactComponent as CancelCheckIcon } from "./svg/cancelCheck.svg";
import { ReactComponent as ListIcon } from "./svg/list.svg";
import { ReactComponent as PreviewIcon } from "./svg/preview.svg";
import { ReactComponent as ArrowDownward } from "./svg/arrow-ios-downward.svg";
import { ReactComponent as ArrowUpward } from "./svg/arrow-ios-upward.svg";
import { ReactComponent as EmojiEvent } from "./svg/emoji_events.svg";
import { ReactComponent as NotFoundIcon } from "./svg/404.svg";
import { ReactComponent as HeaderLogoIcon } from "./svg/header-logo.svg";
import { ReactComponent as FooterLogoIcon } from "./svg/footer-logo.svg";
import { ReactComponent as CheckmarkCircle } from "./svg/checkmark-circle.svg";
import { ReactComponent as CheckmarkCircle2 } from "./svg/checkmark-circle-2.svg";
import { ReactComponent as ShuffleIcon } from "./svg/shuffle.svg";
import { ReactComponent as PieChartIcon } from "./svg/pie-chart.svg";
import { ReactComponent as TrendingUpIcon } from "./svg/trending-up.svg";
import { ReactComponent as UploadIcon } from "./svg/upload.svg";
import { ReactComponent as MoreVerticalIcon } from "./svg/more-vertical.svg";
import { ReactComponent as GridOutline } from "./svg/grid-outline.svg";
import { ReactComponent as LineChartUpIcon } from "./svg/line-chart-up.svg";
import { ReactComponent as FlipRightIcon } from "./svg/flip-right.svg";
import { ReactComponent as DetailsIcon } from "./svg/details.svg";
import { ReactComponent as WarningIcon } from "./svg/warning.svg";
import { ReactComponent as SellReplaceIcon } from "./svg/sell-replace.svg";
import { ReactComponent as NotAccessIcon } from "./svg/noAccess.svg";
import { ReactComponent as CardsIcon } from "./svg/cards.svg";
import { ReactComponent as ShareIcon } from "./svg/share.svg";
import { ReactComponent as ParametrsIcon } from "./svg/parametrs.svg";
import { ReactComponent as DownloadIcon } from "./svg/download.svg";
import { ReactComponent as DoubleArrowLeftIcon } from "./svg/double-arrow-left.svg";
import { ReactComponent as DoubleArrowRightIcon } from "./svg/double-arrow-right.svg";
import { ReactComponent as ArrowDownIcon } from "./svg/arrowDown.svg";
import { ReactComponent as ArrowUpIcon } from "./svg/arrowUp.svg";
import { ReactComponent as CompareIcon } from "./svg/compare.svg";
import { ReactComponent as RemoveCompareIcon } from "./svg/removeCompare.svg";
import { ReactComponent as HideIcon } from "./svg/hide.svg";
import { ReactComponent as EmptyTradeSummaryIcon } from "./svg/emptyTradeSummary.svg";
import { ReactComponent as InfoSelectIcon } from "./svg/infoIcon.svg";
import { ReactComponent as StarCompareIcon } from "./svg/starCompare.svg";
import { ReactComponent as StarSelectIcon } from "./svg/starSelect.svg";
import { ReactComponent as TradeInfo } from "./svg/tradeInfo.svg";
import { ReactComponent as Settings } from "./svg/settings.svg";
import { ReactComponent as LogoutIcon } from "./svg/logout.svg";
import { ReactComponent as DefaultAvatarIcon } from "./svg/defaultAvatar.svg";
import { ReactComponent as HomeIcon } from "./svg/home.svg";
import { ReactComponent as HomeIconFilled } from "./svg/homeIconFilled.svg";
import { ReactComponent as DividerIcon } from "./svg/divider.svg";
import { ReactComponent as CheckIcon } from "./svg/check.svg";
import { ReactComponent as ErrorCloseIcon } from "./svg/errorClose.svg";
import { ReactComponent as DashIcon } from "./svg/dash.svg";
import { ReactComponent as LayoutIcon } from "./svg/layout.svg";
import { ReactComponent as TableListIcon } from "./svg/tableList.svg";
import { ReactComponent as BookIcon } from "./svg/book.svg";
import { ReactComponent as CopyIcon } from "./svg/copy.svg";
import { ReactComponent as Admin } from "./svg/admin.svg";
import { ReactComponent as InboxIcon } from "./svg/inbox.svg";

const ColorMixin = css`
  ${props =>
    props.$color &&
    css`
      & {
        color: ${props.$color};
      }
    `}
`;

const SizeMixin = css`
  ${props =>
    props.$size &&
    css`
      & {
        font-size: ${props.$size};
      }
    `}
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const StyledWarningIcon = styled(WarningIcon)`
  width: 14px;
  ${props =>
    props.color &&
    css`
      path {
        fill: ${props.color};
        stroke: ${props.color};
      }
    `}
`;

export const StyledDefaultAvatarIcon = styled(DefaultAvatarIcon)`
  width: 40px;
  ${props =>
    props.$width &&
    css`
      width: ${props.$width};
      height: ${props.$width};
    `}
`;
export const StyledCopyIcon = styled(CopyIcon)`
  width: 18px;
`;
export const StyledTableListIcon = styled(TableListIcon)``;
export const StyledBookIcon = styled(BookIcon)``;
export const StyledLayoutIcon = styled(LayoutIcon)``;
export const StyledDashIcon = styled(DashIcon)``;
export const StyledCheckIcon = styled(CheckIcon)``;
export const StyledErrorCloseIcon = styled(ErrorCloseIcon)``;
export const StyledDividerIcon = styled(DividerIcon)``;
export const StyledHomeIcon = styled(HomeIcon)``;
export const StyledHomeIconFilled = styled(HomeIconFilled)``;
export const StyledLogoutIcon = styled(LogoutIcon)``;
export const StyledSettingsIcon = styled(Settings)``;
export const StyledAdminIcon = styled(Admin)``;
export const StyledEmptyTradeSummaryIcon = styled(EmptyTradeSummaryIcon)``;
export const StyledHideIcon = styled(HideIcon)``;
export const StyledInfoSelectIcon = styled(InfoSelectIcon)`
  width: 40px;
`;
export const StyledTradeInfo = styled(TradeInfo)``;
export const StyledStarSelectIcon = styled(StarSelectIcon)``;
export const StyledStarCompareIcon = styled(StarCompareIcon)``;
export const StyledCompareIcon = styled(CompareIcon)``;
export const StyledRemoveCompareIcon = styled(RemoveCompareIcon)``;
export const StyledArrowDownIcon = styled(ArrowDownIcon)``;
export const StyledArrowUpIcon = styled(ArrowUpIcon)``;
export const StyledDoubleArrowLeftIcon = styled(DoubleArrowLeftIcon)``;
export const StyledDoubleArrowRightIcon = styled(DoubleArrowRightIcon)``;
export const StyledDownloadIcon = styled(DownloadIcon)``;
export const StyledParametrsIcon = styled(ParametrsIcon)``;
export const StyledShareIcon = styled(ShareIcon)``;
export const StyledNotAccessIcon = styled(NotAccessIcon)``;
export const StyledCardsIcon = styled(CardsIcon)``;
export const StyledDetailsIcon = styled(DetailsIcon)``;
export const StyledExcelIcon = styled(ExcelIcon)``;
export const StyledCloseIcon = styled(CloseIcon)`
  ${props =>
    props.color &&
    css`
      path {
        fill: ${props.color};
        stroke: ${props.color};
      }
    `}
`;
export const StyledInfoIcon = styled(InfoIcon)`
  ${props =>
    props.$hasMargin &&
    css`
      margin: 0 8px;
    `}
`;
export const StyledAddIcon = styled(AddIcon)``;
export const StyledTrashOutlineIcon = styled(TrashOutlineIcon)``;
export const StyledWorkbookIcon = styled(WorkbookIcon)``;
export const StyledStarOutlineIcon = styled(StarOutlineIcon)``;
export const StyledPeopleOutlineIcon = styled(PeopleOutlineIcon)``;
export const StyledEditOutlineIcon = styled(EditOutlineIcon)``;
export const StyledArrowCircleLeftIcon = styled(ArrowCircleLeftIcon)``;
export const StyledDoubleArrowUpIcon = styled(DoubleArrowUpIcon)``;
export const StyledDoubleArrowDownIcon = styled(DoubleArrowDownIcon)``;
export const StyledLoginIcon = styled(LoginIcon)``;
export const StyledEmailIcon = styled(EmailIcon)``;
export const StyledPhoneIcon = styled(PhoneIcon)``;
export const StyledPinIcon = styled(PinIcon)`
  min-width: 20px;
`;
export const StyledOverviewIcon = styled(OverviewIcon)``;
export const StyledResignIcon = styled(ResignIcon)``;
export const StyledSyncOutlineIcon = styled(SyncOutlineIcon)``;
export const StyledAnimatedSyncOutlineIcon = styled(SyncOutlineIcon)`
  animation: ${rotation} 2s infinite linear;
`;
export const StyledStarIcon = styled(StarIcon)`
  ${props =>
    props.color &&
    css`
      path {
        fill: ${props.color};
        stroke: ${props.color};
      }
    `}
`;
export const StyledChevronLeftCircle = styled(ChevronLeftCircle)``;
export const StyledBriefcaseIcon = styled(BriefcaseIcon)`
  ${props =>
    props.$color &&
    css`
      & {
        color: ${props.$color};
      }
    `}
`;
export const StyledEyeInvisibleOutlinedIcon = styled(EyeInvisibleOutlined)`
  ${ColorMixin}
  ${SizeMixin}
`;
export const StyledLockFilledIcon = styled(LockFilled)`
  ${ColorMixin}
  ${SizeMixin}
`;
export const StyledSuccessCheckIcon = styled(SuccessCheckIcon)``;
export const StyledFailedCheckIcon = styled(FailedCheckIcon)``;
export const StyledCancelCheckIcon = styled(CancelCheckIcon)``;
export const StyledListIcon = styled(ListIcon)``;
export const StyledPreviewIcon = styled(PreviewIcon)``;
export const StyledArrowDownwardIcon = styled(ArrowDownward)``;
export const StyledArrowUpwardIcon = styled(ArrowUpward)``;
export const StyledEmojiEventIcon = styled(EmojiEvent)``;
export const StyledNotFoundIcon = styled(NotFoundIcon)``;
export const StyledHeaderLogoIcon = styled(HeaderLogoIcon)``;
export const StyledFooterLogoIcon = styled(FooterLogoIcon)``;
export const StyledCheckmarkCircleIcon = styled(CheckmarkCircle)``;
export const StyledCheckmarkCircleIcon2 = styled(CheckmarkCircle2)``;
export const StyledShuffleIcon = styled(ShuffleIcon)`
  ${props =>
    props.$color &&
    css`
      path {
        fill: ${props.$color};
      }
    `}
`;
export const StyledPieChartIcon = styled(PieChartIcon)``;
export const StyledSellReplaceIcon = styled(SellReplaceIcon)`
  ${props =>
    props.$color &&
    css`
      path {
        stroke: ${props.$color};
      }
    `}
`;
export const StyledTrendingUpIcon = styled(TrendingUpIcon)`
  ${props =>
    props.$color &&
    css`
      path {
        fill: ${props.$color};
      }
    `}
`;
export const StyledUploadIcon = styled(UploadIcon)``;
export const StyledMoreVerticalIcon = styled(MoreVerticalIcon)``;
export const StyledGridOutlineIcon = styled(GridOutline)``;
export const StyledLineChartUpIcon = styled(LineChartUpIcon)``;
export const StyledFlipRightIcon = styled(FlipRightIcon)`
  path {
    fill: none !important;
    stroke: currentColor;
  }
`;
export const StyledInboxIcon = styled(InboxIcon)``;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  StyledExcelIcon,
  StyledCloseIcon,
  StyledInfoIcon,
  StyledAddIcon,
  StyledTrashOutlineIcon,
  StyledWorkbookIcon,
  StyledStarIcon,
  StyledStarOutlineIcon,
  StyledPeopleOutlineIcon,
  StyledEditOutlineIcon,
  StyledArrowCircleLeftIcon,
  StyledDoubleArrowUpIcon,
  StyledDoubleArrowDownIcon,
  StyledEmailIcon,
  StyledPhoneIcon,
  StyledPinIcon,
  StyledChevronLeftCircle,
  StyledOverviewIcon,
  StyledResignIcon,
  StyledSyncOutlineIcon,
  StyledLoginIcon,
  StyledSuccessCheckIcon,
  StyledFailedCheckIcon,
  StyledListIcon,
  StyledPreviewIcon,
  StyledArrowDownwardIcon,
  StyledArrowUpwardIcon,
  StyledEmojiEventIcon,
  StyledNotFoundIcon,
  StyledNotAccessIcon,
  StyledCheckmarkCircleIcon,
  StyledCheckmarkCircleIcon2,
  StyledUploadIcon,
  StyledMoreVerticalIcon,
  StyledGridOutlineIcon,
  StyledLineChartUpIcon,
  StyledFlipRightIcon,
  StyledWarningIcon,
  StyledInboxIcon,
};
