import { isValid, format, isToday } from "date-fns";

const handleFormatDate = ({ value, showTime = false }) => {
  const date = new Date(value);
  if (isValid(date)) {
    const timeFormat = showTime ? "MM.dd.yyyy (h:mm aaa)" : "MM.dd.yyyy";
    const formatter = isToday(date) ? "h:mm aaa" : timeFormat;
    return format(date, formatter);
  }
  return "";
};

export default handleFormatDate;
