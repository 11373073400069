import { useContext } from "react";
import PortfolioResultContent from "./ResultContent";
import NotFound from "../../ErrorPages/NotFound";
import { PageContext } from "../context";
import NoPermissions from "../../ErrorPages/NoPermissions";

const PortfolioBuilder = () => {
  const { isLoading, isNotFound, isForbidden } = useContext(PageContext);

  if (isNotFound) return <NotFound />;

  if (isForbidden) return <NoPermissions />;

  return <PortfolioResultContent pageLoading={isLoading} />;
};

export default PortfolioBuilder;
