import { useCallback, useContext, useEffect, useState } from "react";
import { getPortfolioGenerationByStep } from "../../../api/endpoints/portfolios";
import {
  buildPortfolioResult,
  getPortfolioResultProgress,
  cancelPortfolioResult,
} from "../../../api/endpoints/results";
import {
  PROGRESS_STATES,
  PROGRESS_STATES_LABEL,
} from "../../../constants/constraints";
import { handleErrorMessages } from "../../../utils/messages";
import RunTimeCell from "../../../features/ResultList/RunTimeCell";
import { StyledTimeElapsedWrapper } from "../../styles";
import useErrorStatus from "../../../hooks/useErrorStatus";
import { PageContext as ResultDetailsContext } from "../../../features/ResultDetails/context";
import { removeSkippedPortfolios } from "./handlers";

const usePortfolioData = ({ resultUUID, portfolioListLength = 0 }) => {
  const [startBuildLoading, setStartBuildLoading] = useState(false);
  const { checkIsErrorStatus, setErrorStatus } = useErrorStatus();
  const { portfolioBuildProgress, setPortfolioBuildProgress } =
    useContext(ResultDetailsContext);

  const getPortfolioBuildProgress = useCallback(
    force => {
      if (
        !force &&
        (portfolioBuildProgress?.status === PROGRESS_STATES.draft ||
          portfolioBuildProgress?.status === PROGRESS_STATES.finished ||
          portfolioBuildProgress?.status === PROGRESS_STATES.failed)
      ) {
        return;
      }

      if (!resultUUID) return;

      getPortfolioResultProgress(resultUUID)
        .then(data => {
          const filteredPortfolios = removeSkippedPortfolios(data.portfolios);
          const newData = { ...data, portfolios: filteredPortfolios };

          return setPortfolioBuildProgress(newData);
        })
        .catch(error => {
          const response = error?.response || {};
          const { status } = response;
          const isErrorStatus = checkIsErrorStatus(status);
          setErrorStatus(status);
          if (!isErrorStatus) {
            handleErrorMessages(error);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      portfolioBuildProgress?.status,
      resultUUID,
      checkIsErrorStatus,
      setErrorStatus,
    ],
  );

  const onClickPortfolioBuild = async (resultID, callbackFn = () => {}) => {
    setStartBuildLoading(true);
    buildPortfolioResult(resultID)
      .then(() => {
        callbackFn();
        getPortfolioBuildProgress(true);
      })
      .catch(error => {
        const errorMsg = error?.response?.data?.detail?.errors[0]?.msg_long;
        const portfolioName = error?.response?.data?.detail?.portfolio_name;
        const customMsg = `Portfolio ${portfolioName} has error: ${errorMsg}`;
        handleErrorMessages(error, customMsg);
      })
      .finally(() => setStartBuildLoading(false));
  };

  const getPortfolioResultAtStep = useCallback(
    (portfolioUUID, step) => getPortfolioGenerationByStep(portfolioUUID, step),
    [],
  );

  useEffect(() => {
    getPortfolioBuildProgress(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultUUID]);

  const getTitleDetailsByStatus = useCallback(
    ({ portfolioUUID, isSideNav = false }) => {
      const {
        portfolios = {},
        status,
        startedAt,
        msg,
      } = portfolioBuildProgress;

      const portfoliosArr = Object.values(portfolios);
      const finishedBuildPortfolios = portfoliosArr.filter(
        portfolio => portfolio?.status === PROGRESS_STATES.finished,
      );

      const isMultiPortfolio = portfolioListLength > 1;
      const titlePrefix = isMultiPortfolio ? "Multi-portfolio" : "Portfolio";

      const currentPortfolioData = portfolios[portfolioUUID] || {};
      const currentPortfolioFailedConstraints =
        currentPortfolioData.failed_constraints || {};

      const currentPortfolioErrorMessage =
        currentPortfolioData.msg || msg || "";
      const overAllStatus = isMultiPortfolio
        ? status || PROGRESS_STATES.draft
        : currentPortfolioData.status || PROGRESS_STATES.draft;
      const currentPortfolioStatus =
        currentPortfolioData.status || status || PROGRESS_STATES.draft;
      const currentPortfolioTotal = currentPortfolioData.total || 0;
      const currentPortfolioStep = currentPortfolioData.step;

      const percentage =
        overAllStatus === PROGRESS_STATES.finished
          ? 100
          : (100 * currentPortfolioStep) / currentPortfolioTotal;

      const multiPortfolioSubtitle = `${finishedBuildPortfolios.length} out of ${portfolioListLength}`;
      const inProgressSubtitle = (
        <StyledTimeElapsedWrapper>
          <RunTimeCell
            record={{
              status: currentPortfolioStatus,
              startedAt,
            }}
          />
          {isMultiPortfolio
            ? `(${multiPortfolioSubtitle})`
            : `(step ${currentPortfolioStep}/${currentPortfolioTotal || 0})`}
        </StyledTimeElapsedWrapper>
      );

      const defaultResponse = {
        title: "",
        subtitle: "",
        percentage,
        status: currentPortfolioStatus,
        errorMessage: "",
        failedConstraints: currentPortfolioFailedConstraints,
      };

      const statusLabel = PROGRESS_STATES_LABEL[status]
        ? PROGRESS_STATES_LABEL[status]
        : status;
      const checkStatus = isSideNav
        ? currentPortfolioData?.status
        : overAllStatus;

      switch (checkStatus) {
        case PROGRESS_STATES.inProgress:
          return currentPortfolioStep !== undefined
            ? {
                ...defaultResponse,
                title: `${titlePrefix} Generation`,
                subtitle: inProgressSubtitle,
              }
            : {
                ...defaultResponse,
                title: `${titlePrefix} Generation`,
                subtitle: "Initializing...",
              };
        case PROGRESS_STATES.failed:
          return {
            ...defaultResponse,
            title: `${titlePrefix} Generation`,
            subtitle: `${statusLabel}`,
            errorMessage: currentPortfolioErrorMessage,
          };
        case PROGRESS_STATES.cancelled:
          return {
            ...defaultResponse,
            title: `${titlePrefix} Generation`,
            subtitle: isMultiPortfolio ? multiPortfolioSubtitle : "Canceled",
          };
        case PROGRESS_STATES.finished:
          return {
            ...defaultResponse,
            title: `${titlePrefix} Generated`,
            subtitle: isMultiPortfolio ? multiPortfolioSubtitle : "Finished",
          };
        case PROGRESS_STATES.queued:
          return {
            ...defaultResponse,
            title: `${titlePrefix} Generation`,
            subtitle: "Queued",
          };
        case PROGRESS_STATES.draft:
        default:
          return {
            ...defaultResponse,
            subtitle: isMultiPortfolio ? multiPortfolioSubtitle : "",
            percentage: 0,
          };
      }
    },
    [portfolioBuildProgress, portfolioListLength],
  );

  const onPortfolioCancelBuild = useCallback(() => {
    cancelPortfolioResult(resultUUID)
      .then(() => {
        setPortfolioBuildProgress(prevState => {
          const newPortfolios = Object.entries(prevState?.portfolios).reduce(
            (acc, [key, value]) => {
              const { status } = value;
              const newStatus =
                status !== PROGRESS_STATES.finished
                  ? PROGRESS_STATES.cancelled
                  : status;
              return { ...acc, [key]: { ...value, status: newStatus } };
            },
            {},
          );
          return {
            ...prevState,
            status: PROGRESS_STATES.cancelled,
            portfolios: newPortfolios,
          };
        });
      })
      .catch(error => handleErrorMessages(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultUUID]);

  return {
    portfolioBuildProgress,
    startBuildLoading,
    onClickPortfolioBuild,
    onPortfolioCancelBuild,
    getPortfolioResultAtStep,
    getTitleDetailsByStatus,
    getPortfolioBuildProgress,
  };
};

export default usePortfolioData;
