import styled, { css } from "styled-components/macro";
import { Input } from "antd";
import {
  darkGrey5,
  gray4,
  accentRed,
  gray30,
  darkGrey2,
} from "../../constants/colors";

export const StyledField = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  margin-bottom: 24px;
`;

export const StyledInputWrapper = styled.div`
  .ant-input-number-group-wrapper {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
  }
  .ant-input-number .ant-input-number-input {
    font-family: "Inter", sans-serif;
  }
  .ant-input-number-affix-wrapper,
  .ant-input-number {
    display: flex;
    flex-flow: row nowrap;
    width: auto;
    border-color: ${gray4};
    float: none;
    height: 34px;
    ${({ showError }) =>
      showError &&
      css`
        border-color: ${accentRed};
      `}
  }
  .ant-input-number-group-addon {
    border-color: ${gray4};
    ${({ showError }) =>
      showError &&
      css`
        border-color: ${accentRed};
      `}
  }

  .ant-input-number-wrapper-disabled {
    background-color: transparent;
  }

  .ant-input-number-disabled,
  .ant-input-number[disabled] {
    background-color: transparent;
  }

  .ant-input-number-disabled .ant-input-number-input,
  .ant-input-number-readonly .ant-input-number-input {
    color: ${darkGrey2};
  }

  .ant-input-number-wrapper-disabled > .ant-input-number-group-addon,
  .ant-input-number-wrapper-disabled > .ant-input-number-group-addon:hover {
    border-color: ${gray4};
    background-color: transparent;
  }
`;

export const StyledInput = styled(Input)`
  border-color: ${gray30};
  height: 34px;
`;

export const StyledInputLabel = styled.div`
  color: ${darkGrey5};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin-bottom: 0.5rem;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 600;
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export const StyledRangeInput = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  align-items: center;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  flex-flow: row nowrap;

  input {
    display: none;
  }
`;

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 16px 0;
`;

export const StyledSelectContainerMultiple = styled(StyledSelectContainer)`
  .ant-select:hover .ant-select-arrow {
    visibility: hidden;
  }
`;
