import styled, { css } from "styled-components/macro";
import {
  darkGrey6,
  darkGrey4,
  white,
  gray5,
  gray4,
  darkGrey1,
} from "../../../../../constants/colors";

export const StyledTooltip = styled.div`
  position: absolute;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 8px;
  margin-left: 20px;
  transform: translateY(-50%);
  background: ${darkGrey4};
  border: 1px solid ${darkGrey6};
  font-weight: 500;
  z-index: 2;
  ${props =>
    props.$isLight &&
    css`
      background: ${darkGrey1};
      border: 1px solid ${darkGrey1};
      z-index: 1;
    `}
`;

export const StyledChartTitle = styled.div`
  color: ${darkGrey4};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 24px 24px 16px 24px;
  background-color: ${white};
  margin-left: -24px;
  width: calc(100% + 48px);
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledChartFooter = styled.div`
  padding: 19px 24px 19px 24px;
  background-color: ${white};
  width: 100%;
  height: 68px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 0;
  border-top: solid 1px ${gray5};
`;

export const StyledChartParametrs = styled.div`
  padding: 12px;
  background-color: ${darkGrey4};
  width: fit-content;
  box-sizing: border-box;
  position: absolute;
  right: 24px;
  top: 80px;
  z-index: 3;
  border-radius: 2px;
  border: 1px solid ${gray5};
  box-shadow: 0 4px 12px 0 ${gray4};
  color: ${white};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const StyledChartParametrsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;
