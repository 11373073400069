import styled, { css } from "styled-components/macro";
import { maxScreensSizesPx } from "../styled";
import {
  accentRed,
  blue,
  blueWhite,
  gold,
  green,
  greenWhite,
  lightRed,
  whiteYellow,
  accentViolet,
  lightViolet,
} from "../../constants/colors";
import { PROGRESS_STATES } from "../../constants/constraints";

export const StyledTableWrapper = styled.div`
  padding: 1rem 2rem;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 1rem;
  }
`;

export const StyledActionsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const StyledStatusLabelRow = styled.span`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
`;

export const StyledAnimatedSyncOutlineIconWrapper = styled.span`
  width: 86px;
  display: flex;
  align-items: center;
  justify-content: "flex-end";
`;

export const StyledStatusLabel = styled.span`
  display: flex;
  flex-flow: row;
  padding: 0px 8px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  column-gap: 8px;
  max-height: 24px;
  min-height: 24px;
  width: 110px;
  box-sizing: border-box;

  ${props =>
    (props.status === PROGRESS_STATES.draft ||
      props.status === PROGRESS_STATES.finishedPartially) &&
    css`
      background-color: ${whiteYellow};
      color: ${gold};
    `}

  ${props =>
    props.status === PROGRESS_STATES.cancelled &&
    css`
      background-color: ${lightRed};
      color: ${accentRed};
    `}

    ${props =>
    props.status === PROGRESS_STATES.failed &&
    css`
      background-color: ${lightRed};
      color: ${accentRed};
    `}

    ${props =>
    props.status === PROGRESS_STATES.inProgress &&
    css`
      background-color: ${blueWhite};
      color: ${blue};
    `}

    ${props =>
    props.status === PROGRESS_STATES.queued &&
    css`
      background-color: ${lightViolet};
      color: ${accentViolet};
    `}

    ${props =>
    props.status === PROGRESS_STATES.finished &&
    css`
      background-color: ${greenWhite};
      color: ${green};
    `}
`;

export default StyledTableWrapper;
