import axiosInstance from "../axiosInstance";

export const getPortfoliosData = async resultUUID => {
  const response = await axiosInstance({
    url: `/results/${resultUUID}/portfolios/ `,
  });
  return response?.data;
};

export const deleteResult = async resultUUID => {
  const response = await axiosInstance({
    url: `/results/${resultUUID}/`,
    method: "DELETE",
  });
  return response?.data;
};

export const deleteResults = async uuids => {
  const response = await axiosInstance({
    url: "/results/multiple-delete/",
    method: "POST",
    data: {
      uuids,
    },
  });
  return response?.data;
};

export const getPortfolioGroup = async groupUUID => {
  const response = await axiosInstance({
    url: `/portfolio-groups/${groupUUID}/`,
  });
  return response?.data;
};

export const updatePortfolioGroup = async (groupUUID, data) => {
  const response = await axiosInstance({
    url: `/portfolio-groups/${groupUUID}/`,
    method: "PATCH",
    data,
  });
  return response?.data;
};

export const getPortfolioGroupArguments = async groupUUID => {
  const response = await axiosInstance({
    url: `/portfolio-groups/${groupUUID}/arguments/`,
  });
  return response?.data;
};

export const updatePortfolioGroupArguments = async (groupUUID, data) => {
  const response = await axiosInstance({
    url: `/portfolio-groups/${groupUUID}/arguments/`,
    method: "PATCH",
    data,
  });
  return response?.data;
};

export const getPortfolioLoans = async portfolioUUID => {
  const response = await axiosInstance({
    url: `/portfolio/${portfolioUUID}/loans/`,
  });
  return response?.data;
};

export const getPortfolioArguments = async portfolioUUID => {
  const response = await axiosInstance({
    url: `/portfolios/${portfolioUUID}/arguments/`,
  });
  return response?.data;
};

export const getPortfolioColumnsSummary = async portfolioUUID => {
  const response = await axiosInstance({
    url: `/portfolios/${portfolioUUID}/columns-details/`,
  });
  return response?.data;
};

export const updatePortfolioArguments = async (portfolioUUID, data) => {
  const response = await axiosInstance({
    url: `/portfolios/${portfolioUUID}/arguments/`,
    method: "PATCH",
    data,
  });
  return response?.data;
};

export const getPortfolioGenerations = async portfolioUUID => {
  const response = await axiosInstance({
    url: `/portfolios/${portfolioUUID}/portfolio-generations/`,
  });
  return response?.data;
};

export const getPortfolioGenerationByUUID = async generationUUID => {
  const response = await axiosInstance({
    url: `/portfolio-generations/${generationUUID}/`,
  });
  return response?.data;
};

export const getPortfolioGenerationByStep = async (portfolioUUID, step) => {
  const response = await axiosInstance({
    url: `/portfolios/${portfolioUUID}/by-generation-number/${step}/`,
  });
  return response?.data;
};

export const getPortfolioPieData = async ({ generationUUID, column }) => {
  const response = await axiosInstance({
    url: `/portfolio-generations/${generationUUID}/piedata/${column}/`,
  });
  return response?.data;
};

export const getPortfolioHistogramData = async ({
  generationUUID,
  industry,
  distColumn,
}) => {
  const response = await axiosInstance({
    url: `/portfolio-generations/${generationUUID}/histogram-data/${encodeURIComponent(
      industry,
    )}/${distColumn}/`,
  });
  return response?.data;
};

export const getPortfolioIndustries = async ({ generationUUID }) => {
  const response = await axiosInstance({
    url: `/portfolio-generations/${generationUUID}/industries/`,
  });
  return response?.data;
};

export const getAllPortfolioConstraintsValue = async generationUUID => {
  const response = await axiosInstance({
    url: `/portfolio-generations/${generationUUID}/constraint-values/all/`,
  });
  return response?.data;
};

export const downloadPortfolioStepResults = async generationUUID => {
  const response = await axiosInstance({
    url: `/portfolio-generations/${generationUUID}/download/`,
  });
  return response?.data;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPortfolioGroup,
  updatePortfolioGroup,
  getPortfoliosData,
  getPortfolioGroupArguments,
  updatePortfolioGroupArguments,
  getPortfolioLoans,
  getPortfolioArguments,
  updatePortfolioArguments,
  getPortfolioGenerations,
  getPortfolioGenerationByUUID,
  getPortfolioGenerationByStep,
  getPortfolioPieData,
  getPortfolioHistogramData,
  getAllPortfolioConstraintsValue,
  getPortfolioIndustries,
  downloadPortfolioStepResults,
};
