import PropTypes from "prop-types";
import {
  darkGrey4,
  white,
  black,
  goldLight,
  gold,
} from "../../../../../constants/colors";

const Tooltip = ({ value, isCompare = false }) => (
  <g>
    <rect
      width={35}
      height={20}
      fill="#000"
      rx="4"
      stroke={isCompare ? gold : black}
      style={{
        transform: "translate(-16px, 10px)",
        fill: isCompare ? goldLight : darkGrey4,
      }}
    />
    <text
      style={{
        fontSize: "12px",
        transform: "translate(-7px, 24px)",
        textAnchor: "center",
        fill: isCompare ? black : white,
      }}
    >
      {value}
    </text>
  </g>
);

Tooltip.propTypes = {
  value: PropTypes.number,
  isCompare: PropTypes.bool,
};

export default Tooltip;
