import styled, { css } from "styled-components/macro";
import { Collapse, Tabs } from "antd";
import {
  white,
  gray5,
  darkGrey5,
  blue,
  darkGrey3,
  gray1,
  darkGrey2,
  darkGrey1,
  darkBlue,
  darkGrey6,
  darkGrey4,
  gray6,
  gray4,
  blueWhite,
  gray80,
  gray60,
} from "../../../../constants/colors";
import { maxScreensSizesPx } from "../../../styled";
import { Select } from "../../../../components/Form/Select/Select";

const { Panel } = Collapse;

export const StyledTabs = styled(Tabs)`
  > .ant-tabs-nav {
    margin: 0;
  }

  [class^="ant-tabs"] {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${darkGrey2};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${darkBlue};
  }

  > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }

  .ant-tabs-ink-bar {
    background-color: ${darkBlue};
  }
`;
export const StyledAccordionPanel = styled(Panel)`
  .ant-collapse-header {
    background-color: ${gray1};
  }
`;
export const StyledChartRow = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr;

  .apexcharts-tooltip {
    transform: translateY(100%);
  }

  .clo-chart-point-marker,
  .clo-chart-point-marker + rect,
  .clo-chart-point-label {
    pointer-events: none;
  }

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    grid-template-columns: 1fr;
    margin: 0px 16px;
  }
`;

export const StyledCardDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: ${white};
  border: 1px solid ${gray5};
  min-height: 470px;
`;

export const StyledCardDetailsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 2rem;
  overflow: auto;

  .ant-collapse {
    background-color: inherit;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }

  .ant-collapse-item {
    border: none;

    .ant-collapse-header {
      padding: 0.75rem 1.5rem;
      align-items: center;
    }
  }

  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: 0;
  }
`;

export const StyledCardDetailsListHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.5rem;
`;

export const StyledCardDetailsListNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${darkGrey5};

  &:before {
    content: "#";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${darkGrey2};
  }

  ${props =>
    props.$isSelected &&
    css`
      &:before {
        color: ${blue};
      }
      color: ${blue};
    `}
`;

export const StyledCardDetailsListValue = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${darkGrey5};

  &:before {
    content: "${props => props.$title || ""}";
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: ${darkGrey3};
  }
`;

export const StyledCardDetailsSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  box-sizing: border-box;

  ${props =>
    props.$inner &&
    css`
      padding: 1.5rem 0.5rem 1rem;
    `}
`;

export const StyledCardDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 18px;
  min-height: 80px;
  height: 80px;
`;

export const StyledSellBlock = styled.div`
  padding: 20px 0px;
`;

export const StyledCardDetailsText = styled.div`
  color: ${gray80};
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 38px;
  max-width: 283px;
  font-family: Onest, sans-serif;

  ${props =>
    props.$rightAlign &&
    css`
      justify-content: flex-end;
      padding-right: 47px;
      padding-left: 0;
      font-weight: 700;
      color: ${darkGrey4};
    `}

  ${props =>
    props.$noPadding &&
    css`
      padding: 0;
    `}
`;

export const StyledCardDetailsTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  color: ${darkGrey4};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

export const StyledCardDetailsLink = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${darkBlue};
  text-align: center;
  margin: 1rem;

  cursor: pointer;
`;

export const StyledCardDetailsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: space-between;
  padding: 13px 15px;
  box-sizing: border-box;
  border: solid 5px transparent;
  margin: 1px 0;
  height: 46px;
  max-height: 46px;
  ${props =>
    props.$hasBorder &&
    css`
      border-bottom: dashed 1px ${gray6};
    `}
`;

export const StyledCardDetailsRowActive = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: space-between;
  border: solid 1px transparent;
  padding: 13px 15px;
  box-sizing: border-box;
  height: 46px;
  max-height: 46px;
  ${props =>
    props.$noPadding &&
    css`
      padding: 0;
    `}
  ${props =>
    props.$hasBorder &&
    css`
      border-bottom: dashed 1px ${gray6};
    `}
  ${props =>
    props.$isSelected &&
    css`
      border: solid 1px ${blue};
    `}
`;

export const StyledCardDetailsRowActiveTransactions = styled(
  StyledCardDetailsRowActive,
)`
  ${props =>
    props.$noPadding &&
    css`
      padding: 0;
    `}
`;

export const StyledCardDetailsRowContainer = styled.div`
  border: solid 4px ${blueWhite};
  margin: 1px 0;
  border-radius: 2px;
  transition: border-color 0.1s ease;
  ${props =>
    !props.$isSelected &&
    css`
      border-color: transparent;
    `}
`;

export const StyledCardDetailsStat = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const StyledCardDetailsStatTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${gray60};
  text-transform: uppercase;
  padding-left: 46px;
  display: flex;
  align-items: center;
  gap: 6px;

  ${props =>
    props.$rightAlign &&
    css`
      text-align: right;
      padding-right: 46px;
      padding-left: 0px;
      justify-content: flex-end;
    `}
`;

export const StyledCardDivider = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: solid 1px ${gray5};
  ${props =>
    props.$dashed &&
    css`
      border-bottom-style: dashed;
      margin: 0px;
      width: 100%;
    `}
`;

export const StyledCardDetailsStatValue = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${darkGrey5};
`;

export const StyledTooltip = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;

  background: ${darkGrey5};
  border: 1px solid ${darkGrey6};
  box-shadow: 0 2px 8px rgba(66, 93, 110, 0.15);
`;

export const StyledTooltipTitle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 12px;
  column-gap: 8px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${white};

  &:before {
    content: "";
    display: flex;
    width: 1rem;
    height: 1rem;
    background-color: ${blue};
    border-radius: 500rem;
    margin-right: 0.5rem;
  }
`;

export const StyledTooltipDataLabel = styled.div`
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${darkGrey1};
  display: grid;
  grid-template-columns: 210px auto;
  margin-bottom: 5px;
  padding: 0 1rem 0 1.5rem;
`;

export const StyledSelectContainer = styled("div")`
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: ${darkGrey2};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 0 0 16px 24px;
  border-bottom: 1px solid ${gray5};
  font-weight: 500;
`;

export const StyledChartContainer = styled("div")`
  border: 1px solid ${gray5};
  padding: 0px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
`;

export const StyledYAxisTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: ${darkGrey3};
  margin: 16px 0 0 24px;
`;

export const StyledXAxisTitle = styled(StyledYAxisTitle)`
  text-align: right;
  margin: 0 10px 16px 24px;
`;

export const StyledSelect = styled(Select)`
  min-width: 320px;
`;

export const StyledDivider = styled.div`
  width: 100%;
  height: 8px;
  border-bottom: dashed 2px ${darkGrey3};
  margin-bottom: 8px;
`;

export const StyledButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: solid 1px ${gray4};
  padding: 14px 24px;
  margin-top: auto;
  gap: 10px;
  justify-self: flex-end;
`;

export const StyledArrowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  ${props =>
    props.$rightAlign &&
    css`
      justify-self: flex-end;
      margin-left: auto;
    `}
`;

export const StyledArrowBlock = styled.button`
  background-color: ${gray4};
  padding: 8px;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const StyledDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: transparent;
  ${props =>
    props.$color &&
    css`
      background-color: ${props.$color};
    `}
`;

export const StyledCompareBlock = styled.button`
  width: 160px;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
`;

export const StyledTitlesBlock = styled.div`
  display: flex;
  gap: 10px;
  justify-self: flex-end;
  flex-direction: row;
  &.columns-reverse {
    flex-direction: row-reverse;
  }
`;

export const StyledCompareButtons = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const StyledDiffValue = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${props =>
    props.$color &&
    css`
      color: ${props.$color};
    `}
`;

export const StyledInfoBlockContainer = styled.div`
  border: 1px dashed ${darkGrey6};
  border-radius: 2px;
  width: fit-content;
  position: absolute;
  right: 10px;
  top: 80px;
  z-index: 3;
`;

export const StyledInfoBlock = styled.div`
  border-radius: 2px;
  background-color: ${darkGrey4};
  width: 247px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${gray5};
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 16px;
`;

export const StyledCloseBtn = styled.button`
  align-self: flex-start;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin-top: -4px;
  margin-left: 15px;
  outline: none;
  svg {
    width: 18px;
  }
`;
