const errorMessages = {
  "-1": {
    title: "network error",
    message: "Network Error.",
  },
  401: {
    title: "unauthorized",
    message: "Your session has expired. Please, login again.",
  },
  422: {
    title: "unprocessable entity",
    message:
      "An error occurred while saving the changes. Please, edit data to save.",
  },
  500: {
    title: "internal server error",
    message:
      "Action is unavailable because of an internal server error. Our team is aware of it. In case of need, please, contact support.",
  },
  502: {
    title: "Bad Gateway",
    message:
      "Action is unavailable. Our team is aware of it. In case of need, please, contact support.",
  },
};

export default errorMessages;
