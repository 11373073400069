/* eslint-disable react/prop-types */
import { StyledSelect } from "../../features/DataFilters/styles";

const SelectRenderer = ({ column, node, value, disableInputs }) => {
  const checkedHandler = val => {
    node.setDataValue(column.colId, val);
  };

  return (
    <StyledSelect
      value={value}
      disabled={disableInputs}
      onChange={val => {
        checkedHandler(val);
      }}
      options={[
        { value: "include", label: <span>Include</span> },
        { value: "exclude", label: <span>Exclude</span> },
        { value: "optional", label: <span>Optional</span> },
      ]}
    />
  );
};

export default SelectRenderer;
