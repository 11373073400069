import { createContext } from "react";

const ConstraintsContext = createContext({
  constraintsContext: {
    constraints: {},
    currentPortfolioUUID: "",
  },
  setConstraintsContext: () => {},
});

export default ConstraintsContext;
