import generatePicker from "antd/es/date-picker/generatePicker";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";

import { Button } from "antd";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { StyledDateFilterContainer } from "../styles";
import { getDefaultDateValue } from "../../../../utils/formatDate";

// @ts-ignore
const DatePicker = generatePicker(dateFnsGenerateConfig);

const { RangePicker } = DatePicker;

const RangePickerFilter = ({
  close,
  onChange,
  onReset,
  defaultDates = ["", ""],
}) => {
  const [dates, setDates] = useState(() => getDefaultDateValue(defaultDates));

  const handleReset = () => {
    onReset();
    setDates(["", ""]);
    close();
  };

  const handleChange = useCallback(
    (event, value) => {
      onChange(event, value);
      setDates(getDefaultDateValue(value));
      close();
    },
    [close, onChange],
  );

  return (
    <StyledDateFilterContainer>
      <RangePicker onChange={handleChange} allowClear={false} value={dates} />
      <Button onClick={handleReset}>Reset</Button>
    </StyledDateFilterContainer>
  );
};

RangePickerFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  close: PropTypes.func,
  defaultDates: PropTypes.arrayOf(PropTypes.string),
};

export default RangePickerFilter;
