import { PageContextProvider } from "./context";
import WorkbooksContent from "./content/WorkbooksContent";

const WorkbooksPage = () => (
  <PageContextProvider>
    <WorkbooksContent />
  </PageContextProvider>
);

export default WorkbooksPage;
