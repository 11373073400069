import PropTypes from "prop-types";
import { maxBy } from "lodash";
import { useMemo } from "react";
import { darkGrey1 } from "../../../../../constants/colors";

const TICK_LENGTH = 10;

const AxisLeft = ({ yScale, pixelsPerTick, width }) => {
  const range = yScale.range();

  const ticks = useMemo(() => {
    const height = range[0] - range[1];
    const numberOfTicksTarget = Math.floor(height / pixelsPerTick);

    return yScale.ticks(numberOfTicksTarget).map(value => ({
      value,
      yOffset: yScale(value),
    }));
  }, [yScale, pixelsPerTick, range]);

  const yOffsetMax = maxBy(ticks, item => item?.yOffset).yOffset + 10;

  return (
    <line
      x1={0}
      x2={width + TICK_LENGTH}
      y1={yOffsetMax}
      y2={yOffsetMax}
      stroke={darkGrey1}
      strokeWidth={0.5}
    />
  );
};

AxisLeft.propTypes = {
  pixelsPerTick: PropTypes.number,
  yScale: PropTypes.func,
  width: PropTypes.number,
};

export default AxisLeft;
