import { useNavigate } from "react-router-dom";
import { StyledNotFoundIcon } from "../../components/Icons";
import StyledButton from "../../components/Buttons/Button.styled";
import {
  StyledPageTitle,
  StyledWelcomePageContent,
} from "../Auth/WelcomePage.styled";
import { StyledBodyText } from "../../components/Typography";
import { StyledPageTextContainer } from "../styled";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <StyledWelcomePageContent>
      <StyledNotFoundIcon />
      <StyledPageTextContainer>
        <StyledPageTitle>Page Not Found</StyledPageTitle>
        <StyledBodyText>
          We are sorry for the inconvenience, it looks like you’re trying to
          access the page that has been deleted or never ever existed.
        </StyledBodyText>
      </StyledPageTextContainer>
      <StyledButton $autoWidth onClick={() => navigate("/")}>
        back to homepage
      </StyledButton>
    </StyledWelcomePageContent>
  );
};

export default NotFound;
