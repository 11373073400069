import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PROGRESS_STATES } from "../../constants/constraints";
import { formatIntervalToDuration } from "../../utils/formatDate";

const RunTimeCell = ({ record }) => {
  const [runTime, setRunTime] = useState(null);
  useEffect(() => {
    let timerId;

    const startPollingProgress = () => {
      let interval;
      if (
        !timerId &&
        record?.startedAt &&
        record?.status === PROGRESS_STATES.inProgress
      ) {
        const startDate = new Date(record.startedAt);

        timerId = setInterval(() => {
          interval = formatIntervalToDuration({
            start: startDate,
            end: new Date(),
          });

          setRunTime(interval);
        }, 1000);
      }
    };

    const stopPollingProgress = () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };

    if (record?.status === PROGRESS_STATES.inProgress) {
      startPollingProgress();
    } else if (record?.status) {
      if (record?.startedAt && record?.finishedAt) {
        const startDate = new Date(record.startedAt);
        // NOTE: Locally finishedAt time in wrong timezone, do not fix FE
        // TODO: remove comment when BE is fixed
        const finishedAt = new Date(record?.finishedAt);
        const interval = formatIntervalToDuration({
          start: startDate,
          end: finishedAt,
        });
        setRunTime(interval);
      }

      stopPollingProgress();
    }
    return () => stopPollingProgress();
  }, [record.status, record.finishedAt, record.startedAt]);

  return <span>{runTime}</span>;
};

RunTimeCell.propTypes = {
  record: PropTypes.shape({
    startedAt: PropTypes.string,
    finishedAt: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default RunTimeCell;
