import { darkGrey6 } from "../../constants/colors";
import { StyledH1, StyledLink, StyledTextBody } from "../Typography";
import { StyledPoliciesAndDisclosures } from "./PoliciesAndDisclosures.styled";

const PoliciesAndDisclosures = () => (
  <StyledPoliciesAndDisclosures>
    <StyledH1>Loan Hunter Holdings, LLC TERMS OF SERVICE</StyledH1>
    <StyledTextBody>
      You have elected to use the platform and related services offered by
      either Loan Hunter Holdings, LLC. (known as “Loan Hunter” and such
      platform and services, collectively, the “Loan Hunter Services”) made
      available at{" "}
      <StyledLink href="https://apps.loan-hunter-solutions.com/">
        https://apps.loan-hunter-solutions.com/
      </StyledLink>{" "}
      (or any affiliated or successor URL controlled by Loan Hunter, the “Loan
      Hunter Website”). All references hereinafter to “Selected Services” shall
      refer to the specific Loan Hunter Services provided by either Loan Hunter
      Holdings, LLC or a subsidiary or affiliate of Loan Hunter Holdings, LLC.
    </StyledTextBody>
    <StyledTextBody>
      These terms of service (“Terms of Service” or “Agreement”) govern the
      provision to you (if you are accepting these Terms of Service in your
      individual capacity) or the company of which you are a duly authorized
      employee or agent (“Customer”) of the Selected Services, and Customer’s
      usage of the same. By using the Selected Services, Customer agrees to be
      bound by these Terms of Service to the exclusion of all other terms. If
      Customer does not unconditionally agree to all of the terms and conditions
      of this Agreement, then Customer should not use (and has no right to use)
      the Selected Services or the Loan Hunter Website. Loan Hunter may update
      these Terms of Service from time to time, and it is your responsibility to
      check these Terms of Service periodically for changes.
    </StyledTextBody>
    <StyledTextBody>
      Loan Hunter may make modifications to the Selected Services in its
      discretion, provided that such modifications do not materially adversely
      affect the features or functionality of the Selected Services. This
      Agreement applies solely to the Selected Services. All information
      included in the Loan Hunter Website is intended solely for the person who
      has created the account. Information on this website should not be shared
      via screenshot grab, printing, email, mail, or any other means of
      providing information including to other internal parties. All users
      attest by accepting the Terms of Service of this website that they will
      not share information with any other party. To do so will be a direct
      violation of the Terms of Service and access to the website will be
      revoked and users’ log in information blocked from accessing the system.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      1. Selected Services. Loan Hunter shall use commercially reasonable
      efforts to provide the Selected Services.
    </StyledTextBody>
    <StyledTextBody>
      The Selected Services may incorporate or link to components or services
      provided by one or more third parties (such services, “Third Party
      Services”, and such providers, “Third Party Service Providers”). Customer
      agrees that its use of those Third-Party Services may be subject to terms
      and conditions and policies (including any amendments or modifications to
      those terms and conditions and policies) that are specific to such Third
      Party Services, and that it shall be bound by such terms and conditions
      and policies.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      2. Restrictions; Compliance with Laws and Other Matters.
    </StyledTextBody>
    <StyledTextBody>
      (a) Prohibited Behavior. Customer shall not, and shall not permit any
      users of Customer’s applications hosted by Loan Hunter as part of the
      Selected Services (such applications, “Customer Applications”, and such
      users, “User” or “Users”) or any other party to, engage in, solicit, or
      promote any activity that is illegal, violates the rights of others, or
      could subject Loan Hunter to liability to third parties, including:
    </StyledTextBody>
    <StyledTextBody>
      (i) unauthorized access, monitoring, interference with, or use of Loan
      Hunter or third party accounts, data, computers, systems or networks,
      including the introduction of viruses or similar harmful code via the
      Customer Application or Selected Services;
    </StyledTextBody>
    <StyledTextBody>
      (ii) interference with others’ usage of the Selected Services or any
      system or network, including mail bombing, flooding, broadcast or denial
      of service attacks;
    </StyledTextBody>
    <StyledTextBody>
      (iii) unauthorized collection or use of personal or confidential
      information, including phishing, pharming, scamming, spidering, and
      harvesting;
    </StyledTextBody>
    <StyledTextBody>
      (iv) use of any false, misleading or deceptive TCP-IP packet header
      information in an e-mail or a newsgroup posting;{" "}
    </StyledTextBody>
    <StyledTextBody>
      (v) infringement, misappropriation or other violation of any patent,
      trademark, copyright or other intellectual property or proprietary right;{" "}
    </StyledTextBody>
    <StyledTextBody>
      {" "}
      (vi) collection, storage, publication, transmission, viewing or other use
      of any content that, in Loan Hunter’s opinion, (1) is obscene, (2)
      constitutes or promotes child pornography, (3) is excessively violent,
      incites or threatens violence, or contains harassing content or hate
      speech, (4) is illegally unfair or deceptive, (5) is defamatory or
      violates a person’s privacy, or (6) creates a safety or health risk to an
      individual or the public;
    </StyledTextBody>
    <StyledTextBody>
      (vii) any other activity that places Loan Hunter in the position of
      fostering, or having liability for, illegal activity in any jurisdiction;
    </StyledTextBody>
    <StyledTextBody>
      (viii) any activity that violates the acceptable use policy or similar set
      of terms of any Third Party Service Providers for Third Party Services
      that have been made available to Customer; or
    </StyledTextBody>
    <StyledTextBody>
      (ix) attempting to probe, scan, penetrate or test the vulnerability of a
      Loan Hunter system or network or to breach Loan Hunter’s security or
      authentication measures, whether by passive or intrusive techniques. If
      any IP address or response to any Customer Application becomes listed on
      Spamhaus, Spews, NJABL or any other reputable abuse database, then
      Customer will be deemed to be in breach of this Section 2(a), regardless
      of whether the IP numbers were listed as a result of Customer’s actions.
      Loan Hunter may suspend the provision of Selected Services to Customer if
      Customer or a User engages in any of the foregoing activities. (b)
      Security. Customer shall take all reasonable security precautions in
      connection with its use of the Selected Services. Customer shall protect
      the confidentiality of all usernames, passwords, and other information it
      uses to access the Selected Services and shall change its passwords
      periodically. If the Customer Application is hacked or otherwise accessed
      by a third party without authorization, then Loan Hunter may take the
      Customer Application offline until Loan Hunter determines that the
      intrusion is finally resolved. (c) Cooperation with Investigations and
      Legal Proceedings. Loan Hunter may, without notice to Customer: (i) report
      to the appropriate authorities any conduct by Customer or Users that Loan
      Hunter believes violates applicable criminal law; and (ii) provide any
      information that it has about Customer or its Users in response to a
      request from a law enforcement or government agency, or in response to a
      request in a civil action that on its face meets the requirements for such
      a request.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      4. Customer Application; Customer Account.
    </StyledTextBody>
    <StyledTextBody>
      Customer is solely responsible for its Customer Application, including for
      (i) controlling the access to, and use and security of, the Customer
      Application and the data residing in or processed via the Customer
      Application, (ii) maintaining the security of the passwords and other
      measures used to protect access to Customer’s account, and (iii) all
      instructions provided to Loan Hunter through Customer’s account, whether
      or not authorized by Customer.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      5. Suspension of Selected Services.
    </StyledTextBody>
    <StyledTextBody>
      Loan Hunter may suspend the provision of Selected Services to Customer or
      remove any data or content transmitted via the Selected Services without
      liability (i) if Loan Hunter reasonably believes that any Selected Service
      is being used in violation of this Agreement or applicable law, (ii) if
      requested by a law enforcement or government agency or otherwise to comply
      with applicable law, provided that Loan Hunter shall use commercially
      reasonable efforts to notify Customer prior to suspending Selected
      Services as permitted under this Agreement, or (iii) as otherwise
      specified in this Agreement. Information on Loan Hunter’s servers may be
      unavailable to Customer during a suspension of Selected Services.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>6. Customer Warranties.</StyledTextBody>
    <StyledTextBody>
      Customer represents and warrants to Loan Hunter that (i) the information
      Customer has provided for the purpose of establishing an account with Loan
      Hunter is complete and accurate, and (ii) it has the requisite power and
      authority to enter into this Agreement and to perform all of its
      obligations hereunder. Customer shall update its account information to
      maintain the accuracy of such information during the term of this
      Agreement.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>7. Indemnification.</StyledTextBody>
    <StyledTextBody>
      Customer shall indemnify and hold harmless Loan Hunter from and against
      any and all liabilities, judgments, costs, losses, damages and expenses
      (including reasonable attorneys’ fees, court costs and costs of
      investigation) arising in connection with any claim, suit, action,
      judgment or other proceeding brought or threatened by a third party
      against Loan Hunter, and relating to, based upon, or arising out of or in
      connection with (i) any breach by Customer of any representations,
      warranties, agreements or covenants made, or (ii) Customer’s gross
      negligence, willful misconduct or fraud in connection with its use or
      provision of the Services.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      8. Disclaimer of Warranties.
    </StyledTextBody>
    <StyledTextBody>
      EXCEPT AS EXPRESSLY SPECIFIED IN THIS AGREEMENT, ALL SELECTED SERVICES ARE
      PROVIDED “AS IS” AND Loan Hunter (FOR ITSELF, ITS PARENT, AND ALL OF Loan
      Hunter’S SUBSIDIARIES, AFFILIATES, SUPPLIERS AND LICENSORS) DISCLAIMS ANY
      AND ALL OTHER WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
      NONINFRINGEMENT. NEITHER Loan Hunter (NOR ANY OF ITS PARENT, SUBSIDIARIES,
      AFFILIATES, SUPPLIERS OR LICENSORS) WARRANTS OR REPRESENTS THAT THE
      SELECTED SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE. CUSTOMER
      ACKNOWLEDGES THAT THERE ARE RISKS INHERENT IN INTERNET CONNECTIVITY THAT
      COULD RESULT IN THE LOSS OF CUSTOMER’S PRIVACY, DATA, CONFIDENTIAL
      INFORMATION, AND PROPERTY.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      9. Limitation of Liability.
    </StyledTextBody>
    <StyledTextBody>
      NOTWITHSTANDING ANYTHING ELSE IN THIS AGREEMENT TO THE CONTRARY, IN NO
      EVENT SHALL Loan Hunter Holdings, LLC OR ANY OF THEIR PARENTS,
      SUBSIDIARIES, AFFILIATES, OR ANY OF THEIR EMPLOYEES, OFFICERS, AGENTS,
      LICENSORS OR SUPPLIERS, BE LIABLE UNDER ANY THEORY OF LAW (INCLUDING
      BREACH OF CONTRACT, TORT, STRICT LIABILITY, AND INFRINGEMENT) FOR:
    </StyledTextBody>
    <StyledTextBody>
      I. PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA, OR
      PROFITS, OR BUSINESS INTERRUPTION OR FOR ANY INDIRECT, INCIDENTAL,
      SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES (HOWEVER CAUSED AND UNDER ANY
      THEORY OF LAW INCLUDING BREACH OF CONTRACT, STATUTE, TORT, STRICT
      LIABILITY, AND INFRINGEMENT), EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES; OR
    </StyledTextBody>
    <StyledTextBody>
      II. ANY AMOUNTS IN EXCESS, IN THE AGGREGATE, OF THE FEES ACTUALLY RECEIVED
      BY Loan Hunter FROM CUSTOMER IN THE THREE (3) MONTHS IMMEDIATELY PRECEDING
      THE FIRST OCCURRENCE OF AN EVENT GIVING RISE TO A CLAIM. THIS IS AN
      AGGREGATE LIMIT. THE EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT ENLARGE
      THIS LIMIT. CUSTOMER ACKNOWLEDGES THAT EACH OF THE FOREGOING LIMITATIONS
      OF LIABILITY ARE AN ESSENTIAL PART OF THIS AGREEMENT AND THAT ABSENT SUCH
      LIMITATIONS Loan Hunter WOULD NOT PROVIDE THE SELECTED SERVICES TO
      CUSTOMER OR ENTER INTO THIS AGREEMENT. CUSTOMER ACKNOWLEDGES THAT THE
      SELECTED SERVICES DO NOT INCLUDE DATA BACKUP OR DATA STORAGE SERVICES, AND
      CUSTOMER HEREBY RELEASES Loan Hunter AND Loan Hunter Holdings, LLC AND
      THEIR CONTRACTORS FROM ANY LIABILITY FOR LOSS OF DATA. NOTHING IN THIS
      AGREEMENT SHALL EXCLUDE OR LIMIT EITHER PARTY’S LIABILITY FOR DEATH OR
      PERSONAL INJURY ARISING FROM ITS NEGLIGENCE, OR FOR FRAUD.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>10. Termination.</StyledTextBody>
    <StyledTextBody>
      (a) Customer. Customer may terminate this Agreement upon thirty (30) days
      written notice to Loan Hunter.
    </StyledTextBody>
    <StyledTextBody>
      (b) Loan Hunter. Loan Hunter may terminate this Agreement without
      liability as follows: (i) upon thirty (30) days written notice to Customer
      (ii), upon notice if Customer materially violates any provision of this
      Agreement and fails to cure the violation within thirty (30) days of a
      written notice from Loan Hunter; or (iii) upon one (1) Business Day’s
      notice if Customer uses the Selected Services in violation of a material
      term of this Agreement more than once (after having received notice from
      Loan Hunter regarding the initial violation). A “Business Day” shall mean
      Monday through Friday, 8:00 a.m. to 5:00 p.m., Eastern Standard Time,
      excluding any day that banks in the United States are required or
      permitted to be closed.
    </StyledTextBody>
    <StyledTextBody>
      (c) Effect of Termination. Notwithstanding the foregoing, Customer
      acknowledges and agrees that Loan Hunter may, in its sole discretion and
      without obligation, delete all data or content in Loan Hunter’s possession
      or control, including but not limited to “Customer Data” as defined in
      Section 11(a) hereinbelow, after thirty (30) days following any
      termination or expiration of this Agreement for any reason, without
      liability to Customer. Sections 7, 8, 9, 10, 13, and 14 will survive any
      expiration or termination of this Agreement.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      11. Use of Data; EU Data Subjects; Security.
    </StyledTextBody>
    <StyledTextBody>
      (a) Customer Data. Loan Hunter may use, process, transfer and disclose
      information or data of Customer’s and its Users’ that is stored in a
      Customer Application or otherwise provided by Customer as part of the
      Selected Services (collectively, “Customer Data”) only to the extent
      necessary to provide the Selected Services or as otherwise may be
      permitted under applicable law; provided that, to the extent permitted
      under applicable law, Loan Hunter may also disclose Customer Data (i) to
      third parties in connection with the provision of Selected Services
      (provided such third parties are under a duty to use, process, transfer
      and disclose such Customer Data only as necessary to provide the Selected
      Services), and (ii) in response to requests from law enforcement
      officials, government agencies, or as required in connection with legal
      proceedings.
    </StyledTextBody>
    <StyledTextBody>
      (b) EU Data Subjects. If Loan Hunter receives any Customer Data
      constituting “personal data” as defined in the EU Data Protection
      Directive 95/46/EC (as may be amended from time to time) from Customer or
      its Users in connection with the Selected Services, including any such
      Customer personal data that may be collected by or stored in the Customer
      Application or otherwise hosted on servers owned or controlled by Loan
      Hunter, then Customer agrees that: (i) Customer is the data controller
      with respect to such Customer Data; and (ii) Customer will comply with all
      applicable laws, including the EU Data Protection Directive, applicable to
      such Customer Data, including obtaining all necessary consents from
      relevant EU Data Subjects and taking actions necessary to enable the Data
      Subjects to exercise their rights of notice, disclosure, access,
      accountability and other rights under the relevant data protection law.
      Loan Hunter will act as the data processor of such Customer Data and in
      that capacity will use commercially reasonable efforts to assist Customer
      in enabling it to comply with the applicable data protection law. Customer
      acknowledges that Loan Hunter utilizes servers located in the United
      States, and consents to the transfer of Customer Data to the United
      States. Customer further acknowledges and agrees that any data or
      information stored by Customer on such servers is subject to the laws of
      the United States, including copyright, privacy and defamation laws.
    </StyledTextBody>
    <StyledTextBody>
      (c) Data Security. Loan Hunter will use commercially reasonable efforts to
      prevent the unauthorized access, use or disclosure of Customer Data and
      Direct Customer Information located on Loan Hunter servers, including the
      implementation of industry-standard measures designed to maintain the
      security of such data. Customer acknowledges, however, that Loan Hunter
      cannot guarantee the security of such data given the nature of the
      Internet.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      12. Notices and Communications.
    </StyledTextBody>
    <StyledTextBody>
      (a) Notices. All Customer notices to Loan Hunter under this Agreement
      (including notices required to be in writing) may be sent to Loan Hunter
      via e-mail. Loan Hunter notices to Customer under this Agreement shall be
      given (i) via email to the individual designated as Customer’s “Customer
      Contact” when signing up for the Selected Services online through the Loan
      Hunter Website, (ii) as specified in these Terms and Conditions, or (iii)
      by other means reasonable under the circumstances. Notices are deemed
      received on the day delivered, or if that day is not a Business Day, as of
      the beginning of the first Business Day following the day delivered.
    </StyledTextBody>
    <StyledTextBody>
      (b) Communications with Customer. Loan Hunter shall communicate with
      Customer regarding day-to-day operational issues using email.
    </StyledTextBody>
    <StyledTextBody>
      (c) Customer Responsibilities. Customer shall be responsible for (i)
      ensuring that Customer email addresses used in communicating with Loan
      Hunter are kept current and that spam and other filters for such email
      accounts are configured to accept emails from Loan Hunter, and (ii)
      routinely checking the Loan Hunter Website and product user interface for
      additional information relevant to Customer’s use of the Selected
      Services.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>
      13. Informational Purposes Only.
    </StyledTextBody>
    <StyledTextBody>
      You understand that the publishing of any of the information on this site
      does not constitute a recommendation that any particular special-purpose
      acquisition company, investment, security, transaction, or investment
      strategy (collectively an “Investment”) is suitable for any specific
      person. You understand that neither the Company nor any of its employees,
      agents, or affiliates will advise you personally concerning the
      suitability, value, or risk factors of any particular Investments. To the
      extent any of the content is deemed to be investment advice, such
      information is impersonal and not tailored to the Investment needs of any
      specific person. Investment trading (including without limitation the
      trading of SPACS) involves a high degree of risk and volatility. Past
      results are not an indication of future performance. You understand that
      the performance data of any Investment referenced on the Loan Hunter
      Website is supplied by sources believed to be reliable, that any
      calculations are made using such data, and that such calculations are not
      guaranteed by these sources or Loan Hunter, and that the data and
      calculations may not be complete. Users should always perform their own
      due diligence on any Investment (including but not limited to the review
      of all proxy statements related to any particular Investment) prior to
      making any trade decision. The views expressed on this site are valid only
      for the purpose stated herein and as of the date of this report. No
      obligation is assumed to revise this information to reflect changes,
      events or conditions, which occur subsequent to the date hereof. This site
      does not represent investment advice nor does it provide an opinion
      regarding the fairness of any transaction to any and all parties.
    </StyledTextBody>
    <StyledTextBody $color={darkGrey6}>14. Miscellaneous.</StyledTextBody>
    <StyledTextBody>
      (a) Ownership; Licenses; Customer Data. As between the parties, Loan
      Hunter owns and shall retain all rights in the Selected Services
      (including all related infrastructure), Loan Hunter’s trademarks and
      service marks, and all modifications to any of the foregoing and all
      related intellectual property rights. Customer grants Loan Hunter during
      the term of this Agreement a non-exclusive, worldwide, royalty-free,
      sublicensable, license to use, reproduce, modify, publicly perform,
      publicly display and distribute Customer Data for purposes of providing
      the Selected Services hereunder. In addition, Loan Hunter shall have the
      right, but not the obligation, to retain copies of Customer Data for legal
      compliance purposes.
    </StyledTextBody>
    <StyledTextBody>
      (b) Governing Law, Jurisdiction, Venue. This Agreement shall be governed
      by the laws of the State of New York, USA, excluding its conflicts of law
      rules. Except for disputes involving the assertion of Loan Hunter’s
      intellectual property rights and claims for injunctive relief, any dispute
      arising from or relating to the subject matter of this Agreement shall be
      finally settled by binding arbitration in New York County in the State of
      New York, using the English language, in accordance with the Arbitration
      Rules and Procedures of Judicial Arbitration and Mediation Services, Inc.
      (&quot;JAMS&quot;) then in effect, by one commercial arbitrator with
      substantial experience in resolving intellectual property and commercial
      contract disputes. Such arbitrator shall be selected from the appropriate
      list of JAMS arbitrators in accordance with the JAMS Arbitration Rules and
      Procedures. The prevailing party in the arbitration shall be entitled to
      receive reimbursement of its reasonable expenses (including reasonable
      attorneys&quot; fees, expert witness fees and all other expenses) incurred
      in connection therewith. Judgment upon the award so rendered may be
      entered in a court having jurisdiction, or application may be made to such
      court for judicial acceptance of any award and an order of enforcement, as
      the case may be. Notwithstanding the foregoing, each party shall have the
      right to institute an action in a court of proper jurisdiction for
      injunctive or other equitable relief pending a final decision by the
      arbitrator. For all purposes of this Agreement, the parties consent to
      exclusive jurisdiction and venue in the state and federal courts located
      in New York County in the State of New York. Use of the Selected Services
      is not authorized in any jurisdiction that does not give effect to all
      provisions of this Agreement, including without limitation, this Section.
    </StyledTextBody>
    <StyledTextBody>
      (c) Non-Waiver. A party’s failure or delay in enforcing any provision of
      this Agreement will not be deemed a waiver of that party’s rights with
      respect to that provision or any other provision of this Agreement. A
      party’s waiver of any of its rights under this Agreement is not a waiver
      of any of its other rights with respect to a prior, contemporaneous or
      future occurrence, whether similar in nature or not.
    </StyledTextBody>
    <StyledTextBody>
      (d) Force Majeure. Neither party shall be in default of any obligation
      under this Agreement if the failure to perform the obligation is due to
      any event beyond that party’s control, including significant failure of a
      portion of the power grid, significant failure of the Internet, natural
      disaster, war, riot, insurrection, epidemic, strikes or other organized
      labor action, terrorist activity, or other events of a magnitude or type
      for which precautions are not generally taken in the industry.
    </StyledTextBody>
    <StyledTextBody>
      (e) Severability. In the event any term of this Agreement is held
      unenforceable, the remaining portion of this Agreement will remain in full
      force and effect.
    </StyledTextBody>
    <StyledTextBody>
      (f) Relationship Between the Parties. The parties are independent
      contractors and not partners or joint venturers. Neither party is the
      agent of the other, and neither party may represent to any person that it
      has the power to bind the other on any agreement. This Agreement is
      non-exclusive. Loan Hunter may provide the Selected Services to any
      person, including a competitor of Customer.
    </StyledTextBody>
    <StyledTextBody>
      (g) Assignment. Neither party may assign this Agreement to a third party
      without the written consent of the other party, provided that Loan Hunter
      may upon written notice assign this Agreement to an affiliate or to a
      successor in interest upon any merger, acquisition, change of control,
      reorganization or sale of all or substantially all of its stock or its
      assets that are related to this Agreement. An attempted assignment in
      contravention of the terms and conditions hereof shall be null and void.
    </StyledTextBody>
    <StyledTextBody>
      (h) Agreement. This Agreement is the complete and exclusive agreement
      between the parties regarding its subject matter and supersedes and
      replaces any other agreement, understanding or communication, written or
      oral regarding such subject matter.
    </StyledTextBody>
    <StyledTextBody>LAST UPDATED: NOV 29, 2023</StyledTextBody>
  </StyledPoliciesAndDisclosures>
);

export default PoliciesAndDisclosures;
