import { useCallback } from "react";
import PropTypes from "prop-types";
import { Radio, Space } from "antd";

import {
  StyledRadioGroup,
  StyledTextAfter,
  StyledTextBefore,
} from "./RadioGroup.styled";
import { StyledInputLabel } from "./styles";

const RadioGroup = ({
  value,
  options,
  defaultValue,
  textBefore,
  textAfter,
  name,
  field,
  onChange,
  disabled,
  direction = "vertical",
}) => {
  const onChangeHandler = useCallback(
    event => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  return (
    <StyledRadioGroup data-cy={name} data-field={field}>
      {textBefore && (
        <StyledTextBefore>
          <StyledInputLabel bold>{textBefore}</StyledInputLabel>
        </StyledTextBefore>
      )}
      <Radio.Group
        value={value}
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        disabled={disabled}
      >
        <Space direction={direction}>
          {options.map(option => (
            <Radio
              key={option.value}
              value={option.value}
              checked={option.value === value}
            >
              {option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      {textAfter && (
        <StyledTextAfter>
          <StyledInputLabel bold>{textAfter}</StyledInputLabel>
        </StyledTextAfter>
      )}
    </StyledRadioGroup>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  textBefore: PropTypes.string,
  textAfter: PropTypes.string,
  name: PropTypes.string,
  field: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  direction: PropTypes.string,
};

export default RadioGroup;
