export const WORKBOOK_TYPES = {
  RAMP_UP: { type: "ramp_up", title: "Ramp-up" },
  SELL_REPLACE: { type: "sell_replace", title: "Sell Replace" },
  MULTI_PORTFOLIO: { type: "multi_portfolio", title: "Multi-portfolio" },
  SINGLE_PORTFOLIO: { type: "single_portfolio", title: "Single Portfolio" },
  MIXED: { type: "mixed", title: "Mixed" },
};

export const WORKBOOK_TYPE_VALUES = {
  RAMP_UP: "ramp_up",
  SELL_REPLACE: "sell_replace",
  MULTI_PORTFOLIO: "multi_portfolio",
  SINGLE_PORTFOLIO: "single_portfolio",
  MIXED: "mixed",
};
