import { PageContextProvider } from "./context";
import WorkbookResultsContent from "./content";

const WorkbookResultsPage = () => (
  <PageContextProvider>
    <WorkbookResultsContent />
  </PageContextProvider>
);

export default WorkbookResultsPage;
