import { Outlet, useNavigate, useOutletContext } from "react-router";
import { useContext, useEffect, useMemo } from "react";
import { Skeleton } from "antd";
import { StyledLayout, StyledLayoutContent } from "../../styles";
import ActionsHeader from "../../../features/Shared/ActionsHeader";
import { PROGRESS_STATES } from "../../../constants/constraints";
import { StyledPage, StyledPageHeaderContent } from "../../../features/styled";
import PortfolioBuildProgress from "../../../features/ResultDetails/content/ResultContent/PortfolioBuildProgress";
import { StyledHomeIcon } from "../../../components/Icons";
import usePortfolioData from "../context/usePortfolioData";
import { PageContext as ResultDetailsContext } from "../../../features/ResultDetails/context";
import TabsHeader from "../../../features/Shared/TabsHeader";
import * as routes from "../../../constants/routes";

const ResultDetailsContent = () => {
  const navigate = useNavigate();
  const workbookLayoutContext = useOutletContext();

  const { currentWorkbook, resultUUID, portfolioList } = workbookLayoutContext;

  const portfolioData = usePortfolioData({
    resultUUID,
    portfolioListLength: portfolioList?.length,
  });

  const resultDetailsContext = useContext(ResultDetailsContext);
  const { portfolioBuildProgress, constraintsData, portfolioResultData } =
    resultDetailsContext;
  const status = portfolioBuildProgress?.status;
  const isDraft = status === PROGRESS_STATES.draft;

  const path = window.location.pathname;

  const isLoading =
    !path.includes(routes.FILTERING) && !path.includes(routes.CONSTRAINTS)
      ? workbookLayoutContext?.isLoading || resultDetailsContext?.isLoading
      : workbookLayoutContext?.isLoading;

  const {
    getTitleDetailsByStatus,
    onPortfolioCancelBuild,
    getPortfolioBuildProgress,
  } = portfolioData;

  const filteredPortfolioList = useMemo(() => {
    return portfolioList.filter(
      ({ uuid }) => !!portfolioData.portfolioBuildProgress?.portfolios?.[uuid],
    );
  }, [portfolioData.portfolioBuildProgress.portfolios, portfolioList]);

  useEffect(() => {
    let timerId;

    const startPollingProgress = () => {
      if (!timerId) {
        timerId = setInterval(getPortfolioBuildProgress, 2000);
      }
    };

    const stopPollingProgress = () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };

    const isInProgress =
      portfolioBuildProgress.status === PROGRESS_STATES.inProgress;
    const isInQueue = portfolioBuildProgress.status === PROGRESS_STATES.queued;

    if (isInProgress || isInQueue) {
      startPollingProgress();
    } else if (portfolioBuildProgress.status) {
      stopPollingProgress();
    }
    return () => stopPollingProgress();
  }, [getPortfolioBuildProgress, portfolioBuildProgress.status]);

  const selectedPortfolioName =
    resultDetailsContext?.constraintsData?.selectedPortfolio?.name;
  const currentStep = portfolioResultData?.currentStep;

  const config = useMemo(() => {
    let defaultConfig = [
      {
        icon: <StyledHomeIcon />,
        onClick: () => navigate("/"),
        tooltipText: "Back to workbooks",
      },
      {
        label: currentWorkbook?.name,
        onClick: () => navigate(`/workbooks/${currentWorkbook?.uuid}/results`),
        tooltipText: "Back to workbook results",
      },
      {
        label: resultUUID,
      },
    ];

    const isArgumentsPage = path.includes(routes.CONSTRAINTS);
    const isTradeSummaryPage = path.includes(routes.RESULTS_TRADE_SUMMARY);
    const isDataUpload = path.includes(routes.FILTERING);

    if (isArgumentsPage) {
      return [
        ...defaultConfig,
        {
          label: "Optimization Arguments",
        },
      ];
    }

    if (isTradeSummaryPage) {
      return [
        ...defaultConfig,
        {
          label: "Trade Summary",
        },
      ];
    }

    if (isDataUpload) {
      return [
        ...defaultConfig,
        {
          label: isDraft ? "Data Upload" : "Uploaded Data",
        },
      ];
    }

    defaultConfig = [
      ...defaultConfig,
      {
        label: selectedPortfolioName ? (
          `Portfolio: ${selectedPortfolioName}`
        ) : (
          <Skeleton.Input size="small" active />
        ),
      },
    ];

    if (currentStep) {
      return [
        ...defaultConfig,
        {
          label: `Step ${currentStep}`,
        },
      ];
    }

    return defaultConfig;
  }, [
    currentWorkbook?.name,
    currentWorkbook?.uuid,
    resultUUID,
    path,
    selectedPortfolioName,
    currentStep,
    navigate,
    isDraft,
  ]);
  const currentResult =
    resultDetailsContext?.portfolioResultData?.currentResult;

  return (
    <StyledLayout>
      <StyledLayoutContent>
        <StyledPage>
          <ActionsHeader
            breadcrumbsConfig={config}
            currentResult={currentResult}
          >
            {portfolioBuildProgress?.status !== PROGRESS_STATES.draft ? (
              <StyledPageHeaderContent>
                <PortfolioBuildProgress
                  portfolioBuildProgress={portfolioBuildProgress}
                  getTitleDetailsByStatus={getTitleDetailsByStatus}
                  onPortfolioCancelBuild={onPortfolioCancelBuild}
                  constraintsData={constraintsData}
                />
              </StyledPageHeaderContent>
            ) : null}
          </ActionsHeader>
          <TabsHeader
            portfolioBuildProgress={portfolioBuildProgress}
            isLoading={isLoading}
            currentResult={currentResult}
          />
          <Outlet
            context={{
              ...workbookLayoutContext,
              ...resultDetailsContext,
              ...portfolioData,
              filteredPortfolioList,
            }}
          />
        </StyledPage>
      </StyledLayoutContent>
    </StyledLayout>
  );
};

export default ResultDetailsContent;
