import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  StyledMenuHeader,
  StyledMenuTitle,
  StyledNavigationWrapper,
  StyledSideNavigationArea,
} from "../../styles";
import BuildProgress from "./BuildProgress";
import { StyledMenuList } from "./styles";
import {
  StyledArrowDownwardIcon,
  StyledArrowUpwardIcon,
} from "../../../../components/Icons";
import { PROGRESS_STATES } from "../../../../constants/constraints";
import SideAccordionMenuItem from "./SideAccordionMenuItem";
import { PageContext } from "../../context";

const SideNavigation = ({
  handleMenuItemClick,
  getTitleDetailsByStatus,
  portfolioList,
  selectedGenerations,
}) => {
  const { constraintsData, portfolioResultData } = useContext(PageContext);

  const { resultList, currentStep, getStepNumberByGenerationId } =
    portfolioResultData;

  const [openedTab, setOpenedTab] = useState("");
  const [currentSteps, setCurrentSteps] = useState({});

  const {
    constraints,
    selectedPortfolio,
    setSelectedPortfolio,
    getCorrectPrecisionsValue,
  } = constraintsData;

  useEffect(() => {
    setOpenedTab(portfolioList[0]?.uuid);
    setSelectedPortfolio(portfolioList[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioList[0]?.uuid]);

  useEffect(() => {
    setCurrentSteps(data => ({
      ...data,
      [selectedPortfolio?.uuid]: resultList,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultList]);

  const getExpandMenu = useCallback(info => {
    const { isActive, panelKey } = info;
    return isActive ? (
      <StyledArrowUpwardIcon onClick={() => setOpenedTab(null)} />
    ) : (
      <StyledArrowDownwardIcon onClick={() => setOpenedTab(panelKey)} />
    );
  }, []);

  const handleChangeActivePortfolio = useCallback(
    value => {
      const portfolioId = value[0];
      if (!portfolioId) {
        handleMenuItemClick(null);
      } else {
        setOpenedTab(portfolioId);
      }
      const findPortfolio = portfolioList.find(
        ({ uuid }) => uuid === portfolioId,
      );
      if (findPortfolio) {
        setSelectedPortfolio(findPortfolio);
      }
    },
    [handleMenuItemClick, portfolioList, setSelectedPortfolio],
  );

  const items = useMemo(() => {
    return portfolioList.map(portfolio => {
      const { percentage, status: buildStatus } = getTitleDetailsByStatus({
        portfolioUUID: portfolio.uuid,
        isSideNav: true,
      });

      const header = (
        <StyledMenuHeader>
          <BuildProgress
            percent={percentage}
            buildStatus={buildStatus}
            isActive={selectedPortfolio?.uuid === portfolio.uuid}
          />
          <StyledMenuTitle>{portfolio.name}</StyledMenuTitle>
        </StyledMenuHeader>
      );

      const currentResults = currentSteps[portfolio.uuid] || [];
      const currentFeaturedSteps = getStepNumberByGenerationId(
        selectedGenerations.split(","),
      );

      const isSameValue = (thisInd, prevInd) => {
        if (prevInd < 0) return false;
        const valueThis = getCorrectPrecisionsValue(
          currentResults[thisInd].objective,
        );
        const valuePrev = getCorrectPrecisionsValue(
          currentResults[prevInd].objective,
        );
        return valuePrev === valueThis;
      };

      return {
        key: portfolio.uuid,
        label: header,
        children: (
          <StyledMenuList data-cy="steps-menu-list">
            {currentResults.map(({ gen, objective }, index) => (
              <SideAccordionMenuItem
                key={gen}
                step={gen}
                title={`Step ${gen}`}
                value={getCorrectPrecisionsValue(objective)}
                isSameValue={isSameValue(index, index - 1)}
                onClick={handleMenuItemClick}
                isActive={gen === currentStep}
                isFeatured={currentFeaturedSteps?.includes(gen)}
                icon={gen}
                isLast={resultList?.length === gen}
              />
            ))}
            {resultList.length < constraints?.number_of_generations?.min &&
              buildStatus === PROGRESS_STATES.inProgress && (
                <SideAccordionMenuItem
                  step={resultList.length + 1}
                  title={`Step ${resultList.length + 1}`}
                  value={<LoadingOutlined />}
                />
              )}
          </StyledMenuList>
        ),
      };
    });
  }, [
    constraints?.number_of_generations?.min,
    currentStep,
    currentSteps,
    getCorrectPrecisionsValue,
    getStepNumberByGenerationId,
    getTitleDetailsByStatus,
    handleMenuItemClick,
    portfolioList,
    resultList.length,
    selectedGenerations,
    selectedPortfolio?.uuid,
  ]);

  return (
    <StyledSideNavigationArea>
      <StyledNavigationWrapper data-cy="id-menu-list">
        <Collapse
          accordion
          items={items}
          bordered={false}
          expandIcon={getExpandMenu}
          expandIconPosition="end"
          onChange={handleChangeActivePortfolio}
          activeKey={openedTab}
          defaultActiveKey={portfolioList[0]?.uuid}
          destroyInactivePanel
        />
      </StyledNavigationWrapper>
    </StyledSideNavigationArea>
  );
};

SideNavigation.propTypes = {
  handleMenuItemClick: PropTypes.func,
  getTitleDetailsByStatus: PropTypes.func,
  portfolioList: PropTypes.arrayOf(PropTypes.shape()),
  selectedGenerations: PropTypes.string,
};

export default SideNavigation;
