import { Menu } from "antd";
import styled from "styled-components/macro";
import {
  blue,
  darkGrey4,
  gray5,
  darkGrey5,
  gray30,
  red80,
  gray80,
  gray40,
  gray60,
} from "../../../constants/colors";

export const StyledMenu = styled(Menu)`
  &:before {
    content: " ";
    position: absolute;
    height: 2px;
    width: 100%;
    top: -4px;
    background-color: ${blue};
  }
`;

export const StyledDropdown = styled("div")`
  background-color: ${darkGrey5};
  min-width: 234px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border-radius: 2px;
  box-sizing: border-box;
`;

export const StyledMenuItem = styled("div")`
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${gray30};
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
`;

export const StyledUserMenuItem = styled("div")`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 8px;
`;

export const StyledMenuName = styled("div")`
  color: ${gray40};
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
  margin-top: 8px;
`;

export const StyledMenuEmailItem = styled("div")`
  color: ${gray60};
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  line-height: 16px;
`;

export const StyledMenuItemLogout = styled(StyledMenuItem)`
  color: ${red80};
  border-top: solid 1px ${gray80};
  padding-top: 16px;
`;

export const StyledUserName = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 12px;
  color: ${gray40};
  font-size: 14px;
  position: relative;
  cursor: pointer;
  font-weight: 700;
  line-height: 20px;

  &:hover {
    color: ${({ isMobile }) => (isMobile ? gray5 : darkGrey4)};
  }
  margin: ${({ isMobile }) => (isMobile ? "1rem 0 1.5rem 1rem" : "0px")};
`;
