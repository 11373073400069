import PropTypes from "prop-types";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import { StyledResetWrapper } from "../styles";

const ResetFilter = ({ clearFilters, noInnerPadding = false }) => {
  return (
    <StyledResetWrapper noInnerPadding={noInnerPadding}>
      <StyledTextButton onClick={clearFilters}>Reset Filters</StyledTextButton>
      <div>Data is filtered, to show all records hit reset filters button.</div>
    </StyledResetWrapper>
  );
};

ResetFilter.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  noInnerPadding: PropTypes.bool,
};

export default ResetFilter;
