import styled from "styled-components/macro";
import { gray80, gray20, white } from "../../constants/colors";
import { maxScreensSizesPx } from "../styled";

export const StyledUserBlock = styled.div`
  padding: 24px;
  border: solid 1px ${gray20};
  margin-top: 16px;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  box-sizing: border-box;
  @media (max-width: ${maxScreensSizesPx.mobile}) {
    padding-top: 68px;
  }
`;

export const StyledTitle = styled.div`
  color: ${gray80};
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
`;

export const StyledFormTitle = styled.div`
  color: ${gray80};
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const StyledFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-input-disabled {
    background-color: transparent;
    color: ${gray80};
  }
  .ant-picker-disabled {
    background-color: transparent;
    color: ${gray80} !important;
    opacity: 1;
  }
  .ant-picker .ant-picker-input > input-disabled,
  .ant-picker .ant-picker-input > input[disabled] {
    color: ${gray80} !important;
  }

  .ant-picker {
    width: 100%;
  }
`;

export const StyledFormRow = styled.div`
  display: grid;
  grid-template-columns: 160px 400px;
  align-items: center;
`;

export const StyledSaveButtonContainer = styled.div`
  margin-bottom: 40px;
`;

export const StyledUploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
