import styled, { css } from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { maxScreensSizesPx } from "../styled";
import { black, blue, darkGrey2, gray3, gray70 } from "../../constants/colors";
import { StyledTrashOutlineIcon } from "../../components/Icons";

export const StyledWorkbookName = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const StyledWorkbookIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  cursor: pointer;
`;

export const StyledNavLink = styled(NavLink)`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${blue};
`;

export const StyledSubHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0 2rem 0.5rem;

  background-color: ${gray3};
  color: ${black};

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 0 1rem 0.5rem;
  }
`;

export const StyledMenuList = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
`;

export const StyledActionsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
  justify-content: flex-end;

  > button {
    margin: 1rem 0.25rem 0;
  }
`;

export const StyledMenuItem = styled.div`
  cursor: pointer;
  color: currentColor;
  background-color: transparent;
  border-bottom: 2px solid #e4e7ed;
  text-transform: uppercase;

  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  align-items: center;
  margin-right: 0.25rem;
  padding: 0 2rem 1rem 0;
  position: relative;

  transition: all 0.3s ease;

  ${props =>
    props.active &&
    css`
      color: ${blue};
      border-color: ${blue};
    `}

  &:hover {
    color: ${blue};
  }
`;

export const StyledWorkbookActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  column-gap: 24px;
`;

export const StyledTrashOutlineIconWrapper = styled(StyledTrashOutlineIcon)`
  color: ${gray70};
`;

export const StyledTableWrapper = styled.div`
  padding: 1rem 2rem;
  box-sizing: border-box;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 1rem;
  }

  ${props =>
    props.$noPadding &&
    css`
      padding: 0px;
    `}
`;

export const StyledTableFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "left center right";
  align-items: center;
`;

export const StyledTableInfo = styled.div`
  grid-area: center;

  font-style: normal;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.369231px;
  text-transform: uppercase;

  color: ${darkGrey2};
`;

export const StyledTablePagination = styled.div`
  grid-area: right;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`;

export const StyledDateFilterContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 18px;
`;

export const StyledTableFilterContainer = styled(StyledDateFilterContainer)`
  align-items: stretch;
`;
