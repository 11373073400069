import PropTypes from "prop-types";
import StatisticCard from "../../../../../components/StatisticCard";

const StepStatisticCard = ({
  cardConfig = {},
  resultList,
  step,
  showLastStepDiff,
  showPrevStepDiff,
  getTitle,
  getValue,
}) => {
  const { title, dataIndex, key, suffix, prefix, precision } = cardConfig;

  const currentStep = resultList?.find(result => result.step === step);
  const currStepObjective = currentStep?.objectives_details?.find(
    item => item?.name === key,
  );
  const curreStepValue = currentStep?.[key] || currStepObjective?.value;

  const prevStep = resultList?.find(result => result.step === step - 1);
  const lastStep = resultList?.find(
    result => result.step === resultList.length - 1,
  );

  const showDiff = resultList.some(
    item => item?.[dataIndex] !== currentStep?.[dataIndex],
  );

  const prevStepValue =
    showPrevStepDiff &&
    getValue(key, prevStep?.[dataIndex], precision, suffix, prefix);

  const lastStepValue =
    showLastStepDiff &&
    getValue(key, lastStep?.[dataIndex], precision, suffix, prefix);

  return (
    <StatisticCard
      label={getTitle(key, title)}
      lastStepValue={lastStepValue || ""}
      prevValue={prevStepValue || ""}
      showLastStepDiff={showLastStepDiff}
      showPrevStepDiff={showPrevStepDiff}
      showDiff={showDiff}
      dataCy={key}
      value={getValue(key, curreStepValue, precision, suffix, prefix)}
    />
  );
};

StepStatisticCard.propTypes = {
  cardConfig: PropTypes.shape({
    title: PropTypes.string,
    dataIndex: PropTypes.string,
    key: PropTypes.string,
    suffix: PropTypes.string,
    prefix: PropTypes.string,
    precision: PropTypes.number,
  }),
  resultList: PropTypes.arrayOf(PropTypes.shape()),
  step: PropTypes.number,
  showLastStepDiff: PropTypes.bool,
  showPrevStepDiff: PropTypes.bool,
  getTitle: PropTypes.func,
  getValue: PropTypes.func,
};

export default StepStatisticCard;
