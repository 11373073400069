import { useCallback, useContext } from "react";
import { Pagination, Skeleton } from "antd";
import { useNavigate } from "react-router";
import {
  StyledPageContent,
  StyledPageHeader,
  StyledPageHeaderTitle,
  StyledPageHeaderTitleContainer,
  tableScrollWidth,
} from "../../styled";
import { StyledLayout } from "../../../layout/styles";
import { PageContext } from "../context";
import StyledTable from "../../../components/Table";
import {
  StyledTableFooter,
  StyledTableInfo,
  StyledTablePagination,
  StyledTableWrapper,
} from "../styles";
import Breadcrumbs from "../../Shared/Breadcrumbs";
import { StyledHomeIconFilled } from "../../../components/Icons";
import SubHeaderMenu from "./SubHeaderMenu";

const WorkbooksContent = () => {
  const { workbookData } = useContext(PageContext);
  const navigate = useNavigate();
  const {
    isLoading,
    columns = [],
    workbooks = [],
    paginationData,
    onPageChange,
  } = workbookData;

  const config = [
    {
      icon: <StyledHomeIconFilled />,
    },
  ];

  const TableFooter = useCallback(
    () =>
      paginationData?.pages > 1 && (
        <StyledTableFooter>
          <StyledTableInfo>{`${workbooks?.length} out of ${paginationData?.total}`}</StyledTableInfo>
          <StyledTablePagination>
            <Pagination {...paginationData} onChange={onPageChange} />
          </StyledTablePagination>
        </StyledTableFooter>
      ),
    [onPageChange, paginationData, workbooks?.length],
  );

  return (
    <StyledLayout>
      <StyledPageHeader>
        <Breadcrumbs config={config} />
      </StyledPageHeader>
      <StyledPageHeaderTitleContainer>
        <StyledPageHeaderTitle>Workbooks</StyledPageHeaderTitle>
        <SubHeaderMenu />
      </StyledPageHeaderTitleContainer>
      <StyledTableWrapper $noPadding>
        {isLoading && (
          <StyledPageContent>
            <Skeleton active />
          </StyledPageContent>
        )}
        {!isLoading && (
          <StyledTable
            columns={columns}
            dataSource={workbooks}
            scroll={{ x: tableScrollWidth }}
            rowKey={({ uuid }) => uuid}
            pagination={false}
            footer={TableFooter}
            onRow={record => {
              const { uuid } = record;
              const urlLink = `/workbooks/${uuid}/results/`;
              return {
                onClick: () => {
                  navigate(urlLink);
                },
              };
            }}
          />
        )}
      </StyledTableWrapper>
    </StyledLayout>
  );
};

export default WorkbooksContent;
