import PropTypes from "prop-types";
import { CONSTRAINTS_INPUT_TYPE_VALUES } from "../../../constants/objectives";

export const ConstraintPropType = PropTypes.shape({
  isEnabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  help: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
});

export const InputPropType = PropTypes.oneOf([
  CONSTRAINTS_INPUT_TYPE_VALUES.RANGE,
  CONSTRAINTS_INPUT_TYPE_VALUES.LESS_OR_EQUAL,
  CONSTRAINTS_INPUT_TYPE_VALUES.GREAT_OR_EQUAL,
  CONSTRAINTS_INPUT_TYPE_VALUES.EQUAL,
  CONSTRAINTS_INPUT_TYPE_VALUES.IN,
  CONSTRAINTS_INPUT_TYPE_VALUES.BOOL,
]);
