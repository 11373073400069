import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import {
  StyledMenuItem,
  StyledSubHeader,
  StyledMenuList,
} from "../../layout/styles";

const TabsHeader = ({ menuItems = [], setActiveMenuItem }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <StyledSubHeader>
      <StyledMenuList>
        {menuItems.map(item => {
          const { key, path, label } = item;
          const isActive = currentPath === path;
          return (
            <StyledMenuItem
              data-cy={`menu-list-item-${key}`}
              key={key}
              onClick={() => {
                navigate(path);
                setActiveMenuItem(item);
              }}
              active={isActive}
            >
              {label}
            </StyledMenuItem>
          );
        })}
      </StyledMenuList>
    </StyledSubHeader>
  );
};

TabsHeader.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape()),
  setActiveMenuItem: PropTypes.func,
};

export default TabsHeader;
