/* eslint-disable react/jsx-props-no-spreading, no-unused-vars */
import PropTypes from "prop-types";
import { Button as AntButton } from "antd";
import { forwardRef } from "react";

const Button = forwardRef(({ ...rest }, ref) => (
  <AntButton ref={ref || null} {...rest} />
));

Button.displayName = "Button";

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary"]),
};

export default Button;
