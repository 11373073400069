import {
  formatDateUSA,
  formatPercentage,
  moneyFormatter,
  moneyIntFormatter,
  percentWith3FractionFormatter,
  twoDecimalFormatter,
} from "../utils/dataFormatters";

export const OBJECTIVES = {
  bid_ask_spread: {
    precision: 2,
    suffix: "",
    prefix: "$",
  },
  loan_maturity: {
    precision: 2,
    suffix: "",
    prefix: "",
  },
  warf: {
    precision: 0,
    suffix: "",
    prefix: "",
  },
};

export const MAX_COMPOSITE_OBJECTIVES_NUMBER = 5;

export const OBJECTIVE_ORDER = [
  { value: "min", label: "Minimize" },
  { value: "max", label: "Maximize" },
];

export const CONSTRAINTS_INPUT_TYPES = [
  { value: "r", label: "Range" },
  { value: "lte", label: "Less or Equal" },
  { value: "gte", label: "Greater or Equal" },
  { value: "e", label: "Equal" },
  { value: "b", label: "Boolean" },
];

export const CONSTRAINTS_INPUT_TYPE_VALUES = {
  RANGE: "r",
  LESS_OR_EQUAL: "lte",
  GREAT_OR_EQUAL: "gte",
  EQUAL: "e",
  IN: "in",
  BOOL: "b",
};

export const CONSTRAINTS_CATEGORIES = {
  GENERAL: "General",
  SETTINGS: "Settings",
  CONSTRAINTS: "Constraints",
  OBJECTIVE_FUNCTIONS: "Objective Functions",
  SELL_REPLACE: "Sell Replace",
  COMPLIANCE_TESTS: "Compliance Tests",
};

export const CONSTRAINTS_CATEGORIES_ORDER = [
  CONSTRAINTS_CATEGORIES.GENERAL,
  CONSTRAINTS_CATEGORIES.SETTINGS,
  CONSTRAINTS_CATEGORIES.CONSTRAINTS,
  CONSTRAINTS_CATEGORIES.OBJECTIVE_FUNCTIONS,
  CONSTRAINTS_CATEGORIES.SELL_REPLACE,
  CONSTRAINTS_CATEGORIES.COMPLIANCE_TESTS,
];

export const DIST_COLUMN_DATA = {
  warf: "WARF",
  years_tm: "Maturity",
};

export const COLUMN_FORMATTING = {
  "Amount Outstanding": moneyIntFormatter,
  "Buy Sell": moneyIntFormatter,
  Buy: moneyIntFormatter,
  Sell: moneyIntFormatter,
  "Amt Outstanding": moneyIntFormatter,
  "Tot Coup": percentWith3FractionFormatter,
  "Notional To Buy": moneyIntFormatter,
  "Previous Notional To Buy": moneyIntFormatter,
  "Current Holdings": moneyIntFormatter,
  Quantity: moneyIntFormatter,
  Ask: moneyFormatter,
  "Px Mid": moneyFormatter,
  "Call Price": moneyFormatter,
  Bid: moneyFormatter,
  Offer: moneyFormatter,
  "Evaluated Price": moneyFormatter,
  "Close Bid": moneyFormatter,
  "Close Offer": moneyFormatter,
  "Mty Years Tdy": twoDecimalFormatter,
  "Years Tm": twoDecimalFormatter,
  "Maturity Date": formatDateUSA,
  "Funding Date": formatDateUSA,
  "Call Date": formatDateUSA,
  "Spnr List": formatDateUSA,
  "Mark Date": formatDateUSA,
  "Portfolio Weights": formatPercentage,
};
