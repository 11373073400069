import PropTypes from "prop-types";

const SelectPropTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      abbreviation: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isMulti: PropTypes.bool,
  className: PropTypes.string,
};

export default SelectPropTypes;
