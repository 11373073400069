import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: white;
    margin: 0;
  }
`;

export default GlobalStyle;
