import { Button } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import { StyledTableFilterContainer } from "../styles";
import Checkbox from "../../../Form/Checkbox";
import { choiceType } from "../../../../propTypes/menuType";

const MultipleChoiceFilter = ({
  choices,
  defaultCheckedValues = [],
  onApply,
  onReset,
  onChange,
  close,
}) => {
  const [checkedValues, setCheckedValues] = useState(defaultCheckedValues);

  const handleSubmit = event => {
    event.preventDefault();
    onApply(event, checkedValues);
    close();
  };

  const handleReset = () => {
    onReset();
    setCheckedValues([]);
    close();
  };

  return (
    <StyledTableFilterContainer>
      {choices.map(item => (
        <div key={item.value}>
          <Checkbox
            value={checkedValues?.includes(item.value)}
            checked={checkedValues?.includes(item.value)}
            title={item.label}
            onChange={event => {
              let newCheckedItems = checkedValues?.length
                ? [...checkedValues]
                : [];
              if (event?.target?.checked) {
                newCheckedItems = [...newCheckedItems, item.value];
              } else {
                newCheckedItems = newCheckedItems.filter(
                  status => status !== item.value,
                );
              }

              if (newCheckedItems.length === 1 && newCheckedItems[0] === "") {
                newCheckedItems = [];
              }

              setCheckedValues(newCheckedItems);
              onChange?.(newCheckedItems);
            }}
          />
        </div>
      ))}
      <Button onClick={handleReset}>Reset</Button>
      <Button htmlType="submit" onClick={handleSubmit}>
        Apply
      </Button>
    </StyledTableFilterContainer>
  );
};

MultipleChoiceFilter.propTypes = {
  onApply: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  close: PropTypes.func,
  choices: PropTypes.arrayOf(choiceType).isRequired,
  defaultCheckedValues: PropTypes.arrayOf(PropTypes.string),
};

export default MultipleChoiceFilter;
