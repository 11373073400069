import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { handleErrorMessages } from "../../../utils/messages";
import useErrorStatus from "../../../hooks/useErrorStatus";
import useDataId from "./useDataId";
import { getWorkbook } from "../../../api/endpoints/workbooks";
import { getAllConstants } from "../../../api/endpoints/constants";
import useQuery from "../../../hooks/useQuery";
import { PageContext as ResultListContext } from "../../../features/ResultList/context";

export const Context = createContext({});

export const Provider = ({ children }) => {
  const { workbook_id: workbookId, result_id: resultId } = useParams();
  const { query } = useQuery();

  const resultListContext = useContext(ResultListContext);

  const [selectedGenerations, setSelectedGenerations] = useState(
    query.get("generations") || "",
  );
  const [currentWorkbook, setCurrentWorkbook] = useState(null);
  const [objectives, setObjectives] = useState({});

  useEffect(() => {
    const generations = query.get("generations") || "";
    setSelectedGenerations(generations);
  }, [query]);

  const { isNotFound, isForbidden, setErrorStatus, checkIsErrorStatus } =
    useErrorStatus();

  const handleErrorRequests = useCallback(
    error => {
      const { status } = error.response;
      setErrorStatus(status);
      const isErrorStatus = checkIsErrorStatus(status);

      if (!isErrorStatus) {
        handleErrorMessages(error);
      }
    },
    [checkIsErrorStatus, setErrorStatus],
  );

  const handleGetWorkbook = useCallback(() => {
    getWorkbook(workbookId)
      .then(workbook => setCurrentWorkbook(workbook))
      .catch(handleErrorRequests);
  }, [workbookId, handleErrorRequests]);

  useEffect(() => {
    if (!workbookId) return;
    handleGetWorkbook();
    getAllConstants()
      .then(response => setObjectives(response.objective))
      .catch(handleErrorMessages);
    // eslint-disable-next-line
  }, [workbookId, handleErrorRequests]);

  const { resultUUID, portfolioList, currentPortfolio, refreshPortfolioData } =
    useDataId({
      workbookId,
      resultId,
      handleErrorRequests,
      workbookResults: resultListContext?.workbookResults,
    });

  const [currentGenerations, setCurrentGenerations] = useState([]);
  const [allConstraintsValue, setAllConstraintsValue] = useState(null);
  const [selectedGenerationUUID, setSelectedGenerationUUID] = useState(null);

  const contextData = useMemo(
    () => ({
      objectives,
      workbookId,
      currentWorkbook,
      currentPortfolio,
      resultUUID: resultId || resultUUID,
      portfolioList,
      currentGenerations,
      setCurrentGenerations,
      getResultData: refreshPortfolioData,
      isNotFound,
      isForbidden,
      setAllConstraintsValue,
      allConstraintsValue,
      setSelectedGenerationUUID,
      handleErrorRequests,
      setObjectives,
      selectedGenerationUUID,
      selectedGenerations,
      handleGetWorkbook,
    }),
    [
      objectives,
      workbookId,
      currentWorkbook,
      currentPortfolio,
      resultUUID,
      resultId,
      portfolioList,
      currentGenerations,
      refreshPortfolioData,
      handleErrorRequests,
      setObjectives,
      isNotFound,
      isForbidden,
      allConstraintsValue,
      selectedGenerationUUID,
      selectedGenerations,
      handleGetWorkbook,
    ],
  );

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.defaultProps = {};
Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
