import ReactSelect, { components } from "react-select";
import styled, { css } from "styled-components/macro";
import { Radio, Select } from "antd";
import {
  blue,
  blueWhite,
  darkGrey2,
  darkGrey3,
  darkGrey5,
  gray4,
  gray60,
  gray80,
  white,
} from "../../../constants/colors";
import {
  inputBorderRadius,
  inputOuterHeight,
} from "../../../constants/style_parameters";

export const classPrefix = "react-select";

export const StyledAntSelect = styled(Select)`
  min-width: 200px;
`;

export const StyledAllOption = styled.div`
  padding: 5px 12px;
`;

export const StyledRadioOption = styled(Radio)`
  .ant-radio-inner {
    background-color: ${darkGrey3};
    border-color: ${gray4};
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: ${darkGrey3};
    border: 5px solid ${darkGrey3};

    &:after {
      background-color: transparent;
    }
  }
`;

export const StyledReactSelect = styled(ReactSelect)`
  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid red;
    `}
`;

export const StyledControl = styled(components.Control)`
  && {
    min-height: ${inputOuterHeight};
    box-shadow: none;
    background-color: ${white};
    border-color: ${gray4};
    border-radius: ${inputBorderRadius};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    align-items: center;
    font-family: "Inter", sans-serif;
  }

  &&:hover,
  &.react-select__control--menu-is-open {
    border-color: ${blue};
    cursor: text;
  }

  &.react-select__control--is-disabled {
    background-color: transparent;
  }
  & .react-select__single-value--is-disabled {
    color: ${darkGrey2};
  }

  & .react-select__value-container {
    > div:nth-child(2) {
      margin: 0;
    }
  }
`;

export const StyledPlaceholder = styled(components.Placeholder)`
  && {
    font-family: Inter, serif;
    font-weight: 600;
    color: ${gray60};
  }
`;

export const StyledValueContainer = styled(components.ValueContainer)`
  && {
    min-height: var(--input-inner-height);
    color: ${darkGrey5};
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__input-container,
  .react-select__single-value {
    font-family: Inter, serif;
    font-weight: 600;
    color: ${gray80};
  }
`;

export const StyledIndicatorsContainer = styled(components.IndicatorsContainer)`
  && {
    min-height: var(--input-inner-height);
    padding: 0 0 0 8px;
    align-items: center;

    cursor: pointer;

    & > div[class$="indicatorContainer"] {
      color: ${darkGrey2};
      font-weight: normal;
      padding: 4px 8px;

      &:hover {
        color: ${darkGrey3};
      }
    }

    .react-select__clear-indicator,
    .react-select__indicator-separator {
      display: none;
    }
  }
`;

export const StyledMenu = styled(components.Menu)`
  && {
    border-radius: ${inputBorderRadius};
  }
  .react-select__menu-notice {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
  }
`;

export const StyledOption = styled(components.Option)`
  && {
    display: flex;
    align-items: center;
    min-height: ${inputOuterHeight};
    padding: 0.25rem 0.5rem;
    cursor: pointer;

    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    color: ${darkGrey5};
  }

  &.react-select__option--is-focused {
    :active {
      background-color: ${gray4};
    }

    background-color: ${gray4};
  }

  &.react-select__option--is-selected {
    :active {
      background-color: ${blueWhite};
    }

    background-color: ${blueWhite};
    font-weight: 500;
  }

  &&.react-select__option--is-disabled {
    color: ${darkGrey5};
    cursor: default;
  }
`;
