import { Checkbox } from "antd";
import styled, { css } from "styled-components/macro";
import { blue, darkGrey2, gray8 } from "../../../constants/colors";

export const StyledInputLabel = styled.div`
  font-size: ${({ big }) => (big ? "14px;" : "12px;")}
  font-weight: 500;
  color: ${({ textBlue }) => (textBlue ? blue : darkGrey2)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 17px;

  ${({ bold }) => bold && "font-weight: 600;"}

  ${({ uppercase }) => uppercase && "text-transform: uppercase;"}

  ${({ italic }) => italic && "font-style: italic;"}

  ${({ color }) => color && `color: ${color};`}
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  flex-flow: row nowrap;
  margin: 0px;

  .ant-checkbox:hover,
  .ant-checkbox:focus {
    .ant-checkbox-inner {
      border: 1px solid ${gray8};
      ${props =>
        props.$color &&
        css`
          border: 1px solid ${props.$color};
        `}
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      border-color: ${gray8};
      background-color: ${gray8};
      ${props =>
        props.$color &&
        css`
          background-color: ${props.$color};
          border-color: ${props.$color};
        `}

      &:after {
        height: 2px;
        background-color: white;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${gray8};
    background-color: ${gray8};

    ${props =>
      props.$color &&
      css`
        background-color: ${props.$color};
        border-color: ${props.$color};
      `}
  }

  .ant-checkbox-checked::after {
    border-color: ${gray8};
    ${props =>
      props.$color &&
      css`
        border-color: ${props.$color};
      `}
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${gray8};
    ${props =>
      props.$color &&
      css`
        border-color: ${props.$color};
      `}
  }

  input:focus {
    outline-color: unset;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      .ant-checkbox-disabled + span,
      .ant-checkbox-disabled,
      .ant-checkbox-disabled .ant-checkbox-input {
        cursor: default;
      }
      && {
        cursor: default;

        .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${gray8};
          border-color: ${gray8} !important;

          &::after {
            border-color: white;
          }
        }
      }
      .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: white;
      }
    `}
`;
