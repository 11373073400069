import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import Loading from "./components/Loading";
import showMessageError from "./constants/errors";

/**
 * Handle OKTA callback after user filled the OKTA's auth form
 * @returns {*}
 * @constructor
 */
const HandleLogIn = () => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const { isAuthenticated, error } = authState || {};
  const isOktaAuthFlow = process.env.REACT_APP_AUTH_FLOW === "okta";

  useEffect(() => {
    if (isOktaAuthFlow) {
      (async () => {
        await oktaAuth.handleRedirect();
      })();
    }
  }, [oktaAuth, isOktaAuthFlow]);

  useEffect(() => {
    if (isAuthenticated !== null && error) {
      const text = error.message
        ? `${error.message} Please try again or contact support.`
        : "There is an issue with your login. Please try again or contact support.";
      showMessageError({
        text,
      });
      navigate("/", { replace: true });
    }
  }, [navigate, isAuthenticated, error]);

  return <Loading />;
};

export default HandleLogIn;
