import filterTypes from "../../constants/filterTypes";

export const getDefaultValue = filterValue => {
  return typeof filterValue === "string"
    ? filterValue?.split(",")
    : filterValue;
};

export const getFilterType = ({ type, format, choices }) => {
  if (format === "date-time") {
    return filterTypes.dateRange;
  }

  if (choices) {
    return filterTypes.multipleChoice;
  }

  if (type === "boolean") {
    return filterTypes.boolean;
  }

  return filterTypes.text;
};

export const getChoices = choices => {
  return (
    choices?.map(choice => {
      if (typeof choice === "string") {
        return {
          value: choice,
          label: choice.split("_").join(" "),
        };
      }
      return choice;
    }) ?? [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ]
  );
};

export const getSortSignDirection = sortDirection => {
  switch (sortDirection) {
    case "descend":
      return "-";
    case "ascend":
      return "";
    default:
      return null;
  }
};
