import { useCallback } from "react";
import PropTypes from "prop-types";
import NumberInput from "./NumberInput";
import { StyledRangeInput } from "./styles";

const NumberInputRange = ({
  name,
  title = "",
  type = "number",
  min = null,
  max = null,
  disabled = false,
  onChange,
  checkIsError,
  ...props
}) => {
  const handleChange = useCallback(
    (fieldName, attributeName, value) => {
      onChange(fieldName, attributeName, value);
    },
    [onChange],
  );

  return (
    <StyledRangeInput>
      <NumberInput
        type={type}
        title={title}
        disabled={disabled}
        defaultValue={min}
        name={name}
        attributeName="min"
        onChange={handleChange}
        showError={checkIsError("min")}
        {...props}
      />
      <span>-</span>
      <NumberInput
        type={type}
        title={title}
        disabled={disabled}
        defaultValue={max}
        name={name}
        attributeName="max"
        showError={checkIsError("max")}
        onChange={handleChange}
        {...props}
      />
    </StyledRangeInput>
  );
};

NumberInputRange.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checkIsError: PropTypes.func,
};

export default NumberInputRange;
