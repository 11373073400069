import { StyledLayout } from "../Layout";
import HeaderContainer from "../../layout/AppLayout/content/header";
import Footer from "../Footer";
import TermsAndConditions from "./TermsAndConditions";
import { StyledContentCentered } from "../Layout/Content";
import { PageContextProvider } from "../../layout/AppLayout/context";

const TermsAndConditionsContainer = () => {
  return (
    <PageContextProvider>
      <StyledLayout>
        <HeaderContainer />
        <StyledContentCentered>
          <TermsAndConditions />
        </StyledContentCentered>
        <Footer />
      </StyledLayout>
    </PageContextProvider>
  );
};

export default TermsAndConditionsContainer;
