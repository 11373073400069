/* eslint-disable import/prefer-default-export */

import {
  chartViolet,
  chartOrange,
  chartGreen,
  chartYellow,
  chartBlue,
  chartDarkBlue,
  chartGrey,
  chartPink,
  chartBrown,
  chartAccentGreen,
} from "../../../../constants/colors";

const insertColor = (arr, index, color) => {
  return [...arr.slice(0, index), color, ...arr.slice(index)];
};

export const getChartColors = chartKeys => {
  let chartColors = [
    chartBrown,
    chartDarkBlue,
    chartPink,
    chartGrey,
    chartAccentGreen,
  ];

  chartKeys?.forEach((key, index) => {
    if (key === "objective") {
      chartColors = insertColor(chartColors, index, chartGreen);
    }

    if (key === "warf") {
      chartColors = insertColor(chartColors, index, chartViolet);
    }

    if (key === "loan_maturity") {
      chartColors = insertColor(chartColors, index, chartOrange);
    }

    if (key === "bid_ask_spread") {
      chartColors = insertColor(chartColors, index, chartYellow);
    }

    if (key === "was") {
      chartColors = insertColor(chartColors, index, chartBlue);
    }
  });

  if (chartKeys?.length === 1) {
    chartColors = [chartOrange];
  }

  return chartColors;
};
