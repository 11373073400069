import PropTypes from "prop-types";
import ModalDialog from "../../../components/Modals/ModalDialog";
import StyledButton from "../../../components/Buttons/Button.styled";
import {
  StyledModalPrimaryText,
  StyledModalBoldText,
  StyledModalButtonsContainer,
} from "../../../components/Modals/ModalDialog.styled";

const DeleteModal = ({
  isOpen = false,
  name = "",
  onClose,
  onDelete,
  title = "Remove Workbook",
  subtitle = "You are trying to delete a Workbook. Are you sure you want to remove Workbook",
}) => {
  return (
    <ModalDialog isOpen={isOpen} onClose={onClose} title={title}>
      <div>
        <StyledModalPrimaryText>
          {subtitle}
          {name && (
            <StyledModalBoldText> &quot;{name}&quot;</StyledModalBoldText>
          )}{" "}
          ?
        </StyledModalPrimaryText>
      </div>
      <StyledModalButtonsContainer>
        <StyledButton onClick={onClose} type="secondary" $autoWidth>
          Cancel
        </StyledButton>
        <StyledButton
          data-cy="delete-workbook-button"
          key="submit"
          type="primary"
          onClick={onDelete}
          $autoWidth
        >
          Remove
        </StyledButton>
      </StyledModalButtonsContainer>
    </ModalDialog>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default DeleteModal;
