import { isNull, filter, head, minBy, maxBy, orderBy } from "lodash";
import { objectivesOptions } from "./constants";
import { PROGRESS_STATES } from "../../../constants/constraints";

export const toNumber = (num, objectiveFunc) => {
  if (Number(num) > 10 || objectiveFunc === "weightedWarf") {
    return +Number(num).toFixed(0);
  }
  return +Number(num).toFixed(4);
};

export const getNewItemWithFilteredSolutions = ({
  item,
  isMinimised,
  filterKey,
}) => {
  const { x: xaxisValue, y: yaxisValue, itemSolutions } = item;

  const filteredByObject = isMinimised
    ? minBy(itemSolutions, solution => solution?.[filterKey])
    : maxBy(itemSolutions, solution => solution?.[filterKey]);

  const newItemSolutions = itemSolutions.filter(
    solution => solution?.[filterKey] === filteredByObject[filterKey],
  );
  const newItemData = {
    x: xaxisValue,
    y: yaxisValue,
    itemSolutions: newItemSolutions,
  };

  return newItemData;
};

export const prepareData = ({ source, selectedObjectiveFunc, isMinBest }) => {
  let result = [];
  const isObjectiveSelected = selectedObjectiveFunc === "objective";
  const isMinimised = isMinBest[selectedObjectiveFunc];

  source.forEach(item => {
    const {
      transactions,
      averageCurrentHoldings,
      averageSellAmount,
      portfolioGenerations,
      weightedYearsTm,
      weightedWarf,
      weightedBidAskSpread,
      weightedSpreadBps,
      weightedSpread,
      objective,
    } = item;

    const selectedValue = item[selectedObjectiveFunc];

    const bubbleXaxisValue = transactions;
    const bubbleYaxisValue = toNumber(selectedValue, selectedObjectiveFunc);
    const bubbleSolutions = [
      {
        portfolioGenerations,
        averageCurrentHoldings,
        averageSellAmount,
        weightedYearsTm,
        weightedWarf,
        weightedBidAskSpread,
        weightedSpreadBps,
        weightedSpread,
        objective,
      },
    ];

    const existResultIndex = result.findIndex(resultItem => {
      const { x, y } = resultItem;
      return x === bubbleXaxisValue && y === bubbleYaxisValue;
    });

    const chartDataItem = {
      x: bubbleXaxisValue,
      y: bubbleYaxisValue,
      itemSolutions: bubbleSolutions,
    };

    if (existResultIndex === -1) {
      // add new bubble
      result.push(chartDataItem);
    } else {
      // add new solution to existing bubble data
      const existingResultItem = result[existResultIndex];
      const { itemSolutions } = existingResultItem;
      itemSolutions?.push(bubbleSolutions[0]);
    }
  });

  if (isObjectiveSelected) {
    const newResult = result.reduce((acc, item) => {
      const { x: xaxisValue, y: yaxisValue } = item;
      const newItemData = getNewItemWithFilteredSolutions({
        item,
        isMinimised,
        filterKey: selectedObjectiveFunc,
      });

      if (acc?.[xaxisValue]) {
        let newItem;
        const { y: savedYaxisValue } = acc[xaxisValue];

        if (isMinimised) {
          newItem =
            savedYaxisValue > yaxisValue ? newItemData : acc[xaxisValue];
        } else {
          newItem =
            savedYaxisValue < yaxisValue ? newItemData : acc[xaxisValue];
        }
        return { ...acc, [xaxisValue]: newItem };
      }

      return { ...acc, [xaxisValue]: newItemData };
    }, {});

    result = Object.values(newResult);
  }
  const filteredResult = orderBy(result, ["x"], ["asc"]);

  return filteredResult;
};

export const getFilteredOFDropdownOptions = tradeSummaryInfo => {
  const data = tradeSummaryInfo?.solutions?.values;
  let keysToRemove = [];

  if (data?.length) {
    const dataWithNullValues = filter(data, item => {
      const hasNullValue = Object.values(item).filter(value => isNull(value));
      return hasNullValue ? item : false;
    });

    const firstItem = head(dataWithNullValues);
    let keys = firstItem ? Object.entries(firstItem) : [];

    keys = keys.map(([key, value]) => {
      if (isNull(value)) {
        return key;
      }
      return false;
    });

    keysToRemove = filter(keys, key => key);
  }
  const filteredObjectivesOptions = filter(
    objectivesOptions,
    option => !keysToRemove.includes(option.value),
  );
  return filteredObjectivesOptions;
};

export const removeSkippedPortfolios = portfolios => {
  return Object.entries(portfolios).reduce((acc, [key, value]) => {
    if (value.status === PROGRESS_STATES.skipped) {
      return acc;
    }
    // eslint-disable-next-line no-param-reassign
    acc[key] = value;
    return acc;
  }, {});
};
