import axiosInstance from "../axiosInstance";

export const getUsers = async ({ page }) => {
  const params = { page };
  const response = await axiosInstance({
    url: "users/",
    params,
    method: "GET",
  });
  return response?.data;
};

export const getCurrentUser = async data => {
  const response = await axiosInstance({
    url: "users/me/",
    data,
  });
  return response?.data;
};

export const login = async data => {
  const response = await axiosInstance({
    url: "users/login/",
    data,
    method: "POST",
  });
  return response?.data;
};

export const testSentry = async uuid => {
  const response = await axiosInstance({
    url: `users/sentry-test/${uuid}/`,
  });
  return response?.data;
};

export const changePassword = async data => {
  const response = await axiosInstance({
    url: "users/change-password/",
    data,
    method: "POST",
  });
  return response?.data;
};

export const updateProfile = async data => {
  const response = await axiosInstance({
    url: "users/profile-update/",
    data,
    method: "PUT",
  });
  return response?.data;
};

export const deleteProfilePicture = async data => {
  const response = await axiosInstance({
    url: "users/profile-picture/",
    data,
    method: "DELETE",
  });
  return response?.data;
};

const endpoints = {
  getCurrentUser,
  login,
  testSentry,
  changePassword,
};

export default endpoints;
