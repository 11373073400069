import PropTypes from "prop-types";
import { Fragment } from "react";
import { orderBy, capitalize } from "lodash";
import { Table } from "antd";
import StyledButton from "../../../../components/Buttons/Button.styled";
import ModalDialog from "../../../../components/Modals/ModalDialog";
import {
  StyledModalShadowBtnContainer,
  StyledModalScrollContainer,
  StyledModalSubtitle,
} from "../../../../components/Modals/ModalDialog.styled";
import useScrollTop from "../../../../hooks/useScrollTop";
import ObjectiveSelect from "../../../Constraints/content/ObjectiveSelect";
import {
  StyledConstraintsCardTitle,
  StyledExpandContainer,
} from "../../../Constraints/styles";
import {
  allConstraintColumns,
  formatKey,
} from "../ResultContent/OverviewDetails/tableConfig";
import {
  StyledTableCellContent,
  StyledTableCellDivider,
  StyledTableCellRow,
  StyledTableCellTitle,
} from "../ResultContent/styles";
import { valueTransformer } from "../../../../utils/parseValue";
import { transfromFloatingNumbers } from "../../../../utils/dataFormatters";

const ConstraintsValuesModal = ({
  allConstraintsValueArr,
  isOpen,
  onClose,
  selectedGenerationUUID,
  portfolioObjectives,
  constraintsData,
}) => {
  const [scrollTop, scrollProps] = useScrollTop();
  const sortedConstraintsValues = orderBy(
    allConstraintsValueArr,
    ["isEnabled", "isValid"],
    ["desc", "asc"],
  );

  return (
    <ModalDialog
      title="Constraints Values"
      isOpen={isOpen}
      onClose={onClose}
      width="90vw"
      dataCy="constraints-values-modal"
      scrollTop={scrollTop}
      noContentPadding
      noHeaderPadding={false}
      fullScreen
    >
      <StyledModalScrollContainer {...scrollProps}>
        <StyledModalSubtitle>
          <StyledConstraintsCardTitle $nomargin>
            Generation:
          </StyledConstraintsCardTitle>
          <span>{selectedGenerationUUID}</span>
        </StyledModalSubtitle>
        <StyledConstraintsCardTitle>
          PORTFOLIO OBJECTIVES
        </StyledConstraintsCardTitle>
        <ObjectiveSelect
          defaultObjective={portfolioObjectives}
          constraintsData={constraintsData}
          disabled
          hideSelect
        />
        <Table
          columns={allConstraintColumns}
          dataSource={sortedConstraintsValues}
          pagination={false}
          expandable={{
            // eslint-disable-next-line react/no-unstable-nested-components
            expandedRowRender: record => {
              const { isValid, isEnabled, type } = record;
              const showTable = record?.value?.invalid_rows?.every(
                item => typeof item === "object",
              );

              const getColumns = () => {
                const keys = record?.value?.invalid_rows?.[0]
                  ? Object.keys(record?.value?.invalid_rows?.[0])
                  : [];

                const newColumns = keys.map(key => {
                  const title = capitalize(key.split("_").join(" "));

                  return {
                    title,
                    dataIndex: key,
                    key,
                    render: value => {
                      return typeof value === "number"
                        ? transfromFloatingNumbers(value)
                        : value;
                    },
                  };
                });

                return newColumns;
              };
              if (showTable) {
                return (
                  <>
                    <StyledConstraintsCardTitle>
                      Invalid rows
                    </StyledConstraintsCardTitle>
                    <Table
                      columns={getColumns()}
                      dataSource={record?.value?.invalid_rows}
                      pagination={false}
                    />
                  </>
                );
              }
              return (
                <>
                  <StyledConstraintsCardTitle>
                    Invalid rows
                  </StyledConstraintsCardTitle>
                  <StyledExpandContainer>
                    {record?.value?.invalid_rows?.map((item, index) => {
                      if (item && typeof item === "object") {
                        return (
                          // eslint-disable-next-line
                          <Fragment key={index}>
                            {Object.entries(item).map(
                              ([itemKey, itemValue]) => (
                                <Fragment key={itemKey}>
                                  <StyledTableCellContent
                                    $valid={isValid}
                                    $isEnabled={isEnabled}
                                  >
                                    <StyledTableCellRow>
                                      <StyledTableCellTitle>
                                        {formatKey(itemKey)}:{" "}
                                      </StyledTableCellTitle>
                                      <span>
                                        {valueTransformer[type](
                                          itemValue,
                                          true,
                                        )}
                                      </span>
                                    </StyledTableCellRow>
                                  </StyledTableCellContent>
                                </Fragment>
                              ),
                            )}
                            {index !== record.value.invalid_rows.length - 1 && (
                              <StyledTableCellDivider />
                            )}
                          </Fragment>
                        );
                      }
                      return (
                        <div key={item}>
                          {valueTransformer[type](item, true)}
                        </div>
                      );
                    })}
                  </StyledExpandContainer>
                </>
              );
            },
            rowExpandable: record => {
              const value = record?.value || {};
              return value?.invalid_rows?.length;
            },
          }}
          scroll={{ x: 400 }}
          rowKey="title"
        />
      </StyledModalScrollContainer>
      <StyledModalShadowBtnContainer>
        <StyledButton onClick={onClose} type="secondary" $autoWidth>
          Close
        </StyledButton>
      </StyledModalShadowBtnContainer>
    </ModalDialog>
  );
};

ConstraintsValuesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedGenerationUUID: PropTypes.string,
  allConstraintsValueArr: PropTypes.arrayOf(PropTypes.shape()),
  portfolioObjectives: PropTypes.arrayOf(PropTypes.shape()),
  constraintsData: PropTypes.shape(),
};

export default ConstraintsValuesModal;
