import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { StyledConstraintsSideArea } from "../styles";
import SideMenuItem from "../../ResultDetails/content/ResultContent/SideMenuItem";
import {
  StyledBriefcaseIcon,
  StyledLockFilledIcon,
} from "../../../components/Icons";
import { gray40 } from "../../../constants/colors";
import ConstraintsContext from "./context";

const ConstraintsSideArea = ({
  portfolioList,
  selectedPortfolio,
  setSelectedPortfolio,
  setShowSkeleton,
  showSideMenu = false,
}) => {
  // defining context for constraints to show enabled/disabled state on sidebar
  const { constraintsContext, setConstraintsContext } =
    useContext(ConstraintsContext);

  // set initial portfolio and constraints enabled map
  useEffect(() => {
    if (portfolioList?.length) {
      setSelectedPortfolio(portfolioList?.[0]);

      const portfolioEnabledMap = portfolioList.reduce((acc, portfolio) => {
        return { ...acc, [portfolio.uuid]: { isEnabled: portfolio.isEnabled } };
      }, {});
      setConstraintsContext({
        constraints: portfolioEnabledMap,
        currentPortfolioUUID: portfolioList?.[0]?.uuid,
      });
    }
  }, [portfolioList]);

  const renderSideMenuItem = portfolio => {
    const isActive = selectedPortfolio?.uuid === portfolio.uuid;
    const isEnabled =
      constraintsContext?.constraints?.[portfolio.uuid]?.isEnabled;
    let icon = <StyledBriefcaseIcon />;
    const iconProps = {
      $size: "18px",
    };
    if (!isEnabled) {
      if (!isActive) {
        iconProps.$color = gray40;
      }
      icon = <StyledLockFilledIcon $size="18px" {...iconProps} />;
    }

    return (
      <SideMenuItem
        key={portfolio.uuid}
        title={portfolio.name}
        onClick={() => {
          setSelectedPortfolio(portfolio);
          setShowSkeleton(true);
        }}
        isActive={isActive}
        icon={icon}
        showIcon
        left
        dataCy="mp-menu-item"
      >
        {constraintsContext.constraints?.[portfolio.uuid]}
      </SideMenuItem>
    );
  };

  return (
    showSideMenu &&
    portfolioList?.length && (
      <StyledConstraintsSideArea data-cy="mp-menu">
        {portfolioList?.map(renderSideMenuItem)}
      </StyledConstraintsSideArea>
    )
  );
};

ConstraintsSideArea.propTypes = {
  portfolioList: PropTypes.arrayOf(PropTypes.shape()),
  selectedPortfolio: PropTypes.shape(),
  setSelectedPortfolio: PropTypes.func,
  setShowSkeleton: PropTypes.func,
  showSideMenu: PropTypes.bool,
};

export default ConstraintsSideArea;
