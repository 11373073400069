import axios from "axios";
import addAuthorizationHeader from "./interceptors/addAuthorizationHeader";
import errorsHandling from "./interceptors/errorsHandling";

const axiosInstance = axios.create({
  baseURL: "/api/v1",
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

let authInterceptorId = null;
let errorInterceptorId = null;

export const configureAxiosInstance = (accessTokenProvider = () => null) => {
  if (authInterceptorId !== null) {
    axiosInstance.interceptors.request.eject(authInterceptorId);
  }
  authInterceptorId =
    addAuthorizationHeader(accessTokenProvider)(axiosInstance);

  if (errorInterceptorId === null) {
    errorInterceptorId = errorsHandling()(axiosInstance);
  }
};
export default axiosInstance;
