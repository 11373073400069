import { useCallback, useContext } from "react";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import ToggleInput from "../../../components/Form/Toggle";
import {
  StyledBriefcaseIcon,
  StyledLockFilledIcon,
} from "../../../components/Icons";
import { StyledPageSectionTitle } from "../../styled";
import ConstraintsContext from "./context";
import { ConstraintPropType } from "./types";
import { StyledPortfolioEnabledStateSkeleton } from "../styles";

const ConstraintsTitle = ({
  onSwitchItem,
  children,
  isEnabledSetting,
  isDisabled,
  loading,
}) => {
  const { constraintsContext, setConstraintsContext } =
    useContext(ConstraintsContext);

  const handleTogglePortfolioEnabledState = useCallback(() => {
    const newState = !isEnabledSetting.isEnabled;
    onSwitchItem({
      key: "is_enabled",
      switchArg: newState,
      min: isEnabledSetting.min,
      max: isEnabledSetting.max,
    });
    if (!constraintsContext?.currentPortfolioUUID) {
      return;
    }
    setConstraintsContext(() => ({
      ...constraintsContext,
      constraints: {
        ...constraintsContext.constraints,
        [constraintsContext?.currentPortfolioUUID]: {
          ...constraintsContext?.constraints[
            constraintsContext?.currentPortfolioUUID
          ],
          isEnabled: newState,
        },
      },
    }));
  }, [
    constraintsContext,
    onSwitchItem,
    isEnabledSetting.isEnabled,
    isEnabledSetting.max,
    isEnabledSetting.min,
    setConstraintsContext,
  ]);

  return (
    <StyledPageSectionTitle nomargin>
      {loading ? (
        <StyledPortfolioEnabledStateSkeleton
          shape="round"
          size="small"
          active
        />
      ) : (
        <Tooltip
          placement="top"
          title={`Turn ${
            isEnabledSetting.isEnabled ? "off" : "on"
          } the arguments`}
        >
          <ToggleInput
            value={isEnabledSetting.isEnabled}
            dataCy="enable-portfolio"
            onChange={handleTogglePortfolioEnabledState}
            disabled={isDisabled}
          />
        </Tooltip>
      )}

      {isEnabledSetting.isEnabled ? (
        <StyledBriefcaseIcon />
      ) : (
        <StyledLockFilledIcon $size="18px" />
      )}
      {children}
    </StyledPageSectionTitle>
  );
};

ConstraintsTitle.propTypes = {
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSwitchItem: PropTypes.func,
  children: PropTypes.string,
  isEnabledSetting: ConstraintPropType,
};

export default ConstraintsTitle;
