import PropTypes from "prop-types";
import { StyledTooltip } from "./styles";

const Tooltip = ({ color, tooltipData, xPos, yPos }) => {
  if (!tooltipData) {
    return null;
  }

  return (
    <StyledTooltip
      style={{
        left: xPos,
        top: yPos,
      }}
      $isLight={tooltipData?.isLight}
    >
      <div style={{ color }}>{tooltipData?.y}</div>
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  color: PropTypes.string,
  tooltipData: PropTypes.shape(),
  xPos: PropTypes.number,
  yPos: PropTypes.number,
};

export default Tooltip;
