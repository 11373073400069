import styled, { css } from "styled-components/macro";
import {
  gray00,
  gray80,
  gray20,
  gray70,
  gray30,
  white,
  accentRed,
  gray40,
} from "../../constants/colors";
import { maxScreensSizesPx } from "../styled";

export const StyledUserBlock = styled.div`
  padding: 24px;
  border: solid 1px ${gray20};
  margin-top: 16px;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  box-sizing: border-box;
  @media (max-width: ${maxScreensSizesPx.mobile}) {
    padding-top: 68px;
  }
`;

export const StyledTitle = styled.div`
  color: ${gray80};
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
`;

export const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  align-items: center;
  @media (max-width: 730px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

export const StyledRowTitle = styled.div`
  color: ${gray80};
  font-size: 14px;
  line-height: 20px;
`;

export const StyledRowValue = styled.div`
  color: ${gray80};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const StyledRowValueEditDisabled = styled.div`
  border: solid 1px ${gray30};
  color: ${gray70};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 6px 10px;
  background-color: ${gray00};
  cursor: not-allowed;
  height: 34px;
  width: 322px;
  @media (max-width: 730px) {
    width: 98%;
  }
`;

export const StyledRowValueNotEdit = styled(StyledRowValueEditDisabled)`
  border: solid 1px transparent;
  background-color: ${white};
`;

export const StyledRowValueEdit = styled(StyledRowValueEditDisabled)`
  color: ${gray80};
  background-color: ${white};
`;

export const StyledRowValueEditContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledRowValueEditInputContainer = styled.div`
  width: 322px;
  display: flex;
  gap: 12px;
  .ant-input-affix-wrapper {
    ${({ $error }) =>
      $error &&
      css`
        border-color: ${accentRed} !important;
      `}
  }
  @media (max-width: 730px) {
    width: 98%;
  }
`;

export const StyledPasswordValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: ${gray70};
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 24px;
`;

export const StyledPasswordValidationRow = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const StyledEditBtnContainer = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  display: flex;
  gap: 12px;
`;

export const StyledAvatarImg = styled.img`
  border-radius: 100px;
  height: auto;
`;

export const StyledAvatarContainer = styled.div`
  width: fit-content;
  max-width: fit-content;
  height: fit-content;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  clip-path: circle(100px);
  color: ${gray40};
`;
