import PropTypes from "prop-types";
import { useState } from "react";
import BubblePlotDatasetTransition from "./BubblePlotDatasetTransition";

const Chart = ({
  handleSelectOption,
  selectedDataPoint,
  compareDataPoint,
  filteredObjectiveDropdownOptions,
  chartSeries,
  visibleCharts,
}) => {
  const [hovered, setHovered] = useState(null);
  const filteredChartsSeries = chartSeries?.filter(item =>
    visibleCharts.includes(item?.name),
  );

  return filteredChartsSeries?.map((item, index) => {
    const objectiveFunc = filteredObjectiveDropdownOptions.find(
      funcItem => funcItem.value === item?.name,
    );
    const isLast = index === filteredChartsSeries.length - 1;
    return (
      <BubblePlotDatasetTransition
        data={item?.data || []}
        objectiveFunc={objectiveFunc}
        handleSelectOption={handleSelectOption}
        selectedDataPoint={selectedDataPoint}
        compareDataPoint={compareDataPoint}
        key={item?.name}
        isLast={isLast}
        hovered={hovered}
        setHovered={setHovered}
      />
    );
  });
};

Chart.propTypes = {
  handleSelectOption: PropTypes.func,
  selectedDataPoint: PropTypes.shape(),
  compareDataPoint: PropTypes.shape(),
  filteredObjectiveDropdownOptions: PropTypes.arrayOf(PropTypes.shape()),
  chartSeries: PropTypes.arrayOf(PropTypes.shape()),
  visibleCharts: PropTypes.arrayOf(PropTypes.string),
};

export default Chart;
