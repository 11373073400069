import { format, intervalToDuration, isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import isString from "lodash/isString";
import toString from "lodash/toString";
import * as Sentry from "@sentry/browser";

export const dateFormat = "MMM dd, yyyy";

const formatDate = value => {
  if (!value || (!isString(value) && !isString(value.value))) {
    return "";
  }
  const actualValue = value.value || value;
  try {
    return format(new Date(actualValue), dateFormat);
  } catch (error) {
    error.errorDateValue = toString(value);
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return "";
};

export const secondsToMilliseconds = seconds => seconds * 1000;

export const formatTime = (timeObj, key) =>
  `${timeObj?.[key]}`.length > 1 ? timeObj?.[key] : `0${timeObj?.[key]}`;

export const formatIntervalToDuration = ({ start, end }) => {
  const duration = intervalToDuration({
    start,
    end,
  });
  const hrs = formatTime(duration, "hours");
  const min = formatTime(duration, "minutes");
  const sec = formatTime(duration, "seconds");
  return [hrs, min, sec].join(":");
};

export const getDefaultDateValue = dates => {
  let value = [];
  if (dates?.length) {
    const dateTo = dates[0]?.replace("<", "") ?? new Date();
    const dateFrom = dates[1]?.replace(">", "") ?? new Date();
    if (isValid(new Date(dateTo)) && isValid(new Date(dateFrom))) {
      value = [new Date(dateFrom), new Date(dateTo)];
      return value;
    }
  }
  return value;
};

export default formatDate;

export const getUTCdate = date => {
  const currentDate = new Date(date);
  const utcDate = formatInTimeZone(currentDate, "UTC", "yyyy-MM-dd HH:mm:ss");
  return new Date(utcDate);
};
