import styled, { css } from "styled-components/macro";
import {
  darkGrey2,
  blue,
  blueWhite,
  darkGrey3,
  gray80,
} from "../../constants/colors";

export const StyledText = css`
  font-style: normal;
  line-height: 1.4;
  color: ${gray80};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ regular }) =>
    regular &&
    css`
      font-weight: 500;
    `}
`;

export const StyledH1 = styled.h1`
  ${StyledText};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
`;

export const StyledH2 = styled.h2`
  ${StyledText};
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
`;

export const StyledH3 = styled.h3`
  ${StyledText};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const StyledH4 = styled.h4`
  ${StyledText};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledH5 = styled.h5`
  ${StyledText};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const StyledBodyText = styled.p`
  ${StyledText};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  ${({ level }) => {
    if (level === 2) {
      return css`
        font-size: 12px;
        line-height: 16px;
      `;
    }
    return null;
  }}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
`;

export const StyledInputText = styled.p`
  ${StyledText};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 600;
    `}
`;

export const StyledCardTitle = styled.p`
  ${StyledText};
  display: flex;
  color: ${darkGrey2};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 0 1rem;
`;

export const StyledSmallText = styled.p`
  ${StyledText};
  font-size: 12px;
  line-height: 16px;
  color: ${darkGrey2};
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${blue};

  ${props =>
    props.nonUnderlined &&
    css`
      text-decoration: none;
    `}

  ${({ level }) => {
    if (level === 2) {
      return css`
        font-size: 12px;
        line-height: 16px;
      `;
    }
    return null;
  }}
`;

export const StyledGeneralLink = css`
  font-weight: 600;
  color: ${({ color }) => color ?? blue};
  display: flex;
  align-items: center;

  ${({ underlined = true }) =>
    underlined
      ? css`
          text-decoration: underline;
        `
      : ""}

  ${({ uppercase }) => uppercase && `text-transform: uppercase;`}

  font-size: ${({ size }) => (size ? `${size}px;` : "14px;")}

  ${({ buttonStyle, $withIcon }) =>
    buttonStyle &&
    `
    padding: ${$withIcon ? "10px 14px 10px 10px" : "10px"};
    font-weight: bold;
    &:hover {
      color: ${blue};
      background-color: ${blueWhite};
    }
  `}
`;

export const StyledExternalLink = styled.a`
  ${StyledGeneralLink};
`;

export const StyledTextBody = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px;` : "14px")};
  line-height: 1.57;
  ${props =>
    props.$color
      ? css`
          color: ${props.$color};
        `
      : css`
          color: ${darkGrey3};
        `}
  white-space: pre-wrap;
  font-weight: ${({ bold }) => (bold ? "600;" : "500;")};
  ${({ uppercase }) => uppercase && "text-transform: uppercase;"};
  ${props =>
    props.padded &&
    css`
      padding: 16px 0;
    `}
`;

export const StyledCaption = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  ${({ level }) => {
    if (level === 2) {
      return css`
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      `;
    }

    if (level === 3) {
      return css`
        font-size: 10px;
        line-height: 14px;
        font-weight: 500;
      `;
    }

    return null;
  }}
`;
