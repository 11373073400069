import PropTypes from "prop-types";
import { StyledCloseIcon } from "../Icons";
import { StyledModal } from "./ModalDialog.styled";

const ModalDialog = ({
  isOpen = false,
  title = "",
  onClose,
  footer = null,
  children,
  dataCy = "",
  width = null,
  scrollTop = null,
  noHeaderPadding = true,
  noContentPadding = false,
  fullScreen = false,
}) => {
  return (
    <StyledModal
      title={title}
      centered
      open={isOpen}
      onCancel={onClose}
      scrollTop={scrollTop}
      footer={null}
      width={width || 470}
      noHeaderPadding={noHeaderPadding}
      noContentPadding={noContentPadding}
      autoFocusButton={null}
      $fullScreen={fullScreen}
      closeIcon={<StyledCloseIcon />}
      maskStyle={{
        backgroundColor: "rgba(227, 227, 227, 0.5)",
      }}
      data-cy={dataCy}
    >
      {children}
      {footer}
    </StyledModal>
  );
};

ModalDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func,
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataCy: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  scrollTop: PropTypes.number,
  noHeaderPadding: PropTypes.bool,
  noContentPadding: PropTypes.bool,
  fullScreen: PropTypes.bool,
};

export default ModalDialog;
