import { useCallback, useMemo } from "react";
import { useNavigate, useOutletContext } from "react-router";
import PropTypes from "prop-types";
import { Skeleton, Pagination } from "antd";
import { isValid } from "date-fns";

import { StyledPageContent, tableScrollWidth } from "../styled";
import { StyledLayout } from "../../layout/styles";
import StyledTable from "../../components/Table";
import { StyledDefaultAvatarIcon } from "../../components/Icons";
import { StyledAvatarContainer } from "../Settings/styled";
import { StyledTableFooter } from "../../components/Table/styles";
import {
  StyledTableInfo,
  StyledTablePagination,
  StyledTableWrapper,
} from "../Workbooks/styles";
import { transfromFloatingNumbers } from "../../utils/dataFormatters";
import formatDate from "../../utils/formatDate";
import TabsHeader from "./TabsHeader";
import ResetFilter from "../DataFilters/ResetFilter/ResetFilter";
import useTableFiltersMapper from "./hooks/useTableFiltersMapper";

const getDetailedUrlLookupKeyName = path => {
  return path.match(/:\w+/).pop().replace(":", "");
};

const TableComponent = ({ config, menu }) => {
  const navigate = useNavigate();
  const { isLoading, paginationData, dataTable, setActiveMenuItem, setPage } =
    useOutletContext();
  const {
    mapToFilters,
    handleTableChange,
    showResetBtn,
    setFilters,
    setShowResetBtn,
    clearFilters,
  } = useTableFiltersMapper(config.listParamsSchema, config.detailSchema);

  const columns = useMemo(() => {
    const keys = dataTable?.[0] ? Object.keys(dataTable?.[0]) : [];

    const newColumns = keys.map(key => {
      const title = key.replace(/([a-z](?=[A-Z]))/g, "$1 ");

      const result = {
        title,
        dataIndex: key,
        key,
        render: value => {
          if (key.toLowerCase().includes("picture")) {
            return value ? (
              <StyledAvatarContainer>
                <img
                  src={`data:image/jpeg;base64,${value}`}
                  alt=""
                  width={36}
                />
              </StyledAvatarContainer>
            ) : (
              <StyledDefaultAvatarIcon $width="36px" />
            );
          }

          if (typeof value === "boolean") {
            return value ? "Yes" : "No";
          }

          if (typeof value === "number") {
            return transfromFloatingNumbers(value);
          }

          if (value && isValid(new Date(value))) {
            return formatDate(value);
          }

          return value || "-";
        },
      };

      return mapToFilters(result, key);
    });

    return newColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);

  const handleSetActiveMenuItem = useCallback(
    item => {
      setFilters({});
      setShowResetBtn(false);
      setActiveMenuItem(item);
    },
    [setFilters, setActiveMenuItem, setShowResetBtn],
  );

  const TableFooter = useCallback(
    () =>
      paginationData?.total > dataTable?.length && (
        <StyledTableFooter>
          <StyledTableInfo>{`${dataTable?.length} out of ${paginationData?.total}`}</StyledTableInfo>
          <StyledTablePagination>
            <Pagination {...paginationData} onChange={setPage} />
          </StyledTablePagination>
        </StyledTableFooter>
      ),
    [setPage, paginationData, dataTable?.length],
  );

  return (
    <StyledLayout>
      <TabsHeader
        menuItems={menu}
        setActiveMenuItem={handleSetActiveMenuItem}
        setPage={setPage}
      />
      <StyledTableWrapper $noPadding>
        {isLoading && (
          <StyledPageContent>
            <Skeleton active />
          </StyledPageContent>
        )}
        {showResetBtn && <ResetFilter clearFilters={clearFilters} />}
        {!isLoading && (
          <StyledTable
            columns={columns}
            dataSource={dataTable}
            scroll={{ x: tableScrollWidth }}
            rowKey={({ uuid }) => uuid}
            pagination={false}
            footer={TableFooter}
            onChange={handleTableChange}
            onRow={
              config?.detailPath
                ? record => {
                    const keyName = getDetailedUrlLookupKeyName(
                      config?.detailPath,
                    );
                    const link = config?.detailPath?.replace(
                      `:${keyName}`,
                      record?.uuid,
                    );
                    return {
                      onClick: () => {
                        navigate(link);
                      },
                    };
                  }
                : undefined
            }
          />
        )}
      </StyledTableWrapper>
    </StyledLayout>
  );
};

TableComponent.propTypes = {
  config: PropTypes.shape(),
  menu: PropTypes.arrayOf(PropTypes.shape()),
};

export default TableComponent;
