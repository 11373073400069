import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { Skeleton } from "antd";
import {
  darkBlue,
  darkGrey4,
  goldLight,
  gray3,
  gray5,
  white,
} from "../../constants/colors";
import { StyledChart, StyledChartWrapper, StyledXAxisTitle } from "./styles";
import { transfromFloatingNumbers } from "../../utils/dataFormatters";

const defaultLabelFormatter = text => text;

const Chart = ({
  chartId = "exos-chart",
  data = null,
  seriesData,
  xField,
  yField,
  customOptions = null,
  labelFormatter = defaultLabelFormatter,
  labelYFormatter = defaultLabelFormatter,
  hideAxis,
  showTooltip = true,
  chartHeight = 400,
  step,
  lastStepIcon,
  customTooltipTitle = "",
  featuredStepIcon,
  featuredSteps,
}) => {
  const [chartOptions, setChartOptions] = useState({});
  const [series, setSeries] = useState({});
  const isMultipleSeries = customOptions?.yaxis?.length > 1;

  const getPoints = useCallback(
    ({ currentStep, currentStepValue, lastStep, lastStepValue }) => {
      const newPoints = [];
      if (currentStep) {
        newPoints.push({
          x: `${labelFormatter(currentStep)}`,
          y: currentStepValue,
          marker: {
            size: 6,
            fillColor: white,
            strokeColor: currentStep === lastStep ? goldLight : darkBlue,
            radius: 2,
          },
          label: {
            borderColor: gray5,
            text: labelFormatter(currentStep),
            textAnchor: currentStep > lastStep / 2 ? "end" : "start",
            offsetX: currentStep === lastStep ? -10 : 0,
            offsetY: 0,
            style: {
              color: darkGrey4,
              background: gray3,
              fontSize: "12px",
              fontFamily: "Onest,'Open Sans',Helvetica,Arial,sans-serif",
              padding: {
                left: 5,
                right: currentStep === lastStep ? 18 : 5,
                top: 2,
                bottom: 4,
              },
            },
          },
          image:
            currentStep === lastStep && lastStepValue
              ? {
                  path: lastStepIcon,
                  width: 15,
                  height: 15,
                  offsetX: 0,
                  offsetY: -18,
                }
              : {},
        });
      }

      if (featuredSteps?.length > 0) {
        featuredSteps.forEach(featuredStep => {
          if (featuredStep !== lastStep && data) {
            const stepValue = yField && data?.[featuredStep - 1]?.[yField];
            newPoints.push({
              x: `${labelFormatter(featuredStep)}`,
              y: stepValue,
              marker: {
                size: 6,
                fillColor: white,
                strokeColor: goldLight,
                radius: 2,
              },
              image: {
                path: featuredStepIcon,
                width: 15,
                height: 15,
                offsetX: 0,
                offsetY: -18,
              },
            });
          }
        });
      }

      if (currentStep !== lastStep && lastStepValue) {
        newPoints.push({
          x: `${labelFormatter(lastStep)}`,
          y: lastStepValue,
          marker: {
            size: 6,
            fillColor: white,
            strokeColor: goldLight,
            radius: 2,
          },
          image: {
            path: lastStepIcon,
            width: 15,
            height: 15,
            offsetX: 0,
            offsetY: -18,
          },
        });
      }
      return newPoints;
    },
    [
      data,
      featuredStepIcon,
      featuredSteps,
      labelFormatter,
      lastStepIcon,
      yField,
    ],
  );

  const getNewOptions = useCallback(() => {
    const categories =
      data && data?.map(item => `${labelFormatter(item[xField])}`);
    const lastStep = data?.length;
    const lastStepValue = yField && data?.[lastStep - 1]?.[yField];
    const currentStepValue = step ? data?.[step - 1][yField] : null;

    const points = getPoints({
      currentStep: step,
      currentStepValue,
      lastStep,
      lastStepValue,
    });

    return {
      chart: {
        id: chartId,
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          show: !hideAxis,
          formatter: labelYFormatter,
          maxWidth: hideAxis ? 0 : 150,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Onest,'Open Sans',Helvetica,Arial,sans-serif",
          },
        },
      },
      xaxis: {
        categories,
        labels: {
          maxHeight: hideAxis ? 0 : 100,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Onest,'Open Sans',Helvetica,Arial,sans-serif",
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        enabled: showTooltip,
        style: {
          fontSize: "12px",
          fontFamily: "Onest,'Open Sans',Helvetica,Arial,sans-serif",
        },
      },
      stroke: {
        curve: "smooth",
        colors: [darkBlue],
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      annotations: {
        points,
      },
      fill: {
        type: isMultipleSeries ? "solid" : "gradient",
        colors: isMultipleSeries ? [] : [darkBlue, white],
      },
      ...customOptions,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartId,
    data,
    getPoints,
    hideAxis,
    labelFormatter,
    labelYFormatter,
    step,
    xField,
    yField,
    isMultipleSeries,
    showTooltip,
  ]);

  const getNewSeries = useCallback(
    () => [
      {
        name: customTooltipTitle,
        data: data?.map(item => item[yField]),
      },
    ],
    [customTooltipTitle, data, yField],
  );

  useEffect(() => {
    if (data || step === data?.length) {
      const newOptions = getNewOptions();
      if (!isMultipleSeries && !hideAxis) {
        newOptions.yaxis = {
          axisBorder: {
            show: !isMultipleSeries,
          },
          labels: {
            show: !isMultipleSeries,
            formatter: value => transfromFloatingNumbers(value),
          },
        };
      }

      setChartOptions(newOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, data, getNewOptions, isMultipleSeries, hideAxis]);

  useEffect(() => {
    if (data) {
      const newSeries = getNewSeries();
      setSeries(newSeries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, step]);

  if (Object.keys(chartOptions).length === 0) {
    return <Skeleton active />;
  }

  return (
    <StyledChartWrapper>
      <StyledChart>
        <ReactApexChart
          options={chartOptions}
          series={seriesData || series}
          type="area"
          height={chartHeight}
        />
      </StyledChart>
      {!hideAxis && <StyledXAxisTitle>{xField}</StyledXAxisTitle>}
    </StyledChartWrapper>
  );
};

Chart.propTypes = {
  chartId: PropTypes.string,
  chartHeight: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape()),
  seriesData: PropTypes.arrayOf(PropTypes.shape()),
  xField: PropTypes.string,
  yField: PropTypes.string,
  labelFormatter: PropTypes.func,
  hideAxis: PropTypes.bool,
  showTooltip: PropTypes.bool,
  labelYFormatter: PropTypes.func,
  step: PropTypes.number,
  lastStepIcon: PropTypes.string,
  customTooltipTitle: PropTypes.string,
  featuredStepIcon: PropTypes.string,
  customOptions: PropTypes.shape(),
  featuredSteps: PropTypes.arrayOf(PropTypes.number),
};

export default Chart;
