import PropTypes from "prop-types";
import filterTypes from "../constants/filterTypes";
import { PROGRESS_STATES } from "../constants/constraints";

export const listSchemaType = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  workbookName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  isDeleted: PropTypes.string.isRequired,
  startedAt: PropTypes.string.isRequired,
  finishedAt: PropTypes.string.isRequired,
});

export const choiceType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

export const listParamsSchemaType = PropTypes.shape({
  [PropTypes.string]: PropTypes.shape({
    sortable: PropTypes.bool.isRequired,
    filterable: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(Object.values(filterTypes)).isRequired,
    choices: PropTypes.arrayOf(choiceType).isRequired,
  }),
});

export const detailSchemaType = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  workbookName: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(PROGRESS_STATES)).isRequired,
  createdBy: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  isDeleted: PropTypes.string.isRequired,
  startedAt: PropTypes.string.isRequired,
  finishedAt: PropTypes.string.isRequired,
  workbookUuid: PropTypes.string.isRequired,
});

export const menuType = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  listSchema: listSchemaType.isRequired,
  listParamsSchema: listParamsSchemaType.isRequired,
  detailSchema: detailSchemaType.isRequired,
  detailUrl: PropTypes.string.isRequired,
  detailPath: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
