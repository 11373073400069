import PropTypes from "prop-types";
import {
  StyledMenuItem,
  StyledMenuItemContent,
  StyledMenuItemExtra,
  StyledMenuItemIcon,
  StyledMenuTitle,
  StyledMenuValue,
} from "./styles";
import { StyledEmojiEventIcon } from "../../../../components/Icons";
import { goldLight } from "../../../../constants/colors";

const SideMenuItem = ({
  icon,
  step,
  title,
  value,
  extraInfo,
  onClick = () => {},
  left = false,
  isActive = false,
  showIcon = false,
  header = false,
  isLast = false,
  dataCy = "menu-item",
}) => (
  <StyledMenuItem
    data-cy={isActive ? `${dataCy}-active` : dataCy}
    active={isActive}
    left={left}
    onClick={() => onClick(step)}
    header={header}
  >
    <StyledMenuItemIcon showIcon={showIcon}>{icon}</StyledMenuItemIcon>
    <StyledMenuItemContent showIcon={showIcon}>
      <StyledMenuTitle header={header}>{title}</StyledMenuTitle>
      <StyledMenuValue>{value}</StyledMenuValue>
      {isLast && <StyledEmojiEventIcon color={goldLight} />}
    </StyledMenuItemContent>
    {extraInfo && <StyledMenuItemExtra>{extraInfo}</StyledMenuItemExtra>}
  </StyledMenuItem>
);

SideMenuItem.propTypes = {
  left: PropTypes.bool,
  isActive: PropTypes.bool,
  showIcon: PropTypes.bool,
  header: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  step: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  extraInfo: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  onClick: PropTypes.func,
  isLast: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default SideMenuItem;
