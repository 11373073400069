import {
  StyledPhoneIcon,
  StyledEmailIcon,
  StyledPinIcon,
  StyledFooterLogoIcon,
} from "../Icons";
import {
  StyledFooter,
  StyledLogo,
  StyledCopyrightText,
  StyledCopyrightBlock,
  StyledTitle,
  StyledLink,
  StyledInfoRow,
} from "./Footer.styled";

const data = {
  emailus: "clients@exosfinancial.com",
  callUs: "+1-212-498-8942",
  ourLocation: "12 East 49th Street, 15th Floor, New York, NY 10017",
  termsLink: {
    title: "Terms and Conditions of Use",
    path: "/terms-and-conditions",
  },
  сopyrightText:
    "Broker-Dealer services offered through Exos Securities LLC, Member",
  сopyrightDate: "2022 Loan Hunter",
  policiesLink: {
    title: "Policies and Disclosures",
    path: "/policies-and-disclosures",
  },
};

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <StyledLogo>
          <StyledFooterLogoIcon />
        </StyledLogo>
        <StyledCopyrightBlock>
          <div>
            <StyledCopyrightText>{data.сopyrightText}</StyledCopyrightText>
            <span>FINRA / SIPC.</span>
          </div>
          <div>&copy; {data.сopyrightDate}</div>
        </StyledCopyrightBlock>
      </div>
      <div>
        <StyledTitle>Important Information</StyledTitle>
        <StyledLink
          href={`${window.location.origin}${data.policiesLink.path}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {data.policiesLink.title}
        </StyledLink>
        <StyledLink
          href={`${window.location.origin}${data.termsLink.path}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {data.termsLink.title}
        </StyledLink>
      </div>
      <div>
        <StyledTitle>Contact Us</StyledTitle>
        <StyledInfoRow>
          <StyledPhoneIcon />
          <span>{data.callUs}</span>
        </StyledInfoRow>
        <StyledInfoRow>
          <StyledEmailIcon />
          <StyledLink
            href={`mailto: ${data.emailus}`}
            target="_blank"
            noMargin
            rel="noreferrer noopener"
          >
            {data.emailus}
          </StyledLink>
        </StyledInfoRow>
      </div>
      <div>
        <StyledTitle>address</StyledTitle>
        <StyledInfoRow>
          <StyledPinIcon />
          <span>{data.ourLocation}</span>
        </StyledInfoRow>
      </div>
    </StyledFooter>
  );
};

export default Footer;
