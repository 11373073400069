import PropTypes from "prop-types";
import { Switch } from "antd";
import { StyledToggleLabel, StyledToggleWrapper } from "./styled";

const ToggleInput = ({
  value = false,
  label = "",
  onChange,
  dataCy,
  disabled = false,
  ...restProps
}) => (
  <StyledToggleWrapper>
    <Switch
      data-cy={dataCy}
      checked={value}
      onChange={onChange}
      disabled={disabled}
      {...restProps}
    />
    <StyledToggleLabel>{label}</StyledToggleLabel>
  </StyledToggleWrapper>
);

ToggleInput.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  dataCy: PropTypes.string,
};

export default ToggleInput;
