import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton, message, Pagination } from "antd";
import handleFormatDate from "../../../utils/handleFormatDate";
import {
  StyledPageContent,
  StyledPageHeaderTitle,
  StyledPageHeaderTitleContainer,
  tableScrollWidth,
  StyledTableText,
} from "../../styled";
import { StyledLayout } from "../../../layout/styles";
import StyledTable from "../../../components/Table";
import {
  StyledTableFooter,
  StyledTableInfo,
  StyledTablePagination,
  StyledTableWrapper,
  StyledTrashOutlineIconWrapper,
  StyledWorkbookActions,
} from "../styles";
import {
  deleteWorkbookContributor,
  getWorkbookContributors,
} from "../../../api/endpoints/workbooks";
import { handleErrorMessages } from "../../../utils/messages";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import {
  StyledDefaultAvatarIcon,
  StyledHomeIcon,
  StyledTrashOutlineIcon,
} from "../../../components/Icons";
import DeleteModal from "./DeleteModal";
import useErrorStatus from "../../../hooks/useErrorStatus";
import NoPermissions from "../../ErrorPages/NoPermissions";
import ActionsHeader from "../../Shared/ActionsHeader";
import useQuery from "../../../hooks/useQuery";
import { StyledAvatarContainer } from "../../Settings/styled";

const ContributorsTable = () => {
  const { workbook_id: wId } = useParams();
  const { query, setQuery } = useQuery();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(+query.get("page") || 1);
  const [total, setTotal] = useState(0);
  const [paginationData, setPaginationData] = useState({
    size: "small",
    showSizeChanger: false,
    position: "bottomRight",
    current: page || 1,
    defaultCurrent: 1,
    total: total || data.length || 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [deleteContributor, setDeleteContributor] = useState(null);
  const { setErrorStatus, isForbidden } = useErrorStatus();
  const navigate = useNavigate();

  const handleGetWorkbookContributors = ({ page: newPage }) => {
    setIsLoading(true);
    getWorkbookContributors({ workbookUUID: wId, page: newPage })
      .then(({ items, total: totalItems }) => {
        setData(items);
        setTotal(totalItems);
      })
      .catch(error => {
        const response = error?.response || {};
        const { status } = response;
        setErrorStatus(status);
        if (status !== 403) {
          handleErrorMessages(error);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDeleteContributorClick = () => {
    deleteWorkbookContributor(wId, deleteContributor?.uuid)
      .then(() => {
        setData(prevState =>
          prevState?.filter(item => item?.uuid !== deleteContributor?.uuid),
        );
        message.success({
          content: `Contributor ${deleteContributor?.userEmail} was deleted!`,
          className: "exos-message",
        });
        setDeleteContributor(null);
      })
      .catch(error => handleErrorMessages(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleGetWorkbookContributors({ page });
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    const currentPage = query.get("page");
    setPage(+currentPage || 1);
  }, [query]);

  useEffect(() => {
    setPaginationData(prevState => ({ ...prevState, current: page, total }));
  }, [page, total]);

  const onPageChange = useCallback(
    currentPage => {
      setQuery("page", currentPage);
      setPage(currentPage);
    },
    [setQuery],
  );

  const columns = [
    {
      title: " ",
      dataIndex: "profilePicture",
      key: "profilePicture",
      width: "105px",
      render: profilePicture =>
        profilePicture ? (
          <StyledAvatarContainer>
            <img
              src={`data:image/jpeg;base64,${profilePicture}`}
              alt=""
              width={36}
            />
          </StyledAvatarContainer>
        ) : (
          <StyledDefaultAvatarIcon $width="36px" />
        ),
    },
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
      render: (_, row) => (
        <StyledTableText>
          {row?.firstName} {row?.lastName}
        </StyledTableText>
      ),
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      key: "userEmail",
      render: email => <StyledTableText>{email}</StyledTableText>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: value => handleFormatDate({ value, showTime: true }),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: value => handleFormatDate({ value, showTime: true }),
    },
    {
      title: "Actions",
      align: "right",
      dataIndex: "actions",
      key: "actions",
      render: (value, record) => {
        return (
          <StyledWorkbookActions>
            <StyledTextButton
              $iconOnly
              type="text"
              icon={<StyledTrashOutlineIconWrapper />}
              onClick={() => setDeleteContributor(record)}
            />
          </StyledWorkbookActions>
        );
      },
    },
  ];

  const config = [
    {
      icon: <StyledHomeIcon />,
      onClick: () => navigate("/"),
    },
    {
      label: wId,
    },
    {
      label: "Contributors",
    },
  ];

  const TableFooter = useCallback(
    () =>
      paginationData?.total > data?.length && (
        <StyledTableFooter>
          <StyledTableInfo>{`${data?.length} out of ${paginationData?.total}`}</StyledTableInfo>
          <StyledTablePagination>
            <Pagination {...paginationData} onChange={onPageChange} />
          </StyledTablePagination>
        </StyledTableFooter>
      ),
    [onPageChange, paginationData, data?.length],
  );

  if (isForbidden) return <NoPermissions />;

  return (
    <StyledLayout>
      <ActionsHeader
        breadcrumbsConfig={config}
        showLabes={false}
        postShareCallBack={contributor =>
          setData(prevState => [contributor, ...prevState])
        }
      />
      <StyledPageHeaderTitleContainer>
        <StyledPageHeaderTitle>Contributors</StyledPageHeaderTitle>
      </StyledPageHeaderTitleContainer>
      <StyledTableWrapper $noPadding>
        {isLoading && (
          <StyledPageContent>
            <Skeleton active />
          </StyledPageContent>
        )}
        {!isLoading && (
          <StyledTable
            columns={columns}
            dataSource={data}
            scroll={{ x: tableScrollWidth }}
            rowKey={({ uuid }) => uuid}
            pagination={false}
            footer={TableFooter}
          />
        )}
      </StyledTableWrapper>
      <DeleteModal
        isOpen={Boolean(deleteContributor)}
        name={deleteContributor?.userEmail}
        onClose={() => setDeleteContributor(null)}
        onDelete={handleDeleteContributorClick}
        title="Remove Contributor"
        subtitle="You are trying to delete a Contributor. Are you sure you want to remove Contributor"
      />
    </StyledLayout>
  );
};

export default ContributorsTable;
