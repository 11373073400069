import styled, { css } from "styled-components/macro";
import { LoadingOutlined } from "@ant-design/icons";
import { darkGrey5, gray5, white } from "../../constants/colors";
import { StyledCardTitle } from "../Typography";

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: ${white};
  border: 1px solid ${gray5};
  border-radius: 2px;
  margin-bottom: 1rem;

  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}
  ${props =>
    props.overflowX &&
    css`
      overflow-x: auto;
    `}
`;

export const StyledCardLabel = styled(StyledCardTitle)`
  margin: 0;
`;

export const StyledCardText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${darkGrey5};
  margin: 0;
`;

export const StyledCardContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
  position: relative;
  overflow-x: auto;

  ${props =>
    props.nopadding &&
    css`
      padding: 0;
    `}

  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}
`;

export const StyledCardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${gray5};
  min-height: 65px;

  ${props =>
    props.stickyHeader &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: ${white};
    `}
`;

export const StyledCardActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledLoading = styled(LoadingOutlined)`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
