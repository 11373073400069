import styled from "styled-components/macro";
import {
  StyledH2,
  StyledSmallText,
  StyledLink,
} from "../../components/Typography";
import { maxScreensSizesPx } from "../styled";

export const StyledWelcomePageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  padding: 100px 0;
  text-align: center;

  h2 {
    margin: 0;
  }

  > button {
    margin-top: 30px;
  }
`;

export const StyledNeedHelpBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledButtonContainer = styled.div`
  margin: 0px 0 32px 0;
`;

export const StyledImageWrapper = styled.div`
  margin: 40px 0;
`;

export const StyledLinkText = styled(StyledLink)``;

export const StyledHelpText = styled(StyledSmallText)``;

export const StyledPageTitle = styled(StyledH2)`
  margin: 40px 0 32px 0;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    font-size: 26px;
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 24px;
`;
