import { useMemo } from "react";
import PropTypes from "prop-types";
import {
  darkGrey1,
  darkGrey2,
  darkGrey4,
  darkGrey3,
  gray5,
  gray6,
  white,
} from "../../../../../constants/colors";
import ChartStar from "./ChartStar";
import TransactionsTooltip from "./TransactionsTooltip";

const TICK_LENGTH = 10;

const AxisBottom = ({
  xScale,
  pixelsPerTick,
  isLast = false,
  height,
  hovered,
  selectedDataPoint,
  compareDataPoint,
}) => {
  const range = xScale.range();

  const ticks = useMemo(() => {
    const width = range[1] - range[0];
    const minNumberOfTicks = 10;
    let numberOfTicksTarget = Math.floor(width / pixelsPerTick);

    if (numberOfTicksTarget > minNumberOfTicks) {
      numberOfTicksTarget = minNumberOfTicks;
    }

    return xScale.ticks(numberOfTicksTarget).map(value => ({
      value,
      xOffset: xScale(value),
    }));
  }, [xScale, pixelsPerTick, range]);

  return (
    <>
      <line
        y1={0}
        y2={-height - TICK_LENGTH}
        stroke={darkGrey1}
        strokeWidth={0}
      />
      {ticks.map(({ value, xOffset }) => {
        const isSelected = selectedDataPoint?.transactions === value;
        const isCompare = compareDataPoint?.transactions === value;
        const isHovered = hovered?.transactions === value;

        return (
          <g
            key={value}
            transform={`translate(${xOffset}, 0)`}
            shapeRendering="crispEdges"
          >
            {isLast && (
              <rect
                fill={white}
                width={700}
                height={300}
                style={{
                  transform: "translate(-10px, 0px)",
                }}
              />
            )}
            {isHovered ? (
              <>
                <line
                  y1={TICK_LENGTH}
                  y2={-height - TICK_LENGTH - 25}
                  stroke={darkGrey4}
                  strokeWidth={1}
                  strokeDasharray={7}
                />
                {isLast && <TransactionsTooltip value={value} />}
              </>
            ) : (
              <>
                {isSelected && (
                  <>
                    <line
                      y1={TICK_LENGTH}
                      y2={-height - TICK_LENGTH - 25}
                      stroke={darkGrey3}
                      strokeWidth={1}
                    />
                    {isLast && <TransactionsTooltip value={value} />}
                  </>
                )}
                {isCompare && (
                  <>
                    <line
                      y1={TICK_LENGTH}
                      y2={-height - TICK_LENGTH - 25}
                      stroke={gray6}
                      strokeWidth={1}
                    />
                    {isLast && <ChartStar />}
                    {isLast && (
                      <TransactionsTooltip
                        value={value}
                        isCompare={isCompare}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {isLast && !isSelected && !isCompare && !isHovered && (
              <>
                <text
                  style={{
                    fontSize: "12px",
                    textAnchor: "middle",
                    transform: "translate(0px, 25px)",
                    fill: `${darkGrey4}`,
                  }}
                >
                  {value}
                </text>
                <line y1={TICK_LENGTH} y2={0} stroke={gray5} strokeWidth={1} />
              </>
            )}
          </g>
        );
      })}
      {isLast && (
        <text
          style={{
            fontSize: "10px",
            transform: "translate(-10px, 53px)",
            textTransform: "uppercase",
            textAnchor: "left",
            fill: `${darkGrey2}`,
          }}
        >
          # of transactions
        </text>
      )}
    </>
  );
};

AxisBottom.propTypes = {
  pixelsPerTick: PropTypes.number,
  isLast: PropTypes.bool,
  xScale: PropTypes.func,
  height: PropTypes.number,
  selectedDataPoint: PropTypes.shape(),
  compareDataPoint: PropTypes.shape(),
  hovered: PropTypes.shape({
    xPos: PropTypes.number,
    yPos: PropTypes.number,
    itemSolutions: PropTypes.arrayOf(PropTypes.shape()),
    name: PropTypes.string,
    value: PropTypes.number,
    index: PropTypes.number,
    transactions: PropTypes.number,
  }),
};

export default AxisBottom;
