import { useNavigate } from "react-router-dom";

import { StyledHomeIcon } from "../../components/Icons";
import HeaderContainer from "../../layout/AppLayout/content/header";
import { PageContextProvider } from "../../layout/AppLayout/context";
import { StyledLayout } from "../../layout/styles";
import Breadcrumbs from "../Shared/Breadcrumbs";
import { StyledPageHeader, StyledPageHeaderTitle } from "../styled";
import { StyledTableWrapper } from "../Workbooks/styles";
import UserInfo from "./UserInfo";

const SettingsLayout = () => {
  const navigate = useNavigate();

  const config = [
    {
      icon: <StyledHomeIcon />,
      onClick: () => navigate("/"),
    },
    {
      label: "User Settings",
    },
  ];

  return (
    <PageContextProvider>
      <HeaderContainer />
      <StyledLayout>
        <StyledPageHeader>
          <Breadcrumbs config={config} />
        </StyledPageHeader>
        <StyledTableWrapper>
          <StyledPageHeaderTitle>User settings</StyledPageHeaderTitle>
          <UserInfo />
        </StyledTableWrapper>
      </StyledLayout>
    </PageContextProvider>
  );
};

export default SettingsLayout;
