import { useCallback, useState, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  StyledHeader,
  StyledHeaderContent,
  StyledHeaderLogo,
  StyledMenuButton,
  StyledMenuMobileList,
  StyledMenuItem,
  StyledMainHeaderMenuItem,
  StyledMenuItemIcon,
  StyledMainHeaderMenu,
  StyledMenuItemTitle,
  StyledMainHeaderActions,
  StyledHeaderMenu,
} from "../../styles";
import { StyledUserName } from "./UserMenu.styled";
import { MAIN_MENU_ITEMS } from "../../../constants/menu";
import useIsMobile from "../../../hooks/useIsMobile";
import UserMenu from "./UserMenu";
import { PageContext } from "../context";
import { StyledHeaderLogoIcon } from "../../../components/Icons";

const buildUserName = user => {
  if (!user) {
    return "";
  }

  if (!user.firstName && !user.lastName) {
    return user.email;
  }

  return [user.firstName, user.lastName].filter(person => person).join(" ");
};

const HeaderContainer = () => {
  const { authState } = useOktaAuth();
  const { isAuthenticated } = authState || {};
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useContext(PageContext);
  const { oktaAuth } = useOktaAuth();
  const userName = buildUserName(user);

  const isOktaAuthFlow = process.env.REACT_APP_AUTH_FLOW === "okta";
  const token = localStorage.getItem("accessToken");

  const isAuth = isAuthenticated || process.env.REACT_APP_FAKE_TOKEN;
  const hasToken = isOktaAuthFlow ? isAuth : token;

  const doLogout = async () => {
    if (isOktaAuthFlow) {
      await oktaAuth.signOut();
    } else {
      localStorage.removeItem("accessToken");
      window.location.replace(`${window.location.origin}`);
    }
  };

  const handleCloseMenu = useCallback(() => {
    document.body.removeEventListener("click", () => true);
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  const handleListenerEvent = useCallback(
    event => {
      if (!event.target.closest(".menu")) {
        handleCloseMenu();
      }
    },
    [handleCloseMenu],
  );
  const handleOpenMenu = useCallback(() => {
    document.body.addEventListener("click", handleListenerEvent);
    setIsMenuOpen(true);
  }, [handleListenerEvent]);

  return (
    <StyledHeader>
      <StyledHeaderContent>
        {isMobile && isAuth && (
          <>
            <StyledMenuButton
              onClick={isMenuOpen ? handleCloseMenu : handleOpenMenu}
              className="menu"
            >
              {isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
            </StyledMenuButton>
            <StyledMenuMobileList open={isMenuOpen}>
              <StyledUserName isMobile={isMobile}>{userName}</StyledUserName>
              {MAIN_MENU_ITEMS.map(({ key, path, label }) => (
                <StyledMenuItem key={key} onClick={() => navigate(path)}>
                  {label}
                </StyledMenuItem>
              ))}
              <StyledMenuItem onClick={doLogout}>Logout</StyledMenuItem>
            </StyledMenuMobileList>
          </>
        )}

        <StyledHeaderMenu>
          <StyledHeaderLogo onClick={() => navigate("/")}>
            <StyledHeaderLogoIcon />
          </StyledHeaderLogo>
          {hasToken && (
            <StyledMainHeaderMenu>
              {MAIN_MENU_ITEMS.map(({ key, path, label, icon }) => (
                <StyledMainHeaderMenuItem
                  key={key}
                  data-cy={key}
                  onClick={() => navigate(path)}
                  active={location.pathname === path}
                >
                  <StyledMenuItemIcon>{icon}</StyledMenuItemIcon>
                  <StyledMenuItemTitle>{label}</StyledMenuItemTitle>
                </StyledMainHeaderMenuItem>
              ))}
            </StyledMainHeaderMenu>
          )}
        </StyledHeaderMenu>

        {hasToken && (
          <StyledMainHeaderActions>
            <UserMenu
              userName={isMobile ? "" : userName}
              user={user}
              logout={doLogout}
            />
          </StyledMainHeaderActions>
        )}
      </StyledHeaderContent>
    </StyledHeader>
  );
};

export default HeaderContainer;
