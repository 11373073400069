import styled from "styled-components/macro";
import {
  darkGrey2,
  darkGrey5,
  gray5,
  white,
  darkGrey4,
} from "../../constants/colors";

export const StyledStatisticWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: ${white};
  border: 1px solid ${gray5};
  border-radius: 2px;
  padding: 1rem;
`;

export const StyledStatisticLabel = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.38px;
  text-transform: uppercase;
  color: ${darkGrey2};
  margin: 0 0 0.5rem;
`;

export const StyledStatisticText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${darkGrey5};
  margin: 0;
`;

export const StyledDiffItem = styled.div`
  display: grid;
  grid-template-columns: 1.25rem auto auto;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  color: ${darkGrey4};
  font-size: 12px;
  line-height: 16px;
`;

export const StyledDiffItemsWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: start;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: auto;
  padding-top: 1rem;
`;
