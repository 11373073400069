import styled from "styled-components/macro";

export const StyledPoliciesAndDisclosures = styled.div`
  max-width: 900px;
  padding-top: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export default StyledPoliciesAndDisclosures;
