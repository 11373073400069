import PropTypes from "prop-types";
import StyledCard from "../../../../../components/Card";
import {
  StyledList,
  StyledListItem,
  StyledListTitle,
  StyledListTotal,
} from "../styles";

const ListCard = ({ title, data = [], total }) => {
  const keys = Object.keys(data[0] || {});
  return (
    <StyledCard grow dataCy="result-ratingChart" title={title}>
      <StyledList>
        <StyledListTitle>
          <span>{keys[0]}</span>
          <span>{keys[1]}</span>
        </StyledListTitle>

        {data.map(item => (
          <StyledListItem key={item[keys[0]]}>
            <span>{item[keys[0]]}</span>
            <span>{item[keys[1]]}</span>
          </StyledListItem>
        ))}

        {total && (
          <StyledListTotal>
            <span>Total</span>
            <span>{total}</span>
          </StyledListTotal>
        )}
      </StyledList>
    </StyledCard>
  );
};

ListCard.propTypes = {
  title: PropTypes.string,
  total: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default ListCard;
