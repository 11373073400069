import { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import useFiltersData from "../../../../../layout/WorkbookLayout/useFiltersData";
import { PageContext } from "../../../../../layout/ResultLayout/context";
import useChartsData from "./useChartsData";

const useDetailsData = ({
  selectedPortfolioId,
  step,
  disableInputs = false,
  getPortfolioResultAtStep = () => {},
}) => {
  const { getResultData } = useContext(PageContext);

  const loadFunction = useCallback(
    () => getPortfolioResultAtStep(selectedPortfolioId, step),
    [getPortfolioResultAtStep, selectedPortfolioId, step],
  );

  const filterProps = useFiltersData({
    loadDataFunction: loadFunction,
    refreshPortfolioData: getResultData,
    disableInputs,
  });

  const chartsData = useChartsData({ step });

  return {
    filterProps,
    chartsData,
  };
};

useDetailsData.propTypes = {
  selectedPortfolioId: PropTypes.string,
  step: PropTypes.number,
};

export default useDetailsData;
