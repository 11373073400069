import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { maxBy, minBy, capitalize } from "lodash";
import { renderToStaticMarkup } from "react-dom/server";

import {
  getAllPortfolioConstraintsValue,
  getPortfolioGenerations,
} from "../../../../api/endpoints/portfolios";
import {
  moneyIntFormatter,
  transfromFloatingNumbers,
} from "../../../../utils/dataFormatters";
import { PROGRESS_STATES } from "../../../../constants/constraints";
import { PageContext } from "../../../../layout/ResultLayout/context";
import { getResultByUUID } from "../../../../api/endpoints/results";
import { parseObjectiveName } from "../../../../utils/parseObjectiveName";
import useFiltersData from "../../../../layout/WorkbookLayout/useFiltersData";
import {
  StyledChartTooltipContainer,
  StyledChartTooltipOFName,
  StyledChartTooltipOFValue,
  StyledChartTooltipTitle,
} from "../../../../components/Chart/styles";
import { blue, darkBlue, gray1, white } from "../../../../constants/colors";
import { getChartColors } from "./helpers";
import useIsMobile from "../../../../hooks/useIsMobile";

const usePortfolioResultData = ({
  selectedPortfolio,
  status,
  step,
  constraintsData,
}) => {
  const isMobile = useIsMobile();
  const {
    resultUUID,
    setCurrentGenerations,
    currentGenerations,
    setAllConstraintsValue,
    setSelectedGenerationUUID,
    handleErrorRequests,
    workbookId,
    objectives,
    selectedGenerationUUID,
  } = useContext(PageContext);

  const { objective } = constraintsData;
  const { columnDefs } = useFiltersData({
    workbookId,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [resultList, setResultList] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentResult, setCurrentResult] = useState(null);

  const resultsListKeys = useMemo(() => {
    if (resultList?.length) {
      const objectivesKeys = resultList[0]?.objectives_details?.map(
        item => item?.name,
      );
      return [...Object.keys(resultList[0]), ...objectivesKeys];
    }
    return [];
  }, [resultList]);

  const updateGenerationsList = useCallback(() => {
    setIsLoading(status !== PROGRESS_STATES.inProgress);
    if (!selectedPortfolio?.uuid) {
      setCurrentGenerations([]);
      return;
    }
    getPortfolioGenerations(selectedPortfolio?.uuid)
      .then(generations => {
        const newGenerations =
          generations?.filter(
            generation => generation.status !== PROGRESS_STATES.skipped,
          ) ?? [];
        setCurrentGenerations(newGenerations);
      })
      .catch(error => {
        setCurrentGenerations([]);
        handleErrorRequests(error);
      })
      .finally(() => setIsLoading(false));
  }, [
    selectedPortfolio?.uuid,
    setCurrentGenerations,
    status,
    handleErrorRequests,
  ]);

  const convertResults = useCallback((resObj = {}) => {
    const resArray = Object.values(resObj) || [];
    return resArray.map((item, index) => ({ ...item, gen: index + 1 }));
  }, []);

  const columns = useMemo(() => {
    const defaultKeys = [
      "gen",
      "objective",
      "number_of_holdings",
      "number_of_transactions",
      "overall_notional",
      "loan_maturity",
      "senior_secured",
      "constraints_values",
      "objectives_details",
      "failed_constraints",
      "step",
      "warf",
    ];

    const filteredKeys = resultsListKeys?.filter(
      key => !defaultKeys.includes(key),
    );

    const newColumns = filteredKeys
      .map(key => {
        const objectiveData = resultList[0]?.objectives_details?.find(
          value => value?.name === key,
        );

        const title =
          objectiveData?.objective_field ||
          objectives[key] ||
          capitalize(key.split("_").join(" "));

        return {
          title,
          dataIndex: key,
          key,
          render: value => {
            const newValue =
              typeof objectiveData?.value === "number"
                ? objectiveData?.value
                : value;
            return transfromFloatingNumbers(newValue);
          },
        };
      })
      .filter(
        filteredKey =>
          !filteredKey.key.includes("_normalized") &&
          filteredKey.key !== "constraints_values",
      );

    const defaultColumns = [
      {
        title: "Step",
        dataIndex: "step",
        key: "step",
      },
      {
        title: "Objective Function",
        dataIndex: "objective",
        key: "objective",
        render: value => transfromFloatingNumbers(value),
      },
      {
        title: "Number Of Transactions",
        dataIndex: "number_of_transactions",
        key: "number_of_transactions",
      },
      {
        title: "Number Of Holdings",
        dataIndex: "number_of_holdings",
        key: "number_of_holdings",
      },
      {
        title: "Overall Notional",
        dataIndex: "overall_notional",
        key: "overall_notional",
        render: event => moneyIntFormatter.format(event),
      },
      {
        title: "WARF",
        dataIndex: "warf",
        key: "warf",
        render: (_, row) => {
          const rowData = row?.objectives_details?.find(
            item => item?.name === "warf",
          );
          const value = rowData?.value;
          return transfromFloatingNumbers(value);
        },
      },
      {
        title: "Loan Maturity",
        dataIndex: "loan_maturity",
        key: "loan_maturity",
        render: (_, row) => {
          const rowData = row?.objectives_details?.find(
            item => item?.name === "loan_maturity",
          );
          const value = rowData?.value;
          return (+value).toFixed(2);
        },
      },
      {
        title: "Senior Secure",
        dataIndex: "senior_secured",
        key: "senior_secured",
        render: value => (+value).toFixed(2),
      },
    ];

    return [...defaultColumns, ...newColumns];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsListKeys, columnDefs, objectives]);

  const updatePortfolioResult = useCallback(() => {
    if (!resultUUID) return;
    getResultByUUID(resultUUID)
      .then(results => {
        const portfolios = results?.payload?.portfolios;
        setCurrentResult(results);
        if (!portfolios) return;
        setResultList(
          convertResults(portfolios[selectedPortfolio?.uuid]?.steps || 0),
        );
      })
      .catch(error => {
        handleErrorRequests(error);
      });
  }, [
    convertResults,
    resultUUID,
    selectedPortfolio?.uuid,
    handleErrorRequests,
  ]);

  const getStepNumberByGenerationId = useCallback(
    (genIds = []) => {
      const steps = [];
      genIds.forEach(genId => {
        const currentGeneration = currentGenerations.find(
          generation =>
            generation.portfolioUuid === selectedPortfolio?.uuid &&
            generation.uuid === genId,
        );
        if (currentGeneration) {
          steps.push(currentGeneration.step);
        }
      });

      return steps;
    },
    [currentGenerations, selectedPortfolio?.uuid],
  );

  const handleGetAllPortfolioConstraintsValue = useCallback(() => {
    if (!selectedGenerationUUID) return;
    getAllPortfolioConstraintsValue(selectedGenerationUUID)
      .then(results => {
        setAllConstraintsValue(results);
      })
      .catch(error => handleErrorRequests(error));
  }, [selectedGenerationUUID, setAllConstraintsValue, handleErrorRequests]);

  const getTickAmount = steps => {
    if (isMobile) {
      return 10;
    }
    if (steps <= 25) {
      return steps;
    }
    return Math.ceil(steps / (steps / 25 + 1));
  };

  const getOverviewChartData = useCallback(
    (showMultiSeries = true) => {
      const objectiveChartName = parseObjectiveName(objective);

      if (!showMultiSeries) {
        return resultList.map((res, index) => ({
          step: index + 1,
          [objectiveChartName]: res.objective,
        }));
      }

      const objectiveKeys = objective?.map(item => item?.name) || [];
      const chartKeys =
        objectiveKeys?.length > 1
          ? ["objective", ...objectiveKeys]
          : [...objectiveKeys];

      const chartColors = getChartColors(chartKeys);
      const isSinglChart = chartKeys?.length === 1;
      const settingsColors = isSinglChart ? [darkBlue] : chartColors;

      const options = {
        yaxis: [],
        xaxis: {
          tickAmount: getTickAmount(resultList?.length || 0),
        },
      };

      const series = chartKeys?.map((keyName, index) => {
        const objFuncName = objectives?.[keyName] || objectiveChartName;

        const data = resultList.map(res => {
          if (keyName === "objective") return res[keyName];

          const itemData = res?.objectives_details?.find(
            item => item?.name === keyName,
          );
          return itemData?.value;
        });

        const maxValue = maxBy(data, item => item);
        const minValue = minBy(data, item => item);
        // if maxValue === minValue need to set diff more than 0 to have different min and max values for chart
        const diff = maxValue === minValue ? 0.5 : (maxValue - minValue) * 0.03;

        options.yaxis = [
          ...options.yaxis,
          {
            seriesName: objFuncName,
            axisTicks: {
              show: true,
            },
            min: minValue - diff,
            max: maxValue + diff,
            axisBorder: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
        ];

        return {
          name: objFuncName,
          type: !isSinglChart ? "line" : "area",
          data,
          color: !isSinglChart ? chartColors[index] : blue,
        };
      });

      options.tooltip = {
        y: {
          formatter: value => transfromFloatingNumbers(value),
        },
        marker: {
          fillColors: settingsColors,
        },
        custom: ({ dataPointIndex, seriesIndex }) => {
          return renderToStaticMarkup(
            <StyledChartTooltipContainer>
              <StyledChartTooltipTitle>
                Step: {dataPointIndex + 1}
              </StyledChartTooltipTitle>
              {series?.map((item, index) => {
                const value = item.data[dataPointIndex];
                return (
                  <div key={seriesIndex}>
                    <StyledChartTooltipOFName>
                      {item?.name}
                    </StyledChartTooltipOFName>
                    <StyledChartTooltipOFValue
                      color={isSinglChart ? darkBlue : chartColors[index]}
                    >
                      {transfromFloatingNumbers(value)}
                    </StyledChartTooltipOFValue>
                  </div>
                );
              })}
              <StyledChartTooltipOFName>
                Number of transactions
              </StyledChartTooltipOFName>
              <StyledChartTooltipOFValue color={gray1}>
                {resultList[dataPointIndex].number_of_transactions}
              </StyledChartTooltipOFValue>
            </StyledChartTooltipContainer>,
          );
        },
      };

      options.legend = {
        markers: {
          fillColors: settingsColors,
        },
      };

      options.markers = {
        colors: [white],
        strokeColors: settingsColors,
      };

      options.stroke = {
        curve: "smooth",
        colors: settingsColors,
        // first element is = objective function (always), should be wider than other
        width: isSinglChart ? 3 : [4, 2, 2, 2, 2, 2],
      };

      return { series, options };
    },
    [objective, resultList, objectives],
  );

  useEffect(() => {
    if (currentStep) {
      const currentGeneration = currentGenerations.find(
        generation => generation.step === currentStep,
      );
      setSelectedGenerationUUID(currentGeneration?.uuid);
    }
  }, [currentGenerations, setSelectedGenerationUUID, currentStep]);

  useEffect(() => {
    if (selectedPortfolio?.uuid && status && status !== PROGRESS_STATES.draft) {
      updateGenerationsList();
    }
    if (status && resultUUID) {
      updatePortfolioResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, selectedPortfolio?.uuid, step, resultUUID]);

  useEffect(() => {
    if (
      status &&
      status !== PROGRESS_STATES.inProgress &&
      status !== PROGRESS_STATES.draft
    ) {
      handleGetAllPortfolioConstraintsValue();
    }
  }, [status, handleGetAllPortfolioConstraintsValue]);

  return useMemo(
    () => ({
      isLoading,
      resultList,
      setResultList,
      columns,
      currentStep,
      setCurrentStep,
      getOverviewChartData,
      getStepNumberByGenerationId,
      currentResult,
    }),
    [
      columns,
      currentStep,
      isLoading,
      resultList,
      getOverviewChartData,
      getStepNumberByGenerationId,
      currentResult,
    ],
  );
};

export default usePortfolioResultData;
