import * as routes from "./routes";
import { StyledWorkbookIcon } from "../components/Icons";

export const WORKBOOKS_MENU_ITEMS = [
  {
    key: "created",
    path: "#created",
    label: "Created",
  },
  {
    key: "shared",
    path: "#shared",
    label: "Shared",
  },
  {
    key: "favourite",
    path: "#favourite",
    label: "Favourite",
  },
];

export const MENU_ITEMS = [
  {
    key: routes.FILTERING,
    path: routes.FILTERING,
    label: "Data upload",
  },
  {
    key: routes.CONSTRAINTS,
    path: routes.CONSTRAINTS,
    label: "Optimization Arguments",
  },
];

export const MAIN_MENU_ITEMS = [
  {
    key: "workbooks",
    path: routes.WORKBOOKS,
    label: "Workbooks",
    icon: <StyledWorkbookIcon />,
  },
];
