import PropTypes from "prop-types";
import _ from "lodash";
import { useCallback } from "react";
import { StyledConstraintsList, StyledConstraintsListItem } from "../styles";
import ConstraintsItem from "./ConstraintsItem";
import { CONSTRAINTS_INPUT_TYPE_VALUES } from "../../../constants/objectives";
import { NEW_CONSTRAINT_KEY } from "../../../constants/constraints";

const ConstraintsList = ({
  simple = false,
  canDelete = false,
  readOnly = false,
  loading = false,
  constraints = {},
  attributeError = [],
  setAttributeError,
  handleError,
  constraintsData,
  category,
  dataCy,
}) => {
  const { constraintsLoading } = constraintsData;

  const getType = useCallback(constraint => {
    if (constraint?.type === "boolean")
      return CONSTRAINTS_INPUT_TYPE_VALUES.BOOL;
    const { min, max, eq: eqValue } = constraint;
    if (eqValue) return CONSTRAINTS_INPUT_TYPE_VALUES.EQUAL;
    if (min !== null && max !== null)
      return CONSTRAINTS_INPUT_TYPE_VALUES.RANGE;
    if (min !== null) return CONSTRAINTS_INPUT_TYPE_VALUES.GREAT_OR_EQUAL;
    if (max !== null) return CONSTRAINTS_INPUT_TYPE_VALUES.LESS_OR_EQUAL;
    return CONSTRAINTS_INPUT_TYPE_VALUES.EQUAL;
  }, []);

  return (
    <StyledConstraintsList {...(dataCy && { "data-cy": dataCy })}>
      {_.map(constraints, (constraint, key) => (
        <StyledConstraintsListItem
          key={key}
          data-cy={`constraints-item-${key}`}
        >
          <ConstraintsItem
            isSimple={simple}
            canDelete={!simple && canDelete}
            readOnly={readOnly}
            valueFieldName={key}
            constraint={constraint}
            inputType={key === NEW_CONSTRAINT_KEY ? null : getType(constraint)}
            loading={!!constraintsLoading[key]}
            dataLoading={loading}
            constraintsData={constraintsData}
            category={category}
            attributeError={attributeError}
            setAttributeError={setAttributeError}
            handleError={handleError}
          />
        </StyledConstraintsListItem>
      ))}
    </StyledConstraintsList>
  );
};

ConstraintsList.propTypes = {
  constraintsData: PropTypes.shape().isRequired,
  simple: PropTypes.bool,
  canDelete: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  category: PropTypes.string,
  dataCy: PropTypes.string,
  constraints: PropTypes.shape(),
  attributeError: PropTypes.arrayOf(PropTypes.shape()),
  setAttributeError: PropTypes.func,
  handleError: PropTypes.func,
};

export default ConstraintsList;
