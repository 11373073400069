import { useMemo } from "react";
import { Skeleton } from "antd";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import PropTypes from "prop-types";
import * as routes from "../../constants/routes";
import {
  StyledMenuItem,
  StyledMenuActions,
  StyledSubHeader,
} from "../../layout/styles";
import {
  StyledLabelNumber,
  StyledMenuList,
  StyledLoadingTabs,
  StyledGenerateBtnContainer,
} from "./styled";
import { WORKBOOK_TYPE_VALUES } from "../../constants/workbooks";
import { PROGRESS_STATES } from "../../constants/constraints";
import StyledButton from "../../components/Buttons/Button.styled";
import usePortfolioData from "../../layout/ResultLayout/context/usePortfolioData";
import { StyledAnimatedSyncOutlineIcon } from "../../components/Icons";

const TabsHeader = ({ portfolioBuildProgress, isLoading, currentResult }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { workbook_id: wId, result_id: resultId } = useParams();
  const { workbookResults } = useOutletContext();
  const urlPath = `${routes.WORKBOOKS}/${wId}`;
  const status = portfolioBuildProgress?.status;
  const isDraft = status === PROGRESS_STATES.draft;
  const draftResult = workbookResults?.find(
    item => item?.status === PROGRESS_STATES.draft,
  );

  const hasData = isDraft
    ? Boolean(draftResult?.amountOfPortfolios)
    : Boolean(portfolioBuildProgress?.portfolios);

  const { onClickPortfolioBuild, startBuildLoading } = usePortfolioData({
    portfolioUUID: null,
    resultUUID: null,
  });

  const menuItems = useMemo(() => {
    let MENU_ITEMS = [
      {
        key: `${urlPath}/${routes.RESULTS}/${resultId}/${routes.FILTERING}`,
        path: `${routes.RESULTS}/${resultId}/${routes.FILTERING}`,
        label: "Data upload",
      },
    ];
    if (hasData) {
      MENU_ITEMS = [
        ...MENU_ITEMS,
        {
          key: `${urlPath}/${routes.RESULTS}/${resultId}/constraints`,
          path: `${routes.RESULTS}/${resultId}/constraints`,
          label: "Optimization Arguments",
        },
      ];
    }
    if (status && status === PROGRESS_STATES.draft) {
      return MENU_ITEMS;
    }
    MENU_ITEMS[0].label = "Uploaded data";
    MENU_ITEMS = [
      ...MENU_ITEMS,
      {
        key: `${urlPath}/${routes.RESULTS}/${resultId}`,
        path: `${routes.RESULTS}/${resultId}`,
        label: "Build Result",
      },
    ];
    const isSellReplaceEnabled =
      currentResult?.type === WORKBOOK_TYPE_VALUES.SELL_REPLACE;

    const showTradeSummary =
      isSellReplaceEnabled &&
      [PROGRESS_STATES.finished, PROGRESS_STATES.finishedPartially].includes(
        status,
      );
    if (showTradeSummary) {
      MENU_ITEMS = [
        ...MENU_ITEMS,
        {
          key: `${urlPath}/${routes.RESULTS}/${resultId}/${routes.RESULTS_TRADE_SUMMARY}`,
          path: `${routes.RESULTS}/${resultId}/${routes.RESULTS_TRADE_SUMMARY}`,
          label: "Trade Summary",
        },
      ];
    }
    return MENU_ITEMS;
  }, [status, hasData, urlPath, resultId, currentResult?.type]);

  return (
    <StyledSubHeader>
      <StyledMenuList data-cy="menu-list">
        {isLoading ? (
          <StyledLoadingTabs>
            <Skeleton.Button active />
            <Skeleton.Button active />
          </StyledLoadingTabs>
        ) : (
          <>
            <>
              {menuItems.map(({ key, path, label, labelNumber }) => {
                const isActive = location.pathname === key;
                return (
                  <StyledMenuItem
                    data-cy={`menu-list-item-${key}`}
                    key={key}
                    onClick={() => navigate(`${urlPath}/${path}`)}
                    active={isActive}
                  >
                    {labelNumber && (
                      <StyledLabelNumber isActive={isActive}>
                        <span>{labelNumber}</span>
                      </StyledLabelNumber>
                    )}
                    {label}
                  </StyledMenuItem>
                );
              })}
            </>
            <StyledGenerateBtnContainer>
              {isDraft &&
                hasData &&
                (startBuildLoading ? (
                  <StyledAnimatedSyncOutlineIcon />
                ) : (
                  <StyledButton
                    type="secondary"
                    $width={86}
                    $height={32}
                    $lowercase
                    $autoWidth
                    $noPadding
                    onClick={() => {
                      const urlLink = `${window.location.origin}/workbooks/${wId}/results/${resultId}`;
                      onClickPortfolioBuild(resultId, () =>
                        window.location.replace(urlLink),
                      );
                    }}
                  >
                    Generate
                  </StyledButton>
                ))}
              {!isDraft && (
                <StyledButton
                  type="secondary"
                  $width={106}
                  $height={32}
                  $lowercase
                  $autoWidth
                  $noPadding
                  onClick={() => {
                    const urlLink = `${window.location.origin}/workbooks/${wId}/results/${draftResult?.uuid}/constraints`;
                    window.location.replace(urlLink);
                  }}
                >
                  Latest Draft
                </StyledButton>
              )}
            </StyledGenerateBtnContainer>
          </>
        )}
      </StyledMenuList>
      <StyledMenuActions />
    </StyledSubHeader>
  );
};

TabsHeader.propTypes = {
  portfolioBuildProgress: PropTypes.shape(),
  currentResult: PropTypes.shape(),
  isLoading: PropTypes.bool,
};

export default TabsHeader;
