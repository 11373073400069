import styled, { css } from "styled-components/macro";
import { Select } from "antd";
import { maxScreensSizesPx } from "../../../styled";
import {
  accentRed,
  blue,
  blueHighlight,
  darkGrey2,
  darkGrey4,
  darkGrey5,
  darkGrey6,
  gray1,
  gray2,
  gray30,
  gray4,
  gray6,
  gray8,
  greenWhite,
} from "../../../../constants/colors";

export const StyledMenuList = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  align-items: stretch;
  justify-content: flex-end;

  ${props =>
    props.noreverse &&
    css`
      flex-flow: column nowrap;
      justify-content: flex-start;
    `}

  ${props =>
    props.borderRight &&
    css`
      border-right: 1px solid ${gray4};
    `}
`;

export const StyledIndustrySelect = styled(Select)`
  width: 100%;
`;

export const StyledMenuItemIcon = styled.div`
  display: ${props => (props.showIcon ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  flex: 0 0 48px;
  padding: 10px 0;

  svg path {
    fill: currentColor;
  }

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    display: flex;
  }
`;

export const StyledMenuItemExtra = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 0 1 auto;
  margin-left: auto;
  padding: 0.5rem;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    display: none;
  }
`;

export const StyledMenuItemContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 0 1 auto;
  padding: ${props => (props.showIcon ? "0.25rem 0" : "0.25rem 1rem")};
  overflow: hidden;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    display: none;
  }
`;

export const StyledMenuItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  border-radius: 0.5rem;
  min-height: 42px;
  margin: 0.1rem 0;
  position: relative;
  transition: background-color 0.3s ease;

  ${props =>
    props.header &&
    css`
      margin-bottom: 1rem;
    `};

  &:hover {
    background-color: ${props =>
      props.header ? "transparent" : blueHighlight};
  }

  ${props =>
    props.active &&
    css`
      color: ${blue};
      background-color: ${blueHighlight};
    `}
`;

export const StyledMenuTitle = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: inherit;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${props =>
    props.header &&
    css`
      font-weight: 700;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: ${darkGrey2};
    `}
`;

export const StyledAccordionMenuItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  position: relative;
  min-height: 34px;
  margin-left: 0.15rem;
  color: ${darkGrey5};

  ${props =>
    props.header &&
    css`
      margin-bottom: 1rem;
    `};

  &:before {
    content: "";
    display: block;
    background-color: transparent;
    border-radius: 500rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 0.25rem;
    width: 0.25rem;
    transition: background-color 0.3s ease;
  }

  ${props =>
    props.active &&
    css`
      ${StyledMenuItemIcon},
      ${StyledMenuTitle} {
        color: ${blue};
        font-weight: 700;
      }

      &:before {
        background-color: ${blue};
      }
    `}
`;

export const StyledMenuHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 15px;

  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    display: none;
  }
`;

export const StyledMenuValue = styled.div`
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${darkGrey4};
  margin: 0 10px;

  ${props =>
    props.highlight &&
    css`
      color: ${greenWhite};
      font-weight: 600;
    `}

  ${props =>
    props.isLighter &&
    css`
      color: ${gray6};
      font-weight: 500;
    `}
`;

export const StyledSectionContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  background-color: ${gray1};
  padding: 20px;
`;

export const StyledSectionList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, auto));
  gap: 1rem;
`;

export const StyledSectionRow = styled.div`
  --grid-layout-gap: 1rem;
  --grid-column-count: ${props => (props.$columns ? props.$columns : 2)};
  --grid-item--min-width: 300px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
  width: 100%;
`;

export const StyledSectionColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  ${props =>
    props.fullwidth &&
    css`
      grid-column: -1 / 1;
    `}
`;

export const StyledTitleArea = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: ${maxScreensSizesPx.mobile}) {
    margin-top: 1rem;
  }
`;

export const StyledTitleActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  @media (max-width: ${maxScreensSizesPx.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    row-gap: 10px;
    margin-top: 10px;
  }
`;

export const StyledStatisticContainer = styled.div`
  --grid-layout-gap: 1rem;
  --grid-column-count: 4;
  --grid-item--min-width: 300px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
  width: 100%;

  @media (max-width: ${maxScreensSizesPx.mobile}) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

export const StyledErrorCardContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
`;

export const StyledErrorCardBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledErrorCardList = styled.ol`
  margin: 0 0 0.5rem;
  padding: 0 0 0 1rem;
`;

export const StyledErrorCardListItem = styled.li`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: ${darkGrey6};
`;

export const StyledErrorCardListItemBody = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledErrorConstraintName = styled.span`
  font-style: italic;
  font-weight: 500;
`;

export const StyledErrorConstraintNumber = styled.span`
  color: ${accentRed};
  font-weight: 500;
`;

export const StyledErrorConstraintPercent = styled.span`
  font-weight: 500;
`;

export const StyledErrorCardText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  color: ${darkGrey6};
  margin-bottom: 0.5rem;
`;

export const StyledErrorCardLink = styled(StyledErrorCardText)`
  color: ${blue};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledErrorCardTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: ${accentRed};
  margin: 0.5rem 0;
`;

export const StyledList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StyledListItem = styled.li`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  text-transform: capitalize;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${darkGrey4};

  &:nth-child(odd) {
    background-color: ${gray2};
  }
`;

export const StyledListTitle = styled(StyledListItem)`
  color: ${darkGrey2};
  background-color: white !important;
  border-bottom: 1px solid ${gray2};
`;

export const StyledListTotal = styled(StyledListItem)`
  color: ${darkGrey2};
  font-size: 14px;
`;

export const StyledTableCell = styled.div`
  color: ${({ $isEnabled }) => ($isEnabled ? darkGrey6 : gray8)};
`;

export const StyledTableCellTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 14px;
`;

export const StyledTableCellContent = styled(StyledTableCell)`
  margin: 10px 0;
`;

export const StyledTableCellDivider = styled("div")`
  width: 100%;
  height: 1px;
  background-color: ${gray30};
`;

export const StyledTableCellRow = styled("div")`
  display: flex;
  gap: 8px;
`;

export const StyledTableFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

export const StyledEmptyView = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 24px;
  padding-top: 90px;
  background-color: ${gray1};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${darkGrey5};
`;

export const StyledObjectiveRow = styled.div`
  display: flex;
  column-gap: 8px;
  margin-bottom: 8px;
  margin-left: 52px;
  color: ${darkGrey4};
`;

export const StyledLoadingSidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0px 1rem;
  box-sizing: border-box;
  height: calc(100ch - 0px);
  overflow: hidden;

  .ant-skeleton.ant-skeleton-element .ant-skeleton-input {
    height: 24px;
    width: 100%;
  }
`;
