import {
  createContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import useQuery from "../../../hooks/useQuery";
import { getResultViews } from "../../../api/endpoints/results";
import { handleErrorMessages } from "../../../utils/messages";

import { PROGRESS_STATES } from "../../../constants/constraints";

export const Context = createContext({});

export const Provider = ({ children }) => {
  const { query, setQuery } = useQuery();
  const { result_id: resultId, workbook_id: workbookId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [workbookResults, setWorkbookResults] = useState([]);
  const [portfolioType, setPortfolioType] = useState("");
  const [amountOfPortfolios, setAmountOfPortfolios] = useState(0);
  const [page, setPage] = useState(+query.get("page") || 1);
  const [total, setTotal] = useState(0);
  const [paginationData, setPaginationData] = useState({
    size: "small",
    showSizeChanger: false,
    position: "bottomRight",
    current: page || 1,
    defaultCurrent: 1,
    total: total || workbookResults.length || 0,
  });

  const onPageChange = useCallback(
    currentPage => {
      setQuery("page", currentPage);
      setPage(currentPage);
    },
    [setQuery],
  );

  useEffect(() => {
    const currentPage = query.get("page");
    setPage(+currentPage || 1);
  }, [query]);

  useEffect(() => {
    setPaginationData(data => ({ ...data, current: page, total }));
  }, [page, total]);

  const getWorkbookResults = useCallback(
    ({ page: newPage, filter = "" }) => {
      setIsLoading(true);
      getResultViews({ page: newPage, workbookId, filter })
        .then(({ items: results, total: totalItems }) => {
          setWorkbookResults(results);
          setTotal(totalItems);
          const currResult = results?.length
            ? results?.find(result => result?.uuid === resultId)
            : null;

          const draftResult = results.find(
            result => result.status === PROGRESS_STATES.draft,
          );
          const type = currResult ? currResult?.type : draftResult?.type;
          setPortfolioType(type);
          setAmountOfPortfolios(
            currResult
              ? currResult?.amountOfPortfolios
              : draftResult?.amountOfPortfolios,
          );
        })
        .catch(error => {
          const { status } = error.response;
          if (status !== 403) {
            handleErrorMessages(error);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [workbookId, resultId],
  );

  useEffect(() => {
    getWorkbookResults({ page });
  }, [page, getWorkbookResults]);

  const contextData = useMemo(
    () => ({
      isLoading,
      workbookResults,
      getWorkbookResults,
      paginationData,
      onPageChange,
      portfolioType,
      amountOfPortfolios,
    }),
    [
      getWorkbookResults,
      isLoading,
      onPageChange,
      paginationData,
      workbookResults,
      portfolioType,
      amountOfPortfolios,
    ],
  );

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.defaultProps = {};
Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
