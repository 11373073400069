import styled from "styled-components/macro";
import { Layout as AntLayout } from "antd";

export const StyledLayout = styled(AntLayout)`
  &&& {
    background: inherit;
    overflow: visible;
    overflow-x: visible;
  }

  .ant-layout-content {
    min-height: auto;
  }
`;

export default StyledLayout;
