import PropTypes from "prop-types";
import { useOutletContext } from "react-router";
import { useEffect, useState, useCallback } from "react";

import {
  StyledPageHeader,
  StyledPageHeaderScrollContainer,
  StyledPageHeaderTitle,
  StyledTitleContainer,
} from "../styled";
import WorkbookLabel from "../../components/WorkbookLabel";
import {
  WORKBOOK_TYPES,
  WORKBOOK_TYPE_VALUES,
} from "../../constants/workbooks";
import Breadcrumbs from "./Breadcrumbs";
import { StyledTextButton } from "../../components/Buttons/Button.styled";
import { StyledEditOutlineIcon, StyledShareIcon } from "../../components/Icons";
import EditWorkbookModal from "../Workbooks/content/EditWorkbookModal";
import ShareWorkbookModal from "../Workbooks/content/ShareWorkbookModal";

const MODALS = {
  share: "SHARE",
  edit: "EDIT",
};

const ActionsHeader = ({
  title = "",
  children,
  isLoading = false,
  breadcrumbsConfig = null,
  isRightSideChildren = false,
  currentResult = null,
  showLabes = true,
  postShareCallBack = () => {},
}) => {
  const [workbookCaseLabel, setWorkbookCaseLabel] = useState(null);
  const [modalOpen, setModalOpen] = useState("");
  const {
    portfolioType,
    amountOfPortfolios,
    workbookData,
    handleGetWorkbook,
    currentWorkbook,
  } = useOutletContext();
  const type = currentResult?.type || portfolioType;

  const { onEditWorkbook, onShareWorkbook } = workbookData;

  const openEditModal = useCallback(() => {
    setModalOpen(MODALS.edit);
  }, []);

  const openShareModal = useCallback(() => {
    setModalOpen(MODALS.share);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen("");
  }, []);

  useEffect(() => {
    let caseLabel;

    switch (type) {
      case WORKBOOK_TYPE_VALUES.MIXED:
        caseLabel = WORKBOOK_TYPES.MIXED;
        break;
      case WORKBOOK_TYPE_VALUES.SELL_REPLACE:
        caseLabel = WORKBOOK_TYPES.SELL_REPLACE;
        break;
      case WORKBOOK_TYPE_VALUES.RAMP_UP:
        caseLabel = WORKBOOK_TYPES.RAMP_UP;
        break;
      default:
        break;
    }
    setWorkbookCaseLabel(caseLabel);
  }, [type]);

  const workbookLabel =
    amountOfPortfolios > 1
      ? WORKBOOK_TYPES.MULTI_PORTFOLIO
      : WORKBOOK_TYPES.SINGLE_PORTFOLIO;

  return (
    <StyledPageHeaderScrollContainer>
      <StyledPageHeader>
        {breadcrumbsConfig && !isLoading && (
          <Breadcrumbs config={breadcrumbsConfig} />
        )}
        <StyledTitleContainer>
          {!isRightSideChildren && children}
          {title && <StyledPageHeaderTitle>{title}</StyledPageHeaderTitle>}
          {!isLoading && showLabes && (
            <>
              <WorkbookLabel label={workbookLabel} />
              <WorkbookLabel label={workbookCaseLabel} />
            </>
          )}
          {isRightSideChildren && children}
          <StyledTextButton
            type="text"
            icon={<StyledEditOutlineIcon />}
            onClick={openEditModal}
            $iconOnly
          />
          <StyledTextButton
            type="text"
            icon={<StyledShareIcon />}
            onClick={openShareModal}
            $iconOnly
          />
        </StyledTitleContainer>
      </StyledPageHeader>
      <EditWorkbookModal
        isOpen={modalOpen === MODALS.edit}
        onClose={closeModal}
        handleGetWorkbook={handleGetWorkbook}
        onEditWorkbook={onEditWorkbook}
        workbook={currentWorkbook}
      />
      <ShareWorkbookModal
        isOpen={modalOpen === MODALS.share}
        onClose={closeModal}
        onShareWorkbook={onShareWorkbook}
        postShareCallBack={postShareCallBack}
        workbook={currentWorkbook}
      />
    </StyledPageHeaderScrollContainer>
  );
};

ActionsHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  isLoading: PropTypes.bool,
  showLabes: PropTypes.bool,
  isRightSideChildren: PropTypes.bool,
  breadcrumbsConfig: PropTypes.arrayOf(PropTypes.shape()),
  currentResult: PropTypes.shape(),
  postShareCallBack: PropTypes.func,
};

export default ActionsHeader;
