import styled from "styled-components/macro";

export const StyledLoading = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;

  .anticon-loading {
    display: inline-flex;
    margin: auto;

    svg {
      margin: auto;
    }
  }
`;

export default StyledLoading;
