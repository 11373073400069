import { PageContextProvider as ResultDetailsContextProvider } from "../../features/ResultDetails/context";
import ResultDetailsContent from "./content";

const ResultLayout = () => (
  <ResultDetailsContextProvider>
    <ResultDetailsContent />
  </ResultDetailsContextProvider>
);

export default ResultLayout;
