import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import _ from "lodash";
import SelectRenderer from "../../components/Form/AgGridIncludeSelect";

import { handleErrorMessages, showMessageSuccess } from "../../utils/messages";
import { createWorkbookResultView } from "../../api/endpoints/results";
import { getWorkbookLoanGroup } from "../../api/endpoints/workbooks";
import { COLUMN_FORMATTING } from "../../constants/objectives";

const defFunction = () => true;

const useFiltersData = ({
  workbookId,
  loadDataFunction = getWorkbookLoanGroup,
  refreshPortfolioData = defFunction,
  disableInputs = false,
  showCheckboxes = true,
}) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState(null);

  const gridRef = useRef();

  const loadFile = useCallback(
    () => loadDataFunction(workbookId),
    [loadDataFunction, workbookId],
  );

  const onFilteredDataSave = async () => {
    setIsSaving(true);
    const fileForm = new FormData();
    const file = gridRef.current.api.getDataAsCsv();
    const blob = new Blob([file], { type: "text/csv;charset=utf-8;" });
    fileForm.append("file", blob, "filtered_data.csv");

    createWorkbookResultView(workbookId, fileForm)
      .then(() =>
        showMessageSuccess({
          text: "Data Table was updated!",
        }),
      )
      .finally(() => setIsSaving(false))
      .catch(error => handleErrorMessages(error));
  };

  const uncheckIncludedLoans = useCallback(() => {
    setData(currentData =>
      _.map(currentData, thisData => ({ ...thisData, Include: false })),
    );
  }, []);

  const onFileUploaded = useCallback(
    ({ refreshData = true }) => {
      setIsLoading(true);
      loadFile()
        .then(resp => {
          const columns = _.map(resp.columns, "field");
          const fileData = _.map(resp.data, row => _.zipObject(columns, row));
          setData(fileData);

          const defaultColumnDefs = resp.columns
            ? resp.columns?.map(item => {
                return _.omit(item, ["objective_field", "objective_key"]);
              })
            : [];
          setColumnDefs(defaultColumnDefs);
          if (refreshData && fileData.length > 0) {
            refreshPortfolioData();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [loadFile, refreshPortfolioData],
  );

  useEffect(() => {
    if (!workbookId) return;
    onFileUploaded({ refreshData: true });
  }, [workbookId, onFileUploaded]);

  const filterParams = {
    comparator: (inputDate, cellValue) => {
      const cellDate = new Date(Date.parse(cellValue));

      if (inputDate.getDate() === cellDate.getDate()) {
        return 0;
      }

      return inputDate < cellDate ? -1 : 1;
    },
    browserDatePicker: true,
    buttons: ["reset"],
  };

  const defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true,
  };

  const processedColumnDefs = _.map(columnDefs, (val, index) => {
    const isFirstCol = index === 0;

    const res = {
      ...val,
      checkboxSelection: isFirstCol && showCheckboxes,
      headerCheckboxSelection: isFirstCol && showCheckboxes,
      headerCheckboxSelectionFilteredOnly: isFirstCol,
    };
    const formatter = COLUMN_FORMATTING[res.field];
    if (formatter) {
      res.valueFormatter = params => formatter.format(params.value);
    }
    if (res.filter === "agDateColumnFilter") {
      res.filterParams = filterParams;
      res.cellClass = "agDateField";
    } else {
      res.filterParams = { buttons: ["reset"] };
    }
    if (res.field === "Include") {
      res.cellRenderer = cellData =>
        showCheckboxes
          ? SelectRenderer({ ...cellData, disableInputs })
          : _.capitalize(cellData.value);
      res.cellClass = "grid-cell-centered";
      res.headerTooltip = "Include loan into the final portfolio result.";
    }
    return res;
  });

  processedColumnDefs.frameworkComponents = {
    selectRenderer: cellData => SelectRenderer({ ...cellData, disableInputs }),
  };

  const getTopLoanAgents = useCallback(() => {
    const results = {};

    data?.forEach(item => {
      const itemData = item["Ln Agent"] || "None";
      results[itemData] ||= 0;
      results[itemData] += 1;
    });

    const resultData = [];
    for (const [key, value] of Object.entries(results)) {
      resultData.push({
        agent: key,
        shares: Number((value * 100) / data.length).toFixed(2),
      });
    }

    const top10Items = resultData
      .sort((item1, item2) => item1.shares - item2.shares)
      .slice(0, 10);

    const total = top10Items.reduce((sum, cur) => sum + +cur.shares || 0, 0);

    return {
      data: top10Items.map(agent => ({ ...agent, shares: `${agent.shares}%` })),
      total: Number(total).toFixed(2),
    };
  }, [data]);

  const topLoanAgents = useMemo(() => getTopLoanAgents(), [getTopLoanAgents]);

  return {
    data,
    gridRef,
    isLoading,
    isSaving,
    uncheckIncludedLoans,
    onFilteredDataSave,
    defaultColDef,
    processedColumnDefs,
    onFileUploaded,
    workbookId,
    topLoanAgents,
    columnDefs,
  };
};

export default useFiltersData;
