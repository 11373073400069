import PropTypes from "prop-types";
import * as d3 from "d3";
import { maxBy, minBy } from "lodash";
import { useMemo } from "react";

import AxisLeft from "./AxisLeft";
import AxisBottom from "./AxisBottom";
import Tooltip from "./Tooltip";
import { darkGrey3, white, gray1 } from "../../../../../constants/colors";

const Scatterplot = ({
  width = 740,
  height = 110,
  data,
  objectiveFunc,
  handleSelectOption,
  selectedDataPoint,
  compareDataPoint,
  isLast = false,
  setHovered,
  hovered,
}) => {
  const chartHeght = isLast ? height + 65 : height;
  const activeColor = objectiveFunc?.color;
  const lightColor = objectiveFunc?.tooltipColor;
  const BOUND_HEGHT_MARGIN = 8;
  const MARGIN = { top: 20, right: 10, bottom: isLast ? 75 : 14, left: 10 };
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = chartHeght - MARGIN.top - MARGIN.bottom;

  const minX = minBy(data, item => item?.x)?.x;
  const maxX = maxBy(data, item => item?.x)?.x;
  const minY = minBy(data, item => item?.y)?.y;
  const maxY = maxBy(data, item => item?.y)?.y;

  let diffY = ((maxY - minY) / 100) * 10;

  if (maxY === minY) {
    diffY = maxY > 10 ? 5 : (maxY / 100) * 10;
  }
  const pixelsPerTickX = width / (maxX - minX + 3);
  const pixelsPerTickY = 1;

  const yScale = d3
    .scaleLinear()
    .domain([minY - diffY - diffY, maxY + diffY])
    .range([boundsHeight, 0]);

  const xScale = d3
    .scaleLinear()
    .domain([minX - 1, maxX + 1])
    .range([0, boundsWidth]);

  const tooltipData = useMemo(() => {
    let tooltipsData = [];
    if (hovered) {
      const tooltipPoint = data?.find(
        item => item?.x === hovered?.transactions,
      );
      tooltipsData = [...tooltipsData, tooltipPoint];
    }
    if (
      selectedDataPoint &&
      selectedDataPoint?.objFunc === objectiveFunc?.value
    ) {
      const tooltipPoint = data?.find(
        item => item?.x === selectedDataPoint?.transactions,
      );
      tooltipsData = [...tooltipsData, { ...tooltipPoint, isLight: false }];
    }

    if (
      compareDataPoint &&
      compareDataPoint?.objFunc === objectiveFunc?.value
    ) {
      const tooltipPoint = data?.find(
        item => item?.x === compareDataPoint?.transactions,
      );
      tooltipsData = [...tooltipsData, { ...tooltipPoint, isLight: false }];
    }
    return tooltipsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered, selectedDataPoint, compareDataPoint]);

  const allShapes = data.map((diameter, index) => {
    const isActive =
      (hovered?.index === index && hovered?.name === objectiveFunc?.label) ||
      (selectedDataPoint?.index === index &&
        objectiveFunc?.value === selectedDataPoint?.objFunc);

    const color = isActive ? activeColor : lightColor;
    const cxValue = xScale(diameter?.x);
    const cyValue = yScale(diameter?.y);

    const isActiveGroup =
      hovered?.name === objectiveFunc?.label ||
      hovered?.transactions === diameter?.x ||
      (selectedDataPoint?.objFunc === objectiveFunc?.value &&
        objectiveFunc?.labelhovered?.name === objectiveFunc?.label);
    const isComparative = compareDataPoint?.transactions === diameter?.x;
    const isActiveGroupSelected =
      selectedDataPoint?.transactions === diameter?.x;

    const groupColor =
      (!hovered && !selectedDataPoint?.transactions) ||
      isActiveGroup ||
      isComparative ||
      isActiveGroupSelected
        ? activeColor
        : lightColor;

    const strokeColor =
      isActiveGroup || isComparative || isActiveGroupSelected ? white : gray1;
    const strokeWidth =
      isActiveGroup || isComparative || isActiveGroupSelected ? 2 : 0;

    const isComparativePoint =
      compareDataPoint?.index === index &&
      objectiveFunc?.value === compareDataPoint?.objFunc;

    const isSelectedPoint =
      selectedDataPoint?.index === index &&
      objectiveFunc?.value === selectedDataPoint?.objFunc;

    const isHoverPoint =
      hovered?.index === index && hovered?.name === objectiveFunc?.label;

    return (
      <g
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        onMouseOver={() =>
          setHovered({
            xPos: xScale(diameter.x),
            yPos: yScale(diameter.y),
            itemSolutions: diameter.itemSolutions,
            name: objectiveFunc?.label,
            value: diameter.y,
            index,
            transactions: diameter.x,
          })
        }
        onClick={() => {
          handleSelectOption({ objFunc: objectiveFunc?.value, index });
        }}
        onMouseLeave={() => setHovered(null)}
        onMouseOut={() => setHovered(null)}
        style={{
          cursor: "pointer",
        }}
      >
        {isHoverPoint || isSelectedPoint || isComparativePoint ? (
          <>
            {isHoverPoint && (
              <>
                <circle
                  cx={cxValue}
                  cy={cyValue}
                  r="10"
                  stroke={lightColor}
                  strokeWidth="4"
                  fill={lightColor}
                />
                <circle
                  cx={cxValue}
                  cy={cyValue}
                  r="14"
                  stroke={white}
                  strokeWidth="3"
                  fill={lightColor}
                />
                <circle cx={cxValue} cy={cyValue} r="6" fill={activeColor} />
              </>
            )}
            {isComparativePoint && (
              <>
                <circle
                  cx={cxValue}
                  cy={cyValue}
                  r="8"
                  stroke={activeColor}
                  strokeWidth="2"
                  fill={white}
                />
                <circle
                  cx={cxValue}
                  cy={cyValue}
                  r="10"
                  stroke={activeColor}
                  strokeWidth="2"
                  fill={white}
                />
                <circle cx={cxValue} cy={cyValue} r="6" fill={activeColor} />
              </>
            )}
            {isSelectedPoint && (
              <>
                <circle
                  cx={cxValue}
                  cy={cyValue}
                  r="14"
                  stroke={color}
                  strokeWidth="2"
                  fill={white}
                />
                <circle
                  cx={cxValue}
                  cy={cyValue}
                  r="8"
                  stroke={color}
                  strokeWidth="2"
                  fill={white}
                />
                <circle
                  cx={cxValue}
                  cy={cyValue}
                  r="10"
                  stroke={color}
                  strokeWidth="2"
                  fill={white}
                />
                <circle cx={cxValue} cy={cyValue} r="6" fill={color} />
              </>
            )}
          </>
        ) : (
          <circle
            r={8}
            cx={cxValue}
            cy={cyValue}
            opacity={1}
            stroke={strokeColor}
            fill={groupColor}
            strokeWidth={strokeWidth}
          />
        )}
      </g>
    );
  });

  return (
    <div
      style={{ position: "relative", height: chartHeght }}
      onMouseLeave={() => setHovered(null)}
    >
      <svg
        width={width}
        height={chartHeght}
        style={{ position: "relative", zIndex: 1 }}
      >
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          <AxisLeft
            yScale={yScale}
            pixelsPerTick={pixelsPerTickY}
            width={boundsWidth}
          />
          <g transform={`translate(0, ${boundsHeight + BOUND_HEGHT_MARGIN})`}>
            <AxisBottom
              xScale={xScale}
              pixelsPerTick={pixelsPerTickX}
              height={boundsHeight + BOUND_HEGHT_MARGIN}
              isLast={isLast}
              hovered={hovered}
              selectedDataPoint={selectedDataPoint}
              compareDataPoint={compareDataPoint}
            />
          </g>
          <g shapeRendering="crispEdges">
            <text
              style={{
                fontSize: "10px",
                textTransform: "uppercase",
                textAnchor: "left",
                transform: "translate(0px, -10px)",
                fill: `${darkGrey3}`,
              }}
            >
              {objectiveFunc?.label}
            </text>
          </g>
          {allShapes}
        </g>
      </svg>
      {Boolean(tooltipData?.length) &&
        tooltipData.map((tooltip, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              width: boundsWidth,
              height: boundsHeight,
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
              marginLeft: 5,
              marginTop: -8,
            }}
          >
            <Tooltip
              tooltipData={tooltip}
              color={objectiveFunc?.tooltipColor}
              xPos={xScale(tooltip?.x)}
              yPos={yScale(tooltip?.y)}
            />
          </div>
        ))}
    </div>
  );
};

Scatterplot.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape()),
  objectiveFunc: PropTypes.shape(),
  handleSelectOption: PropTypes.func,
  selectedDataPoint: PropTypes.shape(),
  compareDataPoint: PropTypes.shape(),
  isLast: PropTypes.bool,
  setHovered: PropTypes.func,
  hovered: PropTypes.shape({
    xPos: PropTypes.number,
    yPos: PropTypes.number,
    itemSolutions: PropTypes.arrayOf(PropTypes.shape()),
    name: PropTypes.string,
    value: PropTypes.number,
    index: PropTypes.number,
    transactions: PropTypes.number,
  }),
};

export default Scatterplot;
