import { Outlet } from "react-router";
import { useContext } from "react";
import { PageContext as ResultLayoutContext } from "../../ResultLayout/context";
import { PageContext as ResultListContext } from "../../../features/ResultList/context";
import { PageContext as WorkbooksContext } from "../../../features/Workbooks/context";
import { StyledLayout, StyledLayoutContent } from "../../styles";

const WorkbookContent = () => {
  const resultLayoutContext = useContext(ResultLayoutContext);
  const resultListContext = useContext(ResultListContext);
  const workbooksContext = useContext(WorkbooksContext);

  return (
    <StyledLayout>
      <StyledLayoutContent>
        <Outlet
          context={{
            ...resultLayoutContext,
            ...resultListContext,
            ...workbooksContext,
          }}
        />
      </StyledLayoutContent>
    </StyledLayout>
  );
};

export default WorkbookContent;
