import PropTypes from "prop-types";
import { Select } from "antd";
import { Histogram } from "@ant-design/plots";
import StyledCard from "../../../../../components/Card";
import { StyledChartWrapper } from "../../../styles";
import { StyledIndustrySelect } from "../styles";
import Checkbox from "../../../../../components/Form/Checkbox";

const { Option } = Select;

const BarChartCard = ({ chartsData }) => {
  const {
    histConfig,
    onIndustryChange,
    onDistColumnChange,
    industry,
    industries,
    distColumn,
  } = chartsData;

  const industryTypesOptions = ["All", ...industries];

  const handleChangeIndustryValue = value => {
    const filteredValues = value.filter(item => item !== "All");

    switch (true) {
      case value.includes("All") && !industry.includes("All"):
        onIndustryChange(industryTypesOptions);
        break;
      case value.includes("All") &&
        industry.length === industryTypesOptions.length:
        onIndustryChange(filteredValues);
        break;
      default:
        onIndustryChange(value);
        break;
    }
  };

  const industryValue = typeof industry === "string" ? [industry] : industry;
  const histActions = (
    <>
      <StyledIndustrySelect
        mode="multiple"
        placeholder="Select an attribute"
        onChange={handleChangeIndustryValue}
        onDeselect={value => {
          if (value === "All") {
            onIndustryChange([]);
          }
        }}
        value={industryValue}
        allowClear
        menuItemSelectedIcon={null}
        maxTagCount="responsive"
      >
        {industryTypesOptions.map(type => (
          <Option key={type}>
            <Checkbox
              type="custom"
              key={type}
              value={industryValue.includes(type)}
              disabled
            >
              {type}
            </Checkbox>
          </Option>
        ))}
      </StyledIndustrySelect>

      <Select
        showSearch
        placeholder="Select an attribute"
        optionFilterProp="children"
        value={distColumn}
        onChange={onDistColumnChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
      >
        <Option value="warf">WARF</Option>
        <Option value="years_tm">Maturity</Option>
      </Select>
    </>
  );

  return (
    <StyledCard
      grow
      dataCy="result-histogram"
      title="Distribution"
      actions={histActions}
    >
      <StyledChartWrapper>
        <Histogram {...histConfig} />
      </StyledChartWrapper>
    </StyledCard>
  );
};

BarChartCard.propTypes = {
  chartsData: PropTypes.shape(),
};

export default BarChartCard;
