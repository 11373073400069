import styled from "styled-components/macro";

export const StyledDateFilterContainer = styled.form`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 18px;
`;

export const StyledTableFilterContainer = styled(StyledDateFilterContainer)`
  align-items: stretch;
`;
