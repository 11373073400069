import { Table } from "antd";
import PropTypes from "prop-types";
import { StyledTableWrapper } from "./styles";
import { PROGRESS_STATES } from "../../constants/constraints";

const StyledTable = ({
  handleSelectRow = null,
  defaultSelectedRowKeys = [],
  ...props
}) => {
  const rowSelection = {
    onChange: selectedRowKeys => {
      if (handleSelectRow) {
        handleSelectRow(selectedRowKeys);
      }
    },
    getCheckboxProps: record => {
      if (record.status === PROGRESS_STATES.draft)
        return {
          disabled: true,
          style: { visibility: "hidden" },
        };
      return {
        disabled: false,
      };
    },
  };

  return (
    <StyledTableWrapper>
      <div className={defaultSelectedRowKeys?.length ? "checkbox-visible" : ""}>
        <Table
          {...props}
          rowSelection={
            handleSelectRow
              ? {
                  type: "checkbox",
                  ...rowSelection,
                  defaultSelectedRowKeys,
                }
              : null
          }
        />
      </div>
    </StyledTableWrapper>
  );
};

StyledTable.propTypes = {
  handleSelectRow: PropTypes.func,
  defaultSelectedRowKeys: PropTypes.arrayOf(PropTypes.string),
};

export default StyledTable;
