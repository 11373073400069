import * as Sentry from "@sentry/browser";
import { showMessageError } from "../../utils/messages";
import { parseServerErrorDetails } from "../../utils/parseServerErrorDetails";

function isNetworkError(err) {
  return !!err.isAxiosError && !err.response;
}

const invalidTokenText =
  "You don't have the permission to access the requested resource. It is either read-protected or not readable by the server.";

const errorsHandling = () => axios => {
  return axios.interceptors.response.use(
    response => response,
    error => {
      const status = error?.response?.status;
      const textMessage = error?.response?.data?.message;

      const errorType =
        error?.response?.data?.detail?.[0]?.type ||
        error?.response?.data?.detail?.errors?.[0]?.type;

      if (errorType === "data_aware.error") {
        return Promise.reject(error);
      }

      if (window.Cypress) {
        showMessageError({ text: JSON.stringify(error) });
      }

      if (isNetworkError(error)) {
        showMessageError({
          code: -1,
          text: textMessage,
        });
      }
      // on settings page we have change password logic. For wrong password will be 401 error status so nedd to avoid redirection and logout
      if (
        status === 401 &&
        window.location.pathname !== "/settings" &&
        window.location.pathname !== "/"
      ) {
        window.location.replace(`${window.location.origin}/`);
        localStorage.removeItem("accessToken");
      }

      if (status === 403) {
        Sentry.captureMessage(textMessage || error?.message);
        if (textMessage === invalidTokenText) {
          // OKTA should support token refresh
          // refresh okta token
        }
      } else if (status === 422) {
        const errorMsg =
          error?.response?.data?.detail?.[0]?.msg ||
          error?.response?.data?.detail;
        const errorsDetails = parseServerErrorDetails(errorMsg);

        showMessageError({
          code: status,
          text: errorsDetails,
        });
      } else if (status === 400) {
        Sentry.captureMessage(textMessage || error?.message);
        showMessageError({
          code: status,
          text: error?.response?.data?.detail || textMessage,
        });
      } else if (status === 502) {
        Sentry.captureMessage(textMessage || error?.message);
        showMessageError({
          code: status,
          text: textMessage,
        });
      } else {
        Sentry.captureException(error);
      }

      return Promise.reject(error);
    },
  );
};

export default errorsHandling;
