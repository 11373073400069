import { useOktaAuth } from "@okta/okta-react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import Loading from "../Loading";
import showMessageError from "../../constants/errors";

const login = async (oktaAuth, path = "/") => {
  await oktaAuth.signInWithRedirect({ originalUri: path });
};

const logout = async oktaAuth => {
  await oktaAuth.signOut();
  window.location.replace(`${window.location.origin}`);
};

const ProtectedArea = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const isOktaAuthFlow = process.env.REACT_APP_AUTH_FLOW === "okta";
  const token = localStorage.getItem("accessToken");
  const location = useLocation();

  const isExluded = ["/", "/login"].includes(location.pathname);

  const isAuth = isOktaAuthFlow
    ? authState?.isAuthenticated ||
      isExluded ||
      process.env.REACT_APP_FAKE_TOKEN
    : token;

  if (isOktaAuthFlow && !authState) return <Loading />;
  if (isAuth || !isOktaAuthFlow) {
    if (!isOktaAuthFlow && !token && authState?.isAuthenticated) {
      logout(oktaAuth);
    }
    return <Outlet />;
  }

  login(oktaAuth, location.pathname).catch(error => {
    const text = error.message
      ? `${error.message} Please try again or contact support.`
      : "There is an issue with your login. Please try again or contact support.";
    showMessageError({
      text,
    });
  });
  return null;
};

export default ProtectedArea;
