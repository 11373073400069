import { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import ModalDialog from "../../../components/Modals/ModalDialog";
import { StyledField, StyledInputLabel } from "../../../components/Form/styles";
import { PageContext } from "../context";
import StyledButton from "../../../components/Buttons/Button.styled";
import { StyledModalButtonsContainer } from "../../../components/Modals/ModalDialog.styled";
import { removeExtraWhiteSpaces } from "../../../utils/stringFormatters";

const AddWorkbookModal = ({ isOpen = false, onClose }) => {
  const { workbookData } = useContext(PageContext);
  const { onAddWorkbook } = workbookData;

  const [workBookName, setWorkBookName] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputElement, setInputElement] = useState(null);

  const closeModal = useCallback(() => {
    setWorkBookName("");
    onClose();
  }, [onClose]);

  const handleAddWorkbook = useCallback(() => {
    if (workBookName?.length > 0) {
      const formattedName = removeExtraWhiteSpaces(workBookName);
      onAddWorkbook(formattedName, closeModal, setLoading);
    }
  }, [closeModal, onAddWorkbook, workBookName]);

  const onKeyDown = event => {
    if (event.key === "Enter") {
      handleAddWorkbook();
    }
  };

  useEffect(() => {
    if (isOpen && inputElement) {
      inputElement.focus();
    }

    if (!isOpen) {
      setInputElement(null);
    }
  }, [isOpen, inputElement]);

  const handleChangeInput = useCallback(event => {
    const { value } = event.target;
    setWorkBookName(value);
  }, []);

  return (
    <ModalDialog isOpen={isOpen} title="Add Workbook" onClose={closeModal}>
      <StyledField data-cy="workbook-name-field">
        <StyledInputLabel>WorkBook Name</StyledInputLabel>
        <Input
          ref={input => {
            if (input && isOpen && !inputElement) {
              setInputElement(input);
            }
          }}
          value={workBookName}
          onChange={handleChangeInput}
          onKeyDown={onKeyDown}
          autoFocus
        />
      </StyledField>
      <StyledModalButtonsContainer>
        <StyledButton
          $autoWidth
          data-cy="create-workbook-button"
          key="submit"
          type="primary"
          onClick={handleAddWorkbook}
          disabled={loading || !workBookName}
        >
          {loading ? <LoadingOutlined /> : "Add Workbook"}
        </StyledButton>
      </StyledModalButtonsContainer>
    </ModalDialog>
  );
};

AddWorkbookModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddWorkbookModal;
