import axiosInstance from "../axiosInstance";

export const getAdminMenu = async () => {
  const response = await axiosInstance({
    url: "admin/menu/",
    method: "GET",
  });
  return response?.data;
};

export default getAdminMenu;
