import { Fragment } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import {
  StyledBreadcrumbsBtn,
  StyledBreadcrumbsContainer,
  StyledBreadcrumbsLabel,
} from "./styled";
import { StyledDividerIcon } from "../../components/Icons";

const Breadcrumbs = ({ config = [] }) => (
  <StyledBreadcrumbsContainer>
    {config?.map((item, index) => {
      const isLast = config.length - 1 === index;
      const label =
        item?.label?.length > 22
          ? `${item?.label?.substring(0, 22)}...`
          : item?.label;
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {item?.onClick ? (
            <StyledBreadcrumbsBtn onClick={item?.onClick}>
              {item?.icon && (
                <Tooltip title={item?.tooltipText || item?.label}>
                  {item?.icon}
                </Tooltip>
              )}
              {item?.label && (
                <Tooltip title={item?.tooltipText || item?.label}>
                  <StyledBreadcrumbsLabel $dark>{label}</StyledBreadcrumbsLabel>
                </Tooltip>
              )}
            </StyledBreadcrumbsBtn>
          ) : (
            <StyledBreadcrumbsLabel $bold={isLast}>
              {item?.icon && (
                <Tooltip title={item?.tooltipText || item?.label}>
                  <div>{item?.icon}</div>
                </Tooltip>
              )}
              {item?.label && (
                <Tooltip title={item?.tooltipText || item?.label}>
                  <div>{label}</div>
                </Tooltip>
              )}
            </StyledBreadcrumbsLabel>
          )}
          {!isLast && <StyledDividerIcon />}
        </Fragment>
      );
    })}
  </StyledBreadcrumbsContainer>
);

Breadcrumbs.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape()),
};

export default Breadcrumbs;
