import { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import ModalDialog from "../../../components/Modals/ModalDialog";
import { StyledField, StyledInputLabel } from "../../../components/Form/styles";
import StyledButton from "../../../components/Buttons/Button.styled";
import { StyledModalButtonsContainer } from "../../../components/Modals/ModalDialog.styled";
import { removeExtraWhiteSpaces } from "../../../utils/stringFormatters";

const ShareWorkbookModal = ({
  isOpen = false,
  workbook,
  onClose,
  onShareWorkbook,
  postShareCallBack = () => {},
}) => {
  const [emailToShare, setEmailToShare] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputElement, setInputElement] = useState(null);

  const closeModal = useCallback(
    event => {
      if (event) {
        event.stopPropagation();
      }
      setEmailToShare("");
      onClose();
    },
    [onClose],
  );

  const handleShareWorkbook = useCallback(
    event => {
      if (emailToShare?.length > 0) {
        const formattedEmail = removeExtraWhiteSpaces(emailToShare);
        onShareWorkbook(
          workbook?.uuid,
          formattedEmail,
          data => {
            closeModal(event);
            postShareCallBack(data);
          },
          setLoading,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeModal, onShareWorkbook, emailToShare, workbook?.uuid],
  );

  const handleChangeInput = useCallback(event => {
    setEmailToShare(event.target.value);
  }, []);

  const onKeyDown = event => {
    if (event.key === "Enter") {
      handleShareWorkbook();
    }
  };

  useEffect(() => {
    if (isOpen && inputElement) {
      inputElement.focus();
    }

    if (!isOpen) {
      setInputElement(null);
    }
  }, [isOpen, inputElement]);

  const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    emailToShare,
  );

  return (
    <ModalDialog isOpen={isOpen} title="Share Workbook" onClose={closeModal}>
      <StyledField data-cy="share-email-field">
        <StyledInputLabel>e-mail</StyledInputLabel>
        <Input
          value={emailToShare}
          onChange={handleChangeInput}
          onKeyDown={onKeyDown}
          onClick={event => event.stopPropagation()}
          autoFocus
          ref={input => {
            if (input && isOpen && !inputElement) {
              setInputElement(input);
            }
          }}
        />
      </StyledField>
      <StyledModalButtonsContainer>
        <StyledButton
          data-cy="share-workbook-button"
          key="submit"
          type="primary"
          onClick={event => {
            event.stopPropagation();
            handleShareWorkbook(event);
          }}
          $autoWidth
          disabled={loading || !isValid}
        >
          {loading ? <LoadingOutlined /> : "Share Workbook"}
        </StyledButton>
      </StyledModalButtonsContainer>
    </ModalDialog>
  );
};

ShareWorkbookModal.propTypes = {
  isOpen: PropTypes.bool,
  workbook: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func,
  postShareCallBack: PropTypes.func,
  onShareWorkbook: PropTypes.func.isRequired,
};

export default ShareWorkbookModal;
