// eslint-disable-next-line import/prefer-default-export
export const bulkUpdateMenuItems = [
  {
    key: "include",
    label: "Include",
  },
  {
    key: "exclude",
    label: "Exclude",
  },
  {
    key: "optional",
    label: "Optional",
  },
];
