import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { useMemo } from "react";

import {
  StyledUserName,
  StyledDropdown,
  StyledMenuItem,
  StyledMenuItemLogout,
  StyledMenuEmailItem,
  StyledMenuName,
  StyledUserMenuItem,
} from "./UserMenu.styled";
import {
  StyledAdminIcon,
  StyledDefaultAvatarIcon,
  StyledLogoutIcon,
  StyledSettingsIcon,
} from "../../../components/Icons";
import { StyledAvatarContainer } from "../../../features/Settings/styled";

const DropDownComponent = props => {
  const items = props?.props?.items;
  return (
    <StyledDropdown>
      {items?.map(item => {
        if (item?.key === "user") {
          return (
            <StyledUserMenuItem key={item?.key}>
              {item?.label?.profilePicture ? (
                <StyledAvatarContainer>
                  <img
                    src={`data:image/jpeg;base64,${item?.label?.profilePicture}`}
                    alt=""
                    width={44}
                  />
                </StyledAvatarContainer>
              ) : (
                <StyledDefaultAvatarIcon $width="44px" />
              )}
              <StyledMenuName>{item?.label?.userName}</StyledMenuName>
              <StyledMenuEmailItem>
                {item?.label?.userEmail}
              </StyledMenuEmailItem>
            </StyledUserMenuItem>
          );
        }
        if (item?.key !== "logout") {
          return (
            <StyledMenuItem
              key={item?.key}
              onClick={item?.onClick}
              type="button"
            >
              {item?.icon && item?.icon}
              {item?.label}
            </StyledMenuItem>
          );
        }

        return (
          <StyledMenuItemLogout
            key={item?.key}
            onClick={item?.onClick}
            type="button"
          >
            {item?.icon && item?.icon}
            {item?.label}
          </StyledMenuItemLogout>
        );
      })}
    </StyledDropdown>
  );
};

DropDownComponent.propTypes = {
  props: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

const UserMenu = ({ userName, logout, user }) => {
  const navigate = useNavigate();
  const userEmail = user?.email;

  const dropdownMenuItems = useMemo(() => {
    const superUserMenu = [
      {
        key: "user",
        label: {
          userName,
          userEmail,
          profilePicture: user?.profilePicture,
        },
      },
      {
        label: "Settings",
        onClick: () => navigate("/settings"),
        key: "settings",
        icon: <StyledSettingsIcon />,
      },
      {
        label: "Admin",
        onClick: () => navigate("/admin"),
        key: "admin",
        icon: <StyledAdminIcon />,
      },
      {
        label: "Logout",
        onClick: () => logout(),
        icon: <StyledLogoutIcon data-cy="header-logout" />,
        key: "logout",
      },
    ];

    if (user?.isSuperuser) return superUserMenu;

    return superUserMenu?.filter(item => item?.key !== "admin");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Dropdown
      menu={{ items: dropdownMenuItems }}
      dropdownRender={DropDownComponent}
    >
      <StyledUserName data-cy="header-user-name">
        {user?.profilePicture ? (
          <StyledAvatarContainer>
            <img
              src={`data:image/jpeg;base64,${user?.profilePicture}`}
              alt=""
              width={32}
            />
          </StyledAvatarContainer>
        ) : (
          <StyledDefaultAvatarIcon $width="32px" />
        )}
        <span>{userName}</span>
      </StyledUserName>
    </Dropdown>
  );
};

UserMenu.propTypes = {
  user: PropTypes.shape(),
  userName: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

export default UserMenu;
