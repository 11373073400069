import { Button, Input } from "antd";
import PropTypes from "prop-types";
import { useState } from "react";
import { StyledTableFilterContainer } from "../styles";

const TextFilter = ({
  onReset,
  onApply,
  onChange,
  placeholder = "Filter...",
  defaultValue = "",
  close,
  ...restProps
}) => {
  const [filter, setFilter] = useState(defaultValue);

  const handleChange = event => {
    const { value } = event.target;
    onChange?.(value);
    setFilter(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    onApply(event, filter);
    close();
  };

  const handleReset = () => {
    onReset();
    setFilter("");
    close();
  };

  return (
    <StyledTableFilterContainer>
      <Input
        value={filter}
        onChange={handleChange}
        placeholder={placeholder}
        {...restProps}
      />
      <Button onClick={handleReset}>Reset</Button>
      <Button htmlType="submit" onClick={handleSubmit}>
        Apply
      </Button>
    </StyledTableFilterContainer>
  );
};

TextFilter.propTypes = {
  onApply: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  close: PropTypes.func,
};

export default TextFilter;
