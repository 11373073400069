import { message } from "antd";
import get from "lodash/get";
import * as Sentry from "@sentry/browser";
import errorMessages from "../constants/errors";

let messagesQueue = [];

export const showMessageError = config => {
  const messageText =
    config.text?.[0]?.msg ||
    config.text ||
    get(errorMessages, `${config.code}.message`);

  if (!messageText || messagesQueue.indexOf(messageText) !== -1) {
    return;
  }
  messagesQueue.push(messageText);
  message.error({
    content: messageText,
    onClose: () => {
      messagesQueue = messagesQueue.filter(item => item !== messageText);
    },
  });
};

export const showMessageSuccess = ({ text }) => {
  if (text) {
    message.success({
      content: text,
      className: "exos-message",
    });
  }
};

export const handleErrorMessages = (error, customMessage = "") => {
  // eslint-disable-next-line no-console
  console.error(error);
  Sentry.captureException(error);

  const messageText =
    customMessage ||
    error?.response?.data?.detail?.[0]?.msg ||
    error?.response?.data?.detail ||
    error?.message;

  message.error({
    content: messageText?.toString(),
    className: "exos-message",
  });
};
