import styled, { css } from "styled-components/macro";
import {
  darkGrey2,
  darkGrey3,
  darkGrey5,
  gray4,
  gray80,
} from "../../constants/colors";

export const StyledRadioRewriteAntd = css`
  .ant-radio-checked .ant-radio-inner {
    border-color: ${darkGrey3} !important;

    &:focus {
      border-color: ${darkGrey3} !important;
    }

    &::after {
      background-color: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      transform: scale(1);
      border: 4px solid ${darkGrey3};
    }
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${darkGrey3};
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: white;
    border: 4px solid ${darkGrey3};
  }

  .ant-radio-wrapper-disabled {
    cursor: not-allowed;
  }

  .ant-radio-disabled {
    cursor: not-allowed;

    .ant-radio-inner,
    .ant-radio-input {
      cursor: not-allowed;
    }
  }

  .ant-radio-disabled + span {
    cursor: not-allowed;
    color: ${darkGrey2};
  }

  .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner {
    border-color: ${gray4};
  }
`;

export const StyledRadioGroup = styled.div`
  .ant-radio-wrapper {
    font-size: 14px;
    color: ${gray80};
    padding-right: 24px;
    font-family: "Inter", sans-serif;
    font-weight: 600;

    &:last-child {
      padding-right: 0;
    }

    span {
      padding-right: 0;
    }
  }

  ${StyledRadioRewriteAntd};
`;

export const StyledText = styled.div`
  display: inline-block;
`;

export const StyledTextAfter = styled(StyledText)`
  padding-left: 24px;
`;

export const StyledTextBefore = styled(StyledText)`
  padding-right: 24px;
`;
