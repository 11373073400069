import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  StyledPageTitle,
  StyledWelcomePageContent,
} from "../Auth/WelcomePage.styled";
import StyledButton from "../../components/Buttons/Button.styled";
import { handleErrorMessages } from "../../utils/messages";
import { testSentry } from "../../api/endpoints/users";

const TestSentry = () => {
  const navigate = useNavigate();
  const uuid = uuidv4();

  const handleTestSentry = async (id = null) => {
    try {
      await testSentry(id || uuid);
    } catch (error) {
      handleErrorMessages(error);
    }
  };

  useEffect(() => {
    handleTestSentry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledWelcomePageContent>
      <StyledPageTitle>Check Sentry with uuid: {uuid}</StyledPageTitle>
      <StyledButton
        onClick={() => handleTestSentry(`${uuid}`)}
        type="secondary"
      >
        Generate error
      </StyledButton>
      <StyledButton $autoWidth onClick={() => navigate("/")}>
        back to homepage
      </StyledButton>
    </StyledWelcomePageContent>
  );
};

export default TestSentry;
