const getConfigValue = (key, defaultValue = "") => {
  return process.env[key] || window?.config[key] || defaultValue;
};

const scopesValue = getConfigValue("REACT_APP_OKTA_SCOPES", [
  "openid",
  "profile",
  "email",
]);

const scopes =
  typeof scopesValue === "string" ? scopesValue.split(",") : scopesValue;

const defaultConfig = {
  oidc: {
    clientId: getConfigValue("REACT_APP_OKTA_CLIENT_ID", ""),
    issuer: getConfigValue("REACT_APP_OKTA_ISSUER", ""),
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes,
    pkce: true,
    disableHttpsCheck: true,
    devMode: true,
  },
  apiUri: process.env.REACT_APP_API_HOST,
  environment: getConfigValue("REACT_APP_ENVIRONMENT", "prod"),
  SENTRY_DSN:
    "https://5ae84cb71afcaeed835ba81ebdee8b87@o4506664726429696.ingest.sentry.io/4506664728068096",
};

export const OKTAConfig = () => defaultConfig.oidc;

export default defaultConfig;
