import styled, { css } from "styled-components/macro";
import { maxScreensSizesPx } from "../styled";
import {
  darkGrey4,
  darkGrey6,
  gray1,
  white,
  darkGrey3,
  gray4,
} from "../../constants/colors";

export const StyledPortfolioPageArea = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-areas: "aside content
                        footer footer";
  background-color: ${gray1};
  flex: 1 1 auto;
  position: relative;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    grid-template-columns: ${props =>
      props.hasExtraMenu
        ? "120px calc(100vw - 120px)"
        : "48px calc(100vw - 48px)"};
  }
`;

export const StyledHeaderActionsMenu = styled.div`
  display: none;

  @media (max-width: ${maxScreensSizesPx.smallPc}) {
    display: flex;
  }
`;

export const StyledHeaderActionsButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: flex-end;

  @media (max-width: ${maxScreensSizesPx.smallPc}) {
    display: none;
  }
`;

export const StyledPortfolioPageContent = styled.div`
  grid-area: content;
`;

export const StyledPortfolioPageSideArea = styled.div`
  grid-area: aside;
  display: flex;
  flex-flow: column nowrap;
  padding: 1.5rem 0 0;
  margin: 0 0 1rem 0;
  gap: 1.5rem;

  background-color: ${white};
  border-right: 1px solid ${gray4};

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    margin: 0 0 1rem 0;
  }
`;

export const StyledSideNavigationArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  background-color: ${white};
`;

export const StyledNavigationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${props =>
    props.hasExtraMenu &&
    css`
      grid-template-columns: 1fr 1fr;
    `}

  .ant-collapse {
    background-color: inherit;
    padding: 0 1rem;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 0;
  }

  .ant-collapse-item {
    border: none;

    .ant-collapse-header {
      padding: 0.65rem 0.75rem;

      &[aria-expanded="true"] {
        background-color: #e8eefb;
        border-radius: 0.5rem !important;
      }
    }
  }

  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: 0;
  }
`;

export const StyledMenuHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.75rem;
`;

export const StyledMenuTitle = styled.div`
  @media (max-width: ${maxScreensSizesPx.tablet}) {
    display: none;
  }
`;

export const StyledPortfolioPageFooter = styled.div`
  grid-area: footer;
`;

export const StyledLayoutSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem;

  ${props =>
    props.nopadding &&
    css`
      padding: 0;
    `}

  ${props =>
    props.grow &&
    css`
      flex: 1 1 auto;
    `}

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 2rem 1rem;
  }
`;

export const StyledLabelTitle = styled.p`
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${darkGrey4};

  ${props =>
    props.nomargin &&
    css`
      margin: 0;
    `}
`;

export const StyledSectionTitle = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${darkGrey6};
`;

export const StyledRow2x = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 1rem;

  ${StyledLabelTitle} {
    margin-right: 1rem;
    margin-bottom: 0;
  }
`;

export const StyledChartWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  canvas {
    width: 100% !important;
    height: auto;
  }
`;

export const StyledEmptyViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 1000px;
  margin: 0 auto;
  height: calc(100vh - 220px);
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
`;

export const StyledEmptyViewText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 42px;
  color: ${darkGrey3};
  margin-bottom: 30px;
  text-align: center;
`;

export const StyledModalSectionTitle = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${darkGrey4};
  text-transform: uppercase;
  margin: 20px 0 8px 0;
  letter-spacing: 0.38px;
  :first-child {
    margin: 0 0 8px 0;
  }
`;
