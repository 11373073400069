import styled, { css } from "styled-components/macro";
import { purple70, purple90, purple100 } from "../../constants/colors";
import { WORKBOOK_TYPE_VALUES } from "../../constants/workbooks";
import { maxScreensSizesPx } from "../../features/styled";

const StyledCaseLabel = css`
  background-color: ${purple70};
  color: ${purple100};
`;

const StyledLabel = css`
  background-color: ${purple90};
  color: ${purple70};
  svg path {
    fill: ${purple70};
  }
`;

const typeSwitcher = labelType => {
  switch (labelType) {
    case WORKBOOK_TYPE_VALUES.SELL_REPLACE:
      return StyledCaseLabel;
    case WORKBOOK_TYPE_VALUES.SINGLE_PORTFOLIO:
      return StyledLabel;
    case WORKBOOK_TYPE_VALUES.RAMP_UP:
      return StyledCaseLabel;
    case WORKBOOK_TYPE_VALUES.MULTI_PORTFOLIO:
      return StyledLabel;
    case WORKBOOK_TYPE_VALUES.MIXED:
      return StyledCaseLabel;
    default:
      return StyledCaseLabel;
  }
};

export const StyledLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 6px 14px;
  border-radius: 1rem;
  white-space: nowrap;
  ${({ type }) => typeSwitcher(type)};
`;

export const StyledLabelText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    display: none;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { StyledLabelContainer };
