import { createFilter } from "react-select";
import CreatableReactSelect from "react-select/creatable";
import AsyncReactSelect from "react-select/async";
import { debounce } from "lodash";
import { useCallback } from "react";
import {
  classPrefix,
  StyledControl,
  StyledPlaceholder,
  StyledValueContainer,
  StyledIndicatorsContainer,
  StyledMenu,
  StyledOption,
  StyledReactSelect,
} from "./Select.styled";
import getOption from "./getOption";
import SelectPropTypes from "./SelectPropTypes";

const buildSelect = (
  Component,
  {
    onChange,
    value = "",
    placeholder,
    options = [],
    filter = undefined,
    disabled,
    isOptionDisabled,
    name,
    field,
    withoutOptionsUpgrades,
    components = {},
    ...rest
  },
) => {
  let optionsProps = {
    isDisabled: disabled,
  };

  if (!withoutOptionsUpgrades) {
    const selectOptions =
      value && options.findIndex(item => item !== value)
        ? [...options, getOption(value)]
        : [...options];

    const selectedOption =
      selectOptions && selectOptions.find(opt => opt.value === value);

    optionsProps = {
      value: selectedOption || null,
      options: selectOptions,
      isDisabled: disabled || !selectOptions?.length,
    };
  }

  const handleChange = (option, meta) => {
    switch (meta.action) {
      case "pop-value":
        break;
      default:
        if (onChange) {
          onChange(option?.value, option);
        }
    }
  };

  const defaultComponents = {
    Control: StyledControl,
    Placeholder: StyledPlaceholder,
    ValueContainer: StyledValueContainer,
    IndicatorsContainer: StyledIndicatorsContainer,
    Menu: StyledMenu,
    Option: StyledOption,
  };

  return (
    <div data-cy={name} data-field={field}>
      <Component
        {...rest}
        isClearable
        classNamePrefix={classPrefix}
        onChange={handleChange}
        placeholder={placeholder}
        filterOption={filter}
        value={value}
        isOptionDisabled={isOptionDisabled}
        components={{ ...defaultComponents, ...components }}
        {...optionsProps}
      />
    </div>
  );
};

export const Select = ({
  onChange,
  value = "",
  placeholder,
  options = [],
  isOptionDisabled,
  ...rest
}) => {
  const filter = createFilter({
    ignoreCase: true,
    trim: true,
    matchFrom: "any",
  });

  return buildSelect(StyledReactSelect, {
    onChange,
    value,
    placeholder,
    isOptionDisabled,
    options,
    filter,
    ...rest,
  });
};

Select.propTypes = SelectPropTypes;

export const CreatableSelect = ({
  onChange,
  value = "",
  placeholder,
  options = [],
}) =>
  buildSelect(CreatableReactSelect, {
    onChange,
    value,
    placeholder,
    options,
  });

CreatableSelect.propTypes = SelectPropTypes;

export const AsyncSelect = ({
  onChange,
  value = "",
  placeholder,
  options = [],
  asyncCallback,
  debounceTime,
  isMulti = false,
  className,
  ...rest
}) => {
  const handleLoadOptions = useCallback(
    (inputValue, callback) => {
      asyncCallback(inputValue).then(data => callback(data));
    },
    [asyncCallback],
  );

  const loadOptions = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    debounceTime
      ? debounce(handleLoadOptions, debounceTime)
      : handleLoadOptions;
  }, [debounceTime, handleLoadOptions]);

  return buildSelect(AsyncReactSelect, {
    onChange,
    value,
    placeholder,
    isMulti,
    className,
    withoutOptionsUpgrades: true,
    defaultOptions: options,
    loadOptions,
    ...rest,
  });
};

CreatableSelect.propTypes = SelectPropTypes;
