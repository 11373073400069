import styled from "styled-components/macro";
import {
  blue,
  darkGrey2,
  darkGrey4,
  gray2,
  gray5,
  gray80,
  white,
} from "../../constants/colors";

export const StyledTableHeader = styled.div``;
export const StyledTableFooter = styled.div``;
export const StyledTableWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .ant-table-thead > tr {
    > th {
      background-color: ${white};
      border-top: 1px solid ${gray5};
      color: ${darkGrey2};
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.4;
      text-transform: uppercase;
      padding: 1rem;
    }
  }

  .ant-table-column-title {
    color: ${darkGrey2};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
  }

  .ant-table-tbody > tr {
    &:nth-child(odd) {
      background-color: ${gray2};
    }

    td {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.4;
      color: ${darkGrey4};
      padding: 0.75rem 1rem;

      &.ant-table-column-sort {
        background: inherit;
      }
    }
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    transform: none;
    top: 0;
    bottom: 0;
    height: auto;
  }

  .ant-checkbox .ant-checkbox-inner {
    border-color: transparent;
    background-color: transparent;
  }

  .ant-table-row:hover {
    cursor: pointer;
    .ant-checkbox .ant-checkbox-inner {
      border-color: ${darkGrey4} !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${blue} !important;
    background-color: ${blue} !important;
  }

  .ant-table-thead > tr:hover {
    cursor: pointer;
    .ant-checkbox .ant-checkbox-inner {
      border-color: ${darkGrey4} !important;
    }
  }

  .ant-table-thead > tr {
    .ant-checkbox-indeterminate .ant-checkbox-inner {
      border-color: ${blue} !important;
    }

    .ant-table-column-sort {
      background: inherit;

      .ant-table-column-title {
        font-weight: 700;
        color: ${gray80};
      }
    }
  }

  .ant-table-cell:first-of-type {
    padding-left: 32px;
  }

  .ant-table-cell:last-of-type {
    padding-right: 32px;
  }

  .checkbox-visible {
    .ant-checkbox .ant-checkbox-inner {
      border-color: ${darkGrey4};
    }
  }

  .ant-table-expanded-row-fixed {
    width: 100% !important;
  }
`;

export const StyledTableColHeader = styled.span`
  font-weight: 700;
  color: ${gray80};
`;
