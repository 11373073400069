import { useEffect, useState, useMemo } from "react";
import { useOutletContext } from "react-router";
import { Button, Progress, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import {
  StyledPortfolioProgress,
  StyledPortfolioProgressData,
  StyledPortfolioProgressTitle,
  StyledPortfolioProgressValue,
  StyledProgress,
  StyledProgressActions,
  StyledProgressButton,
} from "../../../styled";
import {
  StyledSuccessCheckIcon,
  StyledFailedCheckIcon,
  StyledCancelCheckIcon,
} from "../../../../components/Icons";
import {
  PROGRESS_STATES,
  PROGRESS_STATES_LABEL,
} from "../../../../constants/constraints";
import { blue } from "../../../../constants/colors";

const PortfolioBuildProgress = ({
  portfolioBuildProgress,
  getTitleDetailsByStatus,
  onPortfolioCancelBuild,
  constraintsData,
}) => {
  const { portfolioList } = useOutletContext();

  const { selectedPortfolio } = constraintsData;

  const [status, setStatus] = useState("normal");
  const [
    { portfolioBuildTitle, portfolioBuildSubTitle, portfolioBuildPercent },
    setPortfolioBuildInfo,
  ] = useState({
    portfolioBuildSubTitle: "",
    portfolioBuildTitle: "",
    portfolioBuildPercent: 0,
  });

  const isMultiPortfolio = portfolioList.length > 1;

  const builtPortfolios = useMemo(() => {
    const portfolios = portfolioBuildProgress?.portfolios || {};
    const portfoliosArr = Object.values(portfolios);

    return portfoliosArr.filter(
      portfolio => portfolio?.status === PROGRESS_STATES.finished,
    );
  }, [portfolioBuildProgress]);

  const percent = isMultiPortfolio
    ? (builtPortfolios.length / portfolioList.length) * 100 || 0
    : portfolioBuildPercent || 0;

  const buildStatus = portfolioBuildProgress?.status;
  const isInProgress = buildStatus === PROGRESS_STATES.inProgress;
  const isInQueue = buildStatus === PROGRESS_STATES.queued;
  const isError = buildStatus === PROGRESS_STATES.failed;
  const isCanceled = buildStatus === PROGRESS_STATES.cancelled;
  const isFinished = buildStatus === PROGRESS_STATES.finished;

  useEffect(() => {
    switch (true) {
      case isError: {
        setStatus("exception");
        break;
      }
      case isFinished: {
        setStatus("success");
        break;
      }
      case isInProgress: {
        setStatus("normal");
        break;
      }
      default: {
        setStatus("normal");
        break;
      }
    }
  }, [isError, isCanceled, isFinished, isInProgress]);

  useEffect(() => {
    const { subtitle, title, percentage } = getTitleDetailsByStatus({
      portfolioUUID: selectedPortfolio?.uuid,
    });

    setPortfolioBuildInfo({
      portfolioBuildTitle: title,
      portfolioBuildSubTitle: subtitle,
      portfolioBuildPercent: percentage,
    });
  }, [getTitleDetailsByStatus, selectedPortfolio?.uuid]);

  return (
    <StyledPortfolioProgress>
      {(isInProgress || isInQueue) && (
        <StyledProgressActions>
          <StyledProgress>
            {(builtPortfolios.length && isMultiPortfolio) ||
            !isMultiPortfolio ? (
              <Progress
                type="circle"
                percent={percent}
                status="normal"
                size={32}
                strokeWidth={10}
                strokeColor={blue}
                format={() =>
                  isMultiPortfolio ? "" : `${percent.toFixed(0)}%`
                }
              />
            ) : (
              <LoadingOutlined style={{ fontSize: 32, color: blue }} />
            )}
          </StyledProgress>
          <StyledProgressButton>
            <Tooltip title="Cancel portfolio generation">
              <Button
                data-cy="cancel-portfolio-generation"
                onClick={onPortfolioCancelBuild}
                type="primary"
                shape="circle"
                icon={<StyledCancelCheckIcon />}
              />
            </Tooltip>
          </StyledProgressButton>
        </StyledProgressActions>
      )}
      {isFinished && <StyledSuccessCheckIcon data-cy="success-icon" />}
      {isError && <StyledFailedCheckIcon />}
      {isCanceled && (
        <StyledCancelCheckIcon
          data-cy="cancel-icon"
          style={{ marginTop: "2px" }}
        />
      )}
      <StyledPortfolioProgressData>
        <StyledPortfolioProgressTitle>
          {portfolioBuildTitle}
        </StyledPortfolioProgressTitle>
        <StyledPortfolioProgressValue
          status={status}
          uppercase={
            PROGRESS_STATES_LABEL[buildStatus] === portfolioBuildSubTitle
          }
        >
          {portfolioBuildSubTitle}
        </StyledPortfolioProgressValue>
      </StyledPortfolioProgressData>
    </StyledPortfolioProgress>
  );
};

PortfolioBuildProgress.propTypes = {
  portfolioBuildProgress: PropTypes.shape(),
  getTitleDetailsByStatus: PropTypes.func,
  onPortfolioCancelBuild: PropTypes.func,
  constraintsData: PropTypes.shape(),
};

export default PortfolioBuildProgress;
