import styled, { css } from "styled-components/macro";
import { Skeleton } from "antd";
import {
  darkGrey5,
  gray4,
  darkGrey2,
  gray1,
  darkGrey4,
  gray6,
  white,
  gray70,
} from "../../constants/colors";
import { StyledCardTitle } from "../../components/Typography";
import { inputBorderRadius } from "../../constants/style_parameters";
import { maxScreensSizesPx } from "../styled";

export const StyledConstraintsArea = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-areas: "constraints-body constraints-body";

  ${props =>
    props.showSideMenu &&
    css`
      grid-template-areas: "constraints-side constraints-body";
    `}

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    grid-template-columns: 80px 1fr;
  }
`;

export const StyledConstraintsSideArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  grid-area: constraints-side;
  background-color: ${white};
  padding: 0.5rem 1rem;
  min-height: 100vh;
`;

export const StyledConstraintsCardTitle = styled(StyledCardTitle)`
  max-width: 1100px;
  ${({ $nomargin }) =>
    $nomargin &&
    css`
      margin: 0;
    `}
`;

export const StyledExpandContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr 1fr;
  gap: 24px;
`;

export const StyledConstraintsBodyArea = styled.div`
  grid-area: constraints-body;
  display: flex;
  flex-flow: column nowrap;
  padding: 0 2rem;
  border-left: 1px solid ${gray4};
  overflow-x: auto;
`;

export const StyledConstraintItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr 1fr;
  gap: 0px 1rem;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  position: relative;

  .ant-input-number-disabled {
    color: ${darkGrey2};
    font-style: normal;
    background: ${gray1};
    border: solid 1px ${gray4};
    font-weight: 600;
  }
`;

export const StyledConstraintItemActions = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  left: 100%;
`;

export const StyledToggleLabel = styled.span`
  margin: 0 0.5rem;
`;

export const StyledSkeletonInput = styled(Skeleton.Input)`
  width: 200px !important;
`;

export const StyledScrollingContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;

  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.01)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.01)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
`;

export const StyledActionsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const StyledConstraintTextArea = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 1 auto;
  padding: 6px 8px;
  height: 34px;
  box-sizing: border-box;

  background: ${gray4};
  border: none;
  border-radius: ${inputBorderRadius};
  box-sizing: border-box;

  color: ${darkGrey5};
  font-size: 14px;
  line-height: 1.4;

  ${props =>
    props.readOnly &&
    css`
      color: ${darkGrey2};
      font-style: normal;
      background: ${gray1};
      border: solid 1px ${gray4};
    `}
`;

export const StyledConstraintTextAreaContent = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: ${gray70};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 4px);
`;

export const StyledConstraintsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledConstraintsListItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 1 auto;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledObjectiveSelectWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 250px 200px 200px;
  gap: 1rem;
  align-items: center;
`;

export const StyledObjectiveListWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  margin: 0 0 1rem;
`;

export const StyledObjectiveItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 260px 200px 150px 50px;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

export const StyledLoadingWrapper = styled.div`
  padding-left: 9px;
`;

export const StyledWrapper = styled.div`
  margin: 1rem 0;
`;

export const StyledEmptyView = styled.div`
  color: ${darkGrey2};
  font-size: 16px;
  line-height: 24px;
`;

export const StyledPortfolioName = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: ${darkGrey4};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;

  svg {
    height: 18px;
    width: 18px;
    path {
      fill: ${gray6};
    }
`;

export const StyledPortfolioListContainer = styled.div`
  width: 500px;
  margin-bottom: 75px;
`;

export const StyledSpinContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  right: 50%;
  margin: 0 auto;
`;

export const StyledObjectivesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const StyledSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  min-height: 80px;
  .ant-skeleton-input {
    width: 50% !important;
    max-width: 500px;
  }
`;

export const StyledPortfolioEnabledStateSkeleton = styled(Skeleton.Button)`
  width: 53px;
  margin-right: 16px;
  line-height: 24px;
  height: 24px;
`;
