export const HOME = "/";
export const ADMIN = "/admin";
export const USERS = "users";
export const ACTIVITY = "activity";
export const WORKBOOKS = "/workbooks";
export const WORKBOOK = "/workbooks/:workbook_id";
export const UPLOAD_FILE = "upload-file";
export const FILTERING = "filtering";
export const CONSTRAINTS = "constraints";
export const PORTFOLIO = "portfolio";
export const RESULTS = "results";
export const RESULTS_ITEM = "results/:result_id";
export const RESULTS_TRADE_SUMMARY = "trade_summary";
export const OKTA_CALLBACK = "/implicit/callback";
export const LOGIN = "login";
export const CONTRIBUTORS = "contributors";
export const TEST_SENTRY = "test-sentry";
export const SETTINGS = "settings";
