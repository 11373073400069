// Color Palette

// Dark neutrals
export const darkGrey6 = "#151C2D";
export const darkGrey5 = "#30384A";
export const darkGrey4 = "#495265";
export const darkGrey3 = "#657289";
export const darkGrey2 = "#848D9F";
export const darkGrey1 = "#A0AABE";

// Light neutrals
export const gray8 = "#9098AA";
export const gray6 = "#C1C7D0";
export const gray5 = "#E4E7ED";
export const gray4 = "#EDEFF4";
export const gray3 = "#F8F9FC";
export const gray2 = "#F5F5F8";
export const gray1 = "#FAFAFC";
export const white = "#FFFFFF";
export const gray00 = "#D9D9D9";
export const gray10 = "#F9FAFC";
export const gray20 = "#EBECEF";
export const gray30 = "#E3E4E8";
export const gray40 = "#C7C9D1";
export const gray50 = "#AEB2C2";
export const gray60 = "#8A91A6";
export const gray70 = "#697187";
export const gray80 = "#444C61";
export const gray90 = "#2F3648";
export const gray100 = "#151C2D";
export const purple70 = "#EDE7F6";
export const purple80 = "#B39DDB";
export const purple90 = "#7E57C2";
export const purple100 = "#4C3474";

// Primary colors
export const blue = "#2669F1";
export const blue20 = "#88A9EB";
export const blue70 = "#EEF2FD";
export const blue80 = "#0095FF";
export const blue90 = "#1C5EE2";
export const blue100 = "#14429E";
export const blueLight = "#E1F1FB";
export const blueHighlight = "#E8EEFB";
export const aliceBlue = "#E6F7FF";
export const darkBlue = "#1C5EE3";
export const blueWhite = "#DDE6F9";
export const green = "#008A4C";
export const green10 = "#DAEFE2";
export const green40 = "#7FCDA2";
export const green70 = "#008A4C";
export const green100 = "#00532E";
export const greenWhite = "#E1EDE7";
export const gold = "#BE950D";
export const whiteYellow = "#F0EBD9";
export const yellow = "#F6C02D";
export const yellow70 = "#FCF5DF";
export const yellow80 = "#F3D983";
export const yellow90 = "#E9B91D";
export const yellow100 = "#806610";
export const black = "#000000";

export const goldLight = "#e9ba1d";
export const specialBlue = "#e0e9fb";
export const red70 = "#FFE5EB";
export const red80 = "#EB9794";
export const red90 = "#DB403D";
export const red100 = "#8E2A28";

export const lagoon70 = "#E0F2F1";
export const lagoon80 = "#80CBC4";
export const lagoon90 = "#009688";
export const lagoon100 = "#005A52";

export const orange70 = "#FFF3E0";
export const orange80 = "#FFD699";
export const orange90 = "#FB8C00";
export const orange100 = "#975400";

export const pink70 = "#FCE4EC";
export const pink80 = "#F48FB1";
export const pink90 = "#EC407A";
export const pink100 = "#971440";

// Accent colors
export const accentRed = "#E92454";
export const accentRosy = "#E96181";
export const lightRed = "#FFCBD7";
export const accentViolet = "#8574CA";
export const lightViolet = "#DFDCF7";
export const accentNavy = "#000E8A";
export const accentGreen = "#4A655D";
export const accentLightGreen = "#E4E8E7";
export const accentBlue = "#32617F";
export const accentLightBlue = "#E0E7EC";
export const accentDarkViolet = "#40299B";
export const accentLightViolet = "#E2DFF0";
export const accentBrown = "#B9551B";
export const accentLightBrown = "#F5E6DD";
export const accentGold = "#8D7524";
export const accentLightGold = "rgba(141, 117, 36, 0.2)";

// Tags
export const tagExosTextColor = "#2A5ABE";

// Shadows
export const lightGreyShadow = "rgba(101, 114, 137, 0.25)";

// Table row colors
export const redLight = "#FFEBEE";
export const greenLight = "#E8F5E9";

// Chart colors
export const chartGreen = "#21BC97";
export const chartGreenLight = "#D1F4E6";
export const chartOrange = "#FB8C00";
export const chartOrangeLight = "#FFD6C9";
export const chartViolet = "#9E63FF";
export const chartVioletLight = "#DAD6FD";
export const chartAccentGreen = "#9AE56B";
export const chartAccentGreenLight = "#D1F4E6";
export const chartYellow = "#FFD600";
export const chartYellowLight = "#F9EABB";
export const chartBlue = "#3FAEFF";
export const chartBlueLight = "#C6EDFF";
export const chartBrown = "#A98174";
export const chartDarkBlue = "#1A5FE5";
export const chartPink = "#FF62D3";
export const chartGrey = "#B6B6B6";
