import { useCallback, useState } from "react";
import StyledButton from "../../../components/Buttons/Button.styled";
import AddWorkbookModal from "./AddWorkbookModal";

const SubHeaderMenu = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const openAddModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <>
      <StyledButton
        type="primary"
        $bold
        data-cy="open-workbook-modal"
        onClick={openAddModal}
      >
        Create Workbook
      </StyledButton>
      <AddWorkbookModal
        isOpen={modalOpen}
        onClose={closeModal}
        title="Add Workbook"
      />
    </>
  );
};

export default SubHeaderMenu;
